import React, { PureComponent } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import sumBuy from 'lodash/sumBy'
import map from 'lodash/map'
import get from 'lodash/get'
import orderBy from 'lodash/orderBy'
import * as Cookies from 'js-cookie'

class DashboardStateWisePhysicalProgressGraph extends PureComponent {

  componentDidMount(){
    this.initGraph() 
  }

  componentDidUpdate(){
    this.initGraph() 
  }

    initGraph() {
      const theme = Cookies.get('theme') ? JSON.parse(Cookies.get('theme')) : {}

      // Themes begin
      am4core.useTheme(am4themes_animated);
      // Themes end

      // Create chart instance
      let chart = am4core.create(this.props.graphId, am4charts.XYChart);

      // Title
      // let title = chart.titles.push(new am4core.Label());
      // title.text = "Research tools used by students";
      // title.fontSize = 25;
      // title.marginBottom = 15;

      // Add data
      // chart.data = [{
      //   "category": "Search engines",
      //   "negative1": -0.1,
      //   "negative2": -0.9,
      //   "positive1": 5,
      //   "positive2": 94
      // }];
      const orderedData = orderBy(this.props.graphData,obj=>+Number(obj.sacRec.replace(/,/g, ''))+Number(obj.sacYet.replace(/,/g, ''))+Number(obj.undCon.replace(/,/g, '')), ['desc'])
      // console.log('orderedData---------->',orderedData)
      chart.data=map(orderedData,obj=>{
         const cmpld= 0//Number(obj.cmpld.replace(/,/g, ''))
        const sacRec= Number(obj.sacRec.replace(/,/g, ''))
        const sacYet= Number(obj.sacYet.replace(/,/g, ''))
        const undCon= Number(obj.undCon.replace(/,/g, ''))
        const cmpldBtNtOcpd= 0//Number(obj.cmpldBtNtOcpd.replace(/,/g, ''))
        // console.log('ovj=------------<', obj)
        return {
          ...obj,
          // stateName:`${obj.stateName} ${get(obj,'ProjectCode') ? '': '-'+ (cmpld+sacRec+sacYet+undCon+cmpldBtNtOcpd).toLocaleString('en-in')}`
          stateName:`${obj.stateName} ${get(obj,'ProjectCode') ? '': '-'+ (obj.totalSanctioned).toLocaleString('en-in')}`
        }
      })

      // Create axes
      let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "stateName";
      categoryAxis.dataFields.value1 = "undCon";
      categoryAxis.tooltipText = "Houses Sanctioned"
      categoryAxis.tooltipPosition = "pointer"
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.inversed = true;
      categoryAxis.renderer.minGridDistance = 20;
      categoryAxis.renderer.axisFills.template.disabled = false;
      categoryAxis.renderer.axisFills.template.fillOpacity = 0;
      


      let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
      valueAxis.min = -100;
      valueAxis.max = 100;
      valueAxis.renderer.minGridDistance = 50;
      valueAxis.renderer.ticks.template.length = 5;
      valueAxis.renderer.ticks.template.disabled = false;
      valueAxis.renderer.ticks.template.strokeOpacity = 1;
      valueAxis.renderer.labels.template.adapter.add("text", function(text) {
        return text;
      })

      var leftSideText = valueAxis.axisRanges.create();
        leftSideText.value = -90;
        leftSideText.endValue = 0;
        leftSideText.label.text = "⟵  Details of houses yet to be grounded  ⟶";
        leftSideText.label.color = "#000000";
        // leftSideText.label.fill = chart.colors.list[0];
        leftSideText.label.dy = 20;
        leftSideText.label.fontWeight = '600';
        leftSideText.label.fontSize = 14;
        leftSideText.grid.strokeOpacity = 0;
        // leftSideText.grid.stroke = male.stroke;

        var rightSideText = valueAxis.axisRanges.create();
        rightSideText.value = 90;
        rightSideText.endValue = 10;
        rightSideText.label.text = "⟵  Progress of grounded houses  ⟶";
        // rightSideText.label.fill = chart.colors.list[1];
        rightSideText.label.dy = 20;
        rightSideText.label.fontWeight = '600';
        rightSideText.label.fontSize = 14;
        rightSideText.grid.strokeOpacity = 0;
        // rightSideText.grid.stroke = female.stroke;

      // Legend
      chart.legend = new am4charts.Legend();
      chart.legend.position = "top";
      chart.legend.markers.template.width = 15
      chart.legend.markers.template.height = 15
      // Use only absolute numbers
      chart.numberFormatter.numberFormat = "#.#s";

      // Create series
      function createSeries(field, name, color,extraValue) {
        // console.log('field, name, color,extraValue--->>', {field, name, color,extraValue})
        //eslint-disable-next-line
        const totalValue = sumBuy(chart.data, obj=> Number(obj[extraValue].replace(/\,/g,''))||0)
        const exactTotalValue = totalValue ? totalValue.toLocaleString('en-in') :'-'
        let series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueX = field;
        series.dataFields.categoryY = "stateName";
        series.stacked = true;
        series.stroke = color;
        series.fill = color;
        // series.hidden = true;
        series.dataFields.extraValueX = extraValue
        series.name =name;
        series.columns.template.tooltipText = "{name}: [bold]{extraValueX}";
        // series.columns.template.url = "https://www.google.com/search?q=hello";
        series.columns.template.events.on("hit", function(ev) {
          // console.log("clicked on ",ev.target.dataItem.dataContext.ProjectCode);
          if(ev.target.dataItem.dataContext.ProjectCode){
            // window.location.href=window.location.origin+'/project-details/'+ev.target.dataItem.dataContext.ProjectCode
            window.open(window.location.origin+'/project-details/'+ev.target.dataItem.dataContext.ProjectCode)
          }
        }, this);
        // series.legendSettings.valueText = name;
        // series.legendSettings.itemValueText = "{valueY}";

        series.legendSettings.labelText = name +' ( '+exactTotalValue+' )';
        // series.dataFields.cmpldValueY = "cmpld"
        // console.log('{sacRecValueY}',
        // '{sacYetValueY}',
        // '{undConValueY}',
        // '{cmpldValueY}')
        // const tooltipExtValue = field === 'sanctionRecently' ? sacRec : field === 'sanctionYet' ? sacYet : field === 'underConstruction' ? undCon : cmpld


        let label = series.bullets.push(new am4charts.LabelBullet());
        label.label.text = "{valueX}%";
        label.label.fill = am4core.color("#fff");
        label.label.strokeWidth = 0;
        label.label.truncate = false;
        label.label.hideOversized = true;
        label.locationX = 0.5;
        return series;
      }
      // var series = chart.series.push(new am4charts.ColumnSeries());
      // series.tooltipText = "hello"

      // let interfaceColors = new am4core.InterfaceColorSet();
      // let positiveColor = interfaceColors.getFor("positive");
      // let negativeColor = interfaceColors.getFor("negative");
      // createSeries("negative2", "Unlikely", negativeColor.lighten(0.5));
      // createSeries("negative1", "Never", negativeColor);
      // createSeries("positive1", "Sometimes", positiveColor.lighten(0.5));
      // createSeries("positive2", "Very often", positiveColor);

      // createSeries("sanctionRecently", "Not due for Completion.", '#03a9f4','sacRec');
      createSeries("sanctionRecently", "Not Grounded but sanction with in 12 months", get(theme,'color3') ? get(theme,'color3') :'#03a9f4','sacRec');
      createSeries("sanctionYet", "Not Grounded but sanctioned beyond 12 months", get(theme,'color14') ? get(theme,'color14') :'#f44336','sacYet');
      createSeries("underConstruction", "Under Construction", get(theme,'color10') ? get(theme,'color10') :'#ffc107','undCon');
      createSeries("completedButNotOccupied", "Completed but not Occupied", get(theme,'color12') ? get(theme,'color12') :'#8bc34a','cmpldBtNtOcpd');
      createSeries("completed", "Completed and Occupied", get(theme,'color11') ? get(theme,'color11') :'#45a951','cmpld');

      // let series = chart.series.push(new am4charts.ColumnSeries());

      chart.legend.events.on("layoutvalidated", function(event){
        chart.legend.itemContainers.each((container)=>{
          if(container.dataItem.dataContext.name === "SanctionedRecently"){
            container.toBack();
          }
        })
      })

    }

    render() {
      // console.log('this.props.graphData---->>>',this.props.graphData)

        return <div id={this.props.graphId} className={this.props.graphId} style={{width: '100%', height: this.props.height}} />;
    }
}

export default DashboardStateWisePhysicalProgressGraph;