import React from "react";
import { Table, Button, Row, Col, Tabs, Tab } from "react-bootstrap";
import {
  Breadcrumb,
  Loader,
  SelectBox,
  Input,
  DateInput,
  CustomModal,
} from "../components";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Formik, Field } from "formik";
import { BASE_URL } from "../store/constant";
import { SanctionOrderFormSchema,SanctionOrderFormClssCNA } from "../utils";
import Axios from "axios";
import map from "lodash/map";
import get from "lodash/get";
import sumBy from "lodash/sumBy";
import values from "lodash/values";
import find from "lodash/find";
import filter from "lodash/filter";
import styled from "styled-components";
// import ReactToPrint from "react-to-print";
import { toast } from "react-toastify";
import moment from "moment";
import isEmpty from "lodash/isEmpty";
import _ from "lodash"

const Wrapper = styled.section`
  position: relative;

  & .nav.nav-tabs {
    a {
      width: 50%;
      background: #ececec;
      font-size: 16px;
      text-align: center;
    }
    a.active {
      background: #fff;
      border-bottom: 1px solid #ccc;
    }
  }
  & .tab-content {
    padding: 20px 30px;
  }

  & .react-modal .react-modal-body {
    width: 90%;
    min-width: 310px;
    max-width: 1360px;
  }
`;

const NoData = styled.div`
  height: 200px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const GroupBtn = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  & button {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    font-size: 12px;
    padding: 0;
    text-align: center;
    margin: 0;
    & i {
      margin-right: 0px;
    }
  }
`;

const Tr = styled.tr`
  & td > .form-group,
  & td > * > .form-group {
    margin-bottom: 0px !important;
  }
  & input[type="number"] {
    text-align: right;
    padding: 1px;
    font-size: 12px;
  }
  .btn-sm {
    padding: 6px 14px;
    font-size: 10px;
    margin: 0;
    width: 100%;
  }
`;
const FileLink = styled.div`
  display: block;
  & a {
    display: block;
    text-align: left;
    font-size: 10px;
    margin-top: 5px;
  }
`;

class ClssCNASanctionorder extends React.Component {
  state = {
    isLoading: false,
    statesList: [],
    dataList: [],
    permission: [],
    userType: ''
  };

  componentDidMount = async () => {
    const { data } = await Axios.get(`${BASE_URL}/states`);
    const { data: permission } = await Axios.get(`${BASE_URL}/dashboard/permission`)
    const { data: recalledBlanceAll } = await Axios.get(`${BASE_URL}/exces/recalledBlanceAllData`);
     //console.log('recalledBlance---->',recalledBlanceAll);
   
    // console.log('permission----->>', get(permission,'data.allowColumn./entry/sanction-utilization'))
    this.setState({
      statesList: map(data, (o) => ({
        label: o.statename,
        value: o.statecode,
      })),
      recalledBlanceAll,
      permission: get(permission, 'data.allowColumn./entry/sanction-utilization', []),
      userType: get(permission, 'data.type', ''),
      isLoadingState: false,
    });
  };

  render() {
    const { isLoading, dataList, statesList, permission, userType,recalledBlanceAll } = this.state;

    const CNAWise=[{  label: 'NHB',
      value: 'NHB'},{label: 'HUDCO',
      value: 'HUDCO'},{label: 'SBI',
      value: 'SBI'}]
    const isAdmin = userType === 'admin' ? true : false
    // console.log('permission----->>', isAdmin, permission)
    return (
      <Wrapper>
            <Row>
              <Formik
                enableReinitialize={true}
                validationSchema={SanctionOrderFormClssCNA}
                initialValues={{
                  openEditModal: false,
                  editData: {
                    isSubmitting: false,
                    CLSS: "CLSS-E",
                    stateCode: "",
                    scheme: "PMAY-U",
                    component: "CLSS",
                    source: "",
                    sanctionOrderNo: "",
                    sanctionOrderDate: "",
                    cnaClssSanctionOrderPDF: "",//releaseOrderPDF
                    releaseAmount: "",
                    matchId: "",
                    head: "",
                    isMatched: 0,
                    activity: "",
                    remarks: "",
                  },
                  isSubmitting: false,
                  CLSS: "CLSS",
                  stateCode: "",
                  scheme: "PMAY-U",
                  component: "CLSS",
                  source: "",
                  sanctionOrderNo: "",
                  sanctionOrderDate: "",
                  cnaClssSanctionOrderPDF: "",//releaseOrderPDF
                  releaseAmount: "",
                  head: "",
                  isMatched: 0,
                  activity: "",
                  remarks: "",
                }}
                onSubmit={async (input, { resetForm, setFieldValue }) => {
                  setFieldValue("isSubmitting", true);
                  let formData = new FormData();
                  formData.append("cnaClssSanctionOrderPDF", input.cnaClssSanctionOrderPDF);//releaseOrderPDF
                  formData.append("stateCode", input.stateCode);
                  formData.append("scheme", input.scheme);
                  formData.append("component", input.component);
                  formData.append("source", input.source);
                  formData.append("sanctionOrderNo", input.sanctionOrderNo);
                  formData.append("sanctionOrderDate", input.sanctionOrderDate);
                  formData.append("releaseAmount", input.releaseAmount);
                  formData.append("head", input.head);
                  formData.append("activity", input.activity);
                  formData.append("isMatched", input.isMatched);
                  formData.append("remarks", input.remarks);

                 // console.log('formData---->',formData);

                  await Axios.post(
                    `${BASE_URL}/sanction-utilization-order/clss-sanction`,
                    formData
                  )
                    .then(async (res) => {
                      toast.success("Form Submitted Successfully.");
                      setFieldValue("isSubmitting", false);
                      setFieldValue("source", "");
                      setFieldValue("sanctionOrderNo", "");
                      setFieldValue("releaseAmount", "");
                      setFieldValue("sanctionOrderDate", "");
                      setFieldValue("cnaClssSanctionOrderPDF", "");//releaseOrderPDF
                      setFieldValue("head", "");
                      setFieldValue("activity", "");
                      setFieldValue("isMatched", 0);
                      setFieldValue("remarks", "");
                      const { data: dataList } = await Axios.get(
                        `${BASE_URL}/sanction-utilization-order/clss-sanction/${get(
                          input,
                          "stateCode"
                        )}`
                      );
                      this.setState({ dataList });
                    })
                    .catch((err) => {
                      toast.error(
                        get(err.response, "statusText") ||
                        "Something went wrong."
                      );
                      setFieldValue("isSubmitting", false);
                    });
                }}
              >
                {(formikBag) => {
                  //console.log('formikBag-999---->', formikBag.values)
                  let filterdDataList = [];
                  let gtrlsAmt=0;
                  if (
                    formikBag.values.scheme &&
                    formikBag.values.component &&
                    formikBag.values.source
                  ) {
                    filterdDataList = filter(
                      dataList,
                      (obj) =>
                        get(obj, "scheme") === formikBag.values.scheme &&
                        get(obj, "component") === formikBag.values.component &&
                        get(obj, "source") === formikBag.values.source
                    );
                  } else if (
                    formikBag.values.scheme &&
                    !formikBag.values.component &&
                    !formikBag.values.source
                  ) {
                    filterdDataList = filter(
                      dataList,
                      (obj) => get(obj, "scheme") === formikBag.values.scheme
                    );
                  } else if (
                    formikBag.values.scheme &&
                    formikBag.values.component &&
                    !formikBag.values.source
                  ) {
                    filterdDataList = filter(
                      dataList,
                      (obj) =>
                        get(obj, "scheme") === formikBag.values.scheme &&
                        get(obj, "component") === formikBag.values.component
                    );
                  } else if (
                    !formikBag.values.component &&
                    !formikBag.values.scheme &&
                    formikBag.values.source
                  ) {
                    filterdDataList = filter(
                      dataList,
                      (obj) => get(obj, "source") === formikBag.values.source
                    );
                  } else {
                    filterdDataList = dataList;
                  }
                  // console.log('formikBag---------->>>',formikBag.values)
                  // console.log('filterdDataList---------->>>',filterdDataList)
                  return (
                    <form
                      onSubmit={(event) => {
                        event.preventDefault();
                        formikBag.validateForm().then((errors) => {
                          const errKeys = Object.keys(errors);
                          if (errKeys.length > 0 && errors) {
                            const errMessage =
                              errors[errKeys[0]]["value"] || errors[errKeys[0]];
                            toast.error(errMessage, {
                              position: toast.POSITION.BOTTOM_CENTER,
                            });
                            // alert(errMessage);
                            return null;
                          } else {
                            return formikBag.handleSubmit();
                          }
                        });
                      }}
                    >
                      <Row>
                        <Col md={3} sm={3} xs={3} className="pr-0">
                          <SelectBox
                            label="Select CNA"
                            options={CNAWise}
                            isrequiredicon={'true'}
                            isClearable={true}
                            value={find(
                              CNAWise,
                              (obj) =>
                                obj.value === get(formikBag.values, "stateCode")
                            )}
                            onChange={async (e) => {
                              formikBag.setFieldValue(
                                "stateCode",
                                get(e, "value")
                              );
                              this.setState({ isLoading: true, dataList: [] });
                              if (e) {
                                //console.log('eeeeeeeee---------->',e);
                                //  const {data:san} = await Axios.get(`${BASE_URL}/uc-submission/sanction-no/${get(e,'value')}`)
                                const { data: dataList } = await Axios.get(
                                  `${BASE_URL}/sanction-utilization-order/clss-sanction/${get(
                                    e,
                                    "value"
                                  )}`
                                );
                                this.setState({ dataList, isLoading: false });
                                // formikBag.setFieldValue('sanctionOrderOptionStateWise',map(san, op=>({label: get(op,'sanctionOrderNo'), value: get(op,'sanctionOrderNo')})))
                              }
                              // console.log({cityData,data})
                            }}
                          />
                        </Col>

                        <Col md={3} sm={3} xs={6} className="pr-0">
                          <SelectBox
                            isrequiredicon={'true'}
                            label="Scheme"
                            options={[
                              { label: "PMAY-U", value: "PMAY-U" },
                              // { label: "RAY", value: "RAY" },
                              // { label: "JnNURM", value: "JnNURM" },
                            ]}
                            value={[
                              { label: get(formikBag.values, "scheme") || "" },
                              { value: get(formikBag.values, "scheme") || "" },
                            ]}
                            isClearable={true}
                            onChange={(e) => {
                              formikBag.setFieldValue(
                                "scheme",
                                get(e, "value")
                              );
                              formikBag.setFieldValue("component", "");
                              formikBag.setFieldValue("source", "");
                            }}
                          />
                        </Col>

                        <Col md={3} sm={3} xs={6} className="pr-0">
                          <SelectBox
                            label="Component"
                            isrequiredicon={'true'}
                            options={
                              get(formikBag.values, "scheme") === "PMAY-U"
                                ? [
                                  { label: "CLSS", value: "CLSS" },
                                 
                                ]
                                : get(formikBag.values, "scheme") === "RAY"
                                  ? [{ label: "RAY", value: "RAY" }]
                                  : get(formikBag.values, "scheme") === "JnNURM"
                                    ? [
                                      { label: "BSUP", value: "BSUP" },
                                      { label: "IHSDP", value: "IHSDP" },
                                    ]
                                    : []
                            }
                            value={[
                              {
                                label: get(formikBag.values, "component") || "",
                              },
                              {
                                value: get(formikBag.values, "component") || "",
                              },
                            ]}
                            isClearable={true}
                            onChange={(e) => {
                              formikBag.setFieldValue(
                                "component",
                                get(e, "value")
                              );
                            }}
                          />
                        </Col>
                        <Col md={3} sm={3} xs={6}>
                          <SelectBox
                            isrequiredicon={'true'}
                            label="Source"
                            options={[
                              { label: "Budget", value: "Budget" },
                              { label: "EBR", value: "EBR" },
                              // { label: "Adjustment", value: "Adjustment" },
                            ]}
                            value={[
                              { label: get(formikBag.values, "source") || "" },
                              { value: get(formikBag.values, "source") || "" },
                            ]}
                            isClearable={true}
                            onChange={(e) => {
                              formikBag.setFieldValue(
                                "source",
                                get(e, "value")
                              );
                            }}
                          />
                        </Col>
                        <Col md={3} sm={3} xs={6} className="pr-0">
                          <Field name="sanctionOrderNo">
                            {({ field, form }) => {
                              return (
                                <Input
                                  label="Sanction No."
                                  isrequiredicon={'true'}
                                  value={get(
                                    form.values,
                                    "sanctionOrderNo",
                                    ""
                                  )}
                                  {...field}
                                />
                              );
                            }}
                          </Field>
                        </Col>
                        <Col md={3} sm={3} xs={6}>
                          <DateInput
                            isrequiredicon={'true'}
                            maxDate={new Date()}
                            label="Date"
                            selected={get(
                              formikBag.values,
                              `sanctionOrderDate`,
                              ""
                            )}
                            onChange={(e) => {
                              formikBag.setFieldValue("sanctionOrderDate", e);
                            }}
                          />
                        </Col>
                        <Col md={3} sm={3} xs={6}>
                          <Field name="releaseAmount">
                            {({ field, form }) => {
                              return (
                                <Input
                                  step="0000.1"
                                  isrequiredicon={'true'}
                                  type="number"
                                  label="Subsidy Amount  (Rs. in lakh)"
                                  value={get(form.values, "releaseAmount", "")}
                                  {...field}
                                />
                              );
                            }}
                          </Field>
                        </Col>
                        <Col md={3} sm={3} xs={6}>
                          <Input
                            label="Release Order PDF."
                            // value={get(form.values, "releaseOrderPDF", "")}releaseOrderPDF
                            type="file"
                            accept="application/pdf, image/*"
                            onChange={async (e) => {
                              formikBag.setFieldValue(
                                "cnaClssSanctionOrderPDF",
                                e.target.files[0]
                              );
                            }}
                          />
                        </Col>
                        <Col md={3} sm={3} xs={6}>
                          <SelectBox
                            label="Category"
                            options={[
                               { label: "EWS-LIG", value: "EWS-LIG" },
                              { label: "MIG", value: "MIG" },

                              // { label: "GEN", value: "GEN" },
                              // { label: "SCSP", value: "SCSP" },
                              // { label: "TSP", value: "TSP" },
                              // { label: "AOE", value: "AOE" },
                              // { label: "TBC", value: "TBC" },
                            ]}
                            value={[
                              { label: get(formikBag.values, "head") || "" },
                              { value: get(formikBag.values, "head") || "" },
                            ]}
                            isClearable={true}
                            onChange={(e) => {
                              formikBag.setFieldValue("head", get(e, "value"));
                            }}
                          />
                        </Col>
                        
                        <Col md={3} sm={3} xs={6}>
                          <Field name="remarks">
                            {({ field, form }) => {
                              return (
                                <Input
                                  label="Remarks"
                                  value={get(form.values, "remarks", "")}
                                  {...field}
                                />
                              );
                            }}
                          </Field>
                        </Col>
                      </Row>

                      <Row
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Button
                          style={{ width: 90 }}
                          variant="warning"
                          type="button"
                          size={"sm"}
                        >
                          <i className="feather icon-delete"></i> Clear
                        </Button>
                        <Button
                          style={{ width: 160 }}
                          disabled={formikBag.values.isSubmitting}
                          type="submit"
                          size={"sm"}
                        >
                          <i
                            className={
                              formikBag.values.isSubmitting
                                ? "fa fa-spinner fa-spin"
                                : "feather icon-upload-cloud"
                            }
                          ></i>
                          Submit
                        </Button>
                      </Row>
                      {/* rELEASE oRDER TABLE */}
                      <section
                        style={{ width: "100%", paddingTop: 50, position: 'relative' }}
                        ref={(el) => (this.printTableRef = el)}
                      >
                        <Table
                          striped
                          bordered

                          className="bg-c-white tableStickyHeader"
                          style={{ fontSize: 12 }}
                        >
                          <thead
                            className="label label-warning text-white text-center"
                            style={{ backgroundColor: "#002b49" }}
                          >
                            <tr>
                              <th style={{ width: 40 }} className="center">
                                Sr.<br/>No.
                              </th>
                              <th style={{ minWidth: 100 }} className="center">
                                CNA
                              </th>
                              <th>Scheme</th>
                              <th>Component</th>
                              <th>Category</th>
                              <th>Source</th>
                              <th>Sanction No.</th>
                              <th>Date</th>
                              <th>Subsidy<br/> Amount<br/><small style={{ color: '#FF9800' }}>(₹ in Lakh)</small></th>
                            
                              {/* <th>Activity</th> */}
                              {/* <th>Recovered<br/> Amount<br/><small style={{ color: '#FF9800' }}>(₹ in Lakh)</small></th> */}
                              <th>Remarks</th>
                              {/* <th>Amount Released<br/> in Project<br/><small style={{ color: '#FF9800' }}>(₹ in Lakh)</small></th> */}
                              <th>Release Order</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {map(filterdDataList, (obj, i) => {

                             // console.log('obj---->',obj);
                              //console.log('recalledBlanceAll------->',recalledBlanceAll);
                              const FltrecalledMatchIdWise=_.filter(recalledBlanceAll,flt=>flt.sanctionPdfMatchId===obj.matchId);
                              const AllReadyRecalledBlance=_.sumBy(FltrecalledMatchIdWise,o=>Number(o.recaalledAmount))
                             // console.log(obj.matchId,'AllReadyRecalledBlance--->',AllReadyRecalledBlance);
                              const rlsAmt = get(obj, "releaseAmount")
                                ? Number(
                                  Number(get(obj, "releaseAmount")).toFixed(4)
                                )
                                : 0;

                                gtrlsAmt +=rlsAmt;
                              const prjRlsAmt = get(obj, "projectReleaseAmount")
                                ? Number(
                                  Number(
                                    get(obj, "projectReleaseAmount")
                                  ).toFixed(2)
                                )
                                : 0;


                              const misMatchValue =
                                Math.abs(prjRlsAmt - rlsAmt) > 0.5 &&
                                (
                                  get(obj, "activity", "") || ""
                                ).toLowerCase() === "projects";
                              return (
                                <tr
                                  key={i}
                                  style={{
                                    background: misMatchValue ? "#ffa2a2" : "",
                                  }}
                                >
                                  <td style={{ widtd: 40 }} className="center">
                                    {i + 1}
                                  </td>
                                  <td>{get(obj, "cnaName")}
                                  </td>
                                  <td>{get(obj, "scheme")}</td>
                                  <td>{get(obj, "component")}</td>
                                  <td>
                                    {get(obj, "head") ? get(obj, "head") : "-"}
                                  </td>
                                  <td>{get(obj, "source")}</td>
                                  <td style={{ width: 250, minWidth: 250 }}>
                                    {get(obj, "sanctionOrderNo")}
                                  </td>
                                  <td>
                                    {get(obj, "releaseDate")
                                      ? moment(get(obj, "releaseDate")).format(
                                        "DD-MMM-YYYY"
                                      )
                                      : "-"}
                                  </td>
                                  <td style={{ textAlign: "right" }}>
                                    {get(obj, "releaseAmount")
                                      ? Number(
                                        Number(
                                          get(obj, "releaseAmount")
                                        ).toFixed(4)
                                      )
                                      : "-"}
                                  </td>
                                

                                  <td>
                                    {get(obj, "remarks")
                                      ? get(obj, "remarks")
                                      : "-"}
                                  </td>
                                
                                  <td style={{ textAlign: "center" }}>
                                    {get(obj, "releaseOrderPDF") &&
                                      get(obj, "releaseOrderPDF") !== "null" ? 
                                      <a
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      href={`${BASE_URL}/drmc/cnaClssSanctionOrderPDF/${get(obj, "releaseOrderPDF")}`}
                                    >
                                      Download
                                      </a>
                                     : (
                                      "-"
                                    )}
                                  </td>
                                  <td
                                    style={{
                                      padding: 2,
                                      minWidth: 135,
                                      width: 135,
                                    }}
                                  >
                                    <GroupBtn>
                                      {isAdmin || permission.indexOf('CLSSCNAEdit') !== -1 ? <Button
                                        title="Edit"
                                        variant="outline-primary"
                                        // disabled={
                                        //   Number(
                                        //     get(obj, "projectReleaseAmount")
                                        //   )
                                        //     ? true
                                        //     : false
                                        // }
                                        onClick={() => {
                                          formikBag.setFieldValue(
                                            "openEditModal",
                                            true
                                          );
                                          formikBag.setFieldValue(
                                            "editData",
                                            obj
                                          );
                                        }}
                                      >
                                        <i className="feather icon-edit"></i>
                                      </Button> : ''}
                                      {isAdmin || permission.indexOf('CLSSCNADelete') !== -1 ? <Button
                                        title="Delete"
                                        variant="outline-danger"
                                        // disabled={true}
                                        disabled={
                                          Number(
                                            get(obj, "projectReleaseAmount")
                                          )
                                            ? true
                                            : false
                                        }
                                        onClick={async () => {
                                          const MySwal = withReactContent(Swal);
                                          MySwal.fire({
                                            title: "Are you sure?",
                                            text:
                                              "Once deleted, you will not be able to recover this file!",
                                            type: "warning",
                                            showCloseButton: true,
                                            showCancelButton: true,
                                          }).then(async (willDelete) => {
                                            if (willDelete.isConfirmed) {
                                              await Axios.delete(
                                                `${BASE_URL}/sanction-utilization-order/clss-sanction/${get(
                                                  obj,
                                                  "matchId"
                                                )}`
                                              )
                                                .then(async (res) => {
                                                  const {
                                                    data: dataList,
                                                  } = await Axios.get(
                                                    `${BASE_URL}/sanction-utilization-order/clss-sanction/${get(
                                                      obj,
                                                      "stateCode"
                                                    )}`
                                                  );
                                                  this.setState({ dataList });
                                                  return MySwal.fire(
                                                    "",
                                                    "Your file has been deleted!",
                                                    "success"
                                                  );
                                                })
                                                .catch((err) => {
                                                  toast.error(
                                                    get(
                                                      err.response,
                                                      "statusText"
                                                    ) || "Something went wrong."
                                                  );
                                                  formikBag.setFieldValue(
                                                    "editData",
                                                    {
                                                      ...get(
                                                        formikBag.values,
                                                        "editData"
                                                      ),
                                                      isSubmitting: false,
                                                    }
                                                  );
                                                });
                                            } else {
                                              return MySwal.fire(
                                                "",
                                                "Your file is safe!",
                                                "error"
                                              );
                                            }
                                          });
                                        }}
                                      >
                                        <i className="feather icon-trash-2"></i>
                                      </Button> : ''}
                                     
                                    
                                    </GroupBtn>
                                  </td>
                                </tr>
                                
                               
                              );
                            
                            })}
                             <tr style={{ background: '#383e37', color: '#fff', fontWeight: 600, height: '25px' }} className='text-right'>
                                  <td style={{ textAlign: 'center' }} colSpan={8}>Total</td>
                                 <td style={{ background: '#0f482e' }}>{gtrlsAmt}</td>
                                  <td colSpan={3}></td>
                              </tr>
                          </tbody>
                        </Table>
                        {isLoading && isEmpty(filterdDataList) ? (
                          <Loader style={{ height: 200 }} />
                        ) : isEmpty(filterdDataList) ? (
                          <NoData>Data not Available OR change state.</NoData>
                        ) : (
                          ""
                        )}
                      </section>

                      {/* Sanction Edit Form */}
                      <CustomModal
                        title={`Edit CLSS-CNA: `}
                        centered
                        showModal={formikBag.values.openEditModal}
                        animation="zoomIn"
                        onClose={() =>
                          formikBag.setFieldValue("openEditModal", false)
                        }
                      >
                        <Row>
                          <Col md={4} sm={4} xs={4} className="pr-0">
                            <SelectBox
                              label="Select CNA"
                              options={CNAWise}
                              isrequiredicon={'true'}
                              isClearable={true}
                              // isDisabled={true}
                              value={find(
                                CNAWise,
                                (obj) =>
                                obj.value ===
                                  get(formikBag.values, "editData.stateCode")

                                
                              )}
                              onChange={async (e) => {
                                formikBag.setFieldValue("editData", {
                                  ...get(formikBag.values, "editData"),
                                  stateCode: get(e, "value"),
                                });
                              }}
                            />
                          </Col>

                          <Col md={4} sm={4} xs={6} className="pr-0">
                            <SelectBox
                              isrequiredicon={'true'}
                              label="Scheme"
                              // isDisabled={true}
                              options={[
                                { label: "PMAY-U", value: "PMAY-U" },
                                // { label: "PMAY-U", value: "PMAY-U" },
                                // { label: "RAY", value: "RAY" },
                                // { label: "JnNURM", value: "JnNURM" },
                              ]}
                              value={[
                                {
                                  label:
                                    get(formikBag.values, "editData.scheme") ||
                                    "",
                                },
                                {
                                  value:
                                    get(formikBag.values, "editData.scheme") ||
                                    "",
                                },
                              ]}
                              isClearable={true}
                              onChange={(e) => {
                                formikBag.setFieldValue("editData", {
                                  ...get(formikBag.values, "editData"),
                                  scheme: get(e, "value"),
                                  component: "",
                                });
                              }}
                            />
                          </Col>

                          <Col md={4} sm={4} xs={4}>
                            <SelectBox
                              label="Component"
                              isrequiredicon={'true'}
                              // isDisabled={true}
                              options={
                                get(formikBag.values, "editData.scheme") ===
                                  "PMAY-U"
                                  ? [
                                    { label: "CLSS", value: "CLSS" },
                                    // { label: "AHP", value: "AHP" },
                                    // { label: "BLC", value: "BLC" },
                                    // { label: "ISSR", value: "ISSR" },
                                    // { label: "OTHER", value: "OTHER" },
                                  ]
                                  : get(formikBag.values, "editData.scheme") ===
                                    "RAY"
                                    ? [{ label: "RAY", value: "RAY" }]
                                    : get(formikBag.values, "editData.scheme") ===
                                      "JnNURM"
                                      ? [
                                        { label: "BSUP", value: "BSUP" },
                                        { label: "IHSDP", value: "IHSDP" },
                                      ]
                                      : []
                              }
                              value={[
                                {
                                  label:
                                    get(
                                      formikBag.values,
                                      "editData.component"
                                    ) || "",
                                },
                                {
                                  value:
                                    get(
                                      formikBag.values,
                                      "editData.component"
                                    ) || "",
                                },
                              ]}
                              isClearable={true}
                              onChange={(e) => {
                                formikBag.setFieldValue("editData", {
                                  ...get(formikBag.values, "editData"),
                                  component: get(e, "value"),
                                });
                              }}
                            />
                          </Col>
                          <Col md={4} sm={4} xs={4} className="pr-0">
                            <SelectBox
                              isrequiredicon={'true'}
                              // isDisabled={true}
                              label="Source"
                              options={[
                                { label: "Budget", value: "Budget" },
                                { label: "EBR", value: "EBR" },
                              ]}
                              value={[
                                {
                                  label:
                                    get(formikBag.values, "editData.source") ||
                                    "",
                                },
                                {
                                  value:
                                    get(formikBag.values, "editData.source") ||
                                    "",
                                },
                              ]}
                              isClearable={true}
                              onChange={(e) => {
                                formikBag.setFieldValue("editData", {
                                  ...get(formikBag.values, "editData"),
                                  source: get(e, "value"),
                                });
                              }}
                            />
                          </Col>
                          <Col md={4} sm={4} xs={4} className="pr-0">
                            <Input
                              label="Sanction No."
                              isrequiredicon={'true'}
                              // disabled={true}
                              value={get(
                                formikBag.values,
                                "editData.sanctionOrderNo",
                                ""
                              )}
                              onChange={(e) => {
                                formikBag.setFieldValue("editData", {
                                  ...get(formikBag.values, "editData"),
                                  sanctionOrderNo: get(e, "target.value"),
                                });
                              }}
                            />
                          </Col>
                          <Col md={4} sm={4} xs={4}>
                            <DateInput
                              isrequiredicon={'true'}
                              // disabled={true}
                              label="Date"
                              selected={get(
                                formikBag.values,
                                `editData.releaseDate`,
                                ""
                              )}
                              onChange={(e) => {
                                formikBag.setFieldValue("editData", {
                                  ...get(formikBag.values, "editData"),
                                  releaseDate: e,
                                });
                              }}
                            />
                          </Col>
                          <Col md={4} sm={4} xs={4} className="pr-0">
                            <Input
                              step="0000.1"
                              isrequiredicon={'true'}
                              type="number"
                              // disabled={true}
                              label="Subsidy Amount (Rs. in lakh)"
                              value={get(
                                formikBag.values,
                                "editData.releaseAmount",
                                ""
                              )}
                              onChange={(e) => {
                                formikBag.setFieldValue("editData", {
                                  ...get(formikBag.values, "editData"),
                                  releaseAmount: get(e, "target.value"),
                                });
                              }}
                            />
                          </Col>
                          <Col md={8} sm={8} xs={8}>
                            <Input
                              label="Release Order PDF."
                              type="file"
                              accept="application/pdf, image/*"
                              onChange={async (e) => {
                                formikBag.setFieldValue("editData", {
                                  ...get(formikBag.values, "editData"),
                                  cnaClssSanctionOrderPDF: e.target.files[0],
                                });
                              }}
                            />
                          </Col>
                          <Col md={3} sm={3} xs={6}>
                            <SelectBox
                              label="Category"
                              // isDisabled={true}
                              options={[
                                { label: "EWS-LIG", value: "EWS-LIG" },
                                { label: "MIG", value: "MIG" },
                              ]}
                              value={[
                                {
                                  label:
                                    get(formikBag.values, "editData.head") ||
                                    "",
                                },
                                {
                                  value:
                                    get(formikBag.values, "editData.head") ||
                                    "",
                                },
                              ]}
                              isClearable={true}
                              onChange={(e) => {
                                formikBag.setFieldValue("editData", {
                                  ...get(formikBag.values, "editData"),
                                  head: get(e, "value"),
                                });
                              }}
                            />
                          </Col>
                          
                          <Col md={3} sm={3} xs={6}>
                            <Input
                              label="Remarks"
                              // disabled={true}
                              value={get(
                                formikBag.values,
                                "editData.remarks",
                                ""
                              )}
                              onChange={(e) => {
                                formikBag.setFieldValue("editData", {
                                  ...get(formikBag.values, "editData"),
                                  remarks: get(e, "target.value"),
                                });
                              }}
                            />
                          </Col>
                        </Row>

                        <Row
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            paddingBottom: 15,
                          }}
                        >
                          <Button
                            style={{ width: 90 }}
                            variant="warning"
                            type="button"
                            size={"sm"}
                            onClick={() =>
                              formikBag.setFieldValue("openEditModal", false)
                            }
                          >
                            <i className="feather icon-delete"></i> Close
                          </Button>
                          <Button
                            style={{ width: 160 }}
                            disabled={get(
                              formikBag.values,
                              "editData.isSubmitting"
                            )}
                            onClick={async () => {
                              const { editData } = formikBag.values;
                              // console.log("editData.------>>", editData);
                              formikBag.setFieldValue("editData", {
                                ...editData,
                                isSubmitting: true,
                              });
                              let formData = new FormData();
                              formData.append(
                                "id",
                                get(formikBag.values, "editData.id")
                              );
                              formData.append(
                                "stateCode",
                                get(formikBag.values, "editData.stateCode")
                              );
                              formData.append(
                                "scheme",
                                get(formikBag.values, "editData.scheme")
                              );
                              formData.append(
                                "component",
                                get(formikBag.values, "editData.component")
                              );
                              formData.append(
                                "source",
                                get(formikBag.values, "editData.source")
                              );
                              formData.append(
                                "sanctionOrderNo",
                                get(
                                  formikBag.values,
                                  "editData.sanctionOrderNo"
                                )
                              );
                              formData.append(
                                "releaseDate",
                                get(formikBag.values, "editData.releaseDate")
                              );
                              formData.append(
                                "releaseAmount",
                                get(formikBag.values, "editData.releaseAmount")
                              );
                              formData.append(
                                "cnaClssSanctionOrderPDF",
                                get(
                                  formikBag.values,
                                  "editData.cnaClssSanctionOrderPDF"
                                )
                              );
                              formData.append(
                                "head",
                                get(formikBag.values, "editData.head")
                              );
                              formData.append(
                                "matchId",
                                get(formikBag.values, "editData.matchId")
                              );
                              formData.append(
                                "activity",
                                get(formikBag.values, "editData.activity")
                              );
                              formData.append(
                                "isMatched",
                                get(formikBag.values, "editData.isMatched")
                              );
                              formData.append(
                                "remarks",
                                get(formikBag.values, "editData.remarks")
                              );

                              if (
                                !get(formikBag.values, "editData.stateCode")
                              ) {
                                toast.error("State is required field.");
                                formikBag.setFieldValue("editData", {
                                  ...editData,
                                  isSubmitting: false,
                                });
                              } else if (
                                !get(formikBag.values, "editData.scheme")
                              ) {
                                toast.error("Scheme is required field.");
                                formikBag.setFieldValue("editData", {
                                  ...editData,
                                  isSubmitting: false,
                                });
                              } else if (
                                !get(formikBag.values, "editData.component")
                              ) {
                                toast.error("Component is required field.");
                                formikBag.setFieldValue("editData", {
                                  ...editData,
                                  isSubmitting: false,
                                });
                              } else if (
                                !get(formikBag.values, "editData.source")
                              ) {
                                toast.error("Source is required field.");
                                formikBag.setFieldValue("editData", {
                                  ...editData,
                                  isSubmitting: false,
                                });
                              } else if (
                                !get(
                                  formikBag.values,
                                  "editData.sanctionOrderNo"
                                )
                              ) {
                                toast.error(
                                  "Sanction Order No. is required field."
                                );
                                formikBag.setFieldValue("editData", {
                                  ...editData,
                                  isSubmitting: false,
                                });
                              } else if (
                                !get(formikBag.values, "editData.releaseDate")
                              ) {
                                toast.error("Date is required field.");
                                formikBag.setFieldValue("editData", {
                                  ...editData,
                                  isSubmitting: false,
                                });
                              } else if (
                                !get(formikBag.values, "editData.releaseAmount")
                              ) {
                                toast.error(
                                  "Subsidy Amount is required field."
                                );
                                formikBag.setFieldValue("editData", {
                                  ...editData,
                                  isSubmitting: false,
                                });
                              } else {
                                await Axios.put(
                                  `${BASE_URL}/sanction-utilization-order/clss-sanction`,
                                  formData
                                )
                                  .then(async (res) => {
                                    toast.success("Data Updated Successfully.");
                                    formikBag.setFieldValue("editData", {});
                                    const { data: dataList } = await Axios.get(
                                      `${BASE_URL}/sanction-utilization-order/clss-sanction/${get(
                                        formikBag.values,
                                        "editData.stateCode"
                                      )}`
                                    );
                                    this.setState({ dataList });
                                    formikBag.setFieldValue(
                                      "openEditModal",
                                      false
                                    );
                                  })
                                  .catch((err) => {
                                    toast.error(
                                      get(err.response, "statusText") ||
                                      "Something went wrong."
                                    );
                                    formikBag.setFieldValue("editData", {
                                      ...get(formikBag.values, "editData"),
                                      isSubmitting: false,
                                    });
                                  });
                              }
                            }}
                            type="button"
                            size={"sm"}
                          >
                            <i
                              className={
                                get(formikBag.values, "editData.isSubmitting")
                                  ? "fa fa-spinner fa-spin"
                                  : "feather icon-upload-cloud"
                              }
                            ></i>
                            Submit
                          </Button>
                        </Row>
                      </CustomModal>
                    </form>
                  );
                }}
              </Formik>
            </Row>
    

      </Wrapper>
    );
  }
}

export default ClssCNASanctionorder;
