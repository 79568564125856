import * as Yup from "yup";
import { isEmail,isValidPassword } from "../utils/index";
// import moment from 'moment'
import get from 'lodash/get'

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/


export const loginSchema = Yup.object().shape({
  userName: Yup.string()
    .nullable()
    .trim()
    .required("Username or Email is Required"),
    // .test("email", "Please enter valid email", email => {
    //   if (!email) {
    //     return true;
    //   }
    //   return isEmail(email);
    // }),
  password: Yup.string()
    .nullable()
    .trim()
    .required("Password field is Required"),
    userCaptcha: Yup.string()
    .nullable()
    .trim()
    .required("Captcha field is Required"),

});

export const SignUpFormSchema = Yup.object().shape({
  designation : Yup.string()
  .nullable()
  .trim()
  .required("Designation field is Required"),
  jurisdiction: Yup.string()
  .nullable()
  .trim()
  .required("Jurisdiction field is Required"),
  organisation: Yup.string()
  .nullable()
  .trim()
  .required("Organisation field is Required"),
  firstName: Yup.string()
    .nullable()
    .trim()
    .required("First Name field is Required"),
  lastName: Yup.string()
    .nullable()
    .trim()
    .required("Last Name field is Required"),
    gender: Yup.string()
    .nullable()
    .trim()
    .required("Gender field is Required"),
  email: Yup.string()
    .nullable()
    .trim()
    .required("Email is Required")
    .test("email", "Email is Not in Correct Format", email => {
      if (!email) {
        return true;
      }
      return isEmail(email);
    }),
  mobileNumber: Yup.string()
    .nullable()
    .trim()
    .matches(phoneRegExp, 'Phone number is not valid')
    .when("salutationType", (val, schema) => {
      return val === "M_2fS"
        ? schema
        : schema
            .required("Mobile Number is Required")
            .min(10, "Mobile Number Cannot be less than 10 Digits")
            .max(10, "Mobile Number Cannot be greater than 10 Digits");
    }),
  password: Yup.string()
    .nullable()
    .trim()
    .required("Password field is Required")
    .min(8, "Password Can't be less than 8 Digits"),


});

export const PAOLetterSchema = Yup.object().shape({
  EVRDataList:Yup.array().of(Yup.object().shape({ 
    value: Yup.string()
    .nullable()
    .trim()
    .required("Sanction No./FTS field is Required"), 
    releaseAmount: Yup.number()
    .required("Release Amount not found."),
    UCReceivedCumulative: Yup.number().max(Yup.ref('releaseAmount'), "UC Received Cumulative should be lower than or equal to Release Amount. ")
    .required("UC Received Cumulative field is Required"),
    // releaseAmount: Yup.number().max(Yup.ref('UCReceivedCumulative'), "Cannot Exceed Max Capacity"), 
    currentUCDate: Yup.string()
    .nullable()
    .trim()
    .required("UC Date field is Required"), 
    UCFileNumber: Yup.string()
    .nullable()
    .trim()
    .required("UC File Number field is Required"), 
    UCFile: Yup.string()
    .nullable()
    .trim()
    .required("UCFile field is Required"), 
  }))
});


export const UpdateUserFormSchema = Yup.object().shape({
  firstName: Yup.string()
    .nullable()
    .trim()
    .required("First Name field is Required"),
  mobileNumber: Yup.string()
    .nullable()
    .trim()
    .when("salutationType", (val, schema) => {
      return val === "M_2fS"
        ? schema
        : schema
            .required("Mobile Number is Required")
            .min(10, "Mobile Number Cannot be less than 10 Digits")
            .max(10, "Mobile Number Cannot be greater than 10 Digits");
    }),
    jurisdiction: Yup.string()
    .nullable()
    .trim()
    .required("Jurisdiction field is Required"),
    role: Yup.string()
    .nullable()
    .trim()
    .required("Role field is Required"),
    email: Yup.string()
    .nullable()
    .trim()
    .required("Email is Required")
    .test("email", "Email is Not in Correct Format", email => {
      if (!email) {
        return true;
      }
      return isEmail(email);
    }),
});


export const profilePasswordSchema = Yup.object().shape({
  newPassword: Yup.string()
    .nullable()
    .trim()
    .required("New Password field is Required")
    .test("repeatPassword", "New Password is Not in Correct Format", pass => {
      if (!pass) {
        return true;
      }
      return isValidPassword(pass);
    }),
    repeatPassword: Yup.string()
    .nullable()
    .trim()
    .required("Repeat Password field is Required")
    .test("repeatPassword", "Repeat Password is Not in Correct Format", pass => {
      if (!pass) {
        return true;
      }
      return isValidPassword(pass);
    }).oneOf([Yup.ref('newPassword'), null], "Passwords don't match !"),
});

export const profileUpdateSchema = Yup.object().shape({
  firstName: Yup.string()
    .nullable()
    .trim()
    .required("First Name field is Required"),
    phoneOfficial: Yup.string()
    .nullable()
    .trim()
    .when("salutationType", (val, schema) => {
      return val === "M_2fS"
        ? schema
        : schema
            .min(6, "Phone Official Cannot be less than 10 Digits")
            .max(11, "Phone Official Cannot be greater than 10 Digits");
    }),
    phoneResidential: Yup.string()
    .nullable()
    .trim()
    .when("salutationType", (val, schema) => {
      return val === "M_2fS"
        ? schema
        : schema
            .min(6, "Phone Residential Cannot be less than 10 Digits")
            .max(11, "Phone Residential Cannot be greater than 10 Digits");
    }),
    organisation: Yup.string()
    .nullable()
    .trim()
    .required("Organisation field is Required"),
    profession: Yup.string()
    .nullable()
    .trim()
    .required("Profession field is Required"),
    gender: Yup.string()
    .nullable()
    .trim()
    .required("Gender field is Required"),
    otherEmail: Yup.string()
    .nullable()
    .trim()
    .test("email", "Other Email is Not in Correct Format", email => {
      if (!email) {
        return true;
      }
      return isEmail(email);
    }),

});

export const CreateUserFormSchema = Yup.object().shape({
  firstName: Yup.string()
    .nullable()
    .trim()
    .required("First Name field is Required"),
  mobileNumber: Yup.string()
    .nullable()
    .trim()
    .when("salutationType", (val, schema) => {
      return val === "M_2fS"
        ? schema
        : schema
            .required("Mobile Number is Required")
            .min(10, "Mobile Number Cannot be less than 10 Digits")
            .max(10, "Mobile Number Cannot be greater than 10 Digits");
    }),
    jurisdiction: Yup.string()
    .nullable()
    .trim()
    .required("Jurisdiction field is Required"),
    role: Yup.string()
    .nullable()
    .trim()
    .required("Role field is Required"),
    email: Yup.string()
    .nullable()
    .trim()
    .required("Email is Required")
    .test("email", "Email is Not in Correct Format", email => {
      if (!email) {
        return true;
      }
      return isEmail(email);
    }),
    password: Yup.string()
    .nullable()
    .trim()
    .required("Password field is Required")
});



export const ProjectFormSchema = Yup.object().shape({

    StateCode: Yup.string()
    .nullable()
    .trim()
    .required("State field is Required"),
    DistrictCode: Yup.string()
    .nullable()
    .trim()
    .required("District field is Required"),
    CityCode: Yup.string()
    .nullable()
    .trim()
    .required("City field is Required"),
    Scheme: Yup.string()
    .nullable()
    .trim()
    .required("Scheme field is Required"),
    Component: Yup.string()
    .nullable()
    .trim()
    .required("Component field is Required"),
    CSMCNo: Yup.string()
    .nullable()
    .trim()
    .required("CSMC No. field is Required"),
    CSMCDate: Yup.string()
    .nullable()
    .trim()
    .required("CSMC Date field is Required"),
    ProjectTitle: Yup.string()
    .nullable()
    .trim()
    .required("Project Title field is Required"),
    ProjectCost: Yup.string()
    .nullable()
    .trim()
    .required("Project Cost field is Required"),
    CentralAssistApproved: Yup.string()
    .nullable()
    .trim()
    .required("Central Assist field is Required"),

    HousesSanctionedNew: Yup.string()
    .nullable()
    .trim()
    .required("Houses Sanctioned Share field is Required"),

    Status: Yup.string()
    .nullable()
    .trim()
    .required("Status field is Required"),

    // StatusDate: Yup.string()
    // .nullable()
    // .trim()
    // .required("Status Date field is Required")
    // .test("StatusDate", "Status Date field is Required", date => {
    //   // console.log('date------>>><><><>>>',date)
    //   if (!date) {
    //     return true;
    //   }
    //   return moment(date).isValid();
    // }),

    
    Remark: Yup.string()
    .nullable()
    .trim()
    .when("Status", (val, schema) => {
      return val === "Active"
        ? schema
        : schema
            .required("Remark field is Required")
    }),
    // email: Yup.string()
    // .nullable()
    // .trim()
    // .required("Email is Required")
    // .test("email", "Email is Not in Correct Format", email => {
    //   if (!email) {
    //     return true;
    //   }
    //   return isEmail(email);
    // })
    
});


export const ExcessPoolFormSchema = Yup.object().shape({
  orderNo: Yup.string()
    .nullable()
    .trim()
    .required("Order No field is Required"),
  orderDate: Yup.string()
    .nullable()
    .trim()
    .required("Order Date field is Required"),
    projectPoolList: Yup.array().of(Yup.object().shape({}).test(
      'is-optional',
      'Min 1 field  is required in ST, SC or Others',
      (obj) => {
        if(get(obj,'st') || get(obj,'others') || get(obj,'sc')){
          return true 
        }else{
          return false 
        }
      }
    )),
});

export const ReleaseFormSchema = Yup.object().shape({
  projectInfo: Yup.object({
    value: Yup.string().nullable().trim().required('Select Project first.')
  }),
  releaseList: Yup.array().of(Yup.object().shape({
    source: Yup.string().nullable().trim().required('Source field is required'),
    installment: Yup.string().nullable().trim().required('Installment field is required'),
    sanctionOrderNo: Yup.string().nullable().trim().required('Sanction No. field is required'),
    releaseDate: Yup.string().nullable().trim().required('Date field is required'),
    head: Yup.string().nullable().trim().required('Head field is required'),
    releaseAmount: Yup.string().nullable().trim().required('Release amount field is required'),
  })
  )
});

export const UCSubmissionFormSchema = Yup.object().shape({
  projectInfo: Yup.object({
    value: Yup.string().nullable().trim().required('Select Project first.')
  }),
  ucList: Yup.array().of(Yup.object().shape({
    ucType: Yup.string().nullable().trim().required('UC Type field is required'),
    installment: Yup.string().nullable().trim().required('Installment field is required'),
    sanctionOrderNo: Yup.string().nullable().trim().required('Sanction No. field is required'),
    letterNo: Yup.string().nullable().trim().required('Letter No field is required'),
    UCDate: Yup.string().nullable().trim().required('UC Date field is required'),
    UCAmount: Yup.string().nullable().trim().required('UC Amount field is required'),
  })
  )
});

// export const TechnologyMappingFormSchema = Yup.object().shape({
//   technologyList: Yup.array().of(Yup.object().shape({
//     houses: Yup.string().nullable().trim().required('Houses field is required'),
//     technology: Yup.string().nullable().trim().required('Technology No. field is required'),
//   })
//   )
// });

export const ClassificationFormSchema = Yup.object().shape({
  Description: Yup.string()
  .nullable()
  .trim()
  .required("Description field is Required"),
  
  DescriptionCode: Yup.string()
  .nullable()
  .trim()
  .required("Description Code is Required"), 

  Applicable: Yup.string()
  .nullable()
  .trim()
  .required("Applicable is Required")
});

export const ConstituencySchema = Yup.object().shape({
    
  StateCode: Yup.string()
  .nullable()
  .trim()
  .required("State Name   is Required"),

  ParlConstName: Yup.string()
  .nullable()
  .trim()
  .required("Parliament Constituency Name is Required"),

  ParlConstNo: Yup.string()
  .nullable()
  .trim()
  .required("Parliament Const. No. is Required"),

  NameShittingMp: Yup.string()
  .nullable()
  .trim()
  .required("Name of Shitting MP is Required"),


});

export const AssemblySchema = Yup.object().shape({
    
  StateCode: Yup.string()
  .nullable()
  .trim()
  .required("State Name   is Required"),

  ParlConstNo: Yup.string()
  .nullable()
  .trim()
  .required("Parliament Constituency No is Required"),

  AssemConstName: Yup.string()
  .nullable()
  .trim()
  .required("Parliament Constituency Name is Required"),

  AssemConstCode: Yup.string()
  .nullable()
  .trim()
  .required("Assembly Const. No. is Required"),

  NameShittingMLA: Yup.string()
  .nullable()
  .trim()
  .required("Name of Shitting MLA is Required"),

});

export const CitySchema = Yup.object().shape({
    
  StateCode: Yup.string()
  .nullable()
  .trim()
  .required("State Name is Required"),

  DistrictCode: Yup.string()
  .nullable()
  .trim()
  .required("District Code is Required"),
  SubDistCode: Yup.string()
  .nullable()
  .trim()
  .required("SubDist. Code is Required"),

  SubDistName: Yup.string()
  .nullable()
  .trim()
  .required("SubDist. Name is Required"),

  CityName: Yup.string()
  .nullable()
  .trim()
  .required("City Name is Required"),

  CityCode: Yup.string()
  .nullable()
  .trim()
  .required("City Code is Required"),

  AssemConstCode: Yup.string()
  .nullable()
  .trim()
  .required("Assembly Const. Name is Required"),

});

export const SchemeMasterFormSchema = Yup.object().shape({
  name: Yup.string()
  .nullable()
  .trim()
  .required("Scheme Name field is Required"),
  shortName: Yup.string()
  .nullable()
  .trim()
  .required("Short Name field is Required"), 
  scheme: Yup.string()
  .nullable()
  .trim()
  .required("Scheme field is Required"), 
});

export const DistrictMasterFormSchema = Yup.object().shape({
  StateCode: Yup.string()
  .nullable()
  .trim()
  .required("State field is Required"),
  DistrictName: Yup.string()
  .nullable()
  .trim()
  .required("District Name field is Required"), 
  DistShortName: Yup.string()
  .nullable()
  .trim()
  .required("Dist. Short Name field is Required"), 
  DistrictCode: Yup.string()
  .nullable()
  .trim()
  .required("District Code field is Required"), 
 });

 export const ImplementingAgencyFormSchema = Yup.object().shape({
  StateCode: Yup.string()
  .nullable()
  .trim()
  .required("State field is Required"),
  ImplementingAgency: Yup.string()
  .nullable()
  .trim()
  .required("Implementing Agency Name field is Required"),  
 });

 export const DocumentMasterFormSchema = Yup.object().shape({
  documentType: Yup.string()
  .nullable()
  .trim()
  .required("Document Type field is Required"),
 });

export const SubComponentMasterFormSchema = Yup.object().shape({
  name: Yup.string()
  .nullable()
  .trim()
  .required("Scheme Name field is Required"),
  shortName: Yup.string()
  .nullable()
  .trim()
  .required("Short Name field is Required"), 
  scheme: Yup.string()
  .nullable()
  .trim()
  .required("Scheme field is Required"), 
  component: Yup.string()
  .nullable()
  .trim()
  .required("Component field is Required"), 
  carpetArea: Yup.string()
  .nullable()
  .trim()
  .required("Carpet Area field is Required"), 
});


export const MaterialFormSchema = Yup.object().shape({
  material: Yup.string()
  .nullable()
  .trim()
  .required("Material Name field is Required"), 
  structure: Yup.string()
  .nullable()
  .trim()
  .required("Structure field is Required"), 
  technology: Yup.string()
  .nullable()
  .trim()
  .required("Technology field is Required"), 
  quantity: Yup.string()
  .nullable()
  .trim()
  .required("Quantity field is Required"), 
});


export const TechnologyFormSchema = Yup.object().shape({
  name: Yup.string()
  .nullable()
  .trim()
  .required("Name field is Required"), 
  code: Yup.string()
  .nullable()
  .trim()
  .required("Code field is Required"), 
  
});

export const SanctionOrderFormSchema = Yup.object().shape({
  stateCode: Yup.string()
    .nullable()
    .trim()
    .required("State field is Required"),
    scheme: Yup.string()
    .nullable()
    .trim()
    .required("Scheme field is Required"),
    component: Yup.string()
    .nullable()
    .trim()
    .required("Component field is Required"),
    source: Yup.string().nullable().trim().required('Source field is required'),
    sanctionOrderNo: Yup.string().nullable().trim().required('Sanction No. field is required'),
    sanctionOrderDate: Yup.string()
    .nullable()
    .trim()
    .required("Date field is Required"),
    releaseAmount: Yup.string().nullable().trim().required('Release amount field is required'),
    // releaseOrderPDF: Yup.string()
    // .nullable()
    // .trim()
    // .required("Release Order PDF field is Required"),

});

export const SanctionOrderFormClssCNA = Yup.object().shape({
  stateCode: Yup.string()
    .nullable()
    .trim()
    .required("CNA field is Required"),
    scheme: Yup.string()
    .nullable()
    .trim()
    .required("Scheme field is Required"),
    component: Yup.string()
    .nullable()
    .trim()
    .required("Component field is Required"),
    source: Yup.string().nullable().trim().required('Source field is required'),
    sanctionOrderNo: Yup.string().nullable().trim().required('Sanction No. field is required'),
    sanctionOrderDate: Yup.string()
    .nullable()
    .trim()
    .required("Date field is Required"),
    releaseAmount: Yup.string().nullable().trim().required('Release amount field is required'),
    // releaseOrderPDF: Yup.string()
    // .nullable()
    // .trim()
    // .required("Release Order PDF field is Required"),

});
export const SanctionOrderFormMalicious= Yup.object().shape({
  stateCode: Yup.string()
    .nullable()
    .trim()
    .required("Malicious field is Required"),
    sanctionOrderNo: Yup.string().nullable().trim().required('Sanction No. field is required'),
    sanctionOrderDate: Yup.string()
    .nullable()
    .trim()
    .required("Date field is Required"),
    releaseAmount: Yup.string().nullable().trim().required('Release amount field is required'),
    // releaseOrderPDF: Yup.string()
    // .nullable()
    // .trim()
    // .required("Release Order PDF field is Required"),

});

export const SanctionOrderFormBmtpc = Yup.object().shape({
  stateCode: Yup.string()
    .nullable()
    .trim()
    .required("BMTPC field is Required"),
    installment: Yup.string()
    .nullable()
    .trim()
    .required("Installment field is Required"),
    sanctionOrderNo: Yup.string().nullable().trim().required('Sanction No. field is required'),
    sanctionOrderDate: Yup.string()
    .nullable()
    .trim()
    .required("Date field is Required"),
    releaseAmount: Yup.string().nullable().trim().required('Release amount field is required'),
    // releaseOrderPDF: Yup.string()
    // .nullable()
    // .trim()
    // .required("Release Order PDF field is Required"),

});


export const MinuteFormSchema = Yup.object().shape({
    scheme:Yup.object().shape({
      value: Yup.string().nullable().trim().required('Scheme field is required'),
      label: Yup.string().nullable().trim().required('Scheme field is required'),
    }),
    SanctionOrderNo: Yup.string().nullable().trim().required('Sanction No. field is required'),
    CSMCNo: Yup.string().nullable().trim().required('CSMC No. field is required'),
    CSMCDate: Yup.string()
    .nullable().trim()
    .required("Date field is Required"),
});