import React from 'react';
// import Modal from "react-animated-modal";
import {Card, Modal} from 'react-bootstrap'
// import Aux from "../../hoc/_Aux";

class CustomModal extends React.Component {

    render() {
        const {style,headerClassName='bg-c-blue',props,size='lg',fullscreen=false,className}=this.props
        return (
            // <Aux>
                <Modal
                show={this.props.showModal}
                onHide={this.props.onClose}
                size={size}
                fullscreen={fullscreen}
                className={className}
                {...props}
                >
                  <Card>
                      <Card.Header as="h5" className={headerClassName} style={{display:'flex',justifyContent: 'space-between',alignItems: 'center'}}>
                        {this.props.title || 'Title'}
                        {this.props.anyComponent}
                        <span onClick={this.props.onClose} style={{cursor:'pointer'}}><i style={{color:'#fff', fontSize:21}} className="fa fa-times-circle" aria-hidden="true"></i></span>
                      </Card.Header>
                      <Card.Body style={{...style, maxHeight:'80vh', overflow: this.props.removeCardBodyOverflow ? 'visible' :'auto'}}>
                          {this.props.children}
                      </Card.Body>
                  </Card>
                </Modal>
            // </Aux>
        );
    }
}

export {CustomModal};