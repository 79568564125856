import React, { Component } from 'react';
import styled from 'styled-components'

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
// import am4themes_animated from "@amcharts/amcharts4/themes/animated";
// import am4themes_material from "@amcharts/amcharts4/themes/material";
const StyledFlex = styled.div`
  display: flex;
  justify-content:center;
  align-item:center;
      padding-top: 5px;
  & > div{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 15px;
    & > span{
      display:block;
      width:15px;
      height:15px;
      margin-right:5px;
      border-radius: 2px;
    }
   
  }
  & div:nth-child(1) span{
    background:#0f6d16;
  }
  & div:nth-child(2) span{
    background:#8bc34a;
  }
  & div:nth-child(3) span{
    background:#ff9800;
  }
  & div:nth-child(4) span{
    background:#23a4d4;
  }
  & div:nth-child(5) span{
    background:#f44336;
  }
`
class DashboardCompletionHousesGraph extends Component {

    componentDidMount() {

        function am4themes_myTheme(target) {
            if (target instanceof am4core.ColorSet) {
              target.list = [
                am4core.color("#DC143C"),
                am4core.color("#32CD32"),
                am4core.color("#FF8C00"),
                am4core.color("#008B8B"),
                am4core.color("#8A2BE2"),
                am4core.color("#191970")
              ];
            }
          }

 // Themes begin
am4core.useTheme(am4themes_myTheme);
// Themes end

// Create chart instance
let chart = am4core.create("DashboardCompletionHousesGraph", am4charts.XYChart);
// chart.scrollbarX = new am4core.Scrollbar();

// Add data
chart.data = [  
{
"country": "Lakshadweep (UT)- 0%",
"visits": 0,
color:'#f44336'
},
{
"country": "A&N Island (UT)- 3%",
"visits": 20,
color:'#f44336'
}, 
{
"country": "Sikkim- 46%",
"visits": 251,
color:'#8bc34a'
}, 
{
"country": "Ladakh (UT)- 21%",
"visits": 370,
color:'#23a4d4'
},                      
{
"country": "Daman & Diu (UT)- 65%",
"visits": 901,
color:'#0f6d16'
}, 
{
"country": "Goa- 94%",
"visits": 927,
color:'#0f6d16'
}, 
{
"country": "Meghalaya- 22%",
"visits": 1016,
color:'#23a4d4'
}, 
{
"country": "Arunachal Pradesh- 28%",
"visits": 2029,
color:'#23a4d4'
}, 
{
"country": "D&N Haveli (UT)- 55%",
"visits": 2626,
color:'#8bc34a'
}, 
{
"country": "Mizoram- 11%",
"visits": 3251,
color:'#f44336'
}, 
{
"country": "Puducherry (UT)- 25%",
"visits": 3387,
color:'#23a4d4'
}, 
{
"country": "Himachal Pradesh- 37%",
"visits": 3788,
color:'#ff9800'
}, 
{
"country": "Manipur- 9%",
"visits": 3851,
color:'#f44336'
}, 
{
"country": "Nagaland- 13%",
"visits": 4119,
color:'#f44336'
}, 
{
"country": "Chandigarh (UT)- 100%",
"visits": 5539,
color:'#0f6d16'
}, 
{
"country": "J&K (UT)- 12%",
"visits": 6686,
color:'#f44336'
}, 
{
"country": "Uttarakhand- 36%",
"visits": 14995,
color:'#ff9800'
},                      
{
"country": "Assam- 16%",
"visits": 19144,
color:'#23a4d4'
}, 
{
"country": "Haryana- 9%",
"visits": 25307,
color:'#f44336'
}, 
{
"country": "Punjab- 28%",
"visits": 26657,
color:'#23a4d4'
}, 
{
"country": "Tripura- 52%",
"visits": 42570,
color:'#8bc34a'
}, 
{
"country": "Delhi (NCR)- 100%",
"visits": 43209,
color:'#0f6d16'
}, 
{
"country": "Odisha- 44%",
"visits": 68936,
color:'#ff9800'
}, 
{
"country": "Bihar- 22%",
"visits": 69827,
color:'#23a4d4'
}, 
{
"country": "Kerala- 57%",
"visits": 75026,
color:'#8bc34a'
}, 
{
"country": "Jharkhand- 39%",
"visits": 78437,
color:'#ff9800'
}, 
{
"country": "Rajasthan- 42%",
"visits": 88280,
color:'#ff9800'
}, 
{
"country": "Chhattisgarh- 35%",
"visits": 91069,
color:'#ff9800'
}, 
{
"country": "Telangana- 47%",
"visits": 105894,
color:'#8bc34a'
}, 
{
"country": "Karnataka- 27%",
"visits": 176331,
color:'#23a4d4'
},                      
{
"country": "West Bengal- 47%",
"visits": 196211,
color:'#8bc34a'
}, 
{
"country": "Tamil Nadu- 39%",
"visits": 301330,
color:'#ff9800'
}, 
{
"country": "Maharashtra- 27%",
"visits": 323965,
color:'#23a4d4'
}, 
{
"country": "Madhya Pradesh- 41%",
"visits": 327232,
color:'#ff9800'
}, 
{
"country": "Andhra Pradesh- 16%",
"visits": 330047,
color:'#23a4d4'
}, 
{
"country": "Gujarat- 60%",
"visits": 403833,
color:'#8bc34a'
}, 
{
"country": "Uttar Pradesh- 31%",
"visits": 499025,
color:'#ff9800'
}, 

];


// Create axes
let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
categoryAxis.dataFields.category = "country";
categoryAxis.renderer.grid.template.location = 0;
categoryAxis.renderer.minGridDistance = 10;
// categoryAxis.renderer.labels.template.horizontalCenter = "right";
// categoryAxis.renderer.labels.template.verticalCenter = "middle";
// categoryAxis.renderer.labels.template.rotation = 0;
categoryAxis.tooltip.disabled = true;
categoryAxis.renderer.minHeight = 110;

let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
valueAxis.renderer.minWidth = 50;

// Create series
let series = chart.series.push(new am4charts.ColumnSeries());
series.sequencedInterpolation = true;
series.dataFields.valueX = "visits";
series.dataFields.categoryY = "country";
series.tooltipText = "[{categoryY}: bold]{valueX}[/]";
series.columns.template.strokeWidth = 0;

series.tooltip.pointerOrientation = "vertical";

series.columns.template.column.cornerRadiusBottomRight = 10;
series.columns.template.column.cornerRadiusTopRight = 10;
series.columns.template.column.fillOpacity = 0.8;

// on hover, make corner radiuses bigger
let hoverState = series.columns.template.column.states.create("hover");
hoverState.properties.cornerRadiusBottomRight = 0;
hoverState.properties.cornerRadiusTopRight = 0;
hoverState.properties.fillOpacity = 1;

series.columns.template.adapter.add("fill", function(fill, target) {
  // return chart.colors.getIndex(target.dataItem.index);
  return am4core.color(target.dataItem.dataContext.color ||"#000");
});

let labelBullet = series.bullets.push(new am4charts.LabelBullet())
labelBullet.label.horizontalCenter = "right";
labelBullet.label.text = "{values.valueX.workingValue}";
labelBullet.label.textAlign = "end";
labelBullet.label.truncate  = false;
labelBullet.label.dx = 40;


// Cursor
chart.cursor = new am4charts.XYCursor();

    }

    render() {
        return(
          <>
        <StyledFlex>
          <div><span/> 65% and above</div>
          <div><span/> 45% To 65%</div>
          <div><span/> 30% To 45%</div>
          <div><span/> 15% To 30%</div>
          <div><span/> less than 15%</div>
        </StyledFlex>
           <div id='DashboardCompletionHousesGraph' className="DashboardCompletionHousesGraph" style={{width: '100%', height: this.props.height}} />
           </>
           );
    }
}

export default DashboardCompletionHousesGraph;