import React from "react";
import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import * as Cookies from "js-cookie";
import { getPercent } from "../utils";
import CountUp from 'react-countup';
import get from "lodash/get";
import map from "lodash/map";

const theme = Cookies.get("theme") ? JSON.parse(Cookies.get("theme")) : {};

const textSplit=(l)=>{
  const lSplit=l.split(' ')
  const txt = map(lSplit,t=>t.slice(0,1).toUpperCase())
  return txt.join()
}

const CardWrapper = styled.div`
  width: 100%;
  background: ${props=>props.background ? props.background :'transparent'};
  color: #fff;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 10px;
  min-height:102.5px;
  // box-shadow: 0 1px 20px 0 rgb(69 90 100 / 8%);
  box-shadow: 0 2px 4px 0 rgb(136 144 195 / 20%), 0 5px 15px 0 rgb(37 44 97 / 15%);
  z-index: 1;
  overflow: hidden;
  transition: 0.7s;
  border: none;
  margin-bottom: 15px;
  transition: all 0.5s ease-in-out;
  position:relative;
  &::before{
    content: "";
    position: absolute;
    background: rgb(85 108 214 / 20%);
    width: 170px;
    height: 400px;
    z-index: -1;
    transform: rotate( 42deg);
    right: 7px;
    top: -14px;
    border-radius: 35px;
  }
  & * {
    color: #fff;
  }
  & p {
    margin: 0;
  }
  & > div > div  > span{
    font-size:24px;
    font-weight:600
  }
`;
const Circle = styled.div`
  width: 60px;
  height: 60px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: 600;
`;

const CustomCard = (props) => {
  return (
    <CardWrapper {...props}>
      <Row>
        <Col
          md={4}
          sm={4}
          xs={4}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight:81,
          }}
        >
          <Circle>{get(props, "icon") ? get(props, "icon") : textSplit(get(props,'name'))}</Circle>
        </Col>
        <Col md={8} sm={8} xs={8} style={{paddingLeft:0}}>
          <p>{get(props,'name')} <small style={{fontSize:10}}>{get(props,'numberFormat') || ''}</small></p>
          {/* <div style={{ fontWeight: 600, fontSize:'24px'}}>{get(props,'value') ? Number(get(props,'value',0)).toLocaleString("en-in") : '-'}</div> */}
          <CountUp 
            start={0} 
            decimal="." 
            end={get(props,'value') ? Number(get(props,'value',0))  : 0} 
            decimals={Number.isInteger(Number(get(props,'value',0))) ? 0 : 2}
            formattingFn={(val)=>{return val.toLocaleString('en-in')}}
            duration={5}
           />
          {get(props,'percent') ? <Row>
            <Col md={10} sm={10} xs={10}>
              <div className="progress m-t-10" style={{ height: "4px",background: 'rgba(0, 0, 0, 0.1)' }}>
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{
                    background: '#fff',
                    width: get(props,'percent') + "%",
                  }}
                  aria-valuenow={get(props,'percent')}
                  aria-valuemin="0"
                  aria-valuemax="100"
                />
              </div>
            </Col>
            <Col md={2} sm={2} xs={2} style={{paddingLeft:0}}>
              <div className="float-right f-w-300" style={{marginTop:2}}>
                {get(props,'percent') + "%"}
              </div>
            </Col>
          </Row>:''}
        </Col>
      </Row>
    </CardWrapper>
  );
};

export { CustomCard };
