import React from "react";
import { Table } from "react-bootstrap";
import { Loader } from "./Loader";
import { BASE_URL } from "../store/constant";
import Axios from "axios";
import map from "lodash/map";
import get from "lodash/get";
import find from "lodash/find";
import _ from 'lodash'
import { toast } from "react-toastify";
import styled from "styled-components";
import moment from "moment";
import { exportTableToExcel } from '../utils'

const Wrapper = styled.div`
  position: relative;
  & h3 {
    text-align: center;
    display: none;
  }

  @media print {
    h3 {
      display: block;
    }
  }
`;

class ComponentList extends React.Component {
  state = {
    isLoading: true,
    projectDataList: [],
    excesProjectWithExcludeComponent: [],
    excesProjectInCaseOfCity: [],
    ScoreCard: '',
  };

  componentDidMount = async () => {
    this.setState({ isLoading: true });

    try {

      //if (get(this.props, "CSMCNo")) {
      // const { data } = await Axios.get(
      //   `${BASE_URL}/project-details/report/csmc/${get(
      //     this.props,
      //     "CSMCNo"
      //   )}/${get(this.props, "stateCode")}`
      // );

      const { data: logDetails } = await Axios.get(`${BASE_URL}/table-audit/getuserDetails?userId=${get(this.props, "CSMCNo")}&Date=${moment(
        get(this.props, "stateCode")
      ).format("YYYY-MM-DD")}`);


      this.setState({ projectDataList: _.filter(logDetails, flt => moment(flt.LoginTime).format('YYYY-MM-DD') === moment(get(this.props, "stateCode")).format('YYYY-MM-DD')), isLoading: false });

    } catch (err) {
      this.setState({ isLoading: false });
      toast.error("Something went wronge.");
    }
  };

  render() {
    const { isLoading, projectDataList } = this.state;





    return (
      <Wrapper>
        <h3>User log details</h3>
        {isLoading ? (
          <>
            <Table striped bordered className="bg-c-white customTable" id="MonitoringofProgress">
              <thead
                className="text-white text-center"
                style={{ background: "#002b49" }}
              >
                <tr style={{ textAlign: "center" }}>
                  <th rowSpan={2} style={{ width: 30, maxWidth: 30 }} className="center">
                    Sr.
                  </th>

                </tr>

              </thead>
            </Table>
            <Loader style={{ position: "absolute", height: 190 }} />
          </>
        ) : (
          <Table className="bg-c-white customTable" id="MonitoringofProgress">

            <thead
              className="label label-warning text-white text-center"
              style={{ backgroundColor: "#002b49" }}
            >
              <tr style={{ textAlign: "center" }}>
                <th rowSpan={2} style={{ width: 30, maxWidth: 30 }} className="center">
                  Sr.
                </th>
                <th> User Id</th>

                <th> User Name</th>
                <th> Login DateTime</th>
                <th> URL</th>
                <th rowSpan='2' style={{ width: 150, minWidth: 150 }} > Activity </th>
                <th> Role </th>
                <th> jurisdiction </th>




              </tr>
            </thead>
            <tbody>
              {map(projectDataList, (obj, i) => {


                return (
                  <tr key={get(obj, "ProjectCode")}>
                    <td>{i + 1}</td>


                    <td className=' text-left' style={{ color: 'blue', cursor: 'pointer' }} >{(obj.userName && obj.userName !== 'null') ? obj.userName : obj.email}</td>
                    <td className=' text-left'>{obj.firstName + ' ' + obj.lastName}</td>

                    <td className=' text-left'>{moment(obj.LoginTime).format(
                      "DD-MM-YYYY HH:mm:ss"
                    )}</td>
                    <td className=' text-left'>{obj.url}</td>
                    <td className=' text-left' style={{ width: 100, wordWrap: 'break-word', whiteSpace: 'normal' }}>{obj.Activity}</td>
                    <td className=' text-left'>{obj.role}</td>
                    <td className=' text-left'>{obj.jurisdiction}</td>



                  </tr>
                );
              })}
            </tbody>
          </Table>
        )}
      </Wrapper>
    );
  }
}

export default ComponentList;
