import React from "react";
// import ReactDOM from "react-dom";
import { createRoot } from 'react-dom/client';
import { createStore } from "redux";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import App from "./App/index";
import * as serviceWorker from "./serviceWorker";
import reducer from "./store/reducer";
import config from "./config";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const store = createStore(reducer);
const container = document.getElementById('root');

const app = (
  <Provider store={store}>
    <ToastContainer
      autoClose={3000}
      hideProgressBar={true}
      newestOnTop={false}
      closeOnClick={true}
      pauseOnHover={true}
      position={"bottom-center"}
    />
    <BrowserRouter basename={config.basename}>
      <App />
    </BrowserRouter>
  </Provider>
);

// ReactDOM.render(app, document.getElementById("root"));

const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(app);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// if(!process.env.NODE_ENV || process.env.NODE_ENV === 'development'){
//   serviceWorker.unregister();
// }else{
//   serviceWorker.register();
// }
serviceWorker.unregister();
