import React from "react";
import { Row, Col, Card, Button, Table } from "react-bootstrap";
import {
  Breadcrumb,
  Input,
  SelectBox,
  TextArea,
  DateInput
} from "../components";

import { Formik, Field } from "formik";

import { Loader } from "./Loader";
import { BASE_URL } from "../store/constant";
import Axios from "axios";
import map from "lodash/map";
import get from "lodash/get";
import find from "lodash/find";
import _ from 'lodash'
import { toast } from "react-toastify";
import styled from "styled-components";
import moment from "moment";
import { exportTableToExcel } from '../utils'

const Wrapper = styled.div`
  position: relative;
  & h3 {
    text-align: center;
    display: none;
  }

  @media print {
    h3 {
      display: block;
    }
  }
`;

class ComponentList extends React.Component {
  state = {
    isLoading: true,
    recentValues: {}
  };

  componentDidMount = async () => {
    this.setState({ isLoading: true });

    const recentValues = this.props.recordValues ? this.props.recordValues : {}
    this.setState({ recentValues, isLoading: false })

  };

  render() {
    const { isLoading, recentValues } = this.state;
    return (
      <Wrapper>
        <Breadcrumb />
        <Formik
          enableReinitialize={true}
          // validationSchema={ProjectFormSchema}
          initialValues={{
            isSubmitting: false,
            id: Number(get(recentValues, "id")),
            ProjectCost: get(recentValues, "ProjectCost"),
            CentralAssistApproved: get(recentValues, "CentralAssistApproved"),
            StateGrantApproved: get(recentValues, "StateGrantApproved"),
            OriginallyCSMCDate: get(recentValues, "OriginallyCSMCDate"),
            OriginallyCSMCNo: get(recentValues, "OriginallyCSMCNo"),
            CSMCDate: get(recentValues, "CSMCDate"),
            CSMCNo: get(recentValues, "CSMCNo"),
            ULBShareApproved: get(recentValues, "ULBShareApproved"),
            BneneficiaryShareApproved: get(
              recentValues,
              "BneneficiaryShareApproved"
            ),
            OtherShare: get(recentValues, "OtherShare"),
            HousesSanctionedNew: get(recentValues, "HousesSanctionedNew"),
            HousesSanctionedEnahncement: get(
              recentValues,
              "HousesSanctionedEnahncement"
            ),
            oldarray: recentValues,
          }}
          onSubmit={async (input, { resetForm, setFieldValue }) => {
            //const MySwal = withReactContent(Swal);

            setFieldValue("isSubmitting", true);

            await Axios
              .put(`${BASE_URL}/project-details/projectInfoTable`, {
                ...input,

              })
              .then((res) => {
                // console.log('res----->', res)
                toast.success("Project Updated Successfully.");
                window.location.reload();
              })
              .catch((err) => {
                toast.error(get(err, 'response.data') || "Something went wrong.");
              });




            setFieldValue("isSubmitting", false);
          }}
        >
          {(formikBag) => {


            return (
              <form
                onSubmit={(event) => {
                  event.preventDefault();
                  formikBag.validateForm().then((errors) => {
                    const errKeys = Object.keys(errors);
                    if (errKeys.length > 0) {
                      const errMessage =
                        typeof errors[errKeys[0]] === "string"
                          ? errors[errKeys[0]]
                          : errors[errKeys[0]]["value"];
                      toast.error(errMessage, {
                        position: toast.POSITION.BOTTOM_CENTER,
                      });
                      // alert(errMessage);
                      return null;
                    } else {
                      return formikBag.handleSubmit();
                    }
                  });
                }}
              >
                <Card>

                  <Card.Body>

                    <Card.Header className="mt-4 mb-2">
                      <Row>
                        <Card.Title as="h5">Salient Details</Card.Title>
                      </Row>
                    </Card.Header>
                    <Row>
                      <Col md={3} sm={3} xs={6}>
                        <Field name="ProjectCost">
                          {({ field, form }) => (
                            <Input
                              //readOnly={get(formikBag.values, "Status") === 'Active' || get(formikBag.values, "Status") === 'Revised' ? false : true}
                              {...field}
                              type={"number"}
                              label={
                                <>
                                  Project Cost<span>(Rs in Cr.)</span>
                                </>
                              }
                              step="0.0001"
                              isrequiredicon={"true"}
                              value={
                                get(formikBag.values, "Status") === 'Cancelled' ? 0 :
                                  get(formikBag.values, "ProjectCost", "") || ""
                              }
                            />
                          )}
                        </Field>
                      </Col>
                      <Col md={3} sm={3} xs={6}>
                        <Field name="CentralAssistApproved">
                          {({ field, form }) => (
                            <Input
                              //readOnly={get(formikBag.values, "Status") === 'Active' || get(formikBag.values, "Status") === 'Revised' ? false : true}

                              {...field}
                              type={"number"}
                              step="0.0001"
                              label={
                                <>
                                  Central Assist<span>(Rs in Cr.)</span>
                                </>
                              }
                              isrequiredicon={"true"}
                              value={
                                get(formikBag.values, "Status") === 'Cancelled' ? 0 :
                                  get(
                                    formikBag.values,
                                    "CentralAssistApproved",
                                    ""
                                  ) || ""
                              }
                            />
                          )}
                        </Field>
                      </Col>
                      <Col md={3} sm={3} xs={6}>
                        <Field name="StateGrantApproved">
                          {({ field, form }) => (
                            <Input
                              //readOnly={get(formikBag.values, "Status") === 'Active' || get(formikBag.values, "Status") === 'Revised' ? false : true}

                              {...field}
                              type={"number"}
                              step="0.0001"
                              label={
                                <>
                                  State Grant <span>(Rs in Cr.)</span>
                                </>
                              }
                              value={
                                get(formikBag.values, "Status") === 'Cancelled' ? 0 :
                                  get(
                                    formikBag.values,
                                    "StateGrantApproved",
                                    ""
                                  ) || ""
                              }
                            />
                          )}
                        </Field>
                      </Col>
                      <Col md={3} sm={3} xs={6}>
                        <Field name="ULBShareApproved">
                          {({ field, form }) => (
                            <Input
                              //readOnly={get(formikBag.values, "Status") === 'Active' || get(formikBag.values, "Status") === 'Revised' ? false : true}

                              {...field}
                              type={"number"}
                              step="0.0001"
                              label={
                                <>
                                  ULB Share <span>(Rs in Cr.)</span>
                                </>
                              }
                              value={
                                get(formikBag.values, "Status") === 'Cancelled' ? 0 :
                                  get(formikBag.values, "ULBShareApproved", "") ||
                                  ""
                              }
                            />
                          )}
                        </Field>
                      </Col>
                      <Col md={3} sm={3} xs={6}>
                        <Field name="BneneficiaryShareApproved">
                          {({ field, form }) => (
                            <Input
                              //readOnly={get(formikBag.values, "Status") === 'Active' || get(formikBag.values, "Status") === 'Revised' ? false : true}

                              {...field}
                              type={"number"}
                              step="0.0001"
                              label={
                                <>
                                  Bneneficiary Share <span>(Rs in Cr.)</span>
                                </>
                              }
                              value={
                                get(formikBag.values, "Status") === 'Cancelled' ? 0 :
                                  get(
                                    formikBag.values,
                                    "BneneficiaryShareApproved",
                                    ""
                                  ) || ""
                              }
                            />
                          )}
                        </Field>
                      </Col>
                      <Col md={3} sm={3} xs={6}>
                        <Field name="OtherShare">
                          {({ field, form }) => (
                            <Input
                              //readOnly={get(formikBag.values, "Status") === 'Active' || get(formikBag.values, "Status") === 'Revised' ? false : true}

                              {...field}
                              type={"number"}
                              step="0.0001"
                              label={
                                <>
                                  Other Share <span>(Rs in Cr.)</span>
                                </>
                              }
                              value={
                                get(formikBag.values, "Status") === 'Cancelled' ? 0 :
                                  get(formikBag.values, "OtherShare", "") || ""
                              }
                            />
                          )}
                        </Field>
                      </Col>
                      <Col md={3} sm={3} xs={6}>
                        <Field name="HousesSanctionedNew">
                          {({ field, form }) => (
                            <Input
                              //readOnly={get(formikBag.values, "Status") === 'Active' || get(formikBag.values, "Status") === 'Revised' ? false : true}

                              {...field}
                              type={"number"}
                              label={
                                <>
                                  Houses Sanctioned New <span>(Nos)</span>
                                </>
                              }
                              isrequiredicon={"true"}
                              value={
                                get(formikBag.values, "Status") === 'Cancelled' ? 0 :
                                  get(
                                    formikBag.values,
                                    "HousesSanctionedNew",
                                    ""
                                  ) || ""
                              }
                            />
                          )}
                        </Field>
                      </Col>
                      <Col md={3} sm={3} xs={6}>
                        <Field name="HousesSanctionedEnahncement">
                          {({ field, form }) => (
                            <Input
                              //readOnly={get(formikBag.values, "Status") === 'Active' || get(formikBag.values, "Status") === 'Revised' ? false : true}

                              {...field}
                              type={"number"}
                              label={
                                <>
                                  Houses Enahncement <span>(Nos)</span>
                                </>
                              }
                              value={
                                get(formikBag.values, "Status") === 'Cancelled' ? 0 :
                                  get(
                                    formikBag.values,
                                    "HousesSanctionedEnahncement",
                                    ""
                                  ) || ""
                              }
                            />
                          )}
                        </Field>
                      </Col>
                    </Row>

                    <Row>

                      <Col md={3} sm={3} xs={3}>


                        <DateInput
                          label="OriginallyCSMCDate"
                          // isrequiredicon={"true"}

                          selected={get(formikBag.values, "OriginallyCSMCDate", "")}
                          onChange={(date) =>
                            formikBag.setFieldValue("OriginallyCSMCDate", date)
                          }
                        />
                      </Col>

                      <Col md={3} sm={3} xs={3}>
                        <Field name="OriginallyCSMCNo">
                          {({ field, form }) => (
                            <Input

                              {...field} label="OriginallyCSMCNo" value={get(formikBag.values, "OriginallyCSMCNo")} />
                          )}

                        </Field>
                      </Col>


                      <Col md={3} sm={3} xs={3}>
                        <Field name="CSMCNo">
                          {({ field, form }) => (
                            <Input
                              {...field}
                              type={"number"}
                              label={" CSMC No."}
                              isrequiredicon={"true"}
                              value={get(formikBag.values, "CSMCNo", "") || ""}
                            />
                          )}
                        </Field>
                      </Col>

                      <Col md={3} sm={3} xs={3}>
                        <DateInput
                          label={" CSMC Date"}
                          isrequiredicon={"true"}
                          selected={get(formikBag.values, "CSMCDate", "")}
                          onChange={(date) =>
                            formikBag.setFieldValue("CSMCDate", date)
                          }
                        />
                      </Col>
                    </Row>


                    <Row
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        paddingTop: 20,
                      }}
                    >
                      <Button
                        style={{ width: 230 }}
                        type="submit"
                        size={"sm"}
                        disabled={formikBag.values.isSubmitting}
                      >
                        <i
                          className={
                            get(formikBag.values, "isSubmitting")
                              ? "fa fa-spinner fa-spin"
                              : "feather icon-upload-cloud"
                          }
                        ></i>{" "}
                        Submit{" "}
                      </Button>
                    </Row>
                  </Card.Body>
                </Card>
              </form>
            );
          }}
        </Formik>




      </Wrapper>
    );
  }
}

export default ComponentList;
