import React, { PureComponent } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import sumBuy from 'lodash/sumBy'
import map from 'lodash/map'
import get from 'lodash/get'
import orderBy from 'lodash/orderBy'
import * as Cookies from 'js-cookie'
import _ from "lodash";

class DashboardGeoTagLikelyDetails extends PureComponent {

  componentDidMount(){
    this.initGraph() 
  }

  componentDidUpdate(){
    this.initGraph() 
  }

    initGraph() {
      const theme = Cookies.get('theme') ? JSON.parse(Cookies.get('theme')) : {}

   
      am4core.useTheme(am4themes_animated);
  
      let chart = am4core.create(this.props.graphId, am4charts.XYChart);

  
      const orderedData =_.orderBy(this.props.graphData2,['housesSanctionedBLC1'],['desc'])  //this.props.graphData2 //orderBy(this.props.graphData2,obj=>obj.housesSanctionedBLC1, ['desc'])
     
      chart.data=map(orderedData,obj=>{
        const cmpld= 0//Number(obj.cmpld.replace(/,/g, ''))
        const sacRec= 0
        const sacYet= Number(obj.housesSanctionedBLC1)
        const undCon= 0
        const cmpldBtNtOcpd= 0//Number(obj.cmpldBtNtOcpd.replace(/,/g, ''))

        return {
          ...obj,
          stateName:`${obj.stateName} ${get(obj,'ProjectCode') ? '': '-'+ (cmpld+sacRec+sacYet+undCon+cmpldBtNtOcpd).toLocaleString('en-in')}`
        }
      })

      // Create axes

      let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "stateName";
      categoryAxis.dataFields.value1 = "plinth";
      categoryAxis.tooltipText = "Houses Sanctioned";
      categoryAxis.tooltipPosition = "pointer"
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.inversed = true;
      categoryAxis.renderer.minGridDistance = 20;
      categoryAxis.renderer.axisFills.template.disabled = false;
      categoryAxis.renderer.axisFills.template.fillOpacity = 0;
    
      let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
      valueAxis.min = -100;
      valueAxis.max = 100;
      valueAxis.renderer.minGridDistance = 50;
      valueAxis.renderer.ticks.template.length = 5;
      valueAxis.renderer.ticks.template.disabled = false;
      valueAxis.renderer.ticks.template.strokeOpacity = 1;
      //  valueAxis.renderer.labels.template.fill = am4core.color("white");
      valueAxis.renderer.labels.template.adapter.add("text", function(text) {
        return text;

      })

      var leftSideText = valueAxis.axisRanges.create();
        leftSideText.value = -90;
        leftSideText.endValue = 0;
        leftSideText.label.text = "⟵  Work Yet to Start  ⟶";
        leftSideText.label.color = "#000000";
        // leftSideText.label.fill = chart.colors.list[0];
        leftSideText.label.dy = 20;
        leftSideText.label.fontWeight = '600';
        leftSideText.label.fontSize = 14;
        leftSideText.grid.strokeOpacity = 0;

     

        var rightSideText = valueAxis.axisRanges.create();
        rightSideText.value = 90;
        rightSideText.endValue = 10;
        rightSideText.label.text = "⟵  Work Started  ⟶";
        // rightSideText.label.fill = chart.colors.list[1];
        rightSideText.label.dy = 20;
        rightSideText.label.fontWeight = '600';
        rightSideText.label.fontSize = 14;
        rightSideText.grid.strokeOpacity = 0;


      // Legend
      chart.legend = new am4charts.Legend();
      chart.legend.position = "top";
      chart.legend.markers.template.width = 15
      chart.legend.markers.template.height = 15
      // Use only absolute numbers
      chart.numberFormatter.numberFormat = "#.#s";

      
      // Create series
      function createSeries(field, name, color,extraValue,extraValue2) {
        //console.log('field, name, color,extraValue--->>', {field, name, color,extraValue})
       //eslint-disable-next-line
       const totalValue = sumBuy(chart.data, obj=> Number(obj[extraValue])||0)
       const exactTotalValue = totalValue ? totalValue.toLocaleString('en-in') :'-'
       let series = chart.series.push(new am4charts.ColumnSeries());
       series.dataFields.valueX = field;
       series.dataFields.valueX2 = extraValue2;
       series.dataFields.categoryY = "stateName";
       series.stacked = true;
       series.stroke = color;
       series.fill = color;
       // series.hidden = true;
       series.dataFields.extraValueX = extraValue
       series.name =name;
       series.columns.template.tooltipText = "{name}: [bold]{extraValueX}";
       // series.columns.template.url = "https://www.google.com/search?q=hello";
       series.columns.template.events.on("hit", function(ev) {
         // console.log("clicked on ",ev.target.dataItem.dataContext.ProjectCode);
         if(ev.target.dataItem.dataContext.ProjectCode){
           // window.location.href=window.location.origin+'/project-details/'+ev.target.dataItem.dataContext.ProjectCode
           window.open(window.location.origin+'/project-details/'+ev.target.dataItem.dataContext.ProjectCode)
         }
       }, this);
    
       series.legendSettings.labelText = name +' ( '+exactTotalValue+' )';
     
       let label = series.bullets.push(new am4charts.LabelBullet());
       label.label.text = "{valueX}%";
       label.label.fill = am4core.color("#fff");
       label.label.strokeWidth = 0;
       label.label.truncate = false;
       label.label.hideOversized = true;
       label.locationX = 0.5;
       return series;
     }
     
      createSeries('GBNSPercentag1', "GeoTag but Not Started",'#f44336','GBNS','GBNSPercentag');
      createSeries('NotGeoTagPercentag1', "Not GeoTag",'#03a9f4','NotGeoTag','NotGeoTagPercentag');
      createSeries('plinthGeoTagPercentage1', "Plinth",'#FFA500','plinth','plinthGeoTagPercentage');
      createSeries('lintelGeoTagPercentage1', "Lintel",'#8bc34a','lintel','lintelGeoTagPercentage');
      createSeries('roofGeoTagPercentage1', "Roof",'#45a951','roof','roofGeoTagPercentage');
      createSeries('completedGeoTagPerCentage1', "Completed",'#228b22','completed','completedGeoTagPerCentage');

      // let series = chart.series.push(new am4charts.ColumnSeries());

      chart.legend.events.on("layoutvalidated", function(event){
        chart.legend.itemContainers.each((container)=>{
          if(container.dataItem.dataContext.name === "SanctionedRecently"){
            container.toBack();
          }
        })
      })

    }

    render() {
      return <div id={this.props.graphId} className={this.props.graphId} style={{width: '100%', height: this.props.height}} />;

    }
}

export default DashboardGeoTagLikelyDetails;