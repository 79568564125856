import React, { PureComponent } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import orderBy from 'lodash/orderBy'
import filter from 'lodash/filter'
import find from 'lodash/find'
import get from 'lodash/get'
import styled from 'styled-components'
import * as Cookies from 'js-cookie'
const theme = Cookies.get('theme') ? JSON.parse(Cookies.get('theme')) : {}


const StyledFlex = styled.div`
  display: flex;
  justify-content:center;
  align-item:center;
      padding-top: 5px;
  & > div{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 15px;
    & > span{
      display:block;
      width:15px;
      height:15px;
      margin-right:5px;
      border-radius: 2px;
    }
   
  }
  & div:nth-child(1) span{
    background:#0f6d16;
  }
  & div:nth-child(2) span{
    background:#8bc34a;
  }
  & div:nth-child(3) span{
    background:#ff9800;
  }
  & div:nth-child(4) span{
    background:#23a4d4;
  }
  & div:nth-child(5) span{
    background:#f44336;
  }
`

const demandSaturationColor=[
  // {high:100,low:100,color: get(theme,'color13') ? get(theme,'color13') :'#0f6d16'},
  // {high:99,low:90,color:  get(theme,'color12') ? get(theme,'color12') :'#8bc34a'},
  // {high:89,low:70,color:  get(theme,'color15') ? get(theme,'color15') :'#ff9800'},
  // {high:69,low:50,color:  get(theme,'color3') ? get(theme,'color3') :'#23a4d4'},
  // {high:49,low:0,color:  get(theme,'color14') ? get(theme,'color14') :'#f44336'},
  {high:1000,low:101,color: get(theme,'color13') ? get(theme,'color13') :'#0f6d16'},
  {high:100,low:75,color:  get(theme,'color2') ? get(theme,'color2') :'#002b49'},
  {high:74,low:0,color:  get(theme,'color14') ? get(theme,'color14') :'#f44336'},
]

// const groundingAndSanctionedColor=[
//   {high:100,low:91,color: get(theme,'color13') ? get(theme,'color13') :'#0f6d16'},
//   {high:90,low:71,color:  get(theme,'color12') ? get(theme,'color12') :'#8bc34a'},
//   {high:70,low:51,color:  get(theme,'color15') ? get(theme,'color15') :'#ff9800'},
//   {high:50,low:26,color:  get(theme,'color3') ? get(theme,'color3') :'#23a4d4'},
//   {high:25,low:0,color:  get(theme,'color14') ? get(theme,'color14') :'#f44336'},
// ]

const completionAndSanctionedColor=[
  {high:100,low:71,color: get(theme,'color13') ? get(theme,'color13') :'#0f6d16'},
  {high:70,low:51,color:  get(theme,'color12') ? get(theme,'color12') :'#8bc34a'},
  {high:50,low:31,color:  get(theme,'color15') ? get(theme,'color15') :'#ff9800'},
  {high:30,low:21,color:  get(theme,'color3') ? get(theme,'color3') :'#23a4d4'},
  {high:20,low:0,color:  get(theme,'color14') ? get(theme,'color14') :'#f44336'},
]

class GroupLineChartForConstructionCost extends PureComponent {
  componentDidMount(){
    setTimeout(()=>{ 
      this.initGraph(this.props.graphType)
    }, 1000);
  }
  componentDidUpdate(){
    this.initGraph(this.props.graphType)
  }

  initGraph(graphType) {
    const theme = Cookies.get('theme') ? JSON.parse(Cookies.get('theme')) : {}

    // Themes begin
    function myTheme1(target) {
      if (target instanceof am4core.ColorSet) {
        target.list = [
          am4core.color(get(theme,'color15')?get(theme,'color15'):"#FF8C00"),
          am4core.color("#32CD32"),
          am4core.color("#8A2BE2"),
          am4core.color("#DC143C"),
          am4core.color("#008B8B"),
          am4core.color("#191970")
        ];
      }
    }

    function myTheme2(target) {
      if (target instanceof am4core.ColorSet) {
        target.list = [
          am4core.color("#0067a5"),
          am4core.color("#5dc669"),
          am4core.color("#f44336"),
          am4core.color("#ffeb3b"), 
          am4core.color("#673ab7"),
          am4core.color("#ff5722")
        ];
      }
    }

    function myTheme3(target) {
      if (target instanceof am4core.ColorSet) {
        target.list = [
          am4core.color("#f6a600"),
          am4core.color("#0067a5"), 
          am4core.color("#FF00FF"),
          am4core.color("#800080"),
          am4core.color("#008080"),
          am4core.color("#00FF00")
        ];
      }
    }

    function myTheme4(target) {
      if (target instanceof am4core.ColorSet) {
        target.list = [
          am4core.color("#FF0000"),
          am4core.color("#fbdb48"),
          am4core.color("#67d868"),
          am4core.color("#808000"),
          am4core.color("#800000"),
          am4core.color("#ff9700")
        ];
      }
    }


    am4core.useTheme(this.props.theme === 'myTheme2' ? myTheme2 : this.props.theme === 'myTheme3' ? myTheme3 : this.props.theme === 'myTheme4' ? myTheme4 :  myTheme1);
    am4core.useTheme(am4themes_animated);
    // Themes end
    // Create chart
    let chart = am4core.create(this.props.id ||'GroupLineChart', am4charts.XYChart);
    // let chart = am4core.create("chartdiv", am4charts.XYChart);
    // Add data

//const graphData = orderBy(this.props.graphData,['region','state'],['desc','desc'])//orderBy(this.props.graphData,[(od)=> { return od.region === 'States' }, 'region','dataSet'],['asc','desc','asc'])
//const graphData = orderBy(this.props.graphData,[(od)=> { return od.region === 'States' }, 'region','dataSet'],['asc','desc','asc'])
const graphData = orderBy(this.props.graphData,['regionFT','state'],['desc','desc'])

const stateList = filter(graphData, o=> o.region ==='States')
  
//console.log('stateList--->',stateList);
const utList = filter(graphData, o=> o.region ==='Union Territories')
//console.log('utList--->',utList);
const neStateList = filter(graphData, o=> o.region ==='North East States')
//console.log('neStateList--->',neStateList);
 chart.data = this.props.removeRegion ? orderBy(this.props.graphData,['dataSet'],['asc']) : graphData 

  // Create axes
let yAxis = chart.yAxes.push(new am4charts.CategoryAxis());
yAxis.dataFields.category = "state";
yAxis.renderer.grid.template.location = 0;
yAxis.renderer.labels.template.fontSize = 12;
yAxis.renderer.minGridDistance = 10;

 

chart.xAxes.push(new am4charts.ValueAxis());

// Create series
let series = chart.series.push(new am4charts.ColumnSeries());
series.dataFields.valueX = "dataSet";
series.dataFields.categoryY = "state";
// series.tooltip.disabled = true;
// series.columns.template.tooltipText = "{categoryY}: [bold]{valueX}[/]";
series.columns.template.adapter.add('tooltipText', function(text, target) {
  const data = get(target,'tooltipDataItem.dataContext');
  // console.log('this.props.graphData------->',graphType)
  if (get(data,'secondValue'))
      return "Sanctioned: " + Number(get(data,'secondValue')).toLocaleString('en-in');
  else
      return "{categoryY}: [bold]{valueX}[/]";
});
 
series.columns.template.strokeWidth = 0;
series.columns.template.adapter.add("fill", function(fill, target) {

  const score = get(target,'dataItem.dataContext.score',0)
//  console.log('graphType------>>',graphType)
const region = get(target,'dataItem.dataContext.region')
//console.log('score2-->',region);
  if(graphType === 'demandAndSaturation'){
    const colorSet = find(demandSaturationColor, fnd => (get(fnd, 'low') <= score && get(fnd, 'high') >= score))
    return am4core.color(get(colorSet,'color') ||"#000");
  }else if(graphType === 'groundingAndSanctioned'){
    //const colorSet = find(groundingAndSanctionedColor, fnd => (get(fnd, 'low') <= score && get(fnd, 'high') >= score))
    return region==='States' ? '#000080' : region==='North East States' ? '#d04e09':'#138808';
   
    

  }else if(graphType === 'completionAndSanctioned'){
    const colorSet = find(completionAndSanctionedColor, fnd => (get(fnd, 'low') <= score && get(fnd, 'high') >= score))

    // console.log('colorSet------->>',colorSet)
    return am4core.color(get(colorSet,'color') ||"#000");
  }else if (target.dataItem) {
    switch(target.dataItem.dataContext.region) {
      case "States":
        return chart.colors.getIndex(0);
      case "North East States":
        return chart.colors.getIndex(0);
      case "Union Territories":
        return chart.colors.getIndex(0);
        default:
          return ''
    }
  }
  return fill;
});

let axisBreaks = {};
let legendData = [];

// Add ranges
function addRange(label, start, end, color) {
  // console.log('label------>>', label)
  let range = yAxis.axisRanges.create();
  range.category = start;
  range.endCategory = end;
  range.label.text = label;
  range.label.disabled = false;
  range.label.fill = color;
  range.label.location = 0;
  range.label.dx = graphType==='investment' ? -130 : graphType === 'demandAndSaturation' ? -161 : -158;
  range.label.dy = label === 'States' ? 250 : 350;
  range.label.fontWeight = "bold";
  range.label.fontSize = 15;
  range.label.horizontalCenter = "left"
  range.label.verticalCenter = "middle"
  range.label.rotation = -90;
  range.label.inside = true;
  
  range.grid.stroke = am4core.color("#002b49");
  range.grid.strokeOpacity = 1;
  range.tick.length = 200;
  range.tick.disabled = false;
  range.tick.strokeOpacity = 0.6;
  range.tick.stroke = am4core.color("#002b49");
  range.tick.location = 0;
  
  range.locations.category = 1;
  let axisBreak = yAxis.axisBreaks.create();
  axisBreak.startCategory = start;
  axisBreak.endCategory = end;
  axisBreak.breakSize = 1;
  axisBreak.fillShape.disabled = true;
  axisBreak.startLine.disabled = true;
  axisBreak.endLine.disabled = true;
  axisBreaks[label] = axisBreak;  

  legendData.push({name:label, fill:color});
}

let labelBullet = series.bullets.push(new am4charts.LabelBullet())
labelBullet.label.horizontalCenter = "right";
labelBullet.label.text = "{values.valueX.workingValue}";
labelBullet.label.textAlign = "end";
labelBullet.label.truncate  = false;
labelBullet.label.dx = 60;

series.columns.template.column.cornerRadiusBottomRight = 101;
series.columns.template.column.cornerRadiusTopRight = 101;



if(!this.props.removeRegion){
  if(this.props.graphType==='investment'){
  addRange("States",  get(stateList,`${stateList.length-1}.state`),get(stateList,'0.state'), chart.colors.getIndex(0));  
  addRange("Union Territories", get(utList,`${utList.length-1}.state`), get(utList,'0.state'), chart.colors.getIndex(0));
  addRange("North East States", get(neStateList,`${neStateList.length-1}.state`), get(neStateList,'0.state'), chart.colors.getIndex(0));
}else if(this.props.graphType==='demandAndSaturation'){

  addRange("States",  get(stateList,`${stateList.length-1}.state`),get(stateList,'0.state'), chart.colors.getIndex(0));
  addRange("Union Territories", get(utList,`${utList.length-1}.state`), get(utList,'0.state'), chart.colors.getIndex(2));
  addRange("North East States", get(neStateList,`${neStateList.length-1}.state`), get(neStateList,'0.state'), chart.colors.getIndex(1));
}else{
  console.log('get(stateLis---->',stateList)
  addRange("States",  get(stateList,`${stateList.length-1}.state`),get(stateList,'0.state'), chart.colors.getIndex(0));
  addRange("Union Territories", get(utList,`${utList.length-1}.state`), get(utList,'0.state'), chart.colors.getIndex(2));
  addRange("North East States", get(neStateList,`${neStateList.length-1}.state`), get(neStateList,'0.state'), chart.colors.getIndex(1));
}}
chart.cursor = new am4charts.XYCursor();


// let legend = new am4charts.Legend();
// legend.position = "top";
// legend.scrollable = true;
// legend.valign = "top";
// legend.reverseOrder = true;
// legend.markers.template.width = 15
// legend.markers.template.height = 15


// chart.legend = legend;
// legend.data = legendData;

// legend.itemContainers.template.events.on("toggled", function(event){
//   let name = event.target.dataItem.dataContext.name;
//   let axisBreak = axisBreaks[name];
//   if(event.target.isActive){
//     axisBreak.animate({property:"breakSize", to:0}, 1000, am4core.ease.cubicOut);
//     yAxis.dataItems.each(function(dataItem){
//       if(dataItem.dataContext.region === name){
//         dataItem.hide(1000, 500);
//       }
//     })
//     series.dataItems.each(function(dataItem){
//       if(dataItem.dataContext.region === name){
//         dataItem.hide(1000, 0, 0, ["valueX"]);
//       }
//     })    
//   }
//   else{
//     axisBreak.animate({property:"breakSize", to:1}, 1000, am4core.ease.cubicOut);
//     yAxis.dataItems.each(function(dataItem){
//       if(dataItem.dataContext.region === name){
//         dataItem.show(1000);
//       }
//     })  

//     series.dataItems.each(function(dataItem){
//       if(dataItem.dataContext.region === name){
//         dataItem.show(1000, 0, ["valueX"]);
//       }
//     })        
//   }
// })



  }

    render() {
      const {graphType}=this.props
     // console.log(graphType,'----probs-->>',this.props)
        return (
          <>{
            graphType === 'demandAndSaturation' ?
        <StyledFlex>
          {/* <div><span/> 100%</div>
          <div><span/> 90% To 99%</div>
          <div><span/> 70% To 89%</div>
          <div><span/> 50% To 69%</div>
          <div><span/> less than 50%</div> */}
          <div><span style={{background:'#0f6d16'}}/> more than 100%</div>
          <div><span style={{background:'#002b49'}}/> 75% To 100%</div>
          <div><span style={{background:'#f44336'}}/> less than 75%</div>
        </StyledFlex>: 
        graphType === 'groundingAndSanctioned' ?''
      //   <StyledFlex>
      //   <div><span/> Above 90%</div>
      //   <div><span/> 71% To 90%</div>
      //   <div><span/> 51% To 70%</div>
      //   <div><span/> 26% To 50%</div>
      //   <div><span/> 0% To 25%</div>
      // </StyledFlex>
       : graphType === 'completionAndSanctioned' ?
       <StyledFlex>
         <div><span/> 70% Above</div>
         <div><span/> 51% To 70%</div>
         <div><span/> 31% To 50%</div>
         <div><span/> 21% To 30%</div>
         <div><span/> less than 21%</div>
       </StyledFlex>
      :''}
        <div id={this.props.id ||'GroupLineChart'} className={this.props.id ||'GroupLineChart'} style={{width: '100%', height: this.props.height,...this.props.style}} />
        </>
        );
    }
}

export default GroupLineChartForConstructionCost;