import React, {PureComponent} from 'react'
import styled from 'styled-components'
import {Spinner} from 'react-bootstrap'


const Container = styled.div`
  width: 100%;
  height:100%;
  background: transparent;
  display: flex;
  padding: 20px; 
  align-items: center;
  padding-top:250px;
  position: relative;
  z-index: 999;
  top:0px;
  left:0px;
  flex-direction: column;
  & p {
    padding-top: 10px;
  }
`


class Loader extends PureComponent{
  state={
    open: false
  }
  render(){
    const {style={},title}=this.props
    return(
      <Container style={style}>
        <Spinner animation="border" variant="primary" />
        {title && <p>{title}</p>}
      </Container>
     
    )
  }
}

export {Loader}