import React from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

import Aux from "../hoc/_Aux";

// am4core.unuseTheme(am4themes_animated);
// am4core.useTheme(am4themes_material);

function am4themes_myTheme(target) {
    if (target instanceof am4core.ColorSet) {
      target.list = [
        am4core.color("#DC143C"),
        am4core.color("#32CD32"),
        am4core.color("#FF8C00"),
        am4core.color("#008B8B"),
        am4core.color("#8A2BE2"),
        am4core.color("#191970")
      ];
    }
  }


class PieChart extends React.Component {

    componentDidMount(){
        this.initilaize()    
    }

    
    componentDidUpdate(){
        this.initilaize()    
    }

    initilaize=()=> {
        if(this.props.theme===1){
            am4core.useTheme(am4themes_myTheme);
        }else{
            am4core.useTheme(am4themes_animated);
        }

        let chart = am4core.create(this.props.id ? this.props.id  : "ProjectCostChartForProjectDetails", am4charts.PieChart);
        chart.radius = am4core.percent(100);

        // chart.data = [{
        //     "country": "Central",
        //     "litres": 3.9900
        // }, {
        //     "country": "State",
        //     "litres": 3.9900
        // }, {
        //     "country": "ULB",
        //     "litres": 8.6555
        // }, {
        //     "country": "Beneficiary",
        //     "litres": 14.6300
        // } ];

        chart.data = this.props.data ? this.props.data : []

        let pieSeries = chart.series.push(new am4charts.PieSeries());
        // pieSeries.template.location = 0;
        pieSeries.dataFields.value = "litres";
        pieSeries.dataFields.category = "country";
        pieSeries.slices.template.stroke = am4core.color("#fff");
        pieSeries.slices.template.strokeWidth = 1;
        pieSeries.slices.template.strokeOpacity = 1;

        pieSeries.ticks.template.disabled = true;
        pieSeries.calculatePercent = true
        pieSeries.alignLabels = false;
        pieSeries.labels.template.text = "{category} \n{value.percent.formatNumber('#.0')}%";
        pieSeries.labels.template.fontSize = 10;
        pieSeries.labels.template.radius = am4core.percent(-50);
        pieSeries.labels.template.fill = am4core.color("#fff");
        
        // chart.legend = new am4charts.Legend();
        // chart.legend.position='right'
        // chart.legend.markers.template.width = 15
        // chart.legend.markers.template.height = 15
        // chart.legend.itemContainers.template.paddingTop = 0

        // pieSeries.events.on("datavalidated", function(ev) {
        //     ev.target.slices.each(function(slice) {
        //       if (slice.dataItem.values.value.percent ===0) {
        //         slice.dataItem.hide();
        //         slice.dataItem.legendDataItem.hide();
        //       }
        //     });
        //   });

        pieSeries.ticks.template.events.on("ready", hideSmall);
        pieSeries.ticks.template.events.on("visibilitychanged", hideSmall);
        pieSeries.labels.template.events.on("ready", hideSmall);
        pieSeries.labels.template.events.on("visibilitychanged", hideSmall);

function hideSmall(ev) {
  if (ev.target.dataItem && (ev.target.dataItem.values.value.percent < 5)) {
    ev.target.hide();
  }
  else {
    ev.target.show();
  }
}

    }

    // componentWillUnmount() {
    //     if (this.chart) {
    //         this.chart.dispose();
    //     }
    // }

    render() {
        return (
            <Aux>
                <div id={this.props.id ? this.props.id : "ProjectCostChartForProjectDetails"} style={{ width: "100%", height: "170px" }} />
            </Aux>
        );
    }
}

export default PieChart;