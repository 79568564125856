import React from 'react'
import styled from 'styled-components'
import { Row, Col, Form, Button } from 'react-bootstrap';
import { Formik, Field } from "formik";
import { DateInput } from './DateInput';
import { Input } from './Input';
import { BASE_URL } from '../store/constant'
import Axios from 'axios';
import map from 'lodash/map'
import find from 'lodash/find'
import get from 'lodash/get'
import pickBy from 'lodash/pickBy'
import identity from 'lodash/identity'

import { SelectBox } from './SelectBox'


const Type = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin:0;
    width: 100%;
    background: rgba(0, 0, 0, 0.10);
    padding: 8px;
    margin-bottom: 10px;
  & > div{
     padding-right: 20px;
    }
  & span{
    font-weight:600;
    padding-right: 20px
  }
`


const Wrapper = styled.section`
  background: ${props => props.background ? props.background : 'aquamarine'};
  padding: 0px 15px 6px 15px;
  margin-bottom: 15px;
`

class Filter extends React.Component {
  state = {
    isLoadingState: true,
    isLoadingDist: false,
    districtList: [],
    statesList: [],
    cityList: [],
    stateData: {}
  }
  componentDidMount = async () => {
    const { data } = await Axios.get(`${BASE_URL}/states`)
    if (get(this.props, 'data.stateCode')) {
      const { data: cityData } = await Axios.get(`${BASE_URL}/city/state/${get(this.props, 'data.stateCode')}`)
      this.setState({ cityList: cityData ? cityData : [] })
    }
    this.setState({ statesList: data, isLoadingState: false })
  }

  render() {
    const { statesList, districtList, isLoadingState, isLoadingDist, cityList } = this.state
    const selectedState = get(this.props, 'data.stateCode') ? find(statesList, obj => obj.statecode === Number(get(this.props, 'data.stateCode'))) : ''
    // console.log('selectedState----<>',selectedState)
    const {
      defaultFromDate='',
      defaultToDate='',
      showDateRange = false,
      getFilterData,
      submitButtonTitle = 'Submit',
      reportType= "projectWise",
      showType = ['stateWise', 'districtWise', 'cityWise', 'projectWise'],
      removeElement = [],
      filterName = 'Report Type'
    } = this.props
    return (
      <Wrapper>
        <Formik
          enableReinitialize={true}
          // validationSchema={get(this.props,'match.params.id') ? UpdateUserFormSchema : CreateUserFormSchema}
          initialValues={{
            reportType: reportType,
            fromDate: defaultFromDate,
            toDate: defaultToDate,
            state: { label: get(selectedState, 'statename'), value: get(selectedState, 'statecode') },
            district: "",
            city: "",
            scheme: '',
            component: '',
            CSMCNo: ''
          }}
          onSubmit={async (input, { resetForm, setValues }) => {
            getFilterData(pickBy(input, identity))
          }}
        >
          {formikBag => {
            console.log('formikBag----->>>', formikBag.values)
            return (
              <form
                onSubmit={event => {
                  event.preventDefault();
                  formikBag.handleSubmit()
                }}
              >
                <Row>
                  <Type>
                    <span>{filterName}:</span>
                    {showType.indexOf('stateWise') >= 0 && <Form.Check
                      custom
                      type="radio"
                      name='reportType'
                      id="stateWise"
                      label="State wise"
                      checked={get(formikBag.values, 'reportType') === 'stateWise'}
                      onClick={(e) => {
                        formikBag.setFieldValue('reportType', 'stateWise')
                      }}
                    />}
                    {showType.indexOf('districtWise') >= 0 && <Form.Check
                      custom
                      type="radio"
                      name='reportType'
                      id="districtWise"
                      label="District wise"
                      checked={get(formikBag.values, 'reportType') === 'districtWise'}
                      onClick={(e) => {
                        formikBag.setFieldValue('reportType', 'districtWise')
                      }}
                    />}
                    {showType.indexOf('cityWise') >= 0 && <Form.Check
                      custom
                      type="radio"
                      name='reportType'
                      id="cityWise"
                      label="City wise"
                      checked={get(formikBag.values, 'reportType') === 'cityWise'}
                      onClick={(e) => {
                        formikBag.setFieldValue('reportType', 'cityWise')
                      }}
                    />}
                    {showType.indexOf('projectWise') >= 0 && <Form.Check
                      custom
                      type="radio"
                      name='reportType'
                      id="projectWise"
                      label="Project wise"
                      checked={get(formikBag.values, 'reportType') === 'projectWise'}
                      onClick={(e) => {
                        formikBag.setFieldValue('reportType', 'projectWise')
                      }}
                    />}
                  </Type>
                </Row>

                {/* Start filter */}
                {get(formikBag.values, 'reportType') && <>
                  <Row>
                  {showDateRange &&
                    <>
                      <Col md={2} sm={2} xs={6} className="pr-md-2 pr-0">
                        <DateInput
                          label={'From Date'}
                          selected={formikBag.values.fromDate}
                          onChange={e =>
                            formikBag.setFieldValue('fromDate', e==='Invalid date' ? 'All' : e)
                          }
                        />
                      </Col>
                      <Col md={2} sm={2} xs={6} className='pr-0'>
                        <DateInput
                          label={'To Date'}
                          selected={formikBag.values.toDate}
                          onChange={e =>
                            formikBag.setFieldValue('toDate', e==='Invalid date' ? 'All' : e)
                          }
                        />
                      </Col>
                    </>}

                  <Col md={3} sm={3} xs={removeElement.indexOf('District') === -1 ? 4 : 6} className="pr-0">
                    <SelectBox
                      label='State'
                      options={[{ label: 'All', value: 'All' }, ...map(statesList, o => ({ label: o.statename, value: o.statecode }))]}
                      isLoading={isLoadingState}
                      isClearable={true}
                      isDisabled={get(this.props, 'data.stateCode') ? true : false}
                      value={get(formikBag.values, 'state')}
                      onChange={async (e) => {
                        this.setState({ isLoadingDist: true, isLoading: true, stateData: e })
                        formikBag.setFieldValue('state', e)
                        formikBag.setFieldValue('district', '')
                        formikBag.setFieldValue('city', '')
                        if (e) {
                          const { data: cityData } = await Axios.get(`${BASE_URL}/city/state/${get(e, 'value')}`)
                          const { data } = await Axios.get(`${BASE_URL}/district/${get(e, 'value')}`)
                          this.setState({ isLoadingDist: false, isLoading: false, cityList: cityData, districtList: data })
                        }
                        // console.log({cityData,data})
                      }}
                    />
                  </Col>
                  {(get(formikBag.values, 'reportType') === 'districtWise' || get(formikBag.values, 'reportType') === 'cityWise' || get(formikBag.values, 'reportType') === 'projectWise') && removeElement.indexOf('District') === -1 ?
                    <Col md={3} sm={3} xs={4} className="pr-0">
                      <SelectBox
                        label='District'
                        isClearable={true}
                        value={get(formikBag.values, 'district')}
                        options={[{ label: 'All', value: 'All' }, ...map(districtList, o => ({ label: o.DistrictName, value: o.DistrictCode }))]}
                        isLoading={isLoadingDist}
                        onChange={async (e) => {
                          this.setState({ isLoadingCity: true })
                          formikBag.setFieldValue('district', e)
                          formikBag.setFieldValue('city', '')
                          if (e) {
                            await Axios.get(`${BASE_URL}/city/${e.value}`).then(res => {
                              this.setState({ isLoadingCity: false, cityList: res.data })
                            }).catch(err => {
                              alert('Networt error.')
                              this.setState({ isLoadingCity: false })
                            })
                          }
                        }}
                      />
                    </Col> : ''}
                  {get(formikBag.values, 'reportType') === 'cityWise' || get(formikBag.values, 'reportType') === 'projectWise' ?
                    <Col md={removeElement.indexOf('District') === -1 ? 2 : 3} sm={removeElement.indexOf('District') === -1 ? 2 : 3} xs={removeElement.indexOf('District') === -1 ? 4 : 6} className="pr-md-0 pr-sm-0">
                      <SelectBox
                        label='City'
                        value={get(formikBag.values, 'city')}
                        options={[{ label: 'All', value: 'All' }, ...map(cityList, o => ({ label: o.CityName, value: o.CityCode }))]}
                        isClearable={true}
                        onChange={(e) => {
                          formikBag.setFieldValue('city', e)
                        }}
                      />
                    </Col> : ''}
                  {removeElement.indexOf('Scheme') === -1 ? <Col md={removeElement.indexOf('District') === -1 ? 2 : 3} sm={removeElement.indexOf('District') === -1 ? 2 : 3} xs={6} className="pr-0">
                    <SelectBox
                      label='Scheme'
                      options={[{ label: 'All', value: 'All' }, { label: 'PMAY-U', value: 'PMAY-U' }, { label: 'RAY', value: 'RAY' }, { label: 'JnNURM', value: 'JnNURM' }]}
                      value={get(formikBag.values, 'scheme')}
                      isClearable={true}
                      onChange={(e) => {
                        formikBag.setFieldValue('scheme', e)
                        formikBag.setFieldValue('component', '')
                      }}
                    />
                  </Col> : ''}
                  {removeElement.indexOf('Component') === -1 ?
                    <Col md={removeElement.indexOf('District') === -1 ? 2 : 3} sm={removeElement.indexOf('District') === -1 ? 2 : 3} xs={6}>
                      <SelectBox
                        label='Component'
                        options={get(formikBag.values, 'scheme.value') === 'PMAY-U' ? [{ label: 'All', value: 'All' }, { label: 'AHP', value: 'AHP' }, { label: 'BLC', value: 'BLC' }, { label: 'ISSR', value: 'ISSR' }] : get(formikBag.values, 'scheme.value') === 'RAY' ? [{ label: 'All', value: 'All' }, { label: 'RAY', value: 'RAY' }] : get(formikBag.values, 'scheme.value') === 'JnNURM' ? [{ label: 'All', value: 'All' }, { label: 'BSUP', value: 'BSUP' }, { label: 'IHSDP', value: 'IHSDP' }] : []}
                        value={get(formikBag.values, 'component')}
                        isClearable={true}
                        onChange={(e) => {
                          formikBag.setFieldValue('component', e)
                        }}
                      />
                    </Col>
                    : ''
                  }

                  {removeElement.indexOf('CSMCNo') === -1 ? <Col md={3} sm={3} xs={6}>
                    <Field name="CSMCNo">
                      {({ field, form }) => {
                        return (
                          <Input
                            label="CSMC No."
                            value={get(form.values, "CSMCNo", "")}
                            {...field}
                          />
                        )
                      }}
                    </Field>
                  </Col> : ''}
                  </Row>

                  <Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button style={{ width: 90 }} variant='warning' type='button' size={'sm'}
                      onClick={() => {
                        formikBag.setFieldValue('stateDate', '')
                        formikBag.setFieldValue('endDate', '')
                        formikBag.setFieldValue('state', '')
                        formikBag.setFieldValue('district', '')
                        formikBag.setFieldValue('city', '')
                        formikBag.setFieldValue('scheme', '')
                        formikBag.setFieldValue('component', '')
                      }}
                    >
                      <i className="feather icon-delete"></i> Clear
                    </Button>
                    <Button style={{width:150}} type='submit' size={'sm'}><i className="feather icon-download-cloud"></i>{submitButtonTitle}</Button>
                  </Row>
                </>}
              </form>
            );
          }}
        </Formik>
      </Wrapper>
    )
  }
}

export { Filter }