import React, { PureComponent } from "react";
import styled from "styled-components";
import { Row, Col, Card,Table } from "react-bootstrap";
import _ from 'lodash'
import {HideColumn} from './HideColumn'
import GroupLineChart from './GroupLineChart'
import get from 'lodash/get'
 
 import {Loader } from './Loader'

class ProgressGraph extends PureComponent {
  state = {
    excludeComponent:['AHP','ISSR','BLC','RAY'],
    totaldata:'',
    totalisAllIndia:'',
    totalisState:'',
    totalisDist:'',
    totalstatesList:'',
    totaldistrictList:'',
    totalcityList:'',
    isLoading: true,
  };
componentDidMount(){
    this.setState({
      isLoading: false,

    })
  }

  componentDidUpdate(){
    this.setState({
      isLoading: true,

    })
    this.setState({
      isLoading: false,

    })

  }

  // componentDidMount(){
  //   this.setState({
  //     totaldata:get(this.props,'data' || []).length,
  //     totalisAllIndia:get(this.props,'isAllIndia' || []),
  //     totalisState:get(this.props,'isState' || []),
  //     totalisDist:get(this.props,'isDist' || []),
  //     totalstatesList:get(this.props,'statesList' || []).length,
  //     totaldistrictList:get(this.props,'districtList' || []).length,
  //     totalcityList:get(this.props,'cityList' || []).length,

  //   })
  // }
  // componentDidUpdate(){
  //   this.setState({
  //     totaldata:get(this.props,'data' || []).length,
  //     totalisAllIndia:get(this.props,'isAllIndia' || []),
  //     totalisState:get(this.props,'isState' || []),
  //     totalisDist:get(this.props,'isDist' || []),
  //     totalstatesList:get(this.props,'statesList' || []).length,
  //     totaldistrictList:get(this.props,'districtList' || []).length,
  //     totalcityList:get(this.props,'cityList' || []).length,

  //   })
 
  //   console.log('<<<--jjkkkkkxcxckj-->>>');
    
  // }

  // shouldComponentUpdate(nextProps, nextState) {
  //   console.log('nextProps---->',nextProps)
  //   console.log('nextState---->',nextState)
    
  //       return (
  //         get(nextProps,'data' || []).length !== get(nextState,'totaldata') || get(nextProps,'isAllIndia' || '') !== get(nextState,'totalisAllIndia') || get(nextProps,'isState' || '')  !== get(nextState,'totalisState') ||get(nextProps,'isDist' || '')  !== get(nextState,'totalisDist') ||get(nextProps,'statesList' || []).length  !== get(nextState,'totalstatesList')  || get(nextProps,'districtList' || []).length  !== get(nextState,'totaldistrictList') ||get(nextProps,'cityList' || []).length !== get(nextState,'totalcityList') 
  //       );
  //     }
      
  render() {
    const { data = [], isAllIndia, isState, isDist, statesList=[], districtList=[], cityList=[],propsisLoading } = this.props;
    const {excludeComponent,isLoading}=this.state
console.log('---propsisLoading--->>>>>>>>>',propsisLoading);
      ////////////////////************Grounding/Completion Vs Sanctioned Graph Data***********///////////////////////
      const grfData = _.filter(data, f=> excludeComponent.indexOf(_.get(f,'Component')) !==-1)
     
      const calculateProgressGraphData =(type)=>{
      const fnlGraphData =  _.map(isAllIndia ? statesList : isState ? districtList : isDist ? cityList : [], (obj) => {
          const rowCode = isAllIndia ? _.get(obj,'statecode') : isState ? _.get(obj,'DistrictCode') : isDist ? _.get(obj,'CityCode'):''
          const rowName = isAllIndia ? _.get(obj,'statename') : isState ? _.get(obj,'DistrictName') : isDist ? _.get(obj,'CityName'):''
          const groupData = _.filter(grfData,f=> isAllIndia ? _.get(f,'StateCode')===rowCode : isState ? _.get(f,'DistrictCode')===rowCode : isDist ? _.get(f,'CityCode')===rowCode : false )
         
          const gSanctioned = _.sumBy(groupData,sm=>Number(_.get(sm,'HousesSanctionedEnahncement') || 0)+Number(_.get(sm,'HousesSanctionedNew') || 0) )
          const gGrounded = _.sumBy(groupData,sm=>_.get(sm,'totalWorkOrders') ? _.get(sm,'totalWorkOrders'):0 )
          const gCompleted = _.sumBy(groupData,sm=>_.get(sm,'completed') ? _.get(sm,'completed'):0 )
  
          // console.log(rowName,'*--------->',{gSanctioned,gGrounded,gCompleted})
        //  console.log(obj,'---->>',rowName,rowCode, groupData,obj)
        if(type==='ground'){
          const score =
          Math.round(
            (gGrounded / gSanctioned) *
              100
          ) > 100
            ? 100
            :  Math.round(
              (gGrounded / gSanctioned) *
                100
            )
        return {
          region: isAllIndia ? (obj.UT
            ? "Union Territories"
            : obj.NEState
            ? "North East States"
            : "States") : isState ? 'States' : isDist ? 'Cities' : 'null',
          state: `${rowName}-${score ? score : 0}%`,
          dataSet: gGrounded,
          secondValue: gSanctioned,
          score: score ? score : 0,
        };
      }else{
        const score =
        Math.round(
          (gCompleted /
            gSanctioned) *
            100
        ) > 100
          ? 100
          :  Math.round(
            (gCompleted /
              gSanctioned) *
              100
          )
      return {
        region: isAllIndia ? (obj.UT
          ? "Union Territories"
          : obj.NEState
          ? "North East States"
          : "States"): isState ? 'States' : isDist ? 'Cities' : 'null',
        state: `${rowName}-${score ? score : 0}%`,
        dataSet: gCompleted,
        secondValue: gSanctioned,
        score: score ? score : 0,
      };
      }
      });
      this.setState({isLoading: false})
      return fnlGraphData
    }
  
      ////////////////////************Grounding/Completion Vs Sanctioned Graph Data***********///////////////////////
  
    const groundGraph = isDist ? _.filter(calculateProgressGraphData('ground'),f=>f.score > 0) : calculateProgressGraphData('ground');
    const completedGraph = isDist ?  _.filter(calculateProgressGraphData(),f=>f.score > 0) : calculateProgressGraphData('');

// console.log('groundGraph--->>>',groundGraph);
// console.log('completedGraph--->>>',completedGraph);

    if (isLoading) {
      return (
        <Loader />
      )
    }
if(propsisLoading===false){
    return(
      <Row
      className="printParent"
      style={{
        pageBreakAfter: "always",
        display: "flex",
        margin: 0,
        background:'#72e5be'
      }}
    >
      <div md={12}>
      <HideColumn
        columnList={[
          {title:'AHP', key:'AHP'},
          {title:'BLC', key:'BLC'},
          {title:'ISSR', key:'ISSR'},
          {title:'RAY', key:'RAY'},
        ]}
        name="Show Component"
        hideColumn={this.state.excludeComponent}
        getHideColumn={(excludeComponent)=>this.setState({excludeComponent,
          isLoading: true
        })}
        
      />
      </div>
      <div
        md={6}
        className="printChildSetup"
        style={{
          pageBreakAfter: "always",
          width: "50%",
          paddingRight: 15,
        }}
      >
        <Card>
          <Card.Header>
            <Card.Title as="h5">
            {isAllIndia ? 'State wise' : isState ? 'District wise' : isDist ? 'City wise' :''} Grounding of Sanctioned Houses{" "}
              <small style={{ fontSize: 12 }}>(Nos)</small>
            </Card.Title>
          </Card.Header>
          <Card.Body style={{ padding: 0 }}>
            {/* {get(dashboardData, "progressData") && (
        <DashboardSanctionedHousesGraph height="900px" />
      )} */}

            {_.get(groundGraph, "length") ? (
              <GroupLineChart
                removeRegion={!isAllIndia}
                graphType="groundingAndSanctioned"
                id="groundingAndSanctioned"
                graphData={groundGraph}
                height={(_.get(groundGraph,'length')*(_.get(groundGraph,'length') < 5 ? 50 : 31.61))+'px'}
              />
            ) : (
              "Data not Found."
            )}
          </Card.Body>
        </Card>
      </div>

      <div
        md={6}
        className="pl-md-0 printChildSetup"
        style={{ pageBreakAfter: "always", width: "50%" }}
      >
        <Card>
          <Card.Header>
            <Card.Title as="h5">
            {isAllIndia ? 'State wise' : isState ? 'District wise' : isDist ? 'City wise' :''} Completion out of Sanctioned Houses{" "}
              <small style={{ fontSize: 12 }}>(Nos)</small>
            </Card.Title>
          </Card.Header>
          <Card.Body style={{ padding: 0 }}>
            {/* {get(dashboardData, "progressData") && (
        <DashboardCompletionHousesGraph height="900px" />
      )} */}
            {_.get(completedGraph, "length") ? (
              <GroupLineChart
                removeRegion={!isAllIndia}
                graphType="completionAndSanctioned"
                id="completionAndSanctioned"
                graphData={completedGraph}
                height={(_.get(completedGraph,'length')*(_.get(completedGraph,'length') < 5 ? 50 : 31.61))+'px'}
              />
            ) : (
              "Data not Found."
            )}
          </Card.Body>
        </Card>
      </div>
    </Row>
    )
            }
  }
}

export default ProgressGraph;
