import React, {PureComponent} from 'react'
import styled from 'styled-components'

const Container = styled.div`
  width:100%;
  margin:0;
  padding:0;
`
const Header = styled.div`
  width:100%;
  margin:0;
  padding:0;
  position:relative;
  cursor:pointer;
  & i{
    color: #fff;
    position: absolute;
    right: 5px;
    top: 6px;
  }
`
const ContentArea = styled.div`
  width:100%;
  margin:0;
  padding:0;
  overflow:hidden;
  height: ${(props)=> props.height ? props.height:"auto" }
`

class Collapsible extends PureComponent{
  state={
    open: false
  }
  render(){
    const {title, children}=this.props
    return(
      <Container>
        <Header onClick={()=> this.setState({open:!this.state.open})}>{title} <i className={this.state.open ? "fa fa-minus" : "fa fa-plus"}/></Header>
        <ContentArea height={this.state.open ? 'auto' : '0px'}>
          {children}
        </ContentArea>
      </Container>
     
    )
  }
}

export default Collapsible;