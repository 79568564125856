import React, { PureComponent } from "react";
import styled from "styled-components";
import { Row, Col, Card, Table } from "react-bootstrap";
import NoImage from "../assets/images/no_image.jpg";
import get from "lodash/get";
import _ from "lodash"
import map from "lodash/map";
import moment from "moment";
import Lightbox from "react-images-texts-videos";
import { Link } from "react-router-dom/cjs/react-router-dom";

const Container = styled.div`
  width: 100%;
  margin: 0;
  padding: 0;

  & img {
    width: 100%;
  }

  & p {
    margin: 0px;
    margin: 0px;
    font-size: 14px !important;
    margin-bottom: 5px;
    & span {
      font-weight: 600;
    }
  }
`;

const TestimonialImg = styled.div`
  width: 120px;
  height: 120px;
  overflow: hidden;
  & img {
    width: 100%;
    height: auto;
    border-radius: 4px;
  }

  & input[type="checkbox"] {
    display: none;
  }

  & img {
    transition: transform 0.25s ease;
    cursor: zoom-in;
  }

  & input[type="checkbox"]:checked ~ label {
    display: inline-block;
    overflow: initial;
    position: absolute;
    width: 237px;

    & img {
      transform: scale(2);
      cursor: zoom-out;
      position: relative;
      z-index: 9999;
    }
  }

  // &:focus {
  //   img {
  //     transform: scale(2);
  //     position: relative;
  //     z-index: 9999;
  //   }
  //   overflow: inherit;
  // }
`;

const ImageView = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Stage = styled.div`
  width: 100%;
  text-align: center;
  text-transform: capitalize;
  color: white;
  font-size:10px;

`;

const Wrapper = styled.section`
  position: relative;
  & td > span {
    display: block;
    text-align: center;
    font-size: 10px;
    font-weight: 600;
  }
  @media print {
    button {
      display: none;
    }
  }
`;

class BeneficiaryCardView extends PureComponent {
  state = {
    open: false,
    lightboxIsOpen: false,
    currentItem: 0,
  };



  render() {
    const { data = [], component, images = [],projectData=[],TableWrapper=[] } = this.props;
    // console.log("--------->", {images,data});
    // console.log('--------component------->>>>',component);

 console.log('--------projectData------->>>>',projectData);
  console.log('--------TableWrapper------->>>>',TableWrapper);

    if (component !== "BLC") {
      return (
        <Container>
          <Row>
            <Col md={6} sm={6}>
              <Wrapper>
                <Table
                  striped
                  bordered
                  className="bg-c-white tableStickyHeader"
                  style={{ fontSize: 12 }}
                >
                  <thead
                    className="label label-warning text-white text-center"
                    style={{ backgroundColor: "#002b49" }}
                  >
                    <tr>
                      <th style={{ width: 40 }} className="center">
                        Sr.
                        <br />
                        No.
                      </th>
                      <th>
                        Name
                      </th>
                      <th>Father Name</th>
                      <th>Occupation</th>
                      <th>Caste</th>

                    </tr>
                  </thead>
                  <tbody>
                    {map(data, (obj, i) => {
                      console.log('obj---->',obj);

                      return (
                        <tr
                          key={i}
                        >
                          <td style={{ widtd: 40 }} className="center">
                            {i + 1}
                          </td>
                          <td><Link to={`/admin-services/NHB_PMAY?benefCode=${get(obj, "Beneficiary_Code")}`} target="_blank">{get(obj, "Beneficiary_Name") ? get(obj, "Beneficiary_Name") : '-'}</Link></td>
                          <td>{get(obj, "Father_Name") ? get(obj, "Father_Name") : '-'}</td>
                          <td style={{ maxWidth: 150, width: 150, textAlign: 'left', wordBreak: 'break-word', whiteSpace: 'inherit' }}>{get(obj, "Occupation_Name") ? get(obj, "Occupation_Name") : '-'}</td>
                          <td>{get(obj, "Caste") ? get(obj, "Caste") : '-'}</td>

                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Wrapper>
            </Col>
            <Col md={6} sm={6}>
              {
                map(_.groupBy(images, 'Project_Location'), (item, itm) => {
                 // console.log('item--->',item);

                  return (
                    <>
                      <Stage style={{ background: "#002b49" }}>

                        Location :{" "}
                        {get(_.first(item), "Project_Location")}

                      </Stage>


                      <ImageView style={{ flexWrap: 'wrap' }}>
                        {map(item, (f, idx) => {

                          return (
                            <TestimonialImg key={idx} style={{ marginBottom: 15 }}
                              onClick={() => this.setState({
                                lightboxIsOpen: true,
                                currentItem: idx,
                                // galleyList: _.map(imgList, (o) => ({
                                //   src: `${BASE_URL}/images/${o.fileName}`,
                                // })),
                              })}>

                              <Stage style={{ background: "#32cd32" }}>
                                Date:{" "}
                                {get(f, "capturedOn")}
                              </Stage>
                              <Stage style={{ background: "rgb(28 158 28)" }}>
                                Block:{" "}
                                {get(f, "Block_No")}
                              </Stage>
                            
                              <label htmlFor={idx}>
                                <img alt={get(f, "capturedOn")} src={get(f, "src")} />
                              </label>
                        

                           
                            </TestimonialImg>
                          

                          );
                        })}
                      </ImageView></>
                  )

                })
              }




            </Col>
          </Row>
          {this.state.lightboxIsOpen && images.length ? (
            <Lightbox
              items={{ type: "images", items: images }}
              isOpen={
                this.state.lightboxIsOpen && images.length ? true : false
              }
              onClickPrev={() =>
                this.setState({ currentItem: this.state.currentItem - 1 })
              }
              onClickNext={() =>
                this.setState({ currentItem: this.state.currentItem + 1 })
              }
              currentItem={this.state.currentItem}
              onClose={() =>
                this.setState({ lightboxIsOpen: false, currentItem: 0 })
              }
            />
          ) : (
            ""
          )}
        </Container>
      );
    } else {
      return (
        <Container>
          {/* <Header onClick={()=> this.setState({open:!this.state.open})}>{title} <i className={this.state.open ? "fa fa-minus" : "fa fa-plus"}/></Header>
        <ContentArea height={this.state.open ? 'auto' : '0px'}>
          {children}
        </ContentArea> */}

                      <TableWrapper>
                        <table>
                          <tbody style={{border:'3px' }}>
                            <tr>
                              <td className="greenBg" rowSpan={2} style={{borderBottom:'2px solid #ed0b0b',color:'blue',background:'rgb(237 214 214)',fontWeight:'bold'}}>
                                Beneficiaries Status
                              </td>
                        
                              <td className="greenBg" style={{background:'rgb(255, 165, 0)'}}>Plinth</td>
                              <td className="greenBg" style={{background:'rgb(139, 195, 74)'}}>Lintel</td>
                              <td className="greenBg" style={{background:'#45a951'}}>Roof</td>
                              <td className="greenBg" style={{ width: 125,background:'#228b22' }}>
                                Completed
                              </td>
                            
                            </tr>
                            <tr>
                              <td  style={{borderBottom:'2px solid #ed0b0b'}}>
                                {_.get(projectData, "plinth")
                                  ? Number(
                                    _.get(projectData, "plinth")
                                  ).toLocaleString("en-in")
                                  : "-"}
                              </td>
                              <td  style={{borderBottom:'2px solid #ed0b0b'}}>
                                {_.get(projectData, "lintel")
                                  ? Number(
                                    _.get(projectData, "lintel")
                                  ).toLocaleString("en-in")
                                  : "-"}
                              </td>
                              <td style={{borderBottom:'2px solid #ed0b0b'}}>
                                {_.get(projectData, "roof")
                                  ? Number(
                                    _.get(projectData, "roof")
                                  ).toLocaleString("en-in")
                                  : "-"}
                              </td>
                             
                              <td  style={{borderBottom:'2px solid #ed0b0b'}}>
                                {_.get(projectData, "completed")
                                  ? Number(
                                    _.get(projectData, "completed")
                                  ).toLocaleString("en-in")
                                  : "-"}
                              </td>
                            </tr>
                         
                          </tbody>
                        </table>
                      </TableWrapper>

        
          {map(data, (obj, i) => {
            const misDataIsPresent = get(obj, 'Stage_3') && get(obj, 'Stage_4') && get(obj, 'Stage_5') && get(obj, 'Stage_6')
// console.log('---misDataIsPresent--------->>>>>>',misDataIsPresent);

// console.log('---obj1--------->>>>>>',obj);
            return (
              <Card key={get(obj, "BeneficiaryCode")}>
             <Row>
              <Col md={12}
                    sm={12}
                    xs={12}></Col>
             </Row>
                <Row>
                  <Col
                    md={1}
                    sm={1}
                    xs={12}
                    style={{
                      width: 60,
                      textAlign: "center",
                      padding: 0,
                      paddingTop: 10,
                    }}
                  >
                    {i + 1}.
                  </Col>
                  <Col md={3} sm={3} xs={12}>
                    <p>
                      <span>Name :</span>{" "}
                      <Link to={`/admin-services/NHB_PMAY?benefCode=${get(obj, "BeneficiaryCode")}`} target="_blank">
                      {get(obj, "BeneficiaryName")
                        ? get(obj, "BeneficiaryName")
                        : ""}
                      </Link>
                    </p>
                    <p>
                      <span>Father Name :</span>{" "}
                      {get(obj, "FatherName") ? get(obj, "FatherName") : ""}
                    </p>
                    <p>
                      <span>Occupation Name :</span>{" "}
                      {get(obj, "OccupationName")
                        ? get(obj, "OccupationName")
                        : ""}
                    </p>
                    <p>
                      <span>Caste :</span>{" "}
                      {get(obj, "Caste") ? get(obj, "Caste") : ""}
                    </p>
                    <p>
                      <span>Religion :</span>{" "}
                      {get(obj, "Religion") ? get(obj, "Religion") : ""}
                    </p>

                  </Col>
                  <Col md={8} sm={8} xs={12}>
                    {
                    // misDataIsPresent ? 
                    <ImageView>
                      {get(obj, 'Stage_6') ? <TestimonialImg>
                        <Stage style={{ background: '#32cd32' }}>{get(obj, 'Stage_6')}</Stage>
                        <Stage style={{ background: '#32cd32' }}>
                          Date:{" "}
                          {get(obj, "Accepted_On_Completed") ? moment(get(obj, "Accepted_On_Completed"), 'DD/MM/YYYY').format(
                            "DD-MMM-YYYY"
                          ) : '-'}
                        </Stage>
                        <input
                          name="imgZoom"
                          type="checkbox"
                          id={get(obj, "BeneficiaryCode") + get(obj, "AnnexureID") + 'Stage_6'}
                        />
                        <label htmlFor={get(obj, "BeneficiaryCode") + get(obj, "AnnexureID") + 'Stage_6'}>
                          <img alt={get(obj, "BeneficiaryName")} src={get(obj, "S6P1")} />
                        </label>
                      </TestimonialImg> : ''}
                      {get(obj, 'Stage_5') ? <TestimonialImg>
                        <Stage style={{ background: '#002b49' }}>{get(obj, 'Stage_5')}</Stage>
                        <Stage style={{ background: '#002b49' }}>
                          Date:{" "}
                          {get(obj, "Accepted_On_Roof") ? moment(get(obj, "Accepted_On_Roof"), 'DD/MM/YYYY').format(
                            "DD-MMM-YYYY"
                          ) : '-'}
                        </Stage>
                        <input
                          name="imgZoom"
                          type="checkbox"
                          id={get(obj, "BeneficiaryCode") + get(obj, "AnnexureID") + 'Stage_5'}
                        />
                        <label htmlFor={get(obj, "BeneficiaryCode") + get(obj, "AnnexureID") + 'Stage_5'}>
                          <img alt={get(obj, "Beneficiary_Name")} src={get(obj, "S5P1")} />
                        </label>
                      </TestimonialImg> : ''}
                      {get(obj, 'Stage_4') ? <TestimonialImg>
                        <Stage style={{ background: '#002b49' }}>{get(obj, 'Stage_4')}</Stage>
                        <Stage style={{ background: '#002b49' }}>
                          Date:{" "}
                          {get(obj, "Accepted_On_Lintel") ? moment(get(obj, "Accepted_On_Lintel"), 'DD/MM/YYYY').format(
                            "DD-MMM-YYYY"
                          ) : '-'}
                        </Stage>
                        <input
                          name="imgZoom"
                          type="checkbox"
                          id={get(obj, "BeneficiaryCode") + get(obj, "AnnexureID") + 'Stage_4'}
                        />
                        <label htmlFor={get(obj, "BeneficiaryCode") + get(obj, "AnnexureID") + 'Stage_4'}>
                          <img alt={get(obj, "BeneficiaryName")} src={get(obj, "S4P1")} />
                        </label>
                      </TestimonialImg> : ''}
                      {get(obj, 'Stage_3') ? <TestimonialImg>
                        <Stage style={{ background: '#002b49' }}>{get(obj, 'Stage_3')}</Stage>
                        <Stage style={{ background: '#002b49' }}>
                          Date:{" "}
                          {get(obj, "Accepted_On_Foundation") ? moment(get(obj, "Accepted_On_Foundation"), 'DD/MM/YYYY').format(
                            "DD-MMM-YYYY"
                          ) : '-'}
                        </Stage>
                        <input
                          name="imgZoom"
                          type="checkbox"
                          id={get(obj, "BeneficiaryCode") + get(obj, "AnnexureID") + 'Stage_3'}
                        />
                        <label htmlFor={get(obj, "BeneficiaryCode") + get(obj, "AnnexureID") + 'Stage_3'}>
                          <img alt={get(obj, "BeneficiaryName")} src={get(obj, "S3P1")} />
                        </label>
                      </TestimonialImg> : ''}
                    </ImageView>

                      // : 
                      // <ImageView>
                      //   {map(get(obj, "bn"), (f, idx) => {
                      //     const uri = get(f, "uri");
                      //     const lastCrt = uri.charAt(uri.length - 1);
                      //     const imgPath =
                      //       get(f, "uri") +
                      //       (lastCrt === "/" ? "" : "/") +
                      //       get(f, "photo1");
                      //     return (
                      //       <React.Fragment key={idx}>
                      //         {get(f, "construction_stage") === "Completed" ? (
                      //           <TestimonialImg>
                      //             <Stage style={{ background: '#32cd32' }}>{get(f, 'construction_stage')}</Stage>
                      //             <Stage style={{ background: "#32cd32" }}>
                      //               Date:{" "}
                      //               {moment(get(f, "ser_time")).format(
                      //                 "DD-MMM-YYYY"
                      //               )}
                      //             </Stage>
                      //             <input
                      //               type="checkbox"
                      //               id={get(obj, "BeneficiaryCode") + idx}
                      //             />
                      //             <label htmlFor={get(obj, "BeneficiaryCode") + idx}>
                      //               <img alt={get(f, "ben_name")} src={imgPath} />
                      //             </label>
                      //           </TestimonialImg>
                      //         ) : (
                      //           <TestimonialImg>
                      //             <Stage style={{ background: '#c00303' }}>{get(f, 'construction_stage')}</Stage>
                      //             <Stage style={{ background: "#c00303" }}>
                      //               Date:{" "}
                      //               {moment(get(f, "ser_time")).format(
                      //                 "DD-MMM-YYYY"
                      //               )}
                      //             </Stage>
                      //             <input
                      //               type="checkbox"
                      //               id={get(obj, "BeneficiaryCode") + idx}
                      //             />
                      //             <label htmlFor={get(obj, "BeneficiaryCode") + idx}>
                      //               <img alt={get(f, "ben_name")} src={imgPath} />
                      //             </label>
                      //           </TestimonialImg>
                      //         )}
                      //       </React.Fragment>
                      //     );
                      //   })}
                      // </ImageView>
                   
                      }
                  </Col>
                </Row>
              </Card>
            );
          })}


        </Container>
      );
    }
  }
}

export default BeneficiaryCardView;
