import React, { PureComponent } from "react";
import { Form } from "react-bootstrap";

import styled from "styled-components";

const Container = styled.div`
  position: relative;
`;
const StyledInput = styled.textarea`
  background: #f4f7fa;
  padding: 10px;
  width: 100%;
  border-radius: 0.2rem;
  border: 1px solid #ced4da;
  &:focus {
    box-shadow: 0 0 0 1px rgba(0, 123, 255, 0.25);
  }
`;
const Label = styled(Form.Label)`
  font-size: 13px;
  & span {
    color: red;
  }
`;

class TextArea extends PureComponent {
  state = {
    open: false
  };
  render() {
    const {
      type = "text",
      label,
      name = "uniqeName",
      isrequiredicon,
      onChange,
      className,
      accept
    } = this.props;

    // console.log("this.props----->>", this.props);
    return (
      <Container className={className}>
        <Form.Group controlId={name}>
          {label && (
            <Label>
              {label} {isrequiredicon ? <span>*</span> : ""}
            </Label>
          )}
          <StyledInput
            autoComplete="off"
            size={"sm"}
            type={type}
            {...this.props}
            accept={accept}
            onChange={event => (onChange ? onChange(event) : null)}
          />
        </Form.Group>
      </Container>
    );
  }
}

export { TextArea };
