import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import config from '../config';
import navigation from '../menu-items';
import { DEMO } from "../store/constant";
import Aux from "../hoc/_Aux";
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const CustomComponent = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom:3px;
  & * >{
    margin: 0 5px;
  }
  & > .custom-control {
    line-height: 24px;
    padding-right: 20px;
  }
`

class Breadcrumb extends Component {
    state = {
        main: [],
        item: []
    };

    componentDidMount() {
        (navigation.items).map((item, index) => {
            if (item.type && item.type === 'group') {
                this.getCollapse(item, index);
            }
            return false;
        });
    };

    UNSAFE_componentWillReceiveProps = () => {
        (navigation.items).map((item, index) => {
            if (item.type && item.type === 'group') {
                this.getCollapse(item);
            }
            return false;
        });
    };

    getCollapse = (item) => {
        if (item.children) {
            (item.children).filter( collapse => {
                if (collapse.type && collapse.type === 'collapse') {
                    this.getCollapse(collapse,);
                } else if (collapse.type && collapse.type === 'item') {
                    if (document.location.pathname === config.basename+collapse.url) {
                        this.setState({item: collapse, main: item});
                    }
                }
                return false;
            });
        }
    };

    render() {
        // console.log('this.state---->', this.state)
        let main, item;
        let breadcrumb = '';
        let title = 'Welcome';
        if (this.state.main && this.state.main.type === 'collapse') {
            main = (
                <li className="breadcrumb-item">
                    <a href={DEMO.BLANK_LINK}>{this.state.main.title}</a>
                </li>
            );
        }

        if (this.state.item && this.state.item.type === 'item') {
            title = this.state.item.title;
            item = (
                <li className="breadcrumb-item">
                    <a href={DEMO.BLANK_LINK}>{title}</a>
                </li>
            );

            if(this.state.item.breadcrumbs !== false) {
                breadcrumb = (
                    <div className="page-header">
                        <div className="page-block">
                            <div className="row align-items-center">
                                <Wrapper className="col-md-12">
                                  <div>
                                    <div className="page-header-title">
                                        <h5 className="m-b-5">{title}</h5>
                                    </div>
                                    <ul className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link to="/"><i className="feather icon-home"/></Link>
                                        </li>
                                        {main}
                                        {item}
                                    </ul>
                                    </div>
                                    <CustomComponent style={{display:'block'}}>
                                    {this.props.search}
                                    </CustomComponent>
                                    <CustomComponent style={{display:'block'}}>
                                      <CustomComponent style={{color: '#111',fontWeight: 600,fontSize: 10}}>
                                        {this.props.anyComponent1}
                                      </CustomComponent>
                                      <CustomComponent>
                                        {this.props.anyComponent}
                                     </CustomComponent>
                                    </CustomComponent>
                                </Wrapper>
                            </div>
                        </div>
                    </div>
                );
            }

        }

        document.title = this.props.title ? this.props.title : title + ' | DRMC Dashboard';

        return (
            <Aux>
                {breadcrumb}
            </Aux>
        );
    }
}

export default Breadcrumb;