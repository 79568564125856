import React from "react";
import { Table } from "react-bootstrap";
import { Loader } from "./Loader";
import { BASE_URL } from "../store/constant";
import Axios from "axios";
import map from "lodash/map";
import get from "lodash/get";
import find from "lodash/find";
import _ from 'lodash'
import { toast } from "react-toastify";
import styled from "styled-components";
import moment from "moment";
import { exportTableToExcel } from '../utils'

const Wrapper = styled.div`
  position: relative;
  & h3 {
    text-align: center;
    display: none;
  }

  @media print {
    h3 {
      display: block;
    }
  }
`;

class ComponentList extends React.Component {
  state = {
    isLoading: true,
    projectDataList: [],
    excesProjectWithExcludeComponent: [],
    excesProjectInCaseOfCity: [],
    ScoreCard: '',
  };

  componentDidMount = async () => {
    this.setState({ isLoading: true });

    try {

      if (get(this.props, "CSMCNo")) {
        const { data } = await Axios.get(
          `${BASE_URL}/project-details/report/csmc/${get(
            this.props,
            "CSMCNo"
          )}/${get(this.props, "stateCode")}`
        );
        const { data: geotagData } = await Axios.get(`${BASE_URL}/geotag/geotagAllData`)
        //const { data: excesProjectWithExcludeComponent } = await Axios.get(`${BASE_URL}/exces/balance/${get(this.props, "stateCode")}`)

        const { data: excesProjectWithExcludeComponent } = await Axios.get(`${BASE_URL}/exces/recalledBlanceAllData`);
        this.setState({geotagData, ScoreCard: get(this.props, "ScoreCard"), projectDataList: data, excesProjectWithExcludeComponent, isLoading: false });
      } else if (get(this.props, "cityCode")) {
        const { data } = await Axios.get(
          `${BASE_URL}/project-details/report/city/${get(
            this.props,
            "cityCode"
          )}`
        );
        const { data: geotagData } = await Axios.get(`${BASE_URL}/geotag/geotagAllData`)
        const excludeComponent = get(this.props, "excludeComponent");
        const projectDataList2 = _.filter(data, obj => excludeComponent.indexOf(obj.Component) === -1)

        const { data: fltStateWiseAllData } = await Axios.get(`${BASE_URL}/exces/recalledBlanceAllData`);
        const ExcludeComponentExcessData = _.filter(fltStateWiseAllData, obj => excludeComponent.indexOf(obj.Component) === -1)

        const excesProjectInCaseOfCity = _.filter(ExcludeComponentExcessData, flt => Number(flt.CityCode) === Number(get(this.props, "cityCode")));
        this.setState({geotagData, projectDataList: projectDataList2, excesProjectInCaseOfCity, isLoading: false });
      }
    } catch (err) {
      this.setState({ isLoading: false });
      toast.error("Something went wronge.");
    }
  };

  render() {
    const { isLoading,geotagData, ScoreCard, projectDataList, excesProjectWithExcludeComponent, excesProjectInCaseOfCity } = this.state;
    const hideColumn = get(this.props, "hideColumn") ? get(this.props, "hideColumn") : [];

    const componentLengthValue1 = _.intersection(hideColumn, ["installmentUC", "totalInstallmentReleased", "CAApproved", "projectCost"]).length;
    const FinancialProgresslength = componentLengthValue1 ? 4 - componentLengthValue1 : 4;

    const componentLengthValue2 = _.intersection(hideColumn, ["housesOccupied", "housesCompleted", "housesGroundedForConstruction", "housesApproved"]).length;
    const PhysicalProgresslength = componentLengthValue2 ? 4 - componentLengthValue2 : 4;
    const columnPermission = get(this.props, "columnPermission") ? get(this.props, "columnPermission") : [];
    const hideAndShowColumn = get(this.props, "hideAndShowColumn") ? get(this.props, "hideAndShowColumn") : [];
    const csmcNo = get(this.props, "csmcNo") ? get(this.props, "csmcNo") : '';



    //console.log('hideColumn--->',hideColumn);

    return (
      <Wrapper>
        <h3>Project List of CMSC No. {get(this.props, "CSMCNo")}</h3>
        {isLoading ? (
          <>
            <Table striped bordered className="bg-c-white customTable" id="MonitoringofProgress">
              <thead
                className="text-white text-center"
                style={{ background: "#002b49" }}
              >
                <tr style={{ textAlign: "center" }}>
                  <th rowSpan={2} style={{ width: 30, maxWidth: 30 }} className="center">
                    Sr.
                  </th>
                  <th rowSpan={2}>Component</th>
                  <th rowSpan={2}>City</th>
                  <th rowSpan={2} style={{ width: 250, minWidth: 250 }}>Project Title</th>
                  <th rowSpan={2}>CSMC Date</th>
                  <th colSpan={FinancialProgresslength}>Financial Progress <span className="text-c-yellow">(Rs in Cr.)</span></th>
                  <th colSpan={PhysicalProgresslength}>Physical Progress <span className="text-c-yellow">(Nos.)</span></th>
                  <th rowSpan='2'> Beneficiaries<br />Attached<br /> <span className="text-c-yellow">(Nos)</span></th>
                  <th colSpan='4'> Additional Funds may be  Released<br /> <span className="text-c-yellow">(₹ in Cr.)</span></th>
                  <th colSpan='3'> 	Houses for additional Funds Released<br /> <span className="text-c-yellow">(Nos)</span></th>
                </tr>
                <tr style={{ textAlign: "center", fontSize: 12 }}>
                  {hideColumn.indexOf('projectCost') === -1 ? <th>
                    Project <br />Cost <br />

                  </th> : ''}
                  {hideColumn.indexOf('CAApproved') === -1 ? <th>
                    Central <br />Assistance
                    <br />

                  </th> : ''}
                  {hideColumn.indexOf('totalInstallmentReleased') === -1 ? <th>
                    Funds
                    <br />
                    Released <br />

                  </th> : ''}
                  {hideColumn.indexOf('installmentUC') === -1 ? <th>
                    UC <br />Received <br />

                  </th> : ''}
                  {hideColumn.indexOf('housesApproved') === -1 ? <th>
                    Houses <br />
                    Approved <br />

                  </th> : ''}
                  {hideColumn.indexOf('housesGroundedForConstruction') === -1 ? <th>
                    Houses <br />
                    Grounded <br />

                  </th> : ''}
                  {hideColumn.indexOf('housesCompleted') === -1 ? <th>
                    Houses <br />
                    Completed <br />

                  </th> : ''}
                  {hideColumn.indexOf('housesOccupied') === -1 ? <th>
                    Houses <br />
                    Occupied <br />

                  </th> : ''}

                  <th>1st.</th>
                  <th>2nd.</th>
                  <th>3rd.</th>
                  <th>Total</th>
                  <th>1st.</th>
                  <th>2nd.</th>
                  <th>3rd.</th>
                </tr>

              </thead>
            </Table>
            <Loader style={{ position: "absolute", height: 190 }} />
          </>
        ) : (
          <Table className="bg-c-white customTable" id="MonitoringofProgress">

            <thead
              className="label label-warning text-white text-center"
              style={{ backgroundColor: "#002b49" }}
            >
              <tr style={{ textAlign: "center" }}>
                <th rowSpan={2} style={{ width: 30, maxWidth: 30 }} className="center">
                  Sr.
                </th>
                <th rowSpan={2}>Component</th>
                <th rowSpan={2}>City</th>
                <th rowSpan={2} style={{ width: 250, minWidth: 250 }}>Project Title</th>
                <th rowSpan={2}>CSMC Date</th>
                {componentLengthValue1 !== 4 ? <th colSpan={FinancialProgresslength}>Financial Progress <span className="text-c-yellow">(Rs in Cr.)</span></th> : ''}
                {componentLengthValue2 !== 4 ? <th colSpan={PhysicalProgresslength}>Physical Progress <span className="text-c-yellow">(Nos.)</span></th> : ''}

                {ScoreCard === 'ScoreCard' ? '' : csmcNo ? columnPermission.indexOf('installmentReleased') !== -1 && hideColumn.indexOf('installmentReleased') === -1 ? <th colSpan='3'> Installment wise <br />Released<br /> <span className="text-c-yellow">(₹ in Cr.)</span></th> : '' : ''}
               
                {ScoreCard === 'ScoreCard' ? '' : csmcNo ? columnPermission.indexOf('stageProgress') !== -1 && hideAndShowColumn.indexOf('stageProgress') === -1 ? <th colSpan='3'> Stage wise Progress <span className="text-c-yellow">(Nos)</span></th> : '' : ''}
              
                {ScoreCard === 'ScoreCard' ? '' : csmcNo ? columnPermission.indexOf('GeoTagProgress') !== -1 && hideAndShowColumn.indexOf('GeoTagProgress') === -1 ? <th colSpan='5'> GeoTag Progress <span className="text-c-yellow">(Nos)</span></th> : '' : ''}
           

                {ScoreCard === 'ScoreCard' ? '' : csmcNo ? columnPermission.indexOf('beneficiariesAttached') !== -1 && hideAndShowColumn.indexOf('beneficiariesAttached') === -1 ? <th rowSpan='2'> Beneficiaries<br />Attached<br /><span className="text-c-yellow">(Nos)</span></th> : '' : <th rowSpan='2'> Beneficiaries<br />Attached<br /><span className="text-c-yellow">(Nos)</span></th>}
               

                {ScoreCard === 'ScoreCard' ? '' : csmcNo ? columnPermission.indexOf('beneficiariesattobeAttached') !== -1 && hideAndShowColumn.indexOf('beneficiariesattobeAttached') === -1 ? <th rowSpan='2'> Beneficiaries <br /> Yet to <br />Attached<br /><span className="text-c-yellow">(Nos)</span></th> : '' : ''}

                {ScoreCard === 'ScoreCard' ? '' : csmcNo ? columnPermission.indexOf('FirstInstallmentReleasedforHouses') !== -1 && hideAndShowColumn.indexOf('FirstInstallmentReleasedforHouses') === -1 ? <th colSpan='4'> Actual Houses Funded for<br /><span className="text-c-yellow">(Nos)</span></th> : '' : ''}
                  {/* {ScoreCard === 'ScoreCard' ? '' : csmcNo ? columnPermission.indexOf('SecondInstallmentReleasedforHouses') !== -1 && hideAndShowColumn.indexOf('SecondInstallmentReleasedforHouses') === -1 ? <th rowSpan='2'> Second <br />Installment <br />Released<br /> for Houses<br /><span className="text-c-yellow">(Nos)</span></th> : '' : ''}
                  {ScoreCard === 'ScoreCard' ? '' : csmcNo ? columnPermission.indexOf('ThirdInstallmentReleasedforHouses') !== -1 && hideAndShowColumn.indexOf('ThirdInstallmentReleasedforHouses') === -1 ? <th rowSpan='2'> Third <br />Installment <br />Released <br />for Houses<br /><span className="text-c-yellow">(Nos)</span></th> : '' : ''}
                  {ScoreCard === 'ScoreCard' ? '' : csmcNo ? columnPermission.indexOf('fundWithExcessHouses') !== -1 && hideAndShowColumn.indexOf('fundWithExcessHouses') === -1 ? <th rowSpan='2'> Fund <br />with Excess <br />Houses<br /><span className="text-c-yellow">(Nos)</span></th> : '' : ''}
                 */}
                {ScoreCard === 'ScoreCard' ? '' : csmcNo ? columnPermission.indexOf('BlanceCentralAssistance') !== -1 && hideAndShowColumn.indexOf('BlanceCentralAssistance') === -1 ? <th rowSpan='2'> Blance <br />Central <br />Assistance<br /><span className="text-c-yellow">(₹ in Cr.)</span></th> : '' : ''}

                {ScoreCard === 'ScoreCard' ? '' : csmcNo ? columnPermission.indexOf('DUThirdInstallmentReleasedforHouses') !== -1 && hideAndShowColumn.indexOf('DUThirdInstallmentReleasedforHouses') === -1 ? <th colSpan='3'>  Installment <br />Due<br /><span className="text-c-yellow">(₹ in Cr.)</span></th> : '' : ''}
                {/* {ScoreCard === 'ScoreCard' ? '' : csmcNo ? columnPermission.indexOf('DUSecondInstallmentReleasedforHouses') !== -1 && hideAndShowColumn.indexOf('DUSecondInstallmentReleasedforHouses') === -1 ? <th rowSpan='2'> Second <br />Installment <br />Due<br /><span className="text-c-yellow">(₹ in Cr.)</span></th> : '' : ''}
                {ScoreCard === 'ScoreCard' ? '' : csmcNo ? columnPermission.indexOf('DUThirdInstallmentReleasedforHouses') !== -1 && hideAndShowColumn.indexOf('DUThirdInstallmentReleasedforHouses') === -1 ? <th rowSpan='2'> Third <br />Installment <br />Due <br /><span className="text-c-yellow">(₹ in Cr.)</span></th> : '' : ''} */}

 
                {ScoreCard === 'ScoreCard' ? '' : csmcNo ? columnPermission.indexOf('additionalFundsMayBeReleased') !== -1 && hideAndShowColumn.indexOf('additionalFundsMayBeReleased') === -1 ? <th colSpan='4'> Additional Funds may be  Released<br /><span className="text-c-yellow">(₹ in Cr.)</span></th> : '' : <th colSpan='4'> Additional Funds may be  Released<br /><span className="text-c-yellow">(₹ in Cr.)</span></th>}
                {ScoreCard === 'ScoreCard' ? '' : csmcNo ? columnPermission.indexOf('housesForAdditionalFundsReleased') !== -1 && hideAndShowColumn.indexOf('housesForAdditionalFundsReleased') === -1 ? <th colSpan='3'> 	Houses for additional Funds Released<br /><span className="text-c-yellow">(Nos)</span></th> : '' : <th colSpan='3'> 	Houses for additional Funds Released<br /><span className="text-c-yellow">(Nos)</span></th>}
              </tr>
              <tr style={{ textAlign: "center", fontSize: 12 }}>
                {hideColumn.indexOf('projectCost') === -1 ? <th>
                  Project <br />Cost <br />

                </th>
                  : ''}
                {hideColumn.indexOf('CAApproved') === -1 ? <th>
                  Central <br />Assistance
                  <br />

                </th> : ''}
                {hideColumn.indexOf('totalInstallmentReleased') === -1 ? <th>
                  Funds
                  <br />
                  Released <br />

                </th> : ''}
                {hideColumn.indexOf('installmentUC') === -1 ? <th>
                  UC <br />Received <br />

                </th> : ''}
                {hideColumn.indexOf('housesApproved') === -1 ? <th>
                  Houses <br />
                  Approved <br />

                </th> : ''}
                {hideColumn.indexOf('housesGroundedForConstruction') === -1 ? <th>
                  Houses <br />
                  Grounded <br />

                </th> : ''}
                {hideColumn.indexOf('housesCompleted') === -1 ? <th>
                  Houses <br />
                  Completed <br />

                </th> : ''}
                {hideColumn.indexOf('housesOccupied') === -1 ? <th>
                  Houses <br />
                  Occupied <br />

                </th> : ''}

                {ScoreCard === 'ScoreCard' ? '' : csmcNo ? columnPermission.indexOf('installmentReleased') !== -1 && hideColumn.indexOf('installmentReleased') === -1 ? <th>1st.</th> : '' : ''}
                {ScoreCard === 'ScoreCard' ? '' : csmcNo ? columnPermission.indexOf('installmentReleased') !== -1 && hideColumn.indexOf('installmentReleased') === -1 ? <th>2nd.</th> : '' : ''}
                {ScoreCard === 'ScoreCard' ? '' : csmcNo ? columnPermission.indexOf('installmentReleased') !== -1 && hideColumn.indexOf('installmentReleased') === -1 ? <th>3rd.</th> : '' : ''}


                {ScoreCard === 'ScoreCard' ? '' : csmcNo ? columnPermission.indexOf('stageProgress') !== -1 && hideAndShowColumn.indexOf('stageProgress') === -1 ? <th>Foundation/<br />Plinth Level</th> : '' : ''}
                {ScoreCard === 'ScoreCard' ? '' : csmcNo ? columnPermission.indexOf('stageProgress') !== -1 && hideAndShowColumn.indexOf('stageProgress') === -1 ? <th>Lintel <br />Level</th> : '' : ''}
                {ScoreCard === 'ScoreCard' ? '' : csmcNo ? columnPermission.indexOf('stageProgress') !== -1 && hideAndShowColumn.indexOf('stageProgress') === -1 ? <th>Roof <br />Level</th> : '' : ''}

            
                {ScoreCard === 'ScoreCard' ? '' : csmcNo ? columnPermission.indexOf('GeoTagProgress') !== -1 && hideAndShowColumn.indexOf('GeoTagProgress') === -1 ? <th>Plinth Level</th> : '' : ''}
                {ScoreCard === 'ScoreCard' ? '' : csmcNo ? columnPermission.indexOf('GeoTagProgress') !== -1 && hideAndShowColumn.indexOf('GeoTagProgress') === -1 ? <th>Lintel <br />Level</th> : '' : ''}
                {ScoreCard === 'ScoreCard' ? '' : csmcNo ? columnPermission.indexOf('GeoTagProgress') !== -1 && hideAndShowColumn.indexOf('GeoTagProgress') === -1 ? <th>Roof <br />Level</th> : '' : ''}
                {ScoreCard === 'ScoreCard' ? '' : csmcNo ? columnPermission.indexOf('GeoTagProgress') !== -1 && hideAndShowColumn.indexOf('GeoTagProgress') === -1 ? <th>Completed </th> : '' : ''}
                {ScoreCard === 'ScoreCard' ? '' : csmcNo ? columnPermission.indexOf('GeoTagProgress') !== -1 && hideAndShowColumn.indexOf('GeoTagProgress') === -1 ? <th>GeoTag but <br />Not Started</th> : '' : ''}



                {ScoreCard === 'ScoreCard' ? '' : csmcNo ? columnPermission.indexOf('FirstInstallmentReleasedforHouses') !== -1 && hideAndShowColumn.indexOf('FirstInstallmentReleasedforHouses') === -1 ? <th>1st.</th> : '' : ''}
                {ScoreCard === 'ScoreCard' ? '' : csmcNo ? columnPermission.indexOf('FirstInstallmentReleasedforHouses') !== -1 && hideAndShowColumn.indexOf('FirstInstallmentReleasedforHouses') === -1 ? <th>2nd.</th> : '' : ''}
                {ScoreCard === 'ScoreCard' ? '' : csmcNo ? columnPermission.indexOf('FirstInstallmentReleasedforHouses') !== -1 && hideAndShowColumn.indexOf('FirstInstallmentReleasedforHouses') === -1 ? <th>3rd.</th> : '' : ''}
                {ScoreCard === 'ScoreCard' ? '' : csmcNo ? columnPermission.indexOf('FirstInstallmentReleasedforHouses') !== -1 && hideAndShowColumn.indexOf('FirstInstallmentReleasedforHouses') === -1 ? <th>Excess.</th> : '' : ''}
                

                
                {ScoreCard === 'ScoreCard' ? '' : csmcNo ? columnPermission.indexOf('DUThirdInstallmentReleasedforHouses') !== -1 && hideAndShowColumn.indexOf('DUThirdInstallmentReleasedforHouses') === -1 ? <th>1st.</th> : '' : ''}
                {ScoreCard === 'ScoreCard' ? '' : csmcNo ? columnPermission.indexOf('DUThirdInstallmentReleasedforHouses') !== -1 && hideAndShowColumn.indexOf('DUThirdInstallmentReleasedforHouses') === -1 ? <th>2nd.</th> : '' : ''}
                {ScoreCard === 'ScoreCard' ? '' : csmcNo ? columnPermission.indexOf('DUThirdInstallmentReleasedforHouses') !== -1 && hideAndShowColumn.indexOf('DUThirdInstallmentReleasedforHouses') === -1 ? <th>3rd.</th> : '' : ''}
                

                {ScoreCard === 'ScoreCard' ? '' : csmcNo ? columnPermission.indexOf('additionalFundsMayBeReleased') !== -1 && hideAndShowColumn.indexOf('additionalFundsMayBeReleased') === -1 ? <th>1st.</th> : '' : <th>1st.</th>}
                {ScoreCard === 'ScoreCard' ? '' : csmcNo ? columnPermission.indexOf('additionalFundsMayBeReleased') !== -1 && hideAndShowColumn.indexOf('additionalFundsMayBeReleased') === -1 ? <th>2nd.</th> : '' : <th>2nd.</th>}
                {ScoreCard === 'ScoreCard' ? '' : csmcNo ? columnPermission.indexOf('additionalFundsMayBeReleased') !== -1 && hideAndShowColumn.indexOf('additionalFundsMayBeReleased') === -1 ? <th>3rd.</th> : '' : <th>3rd.</th>}
                {ScoreCard === 'ScoreCard' ? '' : csmcNo ? columnPermission.indexOf('additionalFundsMayBeReleased') !== -1 && hideAndShowColumn.indexOf('additionalFundsMayBeReleased') === -1 ? <th>Total</th> : '' : <th>Total</th>}

                {ScoreCard === 'ScoreCard' ? '' : csmcNo ? columnPermission.indexOf('housesForAdditionalFundsReleased') !== -1 && hideAndShowColumn.indexOf('housesForAdditionalFundsReleased') === -1 ? <th>1st.</th> : '' : <th>1st.</th>}
                {ScoreCard === 'ScoreCard' ? '' : csmcNo ? columnPermission.indexOf('housesForAdditionalFundsReleased') !== -1 && hideAndShowColumn.indexOf('housesForAdditionalFundsReleased') === -1 ? <th>1st.</th> : '' : <th>1st.</th>}
                {ScoreCard === 'ScoreCard' ? '' : csmcNo ? columnPermission.indexOf('housesForAdditionalFundsReleased') !== -1 && hideAndShowColumn.indexOf('housesForAdditionalFundsReleased') === -1 ? <th>3rd.</th> : '' : <th>3rd.</th>}

               


              </tr>
            </thead>
            <tbody>
              {map(projectDataList, (obj, i) => {
                //console.log('obj--->>>>',obj);
                const excesAmt = _.filter(excesProjectWithExcludeComponent, objx => objx.projectCode === obj.ProjectCode)

                const StateWisegeotagData = _.get(obj, 'Component') ==='BLC' ? _.filter(geotagData, objx => (objx.ProjectCode === obj.ProjectCode && objx.OriginallyCSMCNo === obj.OriginallyCSMCNo)) :[]
             
                
                const recoveredAmtInst1 = _.sumBy(excesAmt, bl => (bl.OriginallyCSMCNo === obj.OriginallyCSMCNo && bl.Component === obj.Component && bl.installment === 1 ? bl.recaalledAmount : 0))
                const recoveredAmtInst2 = _.sumBy(excesAmt, bl => (bl.OriginallyCSMCNo === obj.OriginallyCSMCNo && bl.Component === obj.Component && bl.installment === 2 ? bl.recaalledAmount : 0))
                const recoveredAmtInst3 = _.sumBy(excesAmt, bl => (bl.OriginallyCSMCNo === obj.OriginallyCSMCNo && bl.Component === obj.Component && bl.installment === 3 ? bl.recaalledAmount : 0))

                 const geotagData_new =_.get(obj, 'Component')==='BLC' ? StateWisegeotagData :[]
                
                const plinthGeoTagHousesBLC = _.sumBy(geotagData_new, flt => (flt.Foundation || 0))
                const lintelGeoTagHousesBLC = _.sumBy(geotagData_new, flt => (flt.Lintel || 0))
                const roofGeoTagHousesBLC = _.sumBy(geotagData_new, flt => (flt.Roof || 0))
                const completedGeoTagHousesBLC = _.sumBy(geotagData_new, flt => (flt.Completed || 0))
                const GEOTag = _.sumBy(geotagData_new, flt => (flt.GEOTag || 0))

                const geotagNotStarted=Number(GEOTag)-Number(plinthGeoTagHousesBLC+lintelGeoTagHousesBLC+roofGeoTagHousesBLC+completedGeoTagHousesBLC)
                

                const installment1 = (Number(_.get(obj, 'installment1') || 0) ? Number(Number(_.get(obj, 'installment1') || 0).toFixed(5)) : 0) - recoveredAmtInst1
                const installment2 = (Number(_.get(obj, 'installment2') || 0) ? Number(Number(_.get(obj, 'installment2') || 0).toFixed(5)) : 0) - recoveredAmtInst2
                const installment3 = (Number(_.get(obj, 'installment3') || 0) ? Number(Number(_.get(obj, 'installment3') || 0).toFixed(5)) : 0) - recoveredAmtInst3

                const excesAmtInCaseOfCity = _.get(this.props, 'CSMCNo') ? Number(recoveredAmtInst1 + recoveredAmtInst2 + recoveredAmtInst3) : _.sumBy(_.filter(excesProjectInCaseOfCity, fnd => fnd.projectCode === obj.ProjectCode), flt => flt.recaalledAmount ? flt.recaalledAmount : 0)

                const hounsesSan = Number(get(obj, 'HousesSanctionedEnahncement') || 0) + Number(get(obj, 'HousesSanctionedNew') || 0)
                const housesAttached = Number(obj.AttachedGEN || 0) + Number(obj.AttachedSC || 0) + Number(obj.AttachedST || 0) + Number(obj.AttachedOBC || 0) + Number(obj.AttachedMinority || 0)
                const beneficiariesAttached = housesAttached > hounsesSan ? hounsesSan : housesAttached
                const totalWorkOrders = _.sumBy(obj, o => Number(_.get(o, 'totalWorkOrders') || 0)) ? _.sumBy(obj, o => Number(_.get(o, 'totalWorkOrders') || 0)) : 0

                // const excesAmt = find(excesProjectWithExcludeComponent, fnd => fnd.ProjectCode === obj.ProjectCode)
                const release1st = Number(obj.installment1 || 0) - Number(recoveredAmtInst1 || 0)
                const projectBenefAttached = Number(get(obj, 'AttachedGEN') || 0) + Number(get(obj, 'AttachedSC') || 0) + Number(get(obj, 'AttachedST') || 0) + Number(get(obj, 'AttachedOBC') || 0) + Number(get(obj, 'AttachedMinority') || 0)
                const hounsesGrounded = Number(obj.totalWorkOrders || 0) > 0 ? hounsesSan : 0

                const addlFundsForFirstInstal = obj.Component !== 'BLC' ? ((hounsesGrounded * (obj.Component === 'ISSR' ? .004 : .006)) - release1st) : (((projectBenefAttached > hounsesSan ? hounsesSan : projectBenefAttached) * .006) - release1st || 0)

                const ucRec = Number(obj.uc1 || 0) + (obj.uc2 || 0) + (obj.uc3 || 0) + (obj.uc4 || 0)
                const release2nd = Number(obj.installment2 || 0) - Number(recoveredAmtInst2 || 0)
                const Attached = Number(get(obj, 'AttachedGEN') || 0) + Number(get(obj, 'AttachedSC') || 0) + Number(get(obj, 'AttachedST') || 0) + Number(get(obj, 'AttachedOBC') || 0) + Number(get(obj, 'AttachedMinority') || 0)
                const projectBenefAttached1 = Attached > hounsesSan ? hounsesSan : Attached


                // const plinthGeoTagHousesBLC = _.sumBy(geotagData_new, flt => (flt.Foundation || 0))
                // const lintelGeoTagHousesBLC = _.sumBy(geotagData_new, flt => (flt.Lintel || 0))
                // const roofGeoTagHousesBLC = _.sumBy(geotagData_new, flt => (flt.Roof || 0))
                // const completedGeoTagHousesBLC = _.sumBy(geotagData_new, flt => (flt.Completed || 0))

                const LintelRoofCompAHPISSR =Number(_.get(obj, 'actualCompleted') || 0) + Number(_.get(obj, 'roof') || 0)  + Number(_.get(obj, 'Lintel') || 0)
                
                const GTLintelRoofCompBLC = completedGeoTagHousesBLC + lintelGeoTagHousesBLC + roofGeoTagHousesBLC
               
                const  GTLintelRoofCompBLCSecond= GTLintelRoofCompBLC > hounsesSan ? hounsesSan : GTLintelRoofCompBLC
                const addlFundsForSecondInstal = (ucRec / release1st) > .70 ? (((obj.Component === 'BLC' ? GTLintelRoofCompBLCSecond : LintelRoofCompAHPISSR) * (obj.Component === 'ISSR' ? .004 : .006)) - (release2nd)) : 0

                const release3rd = Number(obj.installment3 || 0) - Number(recoveredAmtInst3 || 0)
                const hounsesCompleted = Number(get(obj, 'completed') || 0)

                const housesCompletedInPercent = hounsesCompleted / hounsesSan
                const attachedInPercent = Attached / hounsesSan

                const GTCompBLC =completedGeoTagHousesBLC
                const CompAHPISSR =Number(_.get(obj, 'actualCompleted') || 0)

                const minHousesComplete = (hounsesCompleted > projectBenefAttached ? projectBenefAttached : projectBenefAttached > hounsesCompleted ? hounsesCompleted : 0)
                const addlFundsForThirdInstal = obj.Component === 'BLC' ? (((ucRec / (release1st + release2nd)) > .70 && housesCompletedInPercent > .89 && attachedInPercent > .89) ? ((GTCompBLC * .003) - release3rd) : 0) : (((ucRec / (release1st + release2nd)) > .70 && housesCompletedInPercent > .89 && attachedInPercent > .89) ? ((CompAHPISSR * (obj.Component === 'ISSR' ? .002 : .003)) - release3rd) : 0)


                const housesForadditionalFundsReleased1st = Math.round(obj.Component === 'ISSR' ? addlFundsForFirstInstal / .004 : addlFundsForFirstInstal / .006)
                const housesForadditionalFundsReleased2nd = Math.round(obj.Component === 'ISSR' ? addlFundsForSecondInstal / .004 : addlFundsForSecondInstal / .006)
                const housesForadditionalFundsReleased3rd = Math.round(obj.Component === 'ISSR' ? addlFundsForThirdInstal / .002 : addlFundsForThirdInstal / .003)



                const dividevalue1 = obj.Component === 'ISSR' ? 0.4 : 0.6
                const dividevalue2 = obj.Component === 'ISSR' ? 0.4 : 0.6
                const dividevalue3 = obj.Component === 'ISSR' ? 0.2 : 0.3
                const divideforCAAProved = obj.Component === 'ISSR' ? 1.0 : 1.5

                // housesApproved=  HousesSanctionedNew + HousesSanctionedEnahncement=hounsesSan
                const beneficiariesattobeAttached = hounsesSan - beneficiariesAttached

                const FirstInstallmentReleasedforHouses = (release1st * 100) / dividevalue1

                const SecondInstallmentReleasedforHouses = (release2nd * 100) / dividevalue2

                const ThirdInstallmentReleasedforHouses = (release3rd * 100) / dividevalue3
                const fundWithExcessHouses = Number(FirstInstallmentReleasedforHouses) - (obj.Component==='BLC' ? beneficiariesAttached : totalWorkOrders)

               // const fundWithExcessHouses = Number(FirstInstallmentReleasedforHouses - beneficiariesAttached)

                const BlanceCentralAssistance2 = ((Number(Number(_.get(obj, 'CentralAssistApproved') || 0).toFixed(2)) - (obj.Component === 'BLC' ? beneficiariesattobeAttached : hounsesGrounded)) * divideforCAAProved) / 100
//const BlanceCentralAssistance = Number(Number(_.get(obj, 'CentralAssistApproved') || 0).toFixed(2))-(((obj.Component === 'BLC' ? beneficiariesattobeAttached : hounsesGrounded)*divideforCAAProved)/100)-Number(installment1+installment2+installment3)

const BlanceCentralAssistance = (((obj.Component === 'BLC' ? beneficiariesattobeAttached : hounsesGrounded)*divideforCAAProved)/100)-Number(installment1+installment2+installment3)

                const Installment1stDUEBLC = (beneficiariesAttached * 0.6) / 100
                //const Installment2stDUEBLC = ((Number(_.get(obj, 'actualCompleted') || 0) + Number(_.get(obj, 'lintel') || 0) + Number(_.get(obj, 'roof') || 0)) * 0.6) / 100
                const Installment2stDUEBLC =((completedGeoTagHousesBLC + lintelGeoTagHousesBLC +roofGeoTagHousesBLC)*0.6)/100
                       
                //const Installment3stDUEBLC = (Number(_.get(obj, 'actualCompleted') || 0) * 0.3) / 100
                const Installment3stDUEBLC =(completedGeoTagHousesBLC * 0.3)/100

                const Installment1stDUEAHPISSR = (hounsesGrounded * dividevalue1) / 100
                const Installment2stDUEAHPISSR = ((Number(_.get(obj, 'actualCompleted') || 0) + Number(_.get(obj, 'roof') || 0)  + Number(_.get(obj, 'Lintel') || 0)) * dividevalue2) / 100
                const Installment3stDUEAHPISSR = (Number(_.get(obj, 'actualCompleted') || 0) * dividevalue3) / 100

                const dueFirst = obj.Component === 'BLC' ? Installment1stDUEBLC : Installment1stDUEAHPISSR
                const dueSecond = obj.Component === 'BLC' ? Installment2stDUEBLC : Installment2stDUEAHPISSR
                const dueThird = obj.Component === 'BLC' ? Installment3stDUEBLC : Installment3stDUEAHPISSR





                return (
                  <tr key={get(obj, "ProjectCode")}>
                    <td style={{ textAlign: "center" }}>{i + 1}.</td>
                    <td>{get(obj, "Component")}</td>
                    <td>{get(obj, "CityName")}</td>
                    <td style={{ width: 500, wordWrap: 'break-word', whiteSpace: 'normal' }}>
                      <a
                        title={get(obj, "ProjectCode")}
                        target="_blank"
                        rel='noopener noreferrer'
                        style={{ width: 250, display: 'block' }}
                        href={`/project-details/${get(obj, "ProjectCode")}`}
                      >
                        {get(obj, "ProjectTitle")}
                      </a>
                    </td>
                    <td>{get(obj, "OriginallyCSMCDate") ? moment(get(obj, "OriginallyCSMCDate")).format('DD-MMM-YYYY') : '-'}</td>
                    {hideColumn.indexOf('projectCost') === -1 ? <td style={{ textAlign: "right" }}>
                      {Number(get(obj, "ProjectCost")) &&
                        get(obj, "Scheme") !== "JnNURM"
                        ? Number(get(obj, "ProjectCost")).toFixed(2)
                        : "-"}
                    </td> : ''}
                    {hideColumn.indexOf('CAApproved') === -1 ? <td style={{ textAlign: "right" }}>
                      {Number(get(obj, "CentralAssistApproved")) &&
                        get(obj, "Scheme") !== "JnNURM"
                        ? Number(get(obj, "CentralAssistApproved")).toFixed(2)
                        : "-"}
                    </td> : ''}
                    {hideColumn.indexOf('totalInstallmentReleased') === -1 ? <td style={{ textAlign: "right" }}>
                      {Number(get(obj, "installment1") || 0) +
                        Number(get(obj, "installment2") || 0) +
                        Number(get(obj, "installment3") || 0) +
                        Number(get(obj, "installment4") || 0) &&
                        get(obj, "Scheme") !== "JnNURM"
                        ? (
                          Number(get(obj, "installment1") || 0) +
                          Number(get(obj, "installment2") || 0) +
                          Number(get(obj, "installment3") || 0) +
                          Number(get(obj, "installment4") || 0) -
                          Number(excesAmtInCaseOfCity)
                        ).toFixed(2)
                        : "-"}
                    </td> : ''}
                    {hideColumn.indexOf('installmentUC') === -1 ? <td style={{ textAlign: "right" }}>
                      {Number(get(obj, "uc1") || 0) +
                        Number(get(obj, "uc2") || 0) +
                        Number(get(obj, "uc3") || 0) +
                        Number(get(obj, "uc4") || 0) &&
                        get(obj, "Scheme") !== "JnNURM"
                        ? (
                          Number(get(obj, "uc1") || 0) +
                          Number(get(obj, "uc2") || 0) +
                          Number(get(obj, "uc3") || 0) +
                          Number(get(obj, "uc4") || 0)
                        ).toFixed(2)
                        : "-"}
                    </td> : ''}
                    {hideColumn.indexOf('housesApproved') === -1 ? <td style={{ textAlign: "right" }}>
                      {Number(get(obj, "HousesSanctionedNew")) +
                        Number(get(obj, "HousesSanctionedEnahncement")) &&
                        get(obj, "Scheme") !== "JnNURM"
                        ? Number(get(obj, "HousesSanctionedNew")) +
                        Number(get(obj, "HousesSanctionedEnahncement"))
                        : "-"}
                    </td> : ''}
                    {hideColumn.indexOf('housesGroundedForConstruction') === -1 ? <td style={{ textAlign: "right" }}>
                      {Number(get(obj, "totalWorkOrders"))
                        ? Number(get(obj, "totalWorkOrders"))
                        : "-"}
                    </td> : ''}
                    {hideColumn.indexOf('housesCompleted') === -1 ? <td style={{ textAlign: "right" }}>
                      {Number(get(obj, "completed"))
                        ? Number(get(obj, "completed"))
                        : "-"}
                    </td> : ''}
                    {hideColumn.indexOf('housesOccupied') === -1 ? <td style={{ textAlign: "right" }}>
                      {Number(get(obj, "occupied"))
                        ? Number(get(obj, "occupied"))
                        : "-"}
                    </td> : ''}


          


                    {ScoreCard === 'ScoreCard' ? '' : csmcNo ? columnPermission.indexOf('installmentReleased') !== -1 && hideAndShowColumn.indexOf('installmentReleased') === -1 ? <td>{installment1 ? Number(installment1.toFixed(2)).toLocaleString('en-in') : '-'}</td> : '' : ''}
                    {ScoreCard === 'ScoreCard' ? '' : csmcNo ? columnPermission.indexOf('installmentReleased') !== -1 && hideAndShowColumn.indexOf('installmentReleased') === -1 ? <td>{installment2 ? Number(installment2.toFixed(2)).toLocaleString('en-in') : '-'}</td> : '' : ''}
                    {ScoreCard === 'ScoreCard' ? '' : csmcNo ? columnPermission.indexOf('installmentReleased') !== -1 && hideAndShowColumn.indexOf('installmentReleased') === -1 ? <td>{installment3 ? Number(installment3.toFixed(2)).toLocaleString('en-in') : '-'}</td> : '' : ''}


                    {ScoreCard === 'ScoreCard' ? '' : csmcNo ? columnPermission.indexOf('stageProgress') !== -1 && hideAndShowColumn.indexOf('stageProgress') === -1 ? <td>{Number(_.get(obj, 'plinth') || 0) ? Number(_.get(obj, 'plinth') || 0).toLocaleString('en-in') : '-'}</td> : '' : ''}
                    {ScoreCard === 'ScoreCard' ? '' : csmcNo ? columnPermission.indexOf('stageProgress') !== -1 && hideAndShowColumn.indexOf('stageProgress') === -1 ? <td>{Number(_.get(obj, 'lintel') || 0) ? Number(_.get(obj, 'lintel') || 0).toLocaleString('en-in') : '-'}</td> : '' : ''}
                    {ScoreCard === 'ScoreCard' ? '' : csmcNo ? columnPermission.indexOf('stageProgress') !== -1 && hideAndShowColumn.indexOf('stageProgress') === -1 ? <td>{Number(_.get(obj, 'roof') || 0) ? Number(_.get(obj, 'roof') || 0).toLocaleString('en-in') : '-'}</td> : '' : ''}
                   
                    {ScoreCard === 'ScoreCard' ? '' : csmcNo ? columnPermission.indexOf('GeoTagProgress') !== -1 && hideAndShowColumn.indexOf('GeoTagProgress') === -1 ? <td>{plinthGeoTagHousesBLC ? plinthGeoTagHousesBLC.toLocaleString('en-in') : '-'}</td>: '' : ''}
                    {ScoreCard === 'ScoreCard' ? '' : csmcNo ? columnPermission.indexOf('GeoTagProgress') !== -1 && hideAndShowColumn.indexOf('GeoTagProgress') === -1 ? <td>{lintelGeoTagHousesBLC ? lintelGeoTagHousesBLC.toLocaleString('en-in') : '-'}</td>: '' : ''}
                    {ScoreCard === 'ScoreCard' ? '' : csmcNo ? columnPermission.indexOf('GeoTagProgress') !== -1 && hideAndShowColumn.indexOf('GeoTagProgress') === -1 ? <td>{roofGeoTagHousesBLC ? roofGeoTagHousesBLC.toLocaleString('en-in') : '-'}</td>: '' : ''}
                    {ScoreCard === 'ScoreCard' ? '' : csmcNo ? columnPermission.indexOf('GeoTagProgress') !== -1 && hideAndShowColumn.indexOf('GeoTagProgress') === -1 ? <td>{completedGeoTagHousesBLC ? completedGeoTagHousesBLC.toLocaleString('en-in') : '-'}</td>: '' : ''}
                    {ScoreCard === 'ScoreCard' ? '' : csmcNo ? columnPermission.indexOf('GeoTagProgress') !== -1 && hideAndShowColumn.indexOf('GeoTagProgress') === -1 ? <td>{geotagNotStarted ? geotagNotStarted.toLocaleString('en-in') : '-'}</td>: '' : ''}
          



                    {ScoreCard === 'ScoreCard' ? '' : csmcNo ? columnPermission.indexOf('beneficiariesAttached') !== -1 && hideAndShowColumn.indexOf('beneficiariesAttached') === -1 ? <td>{beneficiariesAttached ? beneficiariesAttached.toLocaleString('en-in') : '-'}</td> : '' : <td>{beneficiariesAttached ? beneficiariesAttached.toLocaleString('en-in') : '-'}</td>}
{/* //New */}
                    {ScoreCard === 'ScoreCard' ? '' : csmcNo ? columnPermission.indexOf('beneficiariesattobeAttached') !== -1 && hideAndShowColumn.indexOf('beneficiariesattobeAttached') === -1 ? <td>{Number(beneficiariesattobeAttached) ? Number(beneficiariesattobeAttached.toFixed(2)).toLocaleString('en-in') : '-'}</td> : '' : ''}
                    {ScoreCard === 'ScoreCard' ? '' : csmcNo ? columnPermission.indexOf('FirstInstallmentReleasedforHouses') !== -1 && hideAndShowColumn.indexOf('FirstInstallmentReleasedforHouses') === -1 ? <td>{FirstInstallmentReleasedforHouses ? Number(FirstInstallmentReleasedforHouses.toFixed(2)).toLocaleString('en-in') : '-'}</td> : '' : ''}
                    {ScoreCard === 'ScoreCard' ? '' : csmcNo ? columnPermission.indexOf('FirstInstallmentReleasedforHouses') !== -1 && hideAndShowColumn.indexOf('FirstInstallmentReleasedforHouses') === -1 ? <td>{SecondInstallmentReleasedforHouses ? Number(SecondInstallmentReleasedforHouses.toFixed(2)).toLocaleString('en-in') : '-'}</td> : '' : ''}
                    {ScoreCard === 'ScoreCard' ? '' : csmcNo ? columnPermission.indexOf('FirstInstallmentReleasedforHouses') !== -1 && hideAndShowColumn.indexOf('FirstInstallmentReleasedforHouses') === -1 ? <td>{ThirdInstallmentReleasedforHouses ? Number(ThirdInstallmentReleasedforHouses.toFixed(2)).toLocaleString('en-in') : '-'}</td> : '' : ''}
                    {ScoreCard === 'ScoreCard' ? '' : csmcNo ? columnPermission.indexOf('FirstInstallmentReleasedforHouses') !== -1 && hideAndShowColumn.indexOf('FirstInstallmentReleasedforHouses') === -1 ? <td style={{ color: fundWithExcessHouses < 0 ? 'red' : 'inherit' }}>{fundWithExcessHouses ? fundWithExcessHouses < 0 ? `(${Number(fundWithExcessHouses.toFixed(2)).toLocaleString('en-in')})` : Number(fundWithExcessHouses.toFixed(2)).toLocaleString('en-in') : '-'}</td> : '' : ''}
                    {ScoreCard === 'ScoreCard' ? '' : csmcNo ? columnPermission.indexOf('BlanceCentralAssistance') !== -1 && hideAndShowColumn.indexOf('BlanceCentralAssistance') === -1 ? <td style={{ color: BlanceCentralAssistance < 0 ? 'red' : 'inherit' }}>{BlanceCentralAssistance ? BlanceCentralAssistance < 0 ? `(${Number(BlanceCentralAssistance.toFixed(2)).toLocaleString('en-in')})` : Number(BlanceCentralAssistance.toFixed(2)).toLocaleString('en-in') : '-'}</td> : '' : ''}

                    {ScoreCard === 'ScoreCard' ? '' : csmcNo ? columnPermission.indexOf('DUThirdInstallmentReleasedforHouses') !== -1 && hideAndShowColumn.indexOf('DUThirdInstallmentReleasedforHouses') === -1 ? <td>{dueFirst ? Number(dueFirst.toFixed(2)).toLocaleString('en-in') : '-'}</td> : '' : ''}
                    {ScoreCard === 'ScoreCard' ? '' : csmcNo ? columnPermission.indexOf('DUThirdInstallmentReleasedforHouses') !== -1 && hideAndShowColumn.indexOf('DUThirdInstallmentReleasedforHouses') === -1 ? <td>{dueSecond ? Number(dueSecond.toFixed(2)).toLocaleString('en-in') : '-'}</td> : '' : ''}
                    {ScoreCard === 'ScoreCard' ? '' : csmcNo ? columnPermission.indexOf('DUThirdInstallmentReleasedforHouses') !== -1 && hideAndShowColumn.indexOf('DUThirdInstallmentReleasedforHouses') === -1 ? <td>{dueThird ? Number(dueThird.toFixed(2)).toLocaleString('en-in') : '-'}</td> : '' : ''}

                 
{/* End Here */}
                    {ScoreCard === 'ScoreCard' ? '' : csmcNo ? columnPermission.indexOf('additionalFundsMayBeReleased') !== -1 && hideAndShowColumn.indexOf('additionalFundsMayBeReleased') === -1 ? <td style={{ color: addlFundsForFirstInstal < 0 ? 'red' : 'inherit' }}>{addlFundsForFirstInstal ? addlFundsForFirstInstal < 0 ? `(${addlFundsForFirstInstal.toLocaleString('en-in')})` : addlFundsForFirstInstal.toLocaleString('en-in') : '-'}</td> : '' : <td style={{ color: addlFundsForFirstInstal < 0 ? 'red' : 'inherit' }}>{addlFundsForFirstInstal ? addlFundsForFirstInstal < 0 ? `(${addlFundsForFirstInstal.toLocaleString('en-in')})` : addlFundsForFirstInstal.toLocaleString('en-in') : '-'}</td>}
                    {ScoreCard === 'ScoreCard' ? '' : csmcNo ? columnPermission.indexOf('additionalFundsMayBeReleased') !== -1 && hideAndShowColumn.indexOf('additionalFundsMayBeReleased') === -1 ? <td style={{ color: addlFundsForSecondInstal < 0 ? 'red' : 'inherit' }}>{addlFundsForSecondInstal ? addlFundsForSecondInstal < 0 ? `(${addlFundsForSecondInstal.toLocaleString('en-in')})` : addlFundsForSecondInstal.toLocaleString('en-in') : '-'}</td> : '' : <td style={{ color: addlFundsForSecondInstal < 0 ? 'red' : 'inherit' }}>{addlFundsForSecondInstal ? addlFundsForSecondInstal < 0 ? `(${addlFundsForSecondInstal.toLocaleString('en-in')})` : addlFundsForSecondInstal.toLocaleString('en-in') : '-'}</td>}
                    {ScoreCard === 'ScoreCard' ? '' : csmcNo ? columnPermission.indexOf('additionalFundsMayBeReleased') !== -1 && hideAndShowColumn.indexOf('additionalFundsMayBeReleased') === -1 ? <td style={{ color: addlFundsForThirdInstal < 0 ? 'red' : 'inherit' }}>{addlFundsForThirdInstal ? addlFundsForThirdInstal < 0 ? `(${addlFundsForThirdInstal.toLocaleString('en-in')})` : addlFundsForThirdInstal.toLocaleString('en-in') : '-'}</td> : '' : <td style={{ color: addlFundsForThirdInstal < 0 ? 'red' : 'inherit' }}>{addlFundsForThirdInstal ? addlFundsForThirdInstal < 0 ? `(${addlFundsForThirdInstal.toLocaleString('en-in')})` : addlFundsForThirdInstal.toLocaleString('en-in') : '-'}</td>}
                    {ScoreCard === 'ScoreCard' ? '' : csmcNo ? columnPermission.indexOf('additionalFundsMayBeReleased') !== -1 && hideAndShowColumn.indexOf('additionalFundsMayBeReleased') === -1 ? <td style={{ color: (addlFundsForFirstInstal + addlFundsForSecondInstal + addlFundsForThirdInstal) < 0 ? 'red' : 'inherit' }}>{(addlFundsForFirstInstal + addlFundsForSecondInstal + addlFundsForThirdInstal) ? (addlFundsForFirstInstal + addlFundsForSecondInstal + addlFundsForThirdInstal).toLocaleString('en-in') : '-'}</td> : '' : <td style={{ color: (addlFundsForFirstInstal + addlFundsForSecondInstal + addlFundsForThirdInstal) < 0 ? 'red' : 'inherit' }}>{(addlFundsForFirstInstal + addlFundsForSecondInstal + addlFundsForThirdInstal) ? (addlFundsForFirstInstal + addlFundsForSecondInstal + addlFundsForThirdInstal).toLocaleString('en-in') : '-'}</td>}

                    {ScoreCard === 'ScoreCard' ? '' : csmcNo ? columnPermission.indexOf('housesForAdditionalFundsReleased') !== -1 && hideAndShowColumn.indexOf('housesForAdditionalFundsReleased') === -1 ? <td>{housesForadditionalFundsReleased1st ? housesForadditionalFundsReleased1st.toLocaleString('en-in') : '-'}</td> : '' : <td>{housesForadditionalFundsReleased1st ? housesForadditionalFundsReleased1st.toLocaleString('en-in') : '-'}</td>}
                    {ScoreCard === 'ScoreCard' ? '' : csmcNo ? columnPermission.indexOf('housesForAdditionalFundsReleased') !== -1 && hideAndShowColumn.indexOf('housesForAdditionalFundsReleased') === -1 ? <td>{housesForadditionalFundsReleased2nd ? housesForadditionalFundsReleased2nd.toLocaleString('en-in') : '-'}</td> : '' : <td>{housesForadditionalFundsReleased2nd ? housesForadditionalFundsReleased2nd.toLocaleString('en-in') : '-'}</td>}
                    {ScoreCard === 'ScoreCard' ? '' : csmcNo ? columnPermission.indexOf('housesForAdditionalFundsReleased') !== -1 && hideAndShowColumn.indexOf('housesForAdditionalFundsReleased') === -1 ? <td>{housesForadditionalFundsReleased3rd ? housesForadditionalFundsReleased3rd.toLocaleString('en-in') : '-'}</td> : '' : <td>{housesForadditionalFundsReleased3rd ? housesForadditionalFundsReleased3rd.toLocaleString('en-in') : '-'}</td>}

                    {/* //  New Additional column */}

                   

                    {/* {ScoreCard==='ScoreCard'?'' :  <td>{beneficiariesAttached ? beneficiariesAttached.toLocaleString('en-in'):'-' }</td>}
                    {ScoreCard==='ScoreCard'?'' :<td style={{ color: addlFundsForFirstInstal < 0 ? 'red' :'inherit' }}>{addlFundsForFirstInstal ? addlFundsForFirstInstal < 0 ? `(${addlFundsForFirstInstal.toLocaleString('en-in')})` : addlFundsForFirstInstal.toLocaleString('en-in') : '-'}</td>}
                    {ScoreCard==='ScoreCard'?'' :<td style={{ color: addlFundsForSecondInstal < 0 ? 'red' :'inherit' }}>{addlFundsForSecondInstal ? addlFundsForSecondInstal < 0 ? `(${addlFundsForSecondInstal.toLocaleString('en-in')})` : addlFundsForSecondInstal.toLocaleString('en-in') : '-'}</td>}
                    {ScoreCard==='ScoreCard'?'' :<td style={{ color: addlFundsForThirdInstal < 0 ? 'red' :'inherit' }}>{addlFundsForThirdInstal ? addlFundsForThirdInstal < 0 ? `(${addlFundsForThirdInstal.toLocaleString('en-in')})` : addlFundsForThirdInstal.toLocaleString('en-in') : '-'}</td>}
                    {ScoreCard==='ScoreCard'?'' :<td style={{color: (addlFundsForFirstInstal+addlFundsForSecondInstal+addlFundsForThirdInstal) < 0 ? 'red' :'inherit' }}>{(addlFundsForFirstInstal+addlFundsForSecondInstal+addlFundsForThirdInstal) ? (addlFundsForFirstInstal+addlFundsForSecondInstal+addlFundsForThirdInstal).toLocaleString('en-in') : '-'}</td>}

                    {ScoreCard==='ScoreCard'?'' :<td>{housesForadditionalFundsReleased1st ? housesForadditionalFundsReleased1st.toLocaleString('en-in'):'-'}</td>}
                    {ScoreCard==='ScoreCard'?'' : <td>{housesForadditionalFundsReleased2nd ? housesForadditionalFundsReleased2nd.toLocaleString('en-in'):'-'}</td>}
                    {ScoreCard==='ScoreCard'?'' :<td>{housesForadditionalFundsReleased3rd ? housesForadditionalFundsReleased3rd.toLocaleString('en-in'):'-'}</td>}
                     */}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        )}
      </Wrapper>
    );
  }
}

export default ComponentList;
