import React, { PureComponent } from "react";
import { Form } from "react-bootstrap";
import PinInput from 'react-pin-input';

import styled from "styled-components";

const Container = styled.div`
  position: relative;
  margin-bottom:10px;
`;

const Label = styled(Form.Label)`
  font-size: ${props=> props.fontSize ? props.fontSize:'13px'};
  color: ${props=> props.color ? props.color: '#000000'};
  & span {
    color: red;
  }
`;

class PinInputField extends PureComponent {
  state = {
    view: false
  };
  render() {
    const {
      length = 6,
      label,
      isrequiredicon,
      onChange,
      className,
      parentClass,
      onComplete,
    } = this.props;

    // console.log("name----->>", viewpassword && this.state.view, viewpassword ,this.state.view);
    return (
      <Container className={parentClass || className}>
        <Form.Group>
          {label && (
            <Label>
              {label} {isrequiredicon ? <span>*</span> : ''}
            </Label>
          )}
          <PinInput 
            length={length} 
            initialValue=""
            // secret 
            onChange={(value, index) => onChange&&onChange(value, index)} 
            type="numeric" 
            inputMode="number"
            // style={{padding: '10px', background: "white"}}  
            inputStyle={{borderColor: '#ced4da', width:40,height:40, backgroundColor: "white"}}
            inputFocusStyle={{borderColor: '#86b7fe', boxShadow: '0 0 0 1px rgb(0 123 255 / 25%)', backgroundColor: "white"}}
            onComplete={(value, index) => onComplete(value, index)}
            autoSelect={true}
            regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
          />
        </Form.Group>
      </Container>
    );
  }
}

export { PinInputField };
