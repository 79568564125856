import React,{ Fragment } from "react";
import { Table } from "react-bootstrap";
//import { Loader } from "./Loader";
import { BASE_URL } from "../store/constant";
import Axios from "axios";
//import map from "lodash/map";
import get from "lodash/get";
//import find from "lodash/find";

import { toast } from "react-toastify";
import styled from "styled-components";
import moment from "moment";
import _ from 'lodash'

const Wrapper = styled.div`
  position: relative;
  & h3 {
    text-align: center;
    display: none;
  }

  @media print {
    h3 {
      display: block;
    }
  }
`;

class ComponentList extends React.Component {
  state = {
    isLoading: true,

  };

  componentDidMount = async () => {
    this.setState({ isLoading: true });
    try {
      //console.log(this.props);
  
      const { data: ProjectWiseDetails } = await Axios.get(`${BASE_URL}/uc-submission/ProjectDetails/${get(this.props, "ProjectCodeChild")}`);
    
      const InstallmentCount=_.uniqBy(ProjectWiseDetails,obj=>obj.installment);


     // cntCities = _.uniqBy(CitiesData, obj => Number(obj.CityCode)).length;

      this.setState({ProjectWiseDetails,InstallmentCount,isLoading: false}); 
    } catch (err) {
      this.setState({ isLoading: false });
      toast.error("Something went wronge.");
    }
  };

  render() {
    const { ProjectWiseDetails,InstallmentCount } = this.state;
    //console.log('ProjectWiseDetails--->',ProjectWiseDetails);






    return (
      <Wrapper>
        <h3>Project Details Installment/Head Wise. {get(this.props, "ProjectCodeChild")}</h3>
          <Table bordered className="bg-c-white">
            <thead
              className="label label-warning text-white text-center"
              style={{ backgroundColor: "#002b49" }}
            >
               <tr style={{ textAlign: 'center' }}>
                <th rowSpan={2} style={{width: 500, minWidth:250}}> Installment</th>
                <th rowSpan='2' className="center"> Head</th>
                <th rowSpan='2' className="center"> Release No</th>
                <th rowSpan='2' className="center"> Date</th>
                <th colSpan='2'>Amount</th>

              </tr>
          
            </thead>
            <tbody>
              {
                _.map(InstallmentCount, (st, i) => {
                 let installmentvalue=0;
                  const installmentName='Installment-'+_.get(st,'installment')
                  const HeadWiseFilter1=_.filter(ProjectWiseDetails,flt=> Number(flt.installment)=== Number(st.installment));
                  console.log('HeadWiseFilter1------>',HeadWiseFilter1);
                  const HeadWiseFilter= _.orderBy(HeadWiseFilter1, ['head'], ['asc']);
                  console.log('HeadWiseFilter------>',HeadWiseFilter);
                 
                 
                 
                  const installmentcallspan=HeadWiseFilter.length
              
                  const GENHEAD=_.filter(HeadWiseFilter,flt=>flt.head==='GEN').length;
                  const TSPHEAD=_.filter(HeadWiseFilter,flt=>flt.head==='TSP').length;
                  const SCSPHEAD=_.filter(HeadWiseFilter,flt=>flt.head==='SCSP').length;
                  
                  let GEN=0
                  let TSP=0
                  let SCSP=0
                  let GENHEADc=0
                  let TSPHEADc=0
                  let SCSPHEADc=0
                  return (
                    <Fragment key={i}>
                      {
                        _.map(HeadWiseFilter, (obj, idx) => {
                          installmentvalue +=1
                       
                          //GENHEADc +=_.get(obj,'head')==='GEN' ? 1 : 0;

                          GEN +=_.get(obj,'head')==='GEN' ? 1 : 0;
                          TSP +=_.get(obj,'head')==='TSP' ? 1 : 0;
                          SCSP +=_.get(obj,'head')==='SCSP' ? 1 : 0;

                          GENHEADc +=_.get(obj,'head')==='GEN' ? 1 : 0;
                          TSPHEADc +=_.get(obj,'head')==='TSP' ? 1 : 0;
                          SCSPHEADc +=_.get(obj,'head')==='SCSP' ? 1 : 0;
                         
                        
                         // _.get(obj,'head')==='GEN' ? GEN+1 : _.get(obj,'head')==='TSP' ? TSP+1 :_.get(obj,'head')==='SCSP' ? SCSP+1 :''
                        


                          return (
                            <tr >
                         {installmentvalue===1 ? <td rowSpan={installmentcallspan} style={{ textAlign: "center" }}>{installmentName} </td>:''}
                          {GENHEADc===1 && _.get(obj,'head')!=='TSP' && _.get(obj,'head')!=='SCSP'? <td rowSpan={GENHEAD} style={{width: 500, wordWrap: 'break-word',whiteSpace: 'normal'}}>{_.get(obj,'head')} </td>:''}
                          {TSPHEADc===1 && _.get(obj,'head')!=='GEN' && _.get(obj,'head')!=='SCSP' ? <td rowSpan={TSPHEAD} style={{width: 500, wordWrap: 'break-word',whiteSpace: 'normal'}}>{_.get(obj,'head')} </td>:''}
                          {SCSPHEADc===1 && _.get(obj,'head')!=='TSP' && _.get(obj,'head')!=='GEN' ? <td rowSpan={SCSPHEAD} style={{width: 500, wordWrap: 'break-word',whiteSpace: 'normal'}}>{_.get(obj,'head')} </td>:''}
                          
                          
                          
                          <td style={{textAlign:'right'}}>{_.get(obj,'head')==='GEN' ? 'R '+GEN : _.get(obj,'head')==='TSP' ? 'R '+TSP:_.get(obj,'head')==='SCSP' ? 'R '+SCSP:''} </td>
                          <td style={{textAlign:'left'}}>{_.get(obj,'releaseDate') ? moment(get(obj,'releaseDate')).format('DD-MMM-YYYY'):'-'}</td>
                          <td style={{textAlign:'left'}}>{_.get(obj,'releaseAmount')}</td>

                            </tr>
                          )
                        })
                      }

                    </Fragment>
                  )


                })


              }
              
            </tbody>
          </Table>
      
      </Wrapper>
    );
  }
}

export default ComponentList;
