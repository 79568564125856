import React, { Component } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

class ColumnChart3D extends Component {
  componentDidMount(){
    this.initGraph()
  }
  componentDidUpdate(){
    // am4core.disposeAllCharts()
    this.initGraph()
  }

  initGraph() {
    // Themes begin
    function myTheme1(target) {
      if (target instanceof am4core.ColorSet) {
        target.list = [
          am4core.color("#FF8C00"),
          am4core.color("#32CD32"),
          am4core.color("#8A2BE2"),
          am4core.color("#DC143C"),
          am4core.color("#008B8B"),
          am4core.color("#191970")
        ];
      }
    }

    function myTheme2(target) {
      if (target instanceof am4core.ColorSet) {
        target.list = [
          am4core.color("#0067a5"),
          am4core.color("#5dc669"),
          am4core.color("#f44336"),
          am4core.color("#ffeb3b"), 
          am4core.color("#673ab7"),
          am4core.color("#ff5722")
        ];
      }
    }

    function myTheme3(target) {
      if (target instanceof am4core.ColorSet) {
        target.list = [
          am4core.color("#f6a600"),
          am4core.color("#0067a5"), 
          am4core.color("#FF00FF"),
          am4core.color("#800080"),
          am4core.color("#008080"),
          am4core.color("#00FF00")
        ];
      }
    }

    function myTheme4(target) {
      if (target instanceof am4core.ColorSet) {
        target.list = [
          am4core.color("#FF0000"),
          am4core.color("#fbdb48"),
          am4core.color("#67d868"),
          am4core.color("#808000"),
          am4core.color("#800000"),
          am4core.color("#ff9700")
        ];
      }
    }


    am4core.useTheme(this.props.theme === 'myTheme2' ? myTheme2 : this.props.theme === 'myTheme3' ? myTheme3 : this.props.theme === 'myTheme4' ? myTheme4 :  myTheme1);
    am4core.useTheme(am4themes_animated);
    // Themes end
    // Create chart
    let chart = am4core.create(this.props.id ||'ColumnChart3D', am4charts.XYChart3D);
    // Add data
    // chart.data = this.props.graphData
    chart.data = [{
      "country": "USA",
      "visits": 4025
    }, {
      "country": "China",
      "visits": 1882
    }, {
      "country": "Japan",
      "visits": 1809
    },]
   // Create axes
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "country";
    categoryAxis.renderer.labels.template.rotation = 270;
    categoryAxis.renderer.labels.template.hideOversized = false;
    categoryAxis.renderer.minGridDistance = 20;
    categoryAxis.renderer.labels.template.horizontalCenter = "right";
    categoryAxis.renderer.labels.template.verticalCenter = "middle";
    // categoryAxis.tooltip.label.rotation = 270;
    categoryAxis.tooltip.label.horizontalCenter = "right";
    categoryAxis.tooltip.label.verticalCenter = "middle";
    categoryAxis.renderer.grid.template.disabled = true;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.grid.template.disabled = true;
    valueAxis.renderer.labels.template.disabled = true;

    // Create series
    let series = chart.series.push(new am4charts.ColumnSeries3D());
    series.dataFields.valueY = "visits";
    series.dataFields.categoryX = "country";
    // series.name = "value";
    series.tooltipText = "{categoryX}: [bold]{valueY}[/]";
    series.columns.template.fillOpacity = .8;

      let columnTemplate = series.columns.template;
      columnTemplate.strokeWidth = 2;
      columnTemplate.strokeOpacity = 1;
      columnTemplate.stroke = am4core.color("#FFFFFF");

      columnTemplate.adapter.add("fill", function(fill, target) {
        return chart.colors.getIndex(target.dataItem.index);
      })

    columnTemplate.adapter.add("stroke", function(stroke, target) {
      return chart.colors.getIndex(target.dataItem.index);
    })

    chart.cursor = new am4charts.XYCursor();
    chart.cursor.lineX.strokeOpacity = 0;
    chart.cursor.lineY.strokeOpacity = 0;
  }

    render() {
      console.log('------>>',this.props.graphData)
        return <div id={this.props.id ||'ColumnChart3D'} className={this.props.id ||'ColumnChart3D'} style={{width: '70%', height: this.props.height,...this.props.style}} />;
    }
}

export default ColumnChart3D;