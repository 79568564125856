import React, { Component } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import get from 'lodash/get'
import * as Cookies from 'js-cookie'

import styled from 'styled-components';

const Container = styled.div`
  :hover{
    z-index:3
  }
`

class CylinderGauge extends Component {
  state={
    graphValueForCheck:''
  }

  componentDidMount(){
    this.initGraph()
    this.setState({graphValueForCheck:get(this.props.graphData,'0.value1'),thememaster:get(this.props.thememaster,'id'),})
  }
  componentDidUpdate(){
    this.initGraph()
    this.setState({graphValueForCheck:get(this.props.graphData,'0.value1'),thememaster:get(this.props.thememaster,'id'),})
  }

  initGraph() {
    //const theme = Cookies.get('theme') ? JSON.parse(Cookies.get('theme')) : {}
    const theme = this.props.thememaster ? this.props.thememaster : {}
    // Themes begin
    function myTheme1(target) {
      if (target instanceof am4core.ColorSet) {
        target.list = [
          am4core.color(get(theme,'color9') ? get(theme,'color9'):"#FF8C00"),
          am4core.color("#32CD32"),
          am4core.color("#8A2BE2"),
          am4core.color("#DC143C"),
          am4core.color("#008B8B"),
          am4core.color("#191970")
        ];
      }
    }

    function myTheme2(target) {
      if (target instanceof am4core.ColorSet) {
        target.list = [
          am4core.color(get(theme,'color3') ? get(theme,'color3'):"#03a9f4"),
          am4core.color("#0067a5"),
          am4core.color("#f44336"),
          am4core.color("#ffeb3b"), 
          am4core.color("#673ab7"),
          am4core.color("#ff5722")
        ];
      }
    }

    function myTheme3(target) {
      if (target instanceof am4core.ColorSet) {
        target.list = [
          am4core.color(get(theme,'color6') ? get(theme,'color6'):"#0067a5"), 
          am4core.color("#00bfff"),
          am4core.color("#f6a600"),
          am4core.color("#800080"),
          am4core.color("#008080"),
          am4core.color("#00FF00")
        ];
      }
    }

    function myTheme4(target) {
      if (target instanceof am4core.ColorSet) {
        target.list = [
          am4core.color(get(theme,'color14') ? get(theme,'color14'):"#f44336"),
          am4core.color("#FF0000"),
          am4core.color("#67d868"),
          am4core.color("#800000"),
          am4core.color("#808000"),
          am4core.color("#ff9700")
        ];
      }
    }



    am4core.useTheme(this.props.theme === 'myTheme2' ? myTheme2 : this.props.theme === 'myTheme3' ? myTheme3 : this.props.theme === 'myTheme4' ? myTheme4 :  myTheme1);
    am4core.useTheme(am4themes_animated);
    // Themes end
    // Create chart
    let chart = am4core.create(this.props.id ||'CylinderGauge', am4charts.XYChart3D);
    chart.padding(0, -27, 10, 0);

  // Add data
chart.data = this.props.graphData ? this.props.graphData : [{
  "valueLabel1": "2018 Q1",
  "valueLabel2": "test Q1",
  "value1": 30,
  "value2": 70,
  "p1": 30,
  "p2": 70,
}, {
  "valueLabel1": "2018 Q2",
  "valueLabel2": "test Q2",
  "value1": 15,
  "value2": 85,
  "p1": 15,
  "p2": 85,
}, {
  "valueLabel1": "2018 Q3",
  "valueLabel2": "test Q3",
  "value1": 40,
  "value2": 60,
  "p1": 40,
  "p2": 60,
}, 
{
  "valueLabel1": "2018 Q4",
  "valueLabel2": "test Q4",
  "value1": 55,
  "value2": 45,
  "p1": 55,
  "p2": 45,
}];

// Create axes
let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
categoryAxis.dataFields.category = "valueLabel1";
categoryAxis.renderer.grid.template.location = 0;
categoryAxis.renderer.grid.template.strokeOpacity = 0;
categoryAxis.renderer.labels.template.disabled = true;
categoryAxis.renderer.baseGrid.disabled = true;
// categoryAxis.renderer.minGridDistance = 30;
// categoryAxis.renderer.labels.template.fontSize = 12;
// categoryAxis.renderer.grid.template.location = 0.1;
// categoryAxis.renderer.grid.template.marginBottom = 0.1;
// categoryAxis.renderer.grid.template.marginTop = 0.1;

let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
valueAxis.renderer.grid.template.strokeOpacity = 0;
valueAxis.renderer.labels.template.disabled = true;
valueAxis.renderer.baseGrid.disabled = true;
valueAxis.min = -10;
valueAxis.max = 110;
valueAxis.strictMinMax = true;
valueAxis.renderer.baseGrid.disabled = true;
// valueAxis.renderer.labels.template.adapter.add("text", function(text) {
//   console.log('texttexttext-->>', text)   
//   if ((text > 100) || (text < 0)) {
//     return "";
//   }
//   else {
//     return text + "%";
//   }
// })

// Create series
let series1 = chart.series.push(new am4charts.ConeSeries());
series1.dataFields.valueY = "p1";
series1.dataFields.categoryX = "valueLabel1";
series1.columns.template.width = am4core.percent(90);
series1.columns.template.fillOpacity = 0.9;
series1.columns.template.strokeOpacity = 1;
series1.columns.template.strokeWidth = 2;

series1.tooltip.pointerOrientation = "vertical";
series1.columns.template.tooltipText = "{categoryX}: [bold]{value1}";

let categoryLabel = series1.bullets.push(new am4charts.LabelBullet());
let b = series1.bullets.push(new am4charts.LabelBullet());
b.label.text = "{valueLabel1}";
b.label.fill = am4core.color("#fff");
b.label.fontSize = 12;
b.horizontalCenter = "bottom";
b.verticalCenter = "bottom";


categoryLabel.dy = 25; // moves it a bit down;
categoryLabel.label.text = "[bold]{value1}";
categoryLabel.label.fontSize = 12;

// categoryLabel.label.horizontalCenter = "right";
// categoryLabel.label.dx = 10;
categoryLabel.horizontalCenter = "middle";
categoryLabel.verticalCenter = "middle";
categoryLabel.label.fill = am4core.color("#fff");


let series2 = chart.series.push(new am4charts.ConeSeries());
series2.dataFields.valueY = "p2";
series2.dataFields.categoryX = "valueLabel1";
series2.dataFields.categoryExtraX = "valueLabel2";
series2.stacked = true;
series2.columns.template.width = am4core.percent(90);
series2.columns.template.fill = am4core.color("#000");
series2.columns.template.fillOpacity = 0.1;
series2.columns.template.stroke = am4core.color("#000");
series2.columns.template.strokeOpacity = 0.2;
series2.columns.template.strokeWidth = 2;

series2.tooltip.pointerOrientation = "vertical";
series2.columns.template.tooltipText = "{categoryExtraX}: [bold]{value2}";

// let categoryLabel2 = series2.bullets.push(new am4charts.LabelBullet());
// categoryLabel2.label.text = "{value2}";
// categoryLabel2.label.fontSize = 10;
// // categoryLabel2.label.horizontalCenter = "right";
// // categoryLabel2.label.dx = -10;
// categoryLabel2.horizontalCenter = "middle";
// categoryLabel2.verticalCenter = "middle";
// categoryLabel2.label.fill = am4core.color("#000");


  }
  shouldComponentUpdate(nextProps, nextState) {
    return (
      get(nextProps.graphData,'0.value1') !== get(nextState,'graphValueForCheck')||
      get(nextProps.thememaster,'id') !== get(nextState,'thememaster')
    );
  }

    render() {
      // console.log('------>>',this.props.graphData)
        return <Container id={this.props.id ||'CylinderGauge'} className={this.props.id ||'CylinderGauge'} style={{width: this.props.width ? this.props.width : '100%', margin:'auto', height: this.props.height,...this.props.style}} />;
    }
}

export default CylinderGauge;

