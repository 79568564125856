import React from "react";
import { Table } from "react-bootstrap";
//import { Loader } from "./Loader";
import { BASE_URL } from "../store/constant";
import Axios from "axios";
import map from "lodash/map";
import get from "lodash/get";
//import find from "lodash/find";

import { toast } from "react-toastify";
import styled from "styled-components";
//import moment from "moment";
import _ from 'lodash'

const Wrapper = styled.div`
  position: relative;
  & h3 {
    text-align: center;
    display: none;
  }

  @media print {
    h3 {
      display: block;
    }
  }
`;

class ComponentList extends React.Component {
  state = {
    isLoading: true,
    cityList:[]
  };


  componentDidMount = async () => {
    // this.setState({ isLoading: true });
     try {
       //console.log(this.props);
      //  const { data: stateList } = await Axios.get(`${BASE_URL}/states`);
       const { data: cityList } = await Axios.get(`${BASE_URL}/city/state/${get(this.props, "StateCode")}`);
      //  const { data: stateWise1 } = await Axios.get(`${BASE_URL}/project-details/ProjectInfo/AllData/${get(this.props, "StateCode")}`);
       this.setState({cityList}); 
     } catch (err) {
      // this.setState({ isLoading: false });
       toast.error("Something went wronge.");
     }
   };



  render() {
    const { cityList } = this.state;
    const { dataList, StateName } = this.props;

let OrgHousesSanctionedGT=0,OrgProjectCostGT=0,OrgCentralAssistApprovedGT=0,OrgStateGrantApprovedGT=0,OrgULBShareApprovedGT=0,OrgBneneficiaryShareApprovedGT=0

let HousesSanctionedGT =0,ProjectCostGT=0,CentralAssistApprovedGT=0,StateGrantApprovedGT=0,ULBShareApprovedGT=0,BneneficiaryShareApprovedGT=0,diffHousesSanctionedGT=0,diffProjectCostGT=0,diffCentralAssistApprovedGT=0,diffStateGrantApprovedGT=0,diffULBShareApprovedGT=0,diffBneneficiaryShareApprovedGT=0

// console.log('dataList------->', {dataList,cityList})



    return (
      <Wrapper>
        <h3>List of Revised Project list in CMSC No. {get(dataList, "0.CSMCNo")} for the State of {StateName}</h3>
          <Table bordered className="customTable tableStickyHeader">
            <thead
              className="label label-warning text-white text-center"
              style={{ backgroundColor: "#002b49" }}
            >
               <tr style={{ textAlign: 'center' }}>
                <th rowSpan='2' className="center" style={{ width: 30, maxWidth: 30, minWidth: 30 }}>Sr.<br/> No.</th>
                <th rowSpan={2} style={{width: 500, minWidth:250}}> Project Name</th>
                <th rowSpan='2' className="center"> Project <br />Code</th>
                <th rowSpan='2' className="center"> State</th>
                <th rowSpan='2' className="center"> City</th>
                <th colSpan='6'>Originally Sanctioned </th>
                <th colSpan='6'> Revised Sanctioned</th>
                <th colSpan='6'> Curtailed Details</th>
              </tr>
              <tr style={{ textAlign: 'center'}}>
             
                <th>Houses </th>
                <th>Project<br /> cost </th>
                <th>Central<br /> Assistance </th>
                <th>State<br /> Share</th>
                <th>ULB</th>
                <th>Beneficiary<br /> contribution</th>
                <th>Houses </th>
                <th>Project <br /> cost </th>
                <th>Central<br /> Assistance </th>
                <th>State<br /> Share</th>
                <th>ULB</th>
                <th>Beneficiary<br /> contribution</th>
                <th>Houses </th>
                <th>Project<br /> cost </th>
                <th>Central<br /> Assistance </th>
                <th>State<br /> Share</th>
                <th>ULB</th>
                <th>Beneficiary<br /> contribution</th>

              </tr>
            </thead>
            <tbody>
              {map(dataList, (obj, i) => {
                const cityName = _.get(_.find(cityList,d=>d.CityCode===obj.CityCode),'CityName') 
                // console.log('cityName------->',cityName)

                OrgHousesSanctionedGT += _.get(obj,'OrgHousesSanctioned')
                OrgProjectCostGT += _.get(obj,'OrgProjectCost')
                OrgCentralAssistApprovedGT += _.get(obj,'OrgCentralAssistApproved')
                OrgStateGrantApprovedGT += _.get(obj,'OrgStateGrantApproved')
                OrgULBShareApprovedGT += _.get(obj,'OrgULBShareApproved')
                OrgBneneficiaryShareApprovedGT += _.get(obj,'OrgBneneficiaryShareApproved')

                HousesSanctionedGT += _.get(obj,'HousesSanctioned')
                ProjectCostGT += _.get(obj,'ProjectCost')
                CentralAssistApprovedGT += _.get(obj,'CentralAssistApproved')
                StateGrantApprovedGT += _.get(obj,'StateGrantApproved')
                ULBShareApprovedGT += _.get(obj,'ULBShareApproved')
                BneneficiaryShareApprovedGT += _.get(obj,'BneneficiaryShareApproved')

                diffHousesSanctionedGT += _.get(obj,'OrgHousesSanctioned') - _.get(obj,'HousesSanctioned')
                diffProjectCostGT += _.get(obj,'OrgProjectCost') - _.get(obj,'ProjectCost')
                diffCentralAssistApprovedGT += _.get(obj,'OrgCentralAssistApproved') - _.get(obj,'CentralAssistApproved')
                diffStateGrantApprovedGT += _.get(obj,'OrgStateGrantApproved') - _.get(obj,'StateGrantApproved')
                diffULBShareApprovedGT += _.get(obj,'OrgULBShareApproved') - _.get(obj,'ULBShareApproved')
                diffBneneficiaryShareApprovedGT += _.get(obj,'OrgBneneficiaryShareApproved') - _.get(obj,'BneneficiaryShareApproved')

                return (
                  <tr key={get(obj, "ProjectCode")}>
                    <td style={{ textAlign: "center" }}>{i + 1}.</td>

                    <td style={{width: 500, wordWrap: 'break-word',whiteSpace: 'normal'}}>{obj.ProjectTitle ? obj.ProjectTitle :'-' } </td>
                    <td style={{textAlign:'right'}}>{obj.ProjectCode ? obj.ProjectCode :'-' } </td>
                    {i===0?<td rowSpan={dataList.length} style={{textAlign:'left'}}>{StateName ? StateName : '-' }</td>:''}
                    <td style={{textAlign:'left'}}> {cityName ? cityName : '-'}</td>

                    <td style={{textAlign:'right'}}>{_.get(obj,'OrgHousesSanctioned') ? _.get(obj,'OrgHousesSanctioned').toLocaleString('en-in') :'-'} </td>
                    <td style={{textAlign:'right'}}>{_.get(obj,'OrgProjectCost') ? Number(_.get(obj,'OrgProjectCost').toFixed(3)).toLocaleString('en-in') :'-'} </td>
                    <td style={{textAlign:'right'}}>{_.get(obj,'OrgCentralAssistApproved') ? Number(_.get(obj,'OrgCentralAssistApproved').toFixed(3)).toLocaleString('en-in') :'-'} </td>
                    <td style={{textAlign:'right'}}>{_.get(obj,'OrgStateGrantApproved') ? Number(_.get(obj,'OrgStateGrantApproved').toFixed(3)).toLocaleString('en-in') :'-'} </td>
                    <td style={{textAlign:'right'}}>{_.get(obj,'OrgULBShareApproved') ? Number(_.get(obj,'OrgULBShareApproved').toFixed(3)).toLocaleString('en-in') :'-'} </td>
                    <td style={{textAlign:'right'}}>{_.get(obj,'OrgBneneficiaryShareApproved') ? Number(_.get(obj,'OrgBneneficiaryShareApproved').toFixed(3)).toLocaleString('en-in') :'-'} </td>
                  
                    <td style={{textAlign:'right'}}>{_.get(obj,'HousesSanctioned') ? _.get(obj,'HousesSanctioned').toLocaleString('en-in') :'-'} </td>
                    <td style={{textAlign:'right'}}>{_.get(obj,'ProjectCost') ? Number(_.get(obj,'ProjectCost').toFixed(3)).toLocaleString('en-in') :'-'} </td>
                    <td style={{textAlign:'right'}}>{_.get(obj,'CentralAssistApproved') ? Number(_.get(obj,'CentralAssistApproved').toFixed(3)).toLocaleString('en-in') :'-'} </td>
                    <td style={{textAlign:'right'}}>{_.get(obj,'StateGrantApproved') ? Number(_.get(obj,'StateGrantApproved').toFixed(3)).toLocaleString('en-in') :'-'} </td>
                    <td style={{textAlign:'right'}}>{_.get(obj,'ULBShareApproved') ? Number(_.get(obj,'ULBShareApproved').toFixed(3)).toLocaleString('en-in') :'-'} </td>
                    <td style={{textAlign:'right'}}>{_.get(obj,'BneneficiaryShareApproved') ? Number(_.get(obj,'BneneficiaryShareApproved').toFixed(3)).toLocaleString('en-in') :'-'} </td>
                  
                    <td style={{textAlign:'right'}}>{(_.get(obj,'OrgHousesSanctioned')-_.get(obj,'HousesSanctioned')) ? (_.get(obj,'OrgHousesSanctioned')-_.get(obj,'HousesSanctioned')).toLocaleString('en-in') :'-'} </td>
                    <td style={{textAlign:'right'}}>{(_.get(obj,'OrgProjectCost')-_.get(obj,'ProjectCost')) ? Number((_.get(obj,'OrgProjectCost')-_.get(obj,'ProjectCost')).toFixed(3)).toLocaleString('en-in') :'-'} </td>
                    <td style={{textAlign:'right'}}>{(_.get(obj,'OrgCentralAssistApproved')-_.get(obj,'CentralAssistApproved')) ? Number((_.get(obj,'OrgCentralAssistApproved')-_.get(obj,'CentralAssistApproved')).toFixed(3)).toLocaleString('en-in') :'-'} </td>
                    <td style={{textAlign:'right'}}>{(_.get(obj,'OrgStateGrantApproved')-_.get(obj,'StateGrantApproved')) ? Number((_.get(obj,'OrgStateGrantApproved')-_.get(obj,'StateGrantApproved')).toFixed(3)).toLocaleString('en-in') :'-'} </td>
                    <td style={{textAlign:'right'}}>{(_.get(obj,'OrgULBShareApproved')-_.get(obj,'ULBShareApproved')) ? Number((_.get(obj,'OrgULBShareApproved')-_.get(obj,'ULBShareApproved')).toFixed(3)).toLocaleString('en-in') :'-'} </td>
                    <td style={{textAlign:'right'}}>{(_.get(obj,'OrgBneneficiaryShareApproved')-_.get(obj,'BneneficiaryShareApproved')) ? Number((_.get(obj,'OrgBneneficiaryShareApproved')-_.get(obj,'BneneficiaryShareApproved')).toFixed(3)).toLocaleString('en-in') :'-'} </td>

                  </tr>
                );
              })}

          <tr style={{fontWeight:600}}>
                    <td style={{width: 500, wordWrap: 'break-word',whiteSpace: 'normal'}} colSpan={5}>Grand Total </td>

                    <td style={{textAlign:'right'}}>{OrgHousesSanctionedGT ? OrgHousesSanctionedGT.toLocaleString('en-in') :'-'} </td>
                    <td style={{textAlign:'right'}}>{OrgProjectCostGT ? Number(OrgProjectCostGT.toFixed(3)).toLocaleString('en-in') :'-'} </td>
                    <td style={{textAlign:'right'}}>{OrgCentralAssistApprovedGT ? Number(OrgCentralAssistApprovedGT.toFixed(3)).toLocaleString('en-in') :'-'} </td>
                    <td style={{textAlign:'right'}}>{OrgStateGrantApprovedGT ? Number(OrgStateGrantApprovedGT.toFixed(3)).toLocaleString('en-in') :'-'} </td>
                    <td style={{textAlign:'right'}}>{OrgULBShareApprovedGT ? Number(OrgULBShareApprovedGT.toFixed(3)).toLocaleString('en-in') :'-'} </td>
                    <td style={{textAlign:'right'}}>{OrgBneneficiaryShareApprovedGT ? Number(OrgBneneficiaryShareApprovedGT.toFixed(3)).toLocaleString('en-in') :'-'} </td>
                  
                    <td style={{textAlign:'right'}}>{HousesSanctionedGT ? HousesSanctionedGT.toLocaleString('en-in') :'-'} </td>
                    <td style={{textAlign:'right'}}>{ProjectCostGT ? Number(ProjectCostGT.toFixed(3)).toLocaleString('en-in') :'-'} </td>
                    <td style={{textAlign:'right'}}>{CentralAssistApprovedGT ? Number(CentralAssistApprovedGT.toFixed(3)).toLocaleString('en-in') :'-'} </td>
                    <td style={{textAlign:'right'}}>{StateGrantApprovedGT ? Number(StateGrantApprovedGT.toFixed(3)).toLocaleString('en-in') :'-'} </td>
                    <td style={{textAlign:'right'}}>{ULBShareApprovedGT ? Number(ULBShareApprovedGT.toFixed(3)).toLocaleString('en-in') :'-'} </td>
                    <td style={{textAlign:'right'}}>{BneneficiaryShareApprovedGT ? Number(BneneficiaryShareApprovedGT.toFixed(3)).toLocaleString('en-in') :'-'} </td>
                  
                    <td style={{textAlign:'right'}}>{diffHousesSanctionedGT  ? diffHousesSanctionedGT.toLocaleString('en-in') :'-'} </td>
                    <td style={{textAlign:'right'}}>{diffProjectCostGT  ? Number(diffProjectCostGT.toFixed(3)).toLocaleString('en-in') :'-'} </td>
                    <td style={{textAlign:'right'}}>{diffCentralAssistApprovedGT  ? Number(diffCentralAssistApprovedGT.toFixed(3)).toLocaleString('en-in') :'-'} </td>
                    <td style={{textAlign:'right'}}>{diffStateGrantApprovedGT  ? Number(diffStateGrantApprovedGT.toFixed(3)).toLocaleString('en-in') :'-'} </td>
                    <td style={{textAlign:'right'}}>{diffULBShareApprovedGT  ? Number(diffULBShareApprovedGT.toFixed(3)).toLocaleString('en-in') :'-'} </td>
                    <td style={{textAlign:'right'}}>{diffBneneficiaryShareApprovedGT  ? Number(diffBneneficiaryShareApprovedGT.toFixed(3)).toLocaleString('en-in') :'-'} </td>

                  </tr>

            </tbody>
          </Table>
      
      </Wrapper>
    );
  }
}

export default ComponentList;
