// id: 'file-upload',
// title: 'File Upload',
// type: 'item',
// url: '/file-upload',
// classes: 'nav-item',
// icon: 'feather icon-upload-cloud'


const menu = {
	items: [
		{
			id: 'navigation',
			title: 'Navigation',
			type: 'group',
			icon: 'icon-navigation',
			children: [
				{
					id: 'dashboard',
					title: 'Dashboard',
					type: 'item',
					classes: 'nav-item',
					url: '/',
					// breadcrumbs: false,
					icon: 'feather icon-home',

				},
				{
					id: 'dashboard20',
					title: 'Dashboard 2.0',
					type: 'item',
					classes: 'nav-item',
					url: '/dashboard20',
					// breadcrumbs: false,
					icon: 'feather icon-home',

				},

				// 	{
				// 		id: 'investment',
				// 		title: 'Investment',
				// 		type: 'item',
				// 		classes: 'nav-item',
				// 		url: '/investment',
				// 		breadcrumbs: false,
				// 		icon: 'fa fa-inr',

				// },
				{
					id: 'ProgressInmap',
					title: 'Progress in Map',
					type: 'item',
					icon: 'feather icon-map-pin',
					url: '/mapview/demand-saturation'

					// children: [
					// 	{
					// 		id: 'DemandSaturation',
					// 		title: 'Demand Saturation',
					// 		type: 'item',
					// 		url: '/mapview/demand-saturation'
					// 	},
						// {
						// 	id: 'HousesGroundedForConstructionVSSanctioned',
						// 	title: 'Houses Grounded for Construction vs Sanctioned',
						// 	type: 'item',
						// 	url: '/mapview/construction-vs-sanctioned'
						// },
						// {
						// 	id: 'HousesCompletionVSSanctioned',
						// 	title: 'Houses Completion vs Sanctioned',
						// 	type: 'item',
						// 	url: '/mapview/completion-vs-sanctioned'
						// },
						// {
						// 	id: 'InvestmentMobilisationByState/UT',
						// 	title: 'Investment Mobilisation by State/UT',
						// 	type: 'item',
						// 	url: '/mapview/investment-mobilisation'
						// },
						// {
						// 	id: 'ReleaseOfCentralAssistance',
						// 	title: 'Release of Central Assistance',
						// 	type: 'item',
						// 	url: '/mapview/release-of-central-assistance'
						// },
						// {
						// 	id: 'FundUtilisation',
						// 	title: 'Fund Utilisation',
						// 	type: 'item',
						// 	url: '/mapview/fund-utilisation'
						// },
					// ]
				},
				{
					id: 'reports',
					title: 'Monitoring Reports',
					type: 'collapse',
					icon: 'feather icon-sliders',
					children: [
					
						{
							id: 'AtaGlance',
							title: 'At a Glance',
							type: 'item',
							url: '/reports/at-glance'
						},{
							id: 'AtaGlance2.0',
							title: 'At a Glance 2.0',
							type: 'item',
							url: '/reports/at-glanceNew'
						},

						{
							id: 'AtaGlance2',
							title: 'At a Glance 2',
							type: 'item',
							url: '/reports/at-glance-2'
						},
						
						{
							id: 'AtaGlance3',
							title: 'At a Glance 3',
							type: 'item',
							url: '/reports/at-glance-3'
						},
						{
							id: 'ComparativeReport',
							title: 'Comparative Report',
							type: 'item',
							url: '/reports/comparative-report'
						},
						{
							id: 'CSMCwiseMonitoringofProgress',
							title: 'CSMC wise Monitoring of Progress',
							type: 'item',
							url: '/reports/csmc-wise-monitoring-progress'
						},
						{
							id: 'StatusProgressRespectiveYears',
							title: 'Status of Progress in Respective Years',
							type: 'item',
							url: '/reports/status-financial-physical-progress-respective-years'
						},
						{
							id: 'NonStaterHouses',
							title: 'Non Starter House',
							type: 'item',
							url: '/reports/nonStarterHouses'
						},
						{
							id: 'StateReview',
							title: 'State Review',
							type: 'item',
							url: '/reports/state-review'
						},

						// {
						// 	id: 'DistrictWiseProgress',
						// 	title: 'District wise Progress',
						// 	type: 'item',
						// 	url: '/reports/district-wise-progress'
						// },
						 {
							id: 'investment',
							title: 'Investment',
							type: 'item',
							url: '/reports/investment'
						},  {
							id: 'scorecard',
							title: 'Score Card',
							type: 'item',
							url: '/reports/scorecard'
						},
						// {
						// 	id: 'scorecard1',
						// 	title: 'Score Card New',
						// 	type: 'item',
						// 	url: '/reports/scorecard1'
						// },
						{
							id: 'ConsumptionsofMaterials',
							title: 'Consumptions of Materials',
							type: 'item',
							url: '/reports/consumptions-of-materials'
						},
						{
							id: 'SltcCltcHfapoaDetails',
							title: 'Sltc Cltc Hfapoa Details',
							type: 'item',
							url: '/reports/Sltc-Cltc-Hfapoa-Details'
						},

						
						{
							id: 'NewTechnologies',
							title: 'New Technologies',
							type: 'item',
							url: '/reports/NewTechnologies'
						},
						{
							id: 'PPEReport',
							title: 'PPP Report',
							type: 'item',
							url: '/reports/PPPReport'
						},
						{
							id: 'PMInaugurated',
							title: 'PM Inaugurated Projects',
							type: 'item',
							url: '/reports/pminaugurated'
						},
						
						
						{
							id: 'constructionCost',
							title: 'Construction Cost',
							type: 'item',
							url: '/reports/construction-cost'
						},
						
						{
							id: 'MasterPmay',
							title: 'Master PMAY',
							type: 'item',
							url: '/reports/master-pmayu'
						},
						{
							id: 'FAforUpdate',
							title: 'FA for Update',
							type: 'item',
							url: '/reports/FA-for-update'
						},
						{
							id: 'FinancialContribution',
							title: 'Financial Contribution',
							type: 'item',
							url: '/reports/financial-contribution'
						},
						{
							id: 'PhysicalProgressDetails',
							title: 'Physical Progress Status',
							type: 'item',
							url: '/reports/physical-progress-details'
						},
						{
							id: 'FinancialProgressDetails',
							title: 'Financial Progress Status',
							type: 'item',
							url: '/reports/financial-progress-details'
						},
						{
							id: 'ProjectDetailsReport',
							title: 'Project at a glance',
							type: 'item',
							url: '/reports/project-details-report'
						},{
							id: 'KPMG',
							title: 'KPMG',
							type: 'item',
							url: '/reports/KPMG'
						},
						
						
						{
							id: 'ProjectActionplan',
							title: 'HFA Action plan',
							type: 'item',
							url: '/reports/project-action-plan'
						},{
							id: 'StatewiseProgressDetails',
							title: 'AHP Progress Details',
							type: 'item',
							url: '/reports/StatewiseProgressDetails'
						},{
							id: 'CurtailedHouses',
							title: 'Curtailed Houses',
							type: 'item',
							url: '/reports/StateWiseCurtailedHouses'
						},
						{
							id: 'InspectionHistory',
							title: 'Inspection History',
							type: 'item',
							url: '/reports/inspection-history'
						},{
							id: 'OnlyTesting',
							title: 'OnlyTesting',
							type: 'item',
							url: '/reports/onlyTesting'
						},{
							id:'FinancialAndPhysicalPerformance',
							title:'Financial & Physical Performance',
							type:'item',
							url:'/reports/financialandphysical-performance'
						},{
							id:'Physical Progress Monitoring',
							title:'Physical Progress Monitoring',
							type:'item',
							url:'/reports/PhysicalProgressMonitoring'
						},{
							id:'Physical Progress Tracking',
							title:'Physical Progress Tracking',
							type:'item',
							url:'/reports/PhysicalProgressMonitoringNew'
						},
						
						

					]
				},{
					id: 'PhysicalFinancialReport',
					title: 'Physical & Financial Report',
					type: 'collapse',
					icon: 'feather icon-book',
					children: [
						{
							id: 'YearWisePhysical',
							title: 'Year wise Physical',
							type: 'item',
							url: '/reports/year-wise-physical'
						},{
							id: 'Respective YearWise',
							title: 'Respective Year wise-physical',
							type: 'item',
							url: '/reports/respective-year-wise'
						},
						{
							id: 'YearWiseFinancial',
							title: 'Year wise Financial',
							type: 'item',
							url: '/reports/year-wise-financial'
						},
						{
                            id: 'YearWisePhysicalFinancial',
                            title: 'Year wise Physical and Financial',
                            type: 'item',
                            url: '/reports/year-wise-physical-and-financial'
                        },
						{
							id: 'Respective YearWise-Financial',
							title: 'Respective Year wise-Financial',
							type: 'item',
							url: '/reports/respective-year-wise-financial'
						},{
							id: 'CityWise YearWise progress',
							title: 'CityWise YearWise progress',
							type: 'item',
							url: '/reports/cityWise-year-wise-physical-financial'
						},{
							id: 'DistrictCityWiseProgress',
							title: 'District and City Wise Progress',
							type: 'item',
							url: '/reports/districtCityWiseProgress'
						},
						{
							id: 'FinancialProgress(verticalwise)',
							title: 'Financial Progress (Vertical wise)',
							type: 'item',
							url: '/weekly/financial-progress'
						},
						{
							id: 'PhysicalProgress(verticalwise)',
							title: 'Physical Progress (Vertical wise)',
							type: 'item',
							url: '/weekly/physical-progress'
						},
						{
							id: 'StatewiseexRAY&CLSS',
							title: 'State wise excl RAY & CLSS',
							type: 'item',
							url: '/weekly/statewise-exclude-rayclss'
						},
						{
							id: 'PhysicalAndFinancialProgress',
							title: 'Physical & Financial Progress(Old)',
							type: 'item',
							url: '/reports/physical-and-financial-progress'
						},
						{
							id: 'PhysicalAndFinancialProgressDynamic',
							title: 'Physical & Financial Progress',
							type: 'item',
							url: '/reports/physical-and-financial-progress-dynm'
						},
						{
							id: 'PhysicalAndFinancialProgressDynamic2',
							title: 'Physical & Financial Progress2',
							type: 'item',
							url: '/reports/physical-and-financial-progress-dynm2'
						},
						{
							id: 'PhysicalAndFinancialAllIndiaConstWise',
							title: 'Physical & Financial All India Const. wise',
							type: 'item',
							url: '/reports/physical-and-financial-progress-constituencywise-all-india'
						},
						{
                            id: 'PhysicalProgressULB',
                            title: 'Physical Progress ULB',
                            type: 'item',
                            url: '/reports/physical-progress-ULB'
                        },
						{
							id: 'StateWiseProgInc_CLSS_Exc_RAY',
							title: 'StateWise Prog. incl CLSS excl RAY',
							type: 'item',
							url: '/weekly/statewise-incl-clss-exc-ray'
						},
						{
							id: 'StateWiseProgressIncRayExCLSS',
							title: 'StateWise Prog. incl. RAY excl CLSS',
							type: 'item',
							url: '/weekly/statewise-incl-ray-exc-clss'
						},
						{
							id: 'StateWiseProgressAllComp',
							title: 'StateWise Prog. incl RAY & CLSS',
							type: 'item',
							url: '/weekly/statewise-incl-ray-and-clss'
						},
					]
				},
				{
					id: 'CLSSDetails',
					title: 'CLSS Details',
					type: 'collapse',
					icon: 'feather icon-book',
					children: [
						{
							id: 'CLSSstateAndCNAWise',
							title: 'CLSS State / CNA Wise',
							type: 'item',
							url: '/reports/clss-statewise'
						},
						{
							id: 'CLSSstateCNA&YearWise',
							title: 'CLSS State CNA & Year Wise',
							type: 'item',
							url: '/reports/CLSSStateWiseYearWise'
						},
						{
							id: 'ClssCityWise',
							title: 'CLSS City Wise',
							type: 'item',
							url: '/reports/ClssCityWise'
						},{
							id: 'ClssCityWiseYearWise',
							title: 'CLSS City & YearWise',
							type: 'item',
							url: '/reports/ClssCityWiseYearWise'
						},
					]
				},
				{
					id: 'ReleasesUCDetails',
					title: 'Releases & UC Details',
					type: 'collapse',
					icon: 'feather icon-book',
					children: [
						{
							id: 'CumulativeRelease',
							title: 'Releases Head wise',
							type: 'item',
							url: '/reports/releases-headwise'
						},
						
						{
							id: 'InstallmentHeadWise',
							title: 'Installment / Head Wise',
							type: 'item',
							url: '/reports/InstallmentHeadWise'
						},
						{
							id: 'ActualReleased',
							title: 'Actual Released',
							type: 'item',
							url: '/reports/ActualReleased'
						},
						{
							id: 'LiabilityPMAY',
							title: 'PMAY-U Liablity',
							type: 'item',
							url: '/reports/pmay-u-liability'
						},
						{
							id: 'exesCAblance',
							title: 'Excess CA Balance',
							type: 'item',
							url: '/reports/exces-ca-balance'
						},
						{
							id: 'RecalledAmountAdjusted',
							title: 'Recalled Amount Adjusted',
							type: 'item',
							url: '/reports/RecalledAmountAdjusted'
						},
						{
							id: 'ProjectWiseAnalysisReleaseofFunds',
							title: 'Release Analysis',
							type: 'item',
							url: '/reports/csmc-wise-release-fund'
						},
						{
							id: 'ReleaseAnalysis1',
							title: 'Release Analysis1',
							type: 'item',
							url: '/reports/release-analysis'
						},
						{
							id: 'ExpectedRelease',
							title: 'Expected Release of Funds',
							type: 'item',
							url: '/reports/expected-release'
						},{
							id: 'HeadWiseExpectedRelease',
							title: 'Head wise Expected Release of Funds',
							type: 'item',
							url: '/reports/head-wise-expected-release'
						},
						{
							id: 'ApprovedPAOLetter',
							title: 'Approved PAO Letter',
							type: 'item',
							url: '/reports/approved-pao-letter'
						},
						{
							id: 'HeadWiseReleaseAndUC',
							title: 'Head wise Release and UC',
							type: 'item',
							url: '/reports/headwise-release-uc'
						},	{
							id: 'Statewiseucstatus',
							title: 'Sanction Order Wise UCs Status',
							type: 'item',
							url: '/reports/Statewiseucstatus'
						},	{
							id: 'YearWiseReleasedAndUCRecieved',
							title: 'Year Wise Released & UC Received',
							type: 'item',
							url: '/reports/YearWiseReleasedAndUCRecieved'
						},{
							id: 'InstallmentWiseReleasedAndUCRecieved',
							title: 'Installment Wise Released & UC Received',
							type: 'item',
							url: '/reports/InstallmentWiseReleaseAndUC'
						},{
							id:'NonProjectRelease',
							title:'Non-Project-Release & UC',
							type:'item',
							url:'/reports/NonProjectRelease'
						},{
							id: 'YearWise Released Vs UC Received',
							title: 'YearWise Released Vs UC Received',
							type: 'item',
							url: '/reports/yearWiseReleaseVsUCReceived'
						},
					]
				},

				{
					id: 'GeoTaggedReport',
					title: 'GeoTagged Report',
					type: 'collapse',
					icon: 'feather icon-book',
					children: [
						{
							id:'StatusOfGeoTag',
							title:'BLC Progress Vs Geo-Tag',
							type:'item',
							url:'/reports/StatusofHousesSanctioned'
							},
						
						{
							id:'GeoTagDetails',
							title:'Geo-Tag Actual',
							type:'item',
							url:'/weekly/GeoTagDetails'
						},
						{
							id:'GeoTagliklyDetails',
							title:'Geo-Tag Progress-BLC',
							type:'item',
							url:'/weekly/GeoTagLikelyDetails'
						},
						{
							id:'GeoTagliklyDetailsAHPISSR',
							title:'Geo-Tag Progress-AHP/ISSR',
							type:'item',
							url:'/weekly/GeoTagLikelyDetailsAHPISSR'
						},	  
						{
						id:'GeoTag Progress Tracking',
						title:'GeoTag Progress Tracking',
						type:'item',
						url:'/reports/GeoTagProgressTracking'
						},
						{
							id:'Analysis of GeoTag',
							title:'Analysis of GeoTag',
							type:'item',
							url:'/reports/GeoTagAnalysis'
							},
							{
								id:'GeoTagging Performance',
								title:'GeoTagging Performance',
								type:'item',
								url:'/reports/GeoTagTracking'
								},
								{
									id:'GeoTag Monitoring',
									title:'GeoTag Monitoring',
									type:'item',
									url:'/reports/GeoTagMonitoring'
									},
									{
										id:'GeoTagging Performance With AVG',
										title:'Performance of Geo-tagging Stages',
										type:'item',
										url:'/reports/GeoTagAVGPerformance'
										},
										{
											id:'GeoTag NRSA Count',
											title:'GeoTag NRSA Count',
											type:'item',
											url:'/reports/GeoTagNRSACountReport'
											},
										// {
										// 	id:'GeoTagging Analysis2',
										// 	title:'Analysis2',
										// 	type:'item',
										// 	url:'/reports/GeoTagAnalysis2'
										// 	},
					]
				},
				{
					id: 'BeneficiariesDetails',
					title: 'Beneficiaries Details',
					type: 'collapse',
					icon: 'feather icon-book',
					children: [
						
						{
							id: 'CategoriesWiseHouses',
							title: 'Categories wise Houses',
							type: 'item',
							url: '/reports/categories-wise-houses'
						},
						
						{
							id: 'BeneficiaryUpdatesReport',
							title: 'Beneficiary Updates Report (CronJob)',
							type: 'item',
							url: '/admin-services/beneficiary-updates-report'
						},
						{
							id: 'BeneficiaryDataUpdates',
							title: 'Beneficiary Data Updates',
							type: 'item',
							url: '/admin-services/beneficiary-data-updates'
						},
						{
							id: 'BeneficiariesDetails',
							title: 'Beneficiaries Details',
							type: 'item',
							url: '/admin-services/beneficiaries-details'
						},
						{
							id: 'NHB PMAY',
							title: 'NHB PMAY',
							type: 'item',
							url: '/admin-services/NHB_PMAY'
						},
						{
							id: 'MisbeneficiariesDataUpdates',
							title: 'Store Beneficiaries Data',
							type: 'item',
							url: '/admin-services/MisbeneficiariesDataUpdates'
						},
						{
							id: 'testingMISBeneficiariesDataUpdates',
							title: 'testingMISBeneficiariesDataUpdates',
							type: 'item',
							url: '/admin-services/testingMISBeneficiariesDataUpdates'
						},
					]
				},
				{
					id: 'SlumDetails',
					title: 'Slum Details',
					type: 'collapse',
					icon: 'feather icon-book',
					children: [
						
						{
							id:'SlumsDetails',
							title:'Slums Details ',
							type:'item',
							url:'/reports/SlumsDetails'
						},
						{
							id:'SlumsCountReport',
							title:'Slums Count Report ',
							type:'item',
							url:'/reports/SlumsCountReport'
						},
						{
							id:'SlumsDetailsProjectWise',
							title:'Slums Details Project Wise',
							type:'item',
							url:'/reports/SlumsDetailsProjectWise'
						},{
							id: 'Attached Beneficiaries',
							title: 'Attached Slum Beneficiaries',
							type: 'item',
							url: '/reports/slumBeneficiaries'
						},{
							id: 'Houses Population',
							title: 'Houses Population',
							type: 'item',
							url: '/reports/housesPopulation'
						},
						
						
					]
				},

				{
					id: 'JnNURM',
					title: 'JnNURM',
					type: 'collapse',
					icon: 'feather icon-book',
					children: [
						
						{
							id: 'JnNURMstateWise',
							title: 'JnNURM State Wise',
							type: 'item',
							url: '/weekly/jnnurm-state-wise'
						},
						{
							id: 'DistAndCityProgJN',
							title: 'JnNURM Dist-City Wise',
							type: 'item',
							url: '/weekly/jnnurm-dist-city-wise'
						},
						
					]
				},

				{
					id: 'weeklyReports',
					title: 'Reports',
					type: 'collapse',
					icon: 'feather icon-book',
					children: [
						
						// {
						// 	id: 'StateWiseProgressExclRay',
						// 	title: 'State wise progress excl RAY ',
						// 	type: 'item',
						// 	url: '/weekly/statewiseprogress-exclray'
						// },
						
						
						{
							id: 'RAYstateWise',
							title: 'RAY State Wise',
							type: 'item',
							url: '/weekly/ray-state-wise'
						},
						{
							id: 'StateWiseExRayCLSS',
							title: 'Issues under PMAY-U',
							type: 'item',
							url: '/weekly/excl-ray-and-clss'
						},
						{
							id: 'DemandStatus',
							title: 'Demand Status',
							type: 'item',
							url: '/weekly/demand-status'
						},
						{
							id: 'StateWiseBaseLineInfo',
							title: 'Baseline Information',
							type: 'item',
							url: '/weekly/baseline-information'
						},
						{
							id: 'atGlanceWeekly',
							title: 'At a Glance',
							type: 'item',
							url: '/weekly/at-glance'
						},
						
						
					]
				},
				{
					id: 'master',
					title: 'Master',
					type: 'collapse',
					icon: 'feather icon-layers',
					children: [
						{
							id: 'MajorScheme',
							title: 'Major Scheme',
							type: 'item',
							url: '/master/major-scheme'
						},
						{
							id: 'SchemeComponent',
							title: 'Scheme Component',
							type: 'item',
							url: '/master/scheme-component'
						},
						{
							id: 'SubComponent',
							title: 'Sub Component',
							type: 'item',
							url: '/master/sub-component'
						},
						{
							id: 'TechnologyList',
							title: 'Technology',
							type: 'item',
							url: '/master/technology'
						},
						{
							id: 'material',
							title: 'Material',
							type: 'item',
							url: '/master/material'
						},

						{
							id: 'State',
							title: 'State',
							type: 'item',
							url: '/master/state'
						},
						{
							id: 'District',
							title: 'District',
							type: 'item',
							url: '/master/district'
						},
						{
							id: 'City',
							title: 'City',
							type: 'item',
							url: '/master/city'
						},
						{
							id: 'Classification',
							title: 'Classification',
							type: 'item',
							url: '/master/Classification'

						},
						{
							id: 'ClassificationMap',
							title: 'ClassificationMapping',
							type: 'item',
							url: '/master/ClassificationMap'
						},
						{
							id: 'ProjectTechnologyMapping',
							title: 'Project & Technology Mapping',
							type: 'item',
							url: '/master/project-technology-mapping'
						},
						{
							id: 'ParliamentAndAssemblyMapping',
							title: 'Parliament and Assembly Const. Mapping',
							type: 'item',
							url: '/master/parliament-assembly-mapping'
						},

						{
							id: 'Constituency',
							title: 'Constituency',
							type: 'item',
							url: '/master/constituency'
						},
						{
							id: 'Assembly',
							title: 'Assembly',
							type: 'item',
							url: '/master/assembly'
						},
						{
							id: 'ProgressInMapMaster',
							title: 'Progress in Map',
							type: 'item',
							url: '/master/progress-in-map'
						},
						{
							id: 'DocumentUploadMaster',
							title: 'Document Upload Master',
							type: 'item',
							url: '/master/document-upload-master'
						},
						{
							id: 'ThemeMaster',
							title: 'Theme Master',
							type: 'item',
							url: '/master/theme-master'
						},{
							id: 'ImplementingAgency',
							title: 'Implementing Agency Master',
							type: 'item',
							url: '/master/implementing-agency'
						},
						{
							id: 'PAOLetterMaster',
							title: 'PAO Letter Master',
							type: 'item',
							url: '/master/paoletter'
						},
						{
							id: 'LikelyCurtailmentMaster',
							title: 'Likely Curtailment Master',
							type: 'item',
							url: '/master/likely-curtailment-master'
						},
					]
				},
				{
					id: 'AuditData',
					title: 'Audit Data',
					type: 'collapse',
					icon: 'feather icon-file-text',
					children: [
						{
							id: 'AuditDetails',
							title: 'Documents',
							type: 'item',
							url: '/reports/auditDetails'
						},
					]
				},
				{
					id: 'prayash',
					title: 'PRAYASH Data',
					type: 'collapse',
					icon: 'feather icon-file-text',
					children: [
						// {
						// 	id: '3kpi',
						// 	title: '3 KPI',
						// 	type: 'item',
						// 	url: '/prayash/3kpi'
						// },{
						// 	id: '5kpi',
						// 	title: '5 KPI',
						// 	type: 'item',
						// 	url: '/prayash/5kpi'
						// },{
						// 	id: '14kpi',
						// 	title: '14 KPI',
						// 	type: 'item',
						// 	url: '/prayash/14kpi'
						// },
						{
							id: '22kpi',
							title: '22 KPI',
							type: 'item',
							url: '/prayash/22kpi'
						},
					]
				},
				{
					id: 'MISDATA',
					title: 'MIS Data',
					type: 'collapse',
					icon: 'feather icon-file-text',
					children: [
						
						{
							id: 'DashBoardProjectWise',
							title: 'DashBoard ProjectWise',
							type: 'item',
							url: '/mis/dashBoardProjectWise'
						},
						{
							id:'MIS Progress of Project',
							title:'MIS-MPR Progress of Project',
							type:'item',
							url:'/weekly/mis-progressProject'
						},
						{
							id:'Comparision-Report',
							title:'Comparision-Report',
							type:'item',
							url:'/weekly/comparision-Report'
						}
					]
				},

				{
					id: 'dataEntry',
					title: 'Data Entry',
					type: 'collapse',
					icon: 'feather icon-file-text',
					children: [
						{
							id: 'CLSSstateWise',
							title: 'CLSS stateWise CNAWise',
							type: 'item',
							url: '/entry/clss-statewise'
						},
						// {
						// 	id: 'CLSSstateWiseSanction',
						// 	title: 'CLSS stateWise Sanction',
						// 	type: 'item',
						// 	url: '/entry/clssSanctionStateWise'
						// },
						{
							id: 'CLSSCityWise',
							title: 'CLSS City Wise',
							type: 'item',
							url: '/entry/clss-citywise'
						},
						{
							id: 'UploadCategoryWise',
							title: 'Upload Category Wise',
							type: 'item',
							url: '/entry/UploadCategoryWise'
						},
						
						{
							id: 'ProjectInformation',
							title: 'Project Information',
							type: 'item',
							url: '/entry/project-information'
						},
						{
							id: 'PhysicalProgress',
							title: 'Physical Progress',
							type: 'item',
							url: '/entry/physical-progress'
						},
						{
							id: 'PhysicalProgressSubmission',
							title: 'Physical Progress Submission',
							type: 'item',
							url: '/entry/physical-progress-submission'
						},
						{
							id: 'AddCumulativeRelease',
							title: 'Add Cumulative Release',
							type: 'item',
							url: '/entry/add-cumulative-release'
						},
						{
							id: 'ReleasesFundFlow',
							title: 'Releases & Fund Flow',
							type: 'item',
							url: '/entry/releases-fundflow'
						},
						{
							id: 'ExcessCAPool',
							title: 'Excess CA Pool',
							type: 'item',
							url: '/entry/excess-ca-pool'
						},
						{
							id: 'Recalled ExcessCAPool',
							title: 'Recalled Excess CA Pool',
							type: 'item',
							url: '/entry/recalled-excess-ca-pool'
						},
						{
							id: 'UCSubmission',
							title: 'UC Submission',
							type: 'item',
							url: '/entry/uc-submission'
						},
						{
							id: 'SanctionAndUtilization',
							title: 'Sanction And Utilization Order',
							type: 'item',
							url: '/entry/sanction-utilization'
						},
						{
							id: 'Audit',
							title: 'Audit',
							type: 'item',
							url: '/entry/audit'
						},

						{
							id: 'ProjectBriefDetails',
							title: 'Project Brief Details',
							type: 'item',
							url: '/entry/project-brief-details'
						},
						{
							id: 'Minutes',
							title: 'CSMC Minutes',
							type: 'item',
							url: '/entry/minutes'
						},
						{
							id: 'scorecard',
							title: 'State Score Card',
							type: 'item',
							url: '/entry/scorecard'
						},
						{
							id: 'DemandSurvey',
							title: 'Demand Survey',
							type: 'item',
							url: '/entry/demand-survey'
						},
						{
							id: 'DocumentUpload',
							title: 'Document Upload',
							type: 'item',
							url: '/entry/document-upload'
						},
						{
							id: 'ImplementingAgencyMapWithProject',
							title: 'Implementing Agency map with Project',
							type: 'item',
							url: '/entry/implementing-agency-project'
						},
						{
							id:'PAOLetter',
							title:'PAO Letter',
							type:'item',
							url:'/entry/paolist'
						},
						{
							id:'VIPReference',
							title:'VIP Reference',
							type:'item',
							url:'/entry/vip-reference'
						},
						{
							id: 'action',
							title: 'Upload Geo-Tag Annexure Wise',
							type: 'item',
							url: '/admin-services/action'
						},
						{
							id: 'geoTagDataUpdates',
							title: 'GeoTag Data Updates',
							type: 'item',
							url: '/admin-services/geotag-data-updates'
						},
						{
							id: 'AnnexureUpdate',
							title: 'Annexure Update',
							type: 'item',
							url: '/admin-services/annexure-update'
						},
						{
							id: 'MainReportUpdate',
							title: 'MainReportUpdate',
							type: 'item',
							url: '/admin-services/mainReportUpdate'
						},
					]
				},
				{
					id: 'cms',
					title: 'CMS',
					type: 'collapse',
					icon: 'feather icon-settings',
					children: [
						{
							id: 'CMSDashBoard',
							title: 'Dashboard CMS',
							type: 'item',
							url: '/cms/dashboard'
						},
						{
							id: 'Shortfall',
							title: 'Shortfall',
							type: 'item',
							url: '/cms/shortfall'
						},
						// {
						// 	id: 'ReleasesFundFlow',
						// 	title: 'Releases & Fund Flow',
						// 	type: 'item',
						// 	url: '/entry/releases-fundflow'
						// },
						// {
						// 	id: 'UCSubmission',
						// 	title: 'UC Submission',
						// 	type: 'item',
						// 	url: '/entry/uc-submission'
						// },
					]
				},
				{
					id: 'AdminServices',
					title: 'Admin Services',
					type: 'collapse',
					icon: 'feather icon-user',
					children: [
						{
							id: 'BeneficiaryCountCompare',
							title: 'Tracking Beneficiary ',
							type: 'item',
							url: '/admin-services/BeneficiaryCountCompare'
						},
						{
							id: 'Users',
							title: 'Users',
							type: 'item',
							url: '/admin-services/users'
						},{
							id: 'Jurisdiction',
							title: 'Jurisdiction Wise Permission',
							type: 'item',
							url: '/admin-services/jurisdictionPermission'
						},{
							id: 'UsersLog',
							title: 'Users Activity',
							type: 'item',
							url: '/admin-services/usersActivity'
						},

						{
							id: 'UserEntryReport',
							title: 'User entry report',
							type: 'item',
							url: '/admin-services/user-entry'
						},
						{
							id: 'TableRecordCount',
							title: 'Table Record Count',
							type: 'item',
							url: '/admin-services/table-record-count'
						},
						{
							id: 'DataShortfall',
							title: 'Data Shortfall',
							type: 'item',
							url: '/admin-services/data-shortfall'
						},
						{
							id: 'DataForUpdate',
							title: 'Data For Update',
							type: 'item',
							url: '/admin-services/data-for-update'
						},
						
{
							id: 'ProjectBrifShortfallDetails',
							title: 'Project Brif Shortfall Details',
							type: 'item',
							url: '/admin-services/project-shortfall-details'
						},
						
						{
							id: 'logDetails',
							title: 'Log Detail',
							type: 'item',
							url: '/admin-services/logDetails'
						},
						{
							id: 'apiLogs',
							title: 'API Logs',
							type: 'item',
							url: '/admin-services/api-log-details'
						},{
							id: 'Projectfeedback',
							title: 'Project feedback',
							type: 'item',
							url: '/admin-services/project-feedback'
						},
						{
							id: 'thememaster',
							title: 'Theme Details',
							type: 'item',
							url: '/admin-services/thememaster'
						},
					

					]
				},
			]
		},
	]
}

export default menu
