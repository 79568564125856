import React from 'react'
import styled from 'styled-components'
import {Form} from 'react-bootstrap';
import map from 'lodash/map'
import get from 'lodash/get'
import xor from 'lodash/xor'

const Type = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap:wrap;
    margin:0;
    width: 100%;
    background: ${props=> props.background ? props.background : 'rgba(0, 0, 0, 0.10)'};
    padding: 2px;
    margin-bottom: 10px;
  & > div{
     padding-right: 20px;
    }
  & span{
    font-weight:600;
    padding-right: 20px
  }
  & label{
    padding-top: 2px;
  }
`


const Wrapper = styled.section`
  background: ${props=> props.background ? props.background : 'aquamarine'};
  padding: 0px;
  margin-bottom: 15px;
`

class HideColumn extends React.PureComponent{


  render(){
  const {
    columnList=[],
    hideColumn=[],
    getHideColumn,
    name='Show Column'
  }=this.props
  // console.log('hideColumn---->>',hideColumn)
    return(
      <Wrapper background={this.props.background}>
         <Type background={this.props.background}>
            <span>{name==='NotDefault' ? '' : name+':' }</span> 
            {map(columnList, obj=>{
              return(
                <Form.Check
                  key={get(obj,'key')}
                  type="checkbox"
                  id={obj.key}
                  label={get(obj,'title')}
                  checked={hideColumn.indexOf(obj.key) !== -1 }
                  onChange={(e)=> {
                    getHideColumn(xor(hideColumn, [obj.key]),obj.key)
                  }}
                />
              )
            })}
          </Type>
      </Wrapper>
    )
  }
}

export{HideColumn}