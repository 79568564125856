import React from 'react'
import { Row, Col } from 'react-bootstrap';
import { Breadcrumb, Loader } from '../components'
import { Button } from "react-bootstrap"
import { BASE_URL } from '../store/constant'
import Axios from 'axios';
import _ from 'lodash'
import ReactToPrint from "react-to-print";
import styled from 'styled-components'



const Title = styled.h3`
  text-align: center;
  font-size: ${props => props.fontSize ? props.fontSize : '18px'};
  font-weight: 600;
  background: #fff;
  margin: 0px;
  padding-top: 10px;
`
const Wrapper = styled.section`

  & tr td span{
    font-weight:600;
    display:block;
    text-align: left;
    transform: rotate(270deg);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & td, th {
    padding: 4px 2px;
  }
  & th span{
      font-size: 10px;
    }
`


class FAforUpdate extends React.Component {
  state = {
    isLoading: true,
    statesList:[],
    stateWise:[],
    dashboard:[],
    dataFilterType:'',

  }

  componentDidMount = async () => {

    const {data} = await Axios.get(`${BASE_URL}/states`)
    const { data: dashboard } = await Axios.get(`${BASE_URL}/dashboard`)
    const {data:stateWise} = await Axios.get(`${BASE_URL}/report/physical-and-financial-progress`)
    

    console.log("dashboard data", {dashboard})
    
  
   this.setState({stateWise,dashboard, statesList:_.map(data, o=> ({label:o.statename, value:o.statecode,...o,StateCode:o.statecode})), isLoadingState:false,isLoading:false})
  }


  render() {
    const {
        statesList,
        isLoading,
        stateWise,
        dashboard
      } = this.state

let totalHousesSanctioned=0 
let totalCentralAssistance =0 
let totalStateGrant=0 
let totalUlbShare=0 
let totalBeneficiaryShare=0 
let totalOtherShare=0 
let totalCaReleased=0  
let totalPercentageCAreleased=0 
let totalCorrspondingStateRelease=0 
let totalExpenditureStateGrantDBT=0 
let totalPercentageStateDBT=0 
let totalPercentStateDBTwrtCorStateRel=0 
let totalUcReceived=0 
let totalUnspentCAbalance=0  
let totalUcPending=0  
let totalExpenditureCaDBT=0          
  

    return (
      <React.Fragment>

        <Breadcrumb
          anyComponent={
            <>
              <ReactToPrint
                pageStyle="margin:15"
                bodyClass="printBody"
                trigger={() => <Button
                  size={"sm"}
                  variant={'success'}
                  onClick={() => window.print()}
                >
                  <i className="feather icon-printer"></i>Print
                </Button>}
                content={() => this.printTableRef}
              />

            </>
          }
        />


        <Wrapper ref={el => (this.printTableRef = el)}>
          <Col md={12}>
            <Row>
              
              <Col md={12} sm={12} xs={12} style={{ background: '#fff', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }} className="pr-0 pl-0">
                <Title>Details of Central Share and State Share Released and Utilized under PMAY-U<br/> (excluding RAY & CLSS) </Title>
              </Col>
              <Col>
                 <Title style={{ textAlign: 'right'}}> <span className="text-c-red">(₹ in Cr.)</span></Title>
              </Col>
              
              </Row>
          </Col>



          <table className='customTable' id='DataForUpdate'>
            <thead className='label label-warning text-white text-center' style={{ backgroundColor: '#002b49' }}>
              <tr style={{ textAlign: 'center', backgroundColor: '#002b49', color: '#FFFFFF' }}>
                <th rowSpan='2'>Sr.<br/>No.</th>
                <th rowSpan='2'>State/UT</th>
                <th rowSpan='2'>Houses<br/>Sanctioned</th>
                <th rowSpan='2'>Central<br/>Assistance</th>
                <th rowSpan='2'>State<br/>Grant</th>
                <th rowSpan='2'>ULB/<br/>IA Share</th>
                <th rowSpan='2'>Beneficiary<br/>Contribution</th>
                <th rowSpan='2'>Other<br/>Share</th>
                <th rowSpan='2'>Central<br/>Assistance<br/>Released</th>
                <th rowSpan='2'>%of ACA<br/>Released<br/>w.r.t CA</th>
                <th rowSpan='2'>Corresponding<br/>State Share<br/>for Release</th>
                <th colSpan='2'>Released by State<br/>(as per DBT)</th>
                <th rowSpan='2'>%age w.r.t<br/>corresponding<br/>State Share<br/> for Release</th>
                <th rowSpan='2'>Utilization<br/>Certificate<br/>Received<br/>against CA</th>
                <th rowSpan='2'>Unspent<br/>balance (CA)</th>
                <th rowSpan='2'>UC<br/>Pending</th>
                <th rowSpan='2'>Expenditure<br/>incurred of <br/>CA Released<br/>(as per DBT)</th>
                
              </tr>
              <tr>
                  <th>Released</th>
                  <th>%age w.r.t <br/>State Share</th>
              </tr>
            </thead>
            <tbody>

              {
                _.map(statesList, (obj, idx)=>{

                  //console.log('dashboard----->>', dashboard)
                  //console.log('statesList----->>', statesList)
                  // console.log('obj.StateCode----->>', obj.StateCode)

                  const fltStateWiseDashboard = _.find(dashboard.data, (it, o) => Number(it.stateCode) === Number(obj.StateCode))

                  //console.log("fltStateWiseDashboard--->",{fltStateWiseDashboard})

                  const filteredStateWiseData = _.filter(stateWise, (it, o) => Number(it.StateCode) === Number(obj.statecode))
                  
                  const fltSWdataIssrAhpBlcComp = filteredStateWiseData && filteredStateWiseData.filter(a => a.Component === 'ISSR' || a.Component === 'AHP'|| a.Component === 'BLC');
                  const housesSanctioned = (_.sumBy(fltSWdataIssrAhpBlcComp,obj=> Number(_.get(obj,'HousesSanctionedNew')||0)) ? _.sumBy(fltSWdataIssrAhpBlcComp,obj=> Number(_.get(obj,'HousesSanctionedNew')||0)) : 0)+ (_.sumBy(fltSWdataIssrAhpBlcComp,obj=> Number(_.get(obj,'HousesSanctionedEnahncement')||0)) ? _.sumBy(fltSWdataIssrAhpBlcComp,obj=> Number(_.get(obj,'HousesSanctionedEnahncement')||0)) : 0)
                  const centralAssistance= (_.sumBy(fltSWdataIssrAhpBlcComp,obj=> Number(_.get(obj,'CentralAssistApproved')||0)) ? _.sumBy(fltSWdataIssrAhpBlcComp,obj=> Number(_.get(obj,'CentralAssistApproved')||0)) : 0)
                  const stateGrant= (_.sumBy(fltSWdataIssrAhpBlcComp,obj=> Number(_.get(obj,'StateGrantApproved')||0)) ? _.sumBy(fltSWdataIssrAhpBlcComp,obj=> Number(_.get(obj,'StateGrantApproved')||0)) : 0)
                  const ulbShare= (_.sumBy(fltSWdataIssrAhpBlcComp,obj=> Number(_.get(obj,'ULBShareApproved')||0)) ? _.sumBy(fltSWdataIssrAhpBlcComp,obj=> Number(_.get(obj,'ULBShareApproved')||0)) : 0)
                  const beneficiaryShare= (_.sumBy(fltSWdataIssrAhpBlcComp,obj=> Number(_.get(obj,'BneneficiaryShareApproved')||0)) ? _.sumBy(fltSWdataIssrAhpBlcComp,obj=> Number(_.get(obj,'BneneficiaryShareApproved')||0)) : 0)
                  const otherShare= (_.sumBy(fltSWdataIssrAhpBlcComp,obj=> Number(_.get(obj,'OtherShare')||0)) ? _.sumBy(fltSWdataIssrAhpBlcComp,obj=> Number(_.get(obj,'OtherShare')||0)) : 0)
                  const caReleased= (_.sumBy(fltSWdataIssrAhpBlcComp,obj=> Number(_.get(obj,'installment1')||0)) ? _.sumBy(fltSWdataIssrAhpBlcComp,obj=> Number(_.get(obj,'installment1')||0)) : 0)+(_.sumBy(fltSWdataIssrAhpBlcComp,obj=> Number(_.get(obj,'installment2')||0)) ? _.sumBy(fltSWdataIssrAhpBlcComp,obj=> Number(_.get(obj,'installment2')||0)) : 0)+(_.sumBy(fltSWdataIssrAhpBlcComp,obj=> Number(_.get(obj,'installment3')||0)) ? _.sumBy(fltSWdataIssrAhpBlcComp,obj=> Number(_.get(obj,'installment3')||0)) : 0)
                  const percentageCAreleased= (caReleased/centralAssistance)*100
                  const corrspondingStateRelease = (caReleased/centralAssistance)*stateGrant
                  const expenditureStateGrantDBT= (Number( _.get(fltStateWiseDashboard,'AHPISSRstateExpenditure'))||0 ? Number( _.get(fltStateWiseDashboard,'AHPISSRstateExpenditure')) : 0) + (Number( _.get(fltStateWiseDashboard,'BLCstateExpenditure'))||0 ? Number( _.get(fltStateWiseDashboard,'BLCstateExpenditure')) : 0)
                  const percentageStateDBT = (expenditureStateGrantDBT/stateGrant)*100
                  const percentStateDBTwrtCorStateRel= (expenditureStateGrantDBT/ corrspondingStateRelease)*100

                  const ucReceived= (_.sumBy(fltSWdataIssrAhpBlcComp,obj=> Number(_.get(obj,'uc1')||0)) ? _.sumBy(fltSWdataIssrAhpBlcComp,obj=> Number(_.get(obj,'uc1')||0)) : 0)+(_.sumBy(fltSWdataIssrAhpBlcComp,obj=> Number(_.get(obj,'uc2')||0)) ? _.sumBy(fltSWdataIssrAhpBlcComp,obj=> Number(_.get(obj,'uc2')||0)) : 0)+(_.sumBy(fltSWdataIssrAhpBlcComp,obj=> Number(_.get(obj,'uc3')||0)) ? _.sumBy(fltSWdataIssrAhpBlcComp,obj=> Number(_.get(obj,'uc3')||0)) : 0)
                  const unspentCAbalance= caReleased-ucReceived
                  const ucPending= (Number( _.get(fltStateWiseDashboard,'AHPISSRBLCucPending'))||0 ? Number( _.get(fltStateWiseDashboard,'AHPISSRBLCucPending')) : 0) 
                  const expenditureCaDBT= (Number( _.get(fltStateWiseDashboard,'BLCexpenditure'))||0 ? Number( _.get(fltStateWiseDashboard,'BLCexpenditure')) : 0) + (Number( _.get(fltStateWiseDashboard,'AHPISSRexpenditure'))||0 ? Number( _.get(fltStateWiseDashboard,'AHPISSRexpenditure')) : 0)
                  
                  // grand total state wise
                  totalHousesSanctioned += housesSanctioned
                  totalCentralAssistance += centralAssistance
                  totalStateGrant += stateGrant
                  totalUlbShare += ulbShare
                  totalBeneficiaryShare += beneficiaryShare
                  totalOtherShare += otherShare
                  totalCaReleased += caReleased
                  totalPercentageCAreleased= (totalCaReleased/totalCentralAssistance)*100
                  totalCorrspondingStateRelease = (totalCaReleased/totalCentralAssistance)*totalStateGrant
                  totalExpenditureStateGrantDBT += expenditureStateGrantDBT
                  totalPercentageStateDBT = (totalExpenditureStateGrantDBT/totalStateGrant)*100
                  totalPercentStateDBTwrtCorStateRel= (totalExpenditureStateGrantDBT/ totalCorrspondingStateRelease)*100
                  totalUcReceived += ucReceived
                  totalUnspentCAbalance= totalCaReleased-totalUcReceived
                  totalUcPending += ucPending
                  totalExpenditureCaDBT += expenditureCaDBT






                

                  const StateName= _.get(_.find(statesList, s=> s.statecode === _.get(obj,'StateCode')),'statename')

                  return(
                    
              <tr className="text-right" key={idx}>
                <td style={{ textAlign: 'center' }} >{idx + 1}</td>
                <td className="text-left">{StateName}</td>
                <td>{housesSanctioned ? Number(housesSanctioned.toFixed(0)).toLocaleString('en-in'):'-'}</td>
                <td>{centralAssistance ? Number(centralAssistance.toFixed(2)).toLocaleString('en-in'):'-'}</td>
                <td>{stateGrant ? Number(stateGrant.toFixed(2)).toLocaleString('en-in'):'-'}</td>
                <td>{ulbShare ? Number(ulbShare.toFixed(2)).toLocaleString('en-in'):'-'}</td>
                <td>{beneficiaryShare ? Number(beneficiaryShare.toFixed(2)).toLocaleString('en-in'):'-'}</td>
                <td>{otherShare ? Number(otherShare.toFixed(2)).toLocaleString('en-in'):'-'}</td>
                <td>{caReleased ? Number(caReleased.toFixed(2)).toLocaleString('en-in'):'-'}</td>
                <td>{percentageCAreleased ? Number(percentageCAreleased.toFixed(0)).toLocaleString('en-in')+'%':'-'}</td>
                <td>{corrspondingStateRelease ? Number(corrspondingStateRelease.toFixed(2)).toLocaleString('en-in'):'-'}</td>
                <td>{expenditureStateGrantDBT ? Number(expenditureStateGrantDBT.toFixed(2)).toLocaleString('en-in'):'-'}</td>
                <td>{percentageStateDBT ? Number(percentageStateDBT.toFixed(0)).toLocaleString('en-in')+'%':'-'}</td>
                <td>{percentStateDBTwrtCorStateRel ? Number(percentStateDBTwrtCorStateRel.toFixed(0)).toLocaleString('en-in')+'%':'-'}</td>
                <td>{ucReceived ? Number(ucReceived.toFixed(2)).toLocaleString('en-in'):'-'}</td>
                <td>{unspentCAbalance ? Number(unspentCAbalance.toFixed(2)).toLocaleString('en-in'):'-'}</td>
                <td>{ucPending ? Number(ucPending.toFixed(2)).toLocaleString('en-in'):'-'}</td>
                <td>{expenditureCaDBT ? Number(expenditureCaDBT.toFixed(2)).toLocaleString('en-in'):'-'}</td>
                
              </tr>

                  )
                })
              }
              <tr className="text-right" style={{backgroundColor: 'rgb(0, 43, 73)', color:'#fff', fontWeight:600}}>
                <td colSpan='2' style={{textAlign:'center'}}> Total:- </td>
                <td>{totalHousesSanctioned ? Number(totalHousesSanctioned.toFixed(0)).toLocaleString('en-in'):'-'}</td>
                <td>{totalCentralAssistance  ? Number(totalCentralAssistance.toFixed(2)).toLocaleString('en-in'):'-'}</td>
                <td>{totalStateGrant ? Number(totalStateGrant.toFixed(2)).toLocaleString('en-in'):'-'}</td>
                <td>{totalUlbShare ? Number(totalUlbShare.toFixed(2)).toLocaleString('en-in'):'-'}</td>
                <td>{totalBeneficiaryShare ? Number(totalBeneficiaryShare.toFixed(2)).toLocaleString('en-in'):'-'}</td>
                <td>{totalOtherShare ? Number(totalOtherShare.toFixed(2)).toLocaleString('en-in'):'-'}</td>
                <td>{totalCaReleased ? Number(totalCaReleased.toFixed(2)).toLocaleString('en-in'):'-'}</td>
                <td>{totalPercentageCAreleased ? Number(totalPercentageCAreleased.toFixed(0)).toLocaleString('en-in')+'%':'-'}</td>
                <td>{totalCorrspondingStateRelease ? Number(totalCorrspondingStateRelease.toFixed(2)).toLocaleString('en-in'):'-'}</td>
                <td>{totalExpenditureStateGrantDBT ? Number(totalExpenditureStateGrantDBT.toFixed(2)).toLocaleString('en-in'):'-'}</td>
                <td>{totalPercentageStateDBT ? Number(totalPercentageStateDBT.toFixed(0)).toLocaleString('en-in')+'%':'-'}</td>
                <td>{totalPercentStateDBTwrtCorStateRel ? Number(totalPercentStateDBTwrtCorStateRel.toFixed(0)).toLocaleString('en-in')+'%':'-'}</td>
                <td>{totalUcReceived ? Number(totalUcReceived.toFixed(2)).toLocaleString('en-in'):'-'}</td>
                <td>{totalUnspentCAbalance ? Number(totalUnspentCAbalance.toFixed(2)).toLocaleString('en-in'):'-'}</td>
                <td>{totalUcPending ? Number(totalUcPending.toFixed(2)).toLocaleString('en-in'):'-'}</td>
                <td>{totalExpenditureCaDBT ? Number(totalExpenditureCaDBT.toFixed(2)).toLocaleString('en-in'):'-'}</td>
              </tr>
            </tbody>
          </table>


          {isLoading && <Loader
            title='Report Generating...'
            style={{ position: 'absolute', bottom: 0, top: 0, height: '100%', backgroundColor: 'rgba(255, 255, 255, 0.93)' }}
          />}
        </Wrapper>


      </React.Fragment>
    )
  }
}

export default FAforUpdate;