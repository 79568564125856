import React, {useState,useEffect} from "react";
import {Input} from './Input'
import { Button,Card } from "react-bootstrap";
import styled from "styled-components";
import _ from 'lodash'
import {excelFileReader} from '../utils'
import moment from "moment";
import { toast } from "react-toastify";
import Axios from 'axios'
import { BASE_URL } from '../store/constant'
// import * as XLSX from 'xlsx';
import {Loader} from '../components'

const Wrapper = styled.div`
  position: relative;
`;
const TextInput = styled.input`
  padding:0px 2px;
  width:70px;
`

const MprAHPISSRProgressUpdate = (props) => {
  const {statesList,projectList}=props

  const [projectList1, setprojectList]=useState([])
  const [addProjectCode, setaddProjectCode]=useState([])
  const [MPRClubData, setMPRClubData]=useState([])
  const [isExcelReading, setisExcelReading]=useState(false)
  const [isMPRSubmitting, setisMPRSubmitting]=useState(false)
  const [isLoading, setisLoading]=useState(false)
  const [isFechingData, setisFechingData]=useState(true)
  

  useEffect(()=>{
    // setisExcelReading(false)
    if(_.isEmpty(addProjectCode) && !_.isEmpty(projectList)){
        // console.log('---------->Props<-----------')
        if(isFechingData){
          setprojectList(projectList)
           fetchMPRData()
           setisFechingData(false)
        }
    }
  })
  
  // Handle Excel//  
 const handleExcel = (file) => {
  setisExcelReading(true)
  excelFileReader(file,'MPRFile','AHP/ISSR').then(data=>{
    //  console.log('data-------->',data)
    //  Check Uniq
    const isUniq = _.uniq(data, "Annexure_ID")
    if(_.get(isUniq,'length') !== _.get(data,'length')){
      toast.warn('Duplicate entry found.')
      setisExcelReading(false)
      return
    }

    const compo = _.find(data,f=> f.missionComp ==='AHP' || f.missionComp ==='ISSR')

    if(!compo){
      toast.error('Excel format not valid for MPR AHP/ISSR Update')
      setisExcelReading(false)
      return
    }

  // Re-formate Data 

  const addProjectCode = _.map(data, m=>{
    // console.log('---------->>',m)
    // const totalWorkOrders = Number(_.get(m,'issued_to_Contractor')||0)+Number(_.get(m,'Foundation_level')||0)+Number(_.get(m,'Plinth_level')||0)+Number(_.get(m,'Lintel_level')||0)+Number(_.get(m,'Roof_level')||0)+Number(_.get(m,'Houses_completed')||0)

    // console.log(Object.keys(m))
    const objKeys = Object.keys(m)
    const UnderProcessKey =  _.find(objKeys, mt=>mt.replaceAll(' ','').toLocaleLowerCase().match('floated/underprocess'))
    const WorkorderNotIssuedKey =  _.find(objKeys, mt=>mt.replaceAll(' ','').toLocaleLowerCase().match('butworkordernotissued'))
    // console.log({UnderProcessKey,WorkorderNotIssuedKey})
    const obj = _.find(projectList,p=>p.AnnexureID === m.Annexure_ID)
    const ProjectCode = _.get(obj,'ProjectCode')
    const Annexure_ID = _.get(m,'Annexure_ID')
    const floated_Under_process=_.get(m,UnderProcessKey,0)
    const awarded_but_workorder_not_issued=_.get(m,WorkorderNotIssuedKey,0)
    // console.log({floated_Under_process,awarded_but_workorder_not_issued})

    // const Component = _.get(obj,'Component') || _.get(m,'missionComp')
    // const HousesSanctionedEnahncement = _.get(obj,'HousesSanctionedEnahncement')
    // const HousesSanctionedNew = _.get(obj,'HousesSanctionedNew')
    // const StateCode = _.get(obj,'ProjectCode') ? _.get(obj,'StateCode') : _.get(_.find(statesList,p=>p.statename === m.State_Name),'statecode')
    // const Completed = _.get(m,'Houses_completed')
    // const Ground = totalWorkOrders //_.get(m,'Houses_not_started')
    // const Lintel = _.get(m,'Lintel_level')
    // const Occupied = _.get(m,'Houses_completed')
    // const Plinth = Number(_.get(m,'Plinth_level')||0)+Number(_.get(m,'Foundation_level')||0)
    // const Roof = _.get(m,'Roof_level')
    // const type = _.get(m,'type')
    // const SuperStructure = 0
    // const TenderAwarded = totalWorkOrders
    // const TotalWorkOrders = totalWorkOrders
    // const UnderProcess =0
    // // console.log('ProjectCode---->',ProjectCode)
    return({
      ProjectCode,
      Annexure_ID,
      floated_Under_process,
      awarded_but_workorder_not_issued,
      ProjectTitle:m.Project_Name,
      ...m
    })
  })

  
    // console.log('addProjectCode----->',addProjectCode)

    Axios.post(`${BASE_URL}/geotag/mpr`, addProjectCode).then(()=> {
      fetchMPRData()
      toast.success("MPR Table Updated..");
    }).catch(err=>{
      toast.error('Network error')
      setisExcelReading(false)
    })
    

    // End..............
  }).catch(err => {
    setisExcelReading(false)
    toast.error(_.get(err,'msg')||'Internal server error.')
  });
  }

  const fetchMPRData =async()=>{
    // console.log('react-------------->>>>>')
  setisLoading(true)

    try{
      const {data} = await Axios.get(`${BASE_URL}/geotag/mpr/ahpissr`)

      // Club MPR Data......
      const projects=_.filter(projectList, v => v.Component === 'AHP' || v.Component === 'ISSR')
      const dataUnion = _.union(projects, data)
      const clubAllData = _.groupBy(dataUnion, 'ProjectCode')
      const MPRClubData = _.filter(clubAllData, (d) => d.length>1 && d.length<4);
     
      setMPRClubData(MPRClubData)
      setaddProjectCode(_.isEmpty(data) ?  [{}] : data)
      setprojectList(projects)
      setisExcelReading(false)
      setisLoading(false)

    }catch(err){
      toast.error('Internal server error.')
      setisExcelReading(false)
      setisLoading(false)
    }
  }

  const reFetchProgress = async()=>{
    const { data: projectListNew } = await Axios.get(`${BASE_URL}/physical/all-projects-with-annexure`)
    const projectList1 = _.orderBy(_.filter(projectListNew, v => v.Component === 'AHP' || v.Component === 'ISSR'), ['actualCompleted', 'actualTotalWorkOrders'], ['desc', 'asc']);
    const dataUnion = _.union(projectList1, addProjectCode)
    const clubAllData = _.groupBy(dataUnion, 'ProjectCode')
    const MPRClubData = _.orderBy(clubAllData, (d) => d.length, ['desc']);

    setprojectList(projectList1)
    setMPRClubData(MPRClubData)
    setisLoading(false)
    setisMPRSubmitting(false)
    // this.setState({ isGEOTagSubmitting: false })
  }
  

  const manageshortfall=(sGrnd, sCompt, stateCode)=> {
    const stateData = _.filter(projectList1, flt => flt.StateCode === stateCode && ( flt.Component === 'AHP' || flt.Component === 'ISSR'))

    let grnd = Number(sGrnd || 0)
    let compl = Number(sCompt || 0)

    // console.log({projectList1})

    const excludShortfallData = _.map(stateData, obj => {

      const csf = _.get(obj, 'completed') - _.get(obj, 'actualCompleted')


      if (grnd > 0 || compl > 0) {

        const completed = csf && compl > 0 ? (csf > compl ? _.get(obj, 'completed') - compl : _.get(obj, 'completed') - csf) : _.get(obj, 'completed')
        compl -= csf && compl > 0 ? (csf > compl ? compl : csf) : 0

     // const groundDiff = completed - _.get(obj, 'totalWorkOrders')

      const gSortfall = _.get(obj, 'totalWorkOrders') - _.get(obj, 'actualTotalWorkOrders')
      const gsf = completed <=  (_.get(obj, 'totalWorkOrders') - gSortfall) ? gSortfall : _.get(obj, 'totalWorkOrders') - completed


        const totalWorkOrders = gsf && grnd > 0 ? (gsf > grnd ? _.get(obj, 'totalWorkOrders') - grnd : _.get(obj, 'totalWorkOrders') - gsf) : _.get(obj, 'totalWorkOrders')
        grnd -= gsf && grnd > 0 ? (gsf > grnd ? grnd : gsf) : 0

       
        return ({
          ...obj,
          totalWorkOrders,
          completed,
          occupied:completed,
          effectedProjects:(_.get(obj, 'actualTotalWorkOrders') !== _.get(obj, 'totalWorkOrders') ||  _.get(obj, 'actualCompleted') !== _.get(obj, 'completed')) ? true : false
        })
      } else {
        return obj
      }
    })


    const withOutThisState = _.filter(projectList1, flt => flt.StateCode !== stateCode)
    
    const projectListNew = _.filter(_.union(withOutThisState, excludShortfallData), v => v.Component === 'AHP' || v.Component === 'ISSR')

    const dataUnion = _.union(projectListNew, addProjectCode)
    const clubAllData = _.groupBy(dataUnion, 'ProjectCode')
    const MPRClubData = _.orderBy(clubAllData, (d) => d.length, ['desc']);

    setMPRClubData(MPRClubData)
    setprojectList(projectListNew)

    // console.log('geoTagClubData----',this.state.geoTagClubData, geoTagClubData)

    // this.setState({ geoTagClubData})

  }

    // Final update physical progress with file all files data 
    const finalUpdateAllFilesData = async (mpr, type,stateCode) => {

      const stateData = _.filter(projectList1, flt => flt.StateCode === stateCode && (flt.Component==='AHP' || flt.Component==='ISSR'))
      const stateMPRData = _.filter(addProjectCode, flt => flt.StateCode === stateCode)
   
      // console.log('============>>',{stateData,addProjectCode})
 
     const makeDataForUpdate = _.map(stateData, obj => {
 
       const mprObj =  _.find(stateMPRData, o => _.get(o, 'ProjectCode') === _.get(obj, 'ProjectCode')) //_.find(obj, o => _.get(o, 'type') === 'GeoTag')

       const currentProject = obj //_.find(obj, o => !_.get(o, 'type'))
 
 
       const currentSanctioned = (Number(_.get(currentProject, 'HousesSanctionedNew') || 0) + Number(_.get(currentProject, 'HousesSanctionedEnahncement') || 0)) 
       const mismatchSanctioned = (currentSanctioned < Number(_.get(currentProject, 'totalWorkOrders') || 0)) || (currentSanctioned < Number(_.get(currentProject, 'actualTotalWorkOrders') || 0)) || (currentSanctioned < Number(_.get(currentProject, 'actualCompleted') || 0)) || (currentSanctioned < Number(_.get(currentProject, 'completed') || 0)) 
 
       
       const diffGroundInc = Number(_.get(mprObj, 'Ground') || 0) > currentSanctioned ? currentSanctioned : Number(_.get(mprObj, 'Ground') || 0)
       const diffCompletedInc = Number(_.get(mprObj, 'Completed') || 0) > currentSanctioned ? currentSanctioned : Number(_.get(mprObj, 'Completed') || 0)
       const diffOccupiedInc = Number(_.get(mprObj, 'Occupied') || 0) > currentSanctioned ? currentSanctioned : Number(_.get(mprObj, 'Occupied') || 0)

       
       const diffGround = (diffGroundInc - Number(_.get(currentProject, 'totalWorkOrders') || 0)) > 0 ? diffGroundInc - Number(_.get(currentProject, 'totalWorkOrders') || 0) : 0
       const diffActualGround = (diffGroundInc - Number(_.get(currentProject, 'actualTotalWorkOrders') || 0)) > 0 ? diffGroundInc - Number(_.get(currentProject, 'actualTotalWorkOrders') || 0) : 0
       const diffCompleted = (diffCompletedInc - Number(_.get(currentProject, 'completed') || 0)) > 0 ? diffCompletedInc - Number(_.get(currentProject, 'completed') || 0) : 0
       const diffActualCompleted = (diffCompletedInc - Number(_.get(currentProject, 'actualCompleted') || 0)) > 0 ? diffCompletedInc - Number(_.get(currentProject, 'actualCompleted') || 0) : 0
       const diffOccupied = (diffOccupiedInc - Number(_.get(currentProject, 'occupied') || 0)) > 0 ? diffOccupiedInc - Number(_.get(currentProject, 'occupied') || 0) : 0
 
 
       const ProjectCode = _.get(currentProject, 'ProjectCode') || _.get(mprObj, 'ProjectCode')
     const Scheme = _.get(currentProject, 'Scheme') || _.get(mprObj, 'Scheme')
     const Component = _.get(currentProject, 'Component') || _.get(mprObj, 'Component')
     const StateCode = _.get(currentProject, 'StateCode') || _.get(mprObj, 'StateCode')
     const DistrictCode = _.get(currentProject, 'DistrictCode') || _.get(mprObj, 'DistrictCode')
     const CityCode = _.get(currentProject, 'CityCode') || _.get(mprObj, 'CityCode')
     const TotalWorkOrders = Number(_.get(currentProject, 'totalWorkOrders') > currentSanctioned ? currentSanctioned : _.get(currentProject, 'totalWorkOrders') || 0) + diffGround
     const TenderAwarded = TotalWorkOrders //currentSanctioned && _.get(mprObj, 'TenderAwarded') > currentSanctioned ? currentSanctioned : _.get(mprObj, 'TenderAwarded')
     const ActualTotalWorkOrders = Number(_.get(currentProject, 'actualTotalWorkOrders') > currentSanctioned ? currentSanctioned : _.get(currentProject, 'actualTotalWorkOrders') || 0)  + diffActualGround//currentSanctioned && _.get(mprObj, 'TotalWorkOrders') > currentSanctioned ? currentSanctioned : _.get(mprObj, 'TotalWorkOrders')
     

     const UnderProcess = currentSanctioned && _.get(mprObj, 'UnderProcess') > currentSanctioned ? currentSanctioned : (_.get(mprObj, 'UnderProcess') || _.get(obj, 'underProcess') || 0)
     const Ground = 0

     const SuperStructure = currentSanctioned && _.get(mprObj, 'SuperStructure') > currentSanctioned ? currentSanctioned : (_.get(mprObj, 'SuperStructure') ||  _.get(obj, 'superStructure')|| 0)
     const Completed = Number(_.get(currentProject, 'completed') > currentSanctioned ? currentSanctioned : _.get(currentProject, 'completed') || 0) + diffCompleted
     const ActualCompleted = currentSanctioned && _.get(mprObj, 'Completed') > currentSanctioned ? currentSanctioned : (_.get(mprObj, 'Completed') < _.get(currentProject, 'actualCompleted') || !_.get(mprObj, 'Completed')) ? (_.get(currentProject, 'actualCompleted') > currentSanctioned ? currentSanctioned : _.get(currentProject, 'actualCompleted')) : (_.get(mprObj, 'Completed') || _.get(currentProject, 'actualCompleted') || 0)
     const Occupied = Number(_.get(currentProject, 'occupied') > currentSanctioned ? currentSanctioned : _.get(currentProject, 'occupied') || 0) + diffOccupied


     const isEqualGrounded = TotalWorkOrders === Completed
     const isEqualActualGrounded = ActualTotalWorkOrders === ActualCompleted

     const ActualPlinth = isEqualActualGrounded ? 0 :(currentSanctioned && _.get(mprObj, 'Plinth') > currentSanctioned ? currentSanctioned : (_.get(mprObj, 'Plinth') || _.get(obj, 'actualPlinth') || 0))
     const ActualLintel = isEqualActualGrounded ? 0 :(currentSanctioned && (_.get(mprObj, 'Lintel') || 0) > currentSanctioned ? currentSanctioned : (_.get(mprObj, 'Lintel') || _.get(obj, 'actualLintel') || 0))
     const ActualRoof = isEqualActualGrounded ? 0 :(currentSanctioned && (_.get(mprObj, 'Roof') || 0) > currentSanctioned ? currentSanctioned : (_.get(mprObj, 'Roof') || _.get(obj, 'actualRoof') || 0))



     const completedSortfall = Completed - ActualCompleted


     const Roof = isEqualGrounded ? 0 : (ActualRoof - completedSortfall) > 0 ? (ActualRoof - completedSortfall) : 0

     const roofDiff = (ActualRoof - completedSortfall)

     const Lintel = isEqualGrounded ? 0 : roofDiff < 0 ? (ActualLintel + roofDiff < 0  ? 0 : ActualLintel + roofDiff) : ActualLintel

     // const lintelDiff = (ActualLintel + roofDiff) < 0 ? ActualLintel + roofDiff : 0
     const lintelDiff = (ActualLintel + ActualRoof) - completedSortfall

     const Plinth = isEqualGrounded ? 0 : lintelDiff < 0  ? ((ActualPlinth  + lintelDiff) < 0 ? 0 :  (ActualPlinth  + lintelDiff)) : ActualPlinth
 
 
     return ({
       ProjectCode,
       Scheme,
       Component,
       StateCode,
      DistrictCode,
      CityCode,
       TenderAwarded: !currentSanctioned ? 0 : TenderAwarded,
       TotalWorkOrders: !currentSanctioned ? 0 : TotalWorkOrders,
       ActualTotalWorkOrders: !currentSanctioned ? 0 : ActualTotalWorkOrders,
       UnderProcess: !currentSanctioned ? 0 : UnderProcess,
       Ground: !currentSanctioned ? 0 : Ground,
       Plinth: !currentSanctioned ? 0 : Plinth,
       ActualPlinth: !currentSanctioned ? 0 : ActualPlinth,
       Lintel: !currentSanctioned ? 0 : Lintel,
       ActualLintel: !currentSanctioned ? 0 : ActualLintel,
       Roof: !currentSanctioned ? 0 : Roof,
       ActualRoof: !currentSanctioned ? 0 : ActualRoof,
       SuperStructure: !currentSanctioned ? 0 : SuperStructure,
       Completed: !currentSanctioned ? 0 : Completed,
       ActualCompleted: !currentSanctioned ? 0 : ActualCompleted,
       Occupied: !currentSanctioned ? 0 : Occupied,
       source:'MPR',
       effectedProjects:_.get(currentProject, 'effectedProjects') || (mismatchSanctioned || diffGround || diffActualGround || diffCompleted || roofDiff || lintelDiff || diffActualCompleted || diffOccupied) ? true : false
     })
     })
 
     const finalGeoTagUpdationData = _.filter(makeDataForUpdate, f => f.effectedProjects)

     if (_.isEmpty(finalGeoTagUpdationData) || type === 'allState') {
       toast.error("Physical Progress with MPR already updated !")
       setisMPRSubmitting(false)
       setisLoading(false)
       return
     }
 
    //  console.log('makeDataForUpdate------->>',{finalGeoTagUpdationData,makeDataForUpdate})

     await Axios.post(`${BASE_URL}/physical/with-geotag`, finalGeoTagUpdationData).then(res => {
       // await Axios.post(`${BASE_URL}/physical/with-geotag`, makeDataForUpdate).then(res => {
         // setaddProjectCode([])
       toast.success("Data Uploaded Successfully ! ")
       // window.location.reload()
      //  reFetchProgress()
      setisLoading(false)
      setisMPRSubmitting(false)
   
     })
       .catch(err => {
         toast.error("Data Not Updated Successfully ! ")
          setisLoading(false)
         setisMPRSubmitting(false)
       });
 
   }


if(isLoading){
  return(<Loader
    title={'Please wait. Now physical progress data updating'}
    style={{ position: 'absolute', bottom: 0, top: 0, backgroundColor: 'rgba(255, 255, 255, 0.93)' }}
  /> )
}

  return (
    <Wrapper>
      <Card>
        <Card.Header as="h6">MPR File Upload (<small>Last Updated Date : {_.get(addProjectCode,'0.lastSubmitedDate') ? moment(_.get(addProjectCode,'0.lastSubmitedDate')).format('DD-MMM-YYYY'):'--/--/----'}</small>)</Card.Header>
        <Card.Body>
          <Input type="file" accept=".xls,.xlsx" id="MPRAHPandISSRFile" />

          <Button
            size="sm"
            disabled={isExcelReading}
            onClick={(w) => {
              const op = document.getElementById("MPRAHPandISSRFile");
              if (!_.get(op, "files.0")) {
                alert("MPR file not selected.");
              } else {
                handleExcel(_.get(op, "files.0"));
              }
            }}
          >
            {isExcelReading ? "Fetching..." : "Upload File"}
          </Button>
        </Card.Body>
      </Card>
      <table style={{ fontSize: 12, color: '#000' }} className='customTable tableStickyHeader-3'>
            <thead className='text-white' style={{background: '#215967'}}>
            <tr style={{fontWeight: 600, textAlign:'center' }}>
                <th rowSpan={3} style={{width:30}}>Sr.</th>
                <th rowSpan={3}>State</th>
                <th colSpan={4} style={{backgroundColor: '#009570'}}>Existing Progress</th>
                <th colSpan={4} style={{backgroundColor: 'orange'}}>Incremental Progress ( As per MPR tag )</th>
                <th rowSpan={2} colSpan={2}  style={{backgroundColor: '#f44236'}}>Shortfall</th>
                <th rowSpan={2} colSpan={3}>Manage Shortfall</th>
                <th rowSpan={3} style={{width:70}}>Action</th>
              </tr>
            <tr style={{fontWeight: 600, textAlign:'center' }}>
                <th colSpan={2} style={{backgroundColor: '#009570'}}>Grounded</th>
                <th colSpan={2} style={{backgroundColor: '#009570'}}>Completed</th>

                <th colSpan={2} style={{backgroundColor: 'orange'}}>Grounded</th>
                <th colSpan={2} style={{backgroundColor: 'orange'}}>Completed</th>

              </tr>
              <tr style={{fontWeight: 600, textAlign:'center' }}>
                <th style={{backgroundColor: '#009570'}}>Taken</th>
                <th style={{backgroundColor: '#009570'}}>Actual</th>
                <th style={{backgroundColor: '#009570'}}>Taken</th>
                <th style={{backgroundColor: '#009570'}}>Actual</th>
                <th style={{backgroundColor: 'orange'}}>Grounded</th>
                <th style={{backgroundColor: 'orange'}}>Actual</th>
                <th style={{backgroundColor: 'orange'}}>Completed</th>
                <th style={{backgroundColor: 'orange'}}>Actual</th>
                <th style={{backgroundColor: '#f44236'}}>Grounded</th>
                <th style={{backgroundColor: '#f44236'}}>Completed</th>
                <th style={{width:75}}>Grounded</th>
                <th style={{width:75}}>Completed</th>
                <th style={{width:55}}>Save</th>
              </tr>
            </thead>
            <tbody>
              {_.map(statesList, (st, i) => {

                const stateWiseData = _.filter(MPRClubData, flt => {
                  const isMPR = _.find(flt, o => _.get(o, 'type') === 'MPRFile')
                  return (_.get(flt, '0.StateCode') === _.get(st, 'value') && isMPR && _.get(flt, 'length') > 1)
                })

                const blcProjects = _.filter(projectList1, f=> (f.Component==='AHP' || f.Component==='ISSR') && _.get(f, 'StateCode') === _.get(st, 'value'))
                const matchDate = _.find(_.filter(blcProjects,flt=>flt.source==='MPR'), fnd=>moment(fnd.lastSubmitedDate).format('YYYY-MM-DD')===moment().subtract(1, "days").format("YYYY-MM-DD")) ? true : false
                // console.log(_.get(st, 'label', '-'),'matchDate------>',matchDate)
                 const currentGrounded = _.sumBy(blcProjects , sm => sm.totalWorkOrders ? sm.totalWorkOrders : 0)
                 const currentActualGrounded = _.sumBy(blcProjects , sm => sm.actualTotalWorkOrders ? sm.actualTotalWorkOrders : 0)
                 const currentCompleted = _.sumBy(blcProjects , sm => sm.completed ? sm.completed : 0)
                 const currentActualCompleted = _.sumBy(blcProjects , sm => sm.actualCompleted ? sm.actualCompleted : 0)
                 const blcSFGrounded = _.sumBy(blcProjects , sm => Number(_.get(sm, 'totalWorkOrders') || 0) - Number(_.get(sm, 'actualTotalWorkOrders') || 0))
                 const blcSFCompleted = _.sumBy(blcProjects , sm => Number(_.get(sm, 'completed') || 0) - Number(_.get(sm, 'actualCompleted') || 0))

                //  tShortfallGrounded += blcSFGrounded
                //  tshortfallCompleted += blcSFCompleted
            

                  // TotalGrounded += currentGrounded
                  // TotalActualGrounded += currentActualGrounded
                  // TotalCompleted += currentCompleted
                  // TotalActualCompleted += currentActualCompleted

                let diffGround = 0, diffActualGround = 0, diffCompleted = 0, diffActualCompleted = 0 //, diffOccupied = 0
                let shortfallGrounded = 0, shortfallCompleted = 0

                let sfGrndValue = ''
                let sfCompValue = ''
                // console.log({sfGrndValue,sfCompValue})

                _.map(stateWiseData, mpObj => {
                  // console.log('mpObj----------->>', mpObj)
                  const mprObj = _.find(mpObj, o => _.get(o, 'type') === 'MPRFile')
                  const currentProject = _.find(mpObj, o => !_.get(o, 'type'))
                  const currentSanctioned = Number(_.get(currentProject, 'HousesSanctionedNew') || 0) + Number(_.get(currentProject, 'HousesSanctionedEnahncement') || 0)

                  const diffGroundInc = Number(_.get(mprObj, 'Ground') || 0) > currentSanctioned ? currentSanctioned : Number(_.get(mprObj, 'Ground') || 0)
                  const diffCompletedInc = Number(_.get(mprObj, 'Completed') || 0) > currentSanctioned ? currentSanctioned : Number(_.get(mprObj, 'Completed') || 0)
                  // const diffOccupiedInc = Number(_.get(mprObj, 'Occupied') || 0) > currentSanctioned ? currentSanctioned : Number(_.get(mprObj, 'Occupied') || 0)

                  const sfGrounded = Number(_.get(currentProject, 'totalWorkOrders') || 0) - Number(_.get(currentProject, 'actualTotalWorkOrders') || 0)
                  const sfCompleted = Number(_.get(currentProject, 'completed') || 0) - Number(_.get(currentProject, 'actualCompleted') || 0)

                  shortfallGrounded += sfGrounded
                  shortfallCompleted += sfCompleted
          

                  diffGround += (diffGroundInc - Number(_.get(currentProject, 'totalWorkOrders') || 0)) > 0 ? diffGroundInc - Number(_.get(currentProject, 'totalWorkOrders') || 0) : 0
                  diffActualGround += (diffGroundInc - Number(_.get(currentProject, 'actualTotalWorkOrders') || 0)) > 0 ? diffGroundInc - Number(_.get(currentProject, 'actualTotalWorkOrders') || 0) : 0
                  diffCompleted += (diffCompletedInc - Number(_.get(currentProject, 'completed') || 0)) > 0 ? diffCompletedInc - Number(_.get(currentProject, 'completed') || 0) : 0
                  diffActualCompleted += (diffCompletedInc - Number(_.get(currentProject, 'actualCompleted') || 0)) > 0 ? diffCompletedInc - Number(_.get(currentProject, 'actualCompleted') || 0) : 0

                  // tDiffGround += (diffGroundInc - Number(_.get(currentProject, 'totalWorkOrders') || 0)) > 0 ? diffGroundInc - Number(_.get(currentProject, 'totalWorkOrders') || 0) : 0
                  // tDiffActualGround += (diffGroundInc - Number(_.get(currentProject, 'actualTotalWorkOrders') || 0)) > 0 ? diffGroundInc - Number(_.get(currentProject, 'actualTotalWorkOrders') || 0) : 0
                  // tDiffCompleted += (diffCompletedInc - Number(_.get(currentProject, 'completed') || 0)) > 0 ? diffCompletedInc - Number(_.get(currentProject, 'completed') || 0) : 0
                  // tDiffActualCompleted += (diffCompletedInc - Number(_.get(currentProject, 'actualCompleted') || 0)) > 0 ? diffCompletedInc - Number(_.get(currentProject, 'actualCompleted') || 0) : 0

                })


                const isEnable = (diffGround || diffActualGround || diffCompleted || diffActualCompleted )
                // console.log({tDiffGround,tDiffActualGround,tDiffCompleted,tDiffActualCompleted,tDiffOccupied})
                return (
                  <tr key={i.toString() + (diffGround + diffCompleted)} style={{textAlign:'right'}}>
                    <td style={{textAlign:'center'}}>{i+1}.</td>
                    <td style={{textAlign:'left'}}>{_.get(st, 'label', '-')}</td>
                    <td>{currentGrounded ? currentGrounded.toLocaleString('en-in') : '-'}</td>
                    <td>{currentActualGrounded ? currentActualGrounded.toLocaleString('en-in') : '-'}</td>
                    <td>{currentCompleted ? currentCompleted.toLocaleString('en-in') : '-'}</td>
                    <td>{currentActualCompleted ? currentActualCompleted.toLocaleString('en-in') : '-'}</td>

                    <td>{diffGround ? diffGround.toLocaleString('en-in') : '-'}</td>
                    <td>{diffActualGround ? diffActualGround.toLocaleString('en-in') : '-'}</td>
                    <td>{diffCompleted ? diffCompleted.toLocaleString('en-in') : '-'}</td>
                    <td>{diffActualCompleted ? diffActualCompleted.toLocaleString('en-in') : '-'}</td>
                    {/* <td>{diffOccupied ? diffOccupied.toLocaleString('en-in') : '-'}</td> */}
                    <td>{blcSFGrounded ? blcSFGrounded.toLocaleString('en-in') : '-'}</td>
                    <td>{blcSFCompleted ? blcSFCompleted.toLocaleString('en-in') : '-'}</td>

                    <td>
                      <TextInput
                        type='number'
                        disabled={!blcSFGrounded ? true : false}
                        defaultValue={sfGrndValue}
                        onChange={(e) => {
                          if (Number(blcSFGrounded) < Number(e.target.value)) {
                            toast.error(`You can't enter more than ${blcSFGrounded}`)
                            sfGrndValue = ''
                            e.target.value = ''
                          } else {
                            sfGrndValue = e.target.value
                          }
                        }}
                      />
                      </td>
                      <td>
                      <TextInput
                        disabled={!blcSFCompleted ? true : false}
                        defaultValue={sfCompValue}
                        type='number'
                        onChange={(e) => {
                          if (Number(blcSFCompleted) < Number(e.target.value)) {
                            toast.error(`You can't enter more than ${blcSFCompleted}`)
                            sfCompValue = ''
                            e.target.value = ''
                          } else {
                            sfCompValue = e.target.value
                          }

                        }}
                      />
                      </td>
                      <td>
                      {matchDate ? '' :<Button style={{ margin: 0, fontSize: 12, padding: '1px 10px', }}
                        className='manageMPRshortfall'
                        variant="danger"
                        size="sm"
                        // disabled={(!blcSFGrounded && !blcSFCompleted) ? true : false}
                        onClick={() => {
                          // console.log('------------>>',sfGrndValue,sfCompValue)
                          if (!sfGrndValue && !sfCompValue) {
                            toast.error('Shortfall Grounded OR Completed field is empty.')
                          } else {
                            // this.setState({openModalForGeotagUpdate:false},()=>{
                            manageshortfall(sfGrndValue, sfCompValue, _.get(st, 'value', ''))
                            toast.success(`Successfully Grounded: ${sfGrndValue ? sfGrndValue : 0} and Completed: ${sfCompValue ? sfCompValue : 0} remove for ${_.get(st, 'label')}`)
                            const elems = document.getElementsByClassName("manageMPRshortfall");
                            for (var i = 0; i < elems.length; i++) {
                              elems[i].disabled = true;
                            }
                            // })
                          }
                        }}
                      >
                        Save
                      </Button>}
                    </td>
                    <td>
                    {matchDate ? 
                      <Button
                      style={{ margin: 0, fontSize: 12, padding: '3px 4px 3px 12px' }}
                        title="Recall today updated Physical progress."
                        variant="outline-danger"
                        // disabled={isLoading}
                        onClick={async () => {
                            setisLoading(true)
                            try{
                              await Axios.post(`${BASE_URL}/physical/deletetoday-progress`,{source:'MPR',Component:'AHPISSR', StateCode:_.get(st, 'value', '')})
                              reFetchProgress()
                              toast.success('Recall today updated Physical progress data for this state.')

                            }catch(err){
                              toast.error('Internal Server Error.')
                            setisLoading(false)

                            }
                          
                        }}
                    >
                      <i className="fa fa-undo"></i>
                    </Button>:<Button style={{ margin: 0, fontSize: 12, padding: '3px 10px' }}
                        variant="primary"
                        size="sm"
                        // disabled={isMPRSubmitting  || !isEnable}
                        // disabled={!isEnable ? 'true':''}
                        onClick={() => {
                          setisMPRSubmitting(true)
                          setisLoading(true)
                           finalUpdateAllFilesData(stateWiseData, '',_.get(st, 'value', ''))
                        }}
                      >
                       {isMPRSubmitting  ? <i className="fa fa-circle-o-notch fa-spin fa-1"></i> : 'Update'}
                      </Button>}
                    </td>
                  </tr>
                )
              })}
              {/* <tr style={{textAlign:'right', background:'#002b49', color:'#fff'}}>
                <th colSpan={2} style={{textAlign:'center'}}>Total</th>
                <th>{TotalGrounded ? TotalGrounded.toLocaleString('en-in') : '-'}</th>
                <th>{TotalActualGrounded ? TotalActualGrounded.toLocaleString('en-in') : '-'}</th>
                <th>{TotalCompleted ? TotalCompleted.toLocaleString('en-in') : '-'}</th>
                <th>{TotalActualCompleted ? TotalActualCompleted.toLocaleString('en-in') : '-'}</th>
                <th>{tDiffGround ? tDiffGround.toLocaleString('en-in') : '-'}</th>
                <th>{tDiffActualGround ? tDiffActualGround.toLocaleString('en-in') : '-'}</th>
                <th>{tDiffCompleted ? tDiffCompleted.toLocaleString('en-in') : '-'}</th>
                <th>{tDiffActualCompleted ? tDiffActualCompleted.toLocaleString('en-in') : '-'}</th>
                <th>{tShortfallGrounded ? tShortfallGrounded.toLocaleString('en-in') : '-'}</th>
                <th>{tshortfallCompleted ? tshortfallCompleted.toLocaleString('en-in') : '-'}</th>
                <th colSpan={4}/>
              </tr> */}
            </tbody>
          </table>
    </Wrapper>
  );
};

export { MprAHPISSRProgressUpdate };
