import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import { DEMO } from "../store/constant";
import Aux from "../hoc/_Aux";
import styled from 'styled-components';
import map from 'lodash/map'
const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const CustomComponent = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom:3px;
  & * >{
    margin: 0 5px;
  }
  & > .custom-control {
    line-height: 24px;
    padding-right: 20px;
  }
  & .blinkingColor{
    font-size: 13px !important;
    font-weight: 600;
  }
`

class Breadcrumb1 extends Component {
 
    render() {
        // console.log('this.state---->', this.props.anyComponent)
        let breadcrumb = '';
       
        // if (this.state.main && this.state.main.type === 'collapse') {
        //     main = (
        //         <li className="breadcrumb-item">
        //             <a href={DEMO.BLANK_LINK}>{this.state.main.title}</a>
        //         </li>
        //     );
        // }

        // if (this.props.item && this.props.item.type === 'item') {
        //     title = this.props.item.title;
        //     item = (
        //         <li className="breadcrumb-item">
        //             <a href={DEMO.BLANK_LINK}>{title}</a>
        //         </li>
        //     );

            if(this.props.title) {
                breadcrumb = (
                    <div className="page-header">
                        <div className="page-block">
                            <div className="row align-items-center">
                                <Wrapper className="col-md-12">
                                  <div>
                                    <div className="page-header-title">
                                        <h5 className="m-b-5">{this.props.title}</h5>
                                    </div>
                                    <ul className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link to="/"><i className="feather icon-home"/></Link>
                                        </li>
                                        {map(this.props.item, (obj,idx)=>{
                                            return(
                                                <li key={idx} className="breadcrumb-item">
                                                    <a href={DEMO.BLANK_LINK}>{obj.title}</a>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                    </div>
                                    <CustomComponent style={{display:'block'}}>
                                    {this.props.search}
                                    </CustomComponent>
                                    <CustomComponent style={{display:'block'}}>
                                      <CustomComponent style={{color: '#111',justifyContent:'flex-end'}}>
                                        {this.props.anyComponent1}
                                      </CustomComponent>
                                      <CustomComponent>
                                        {this.props.anyComponent}
                                     </CustomComponent>
                                    </CustomComponent>
                                </Wrapper>
                            </div>
                        </div>
                    </div>
                );
            }

        // }

        document.title = this.props.title  + ' | DRMC Dashboard';

        return (
            <Aux>
                {breadcrumb}
            </Aux>
        );
    }
}

export default Breadcrumb1;