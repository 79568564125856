import React, { PureComponent } from "react";
import { Form } from "react-bootstrap";

import styled from "styled-components";

const Container = styled.div`
  position: relative;
  margin-bottom:10px;
  width:100%;
`;
const StyledInput = styled(Form.Control)`
  text-align: ${props=> props.type === 'number' ? 'right' : 'left'};
  background: #fffdec;
  &:focus {
    box-shadow: 0 0 0 1px rgba(0, 123, 255, 0.25);
  }
`;
const Label = styled(Form.Label)`
  font-size: ${props=> props.fontSize ? props.fontSize:'13px'};
  color: ${props=> props.color ? props.color: '#000000'};
  & span {
    color: red;
  }
`;

class Input extends PureComponent {
  state = {
    view: false
  };
  render() {
    const {
      type = "text",
      label,
      isrequiredicon,
      onChange,
      className,
      parentClass,
      accept,
      note,
      notecolor='green',
      clearicon='',
      value,
      autoFocus,
      components,
      viewpassword,
      onClear
    } = this.props;

    // console.log("name----->>", viewpassword && this.state.view, viewpassword ,this.state.view);
    return (
      <Container className={parentClass || className}>
        <Form.Group>
          {label && (
            <Label>
              {label} {isrequiredicon ? <span>*</span> : ''}
            </Label>
          )}
          {components?components:<StyledInput
            autoComplete="off"
            size={"sm"}
            // type={viewpassword && this.state.view ? 'text' : type}
            value={value}
            {...this.props}
            type={viewpassword && this.state.view ? 'text' : type}
            autoFocus={autoFocus}
            accept={accept}
            onChange={event => (onChange ? onChange(event) : null)}
          />}
           {viewpassword ? <i onClick={()=>this.setState({view:!this.state.view})} style={{fontSize: 16,color: '#000',position: 'absolute',right: 10,top: 35}} className={`feather ${this.state.view ? 'icon-eye-off' : 'icon-eye'}`} />:''}
           {clearicon && onClear ? <i onClick={()=>onClear()} style={{fontSize: 16,color: '#000',position: 'absolute',right: 10,top: 35, cursor:'pointer'}} className={'feather icon-x'} />:''}
          {note && (
            <Label style={{position:'absolute',right: 0}} fontSize='10px' color={notecolor}>
              {note}
            </Label>
          )}
        </Form.Group>
      </Container>
    );
  }
}

export { Input };
