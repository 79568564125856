import React, { Component } from 'react';

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_kelly from "@amcharts/amcharts4/themes/kelly";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

import get from 'lodash/get'
/* Chart code */



class RespectiveYearsGraph extends Component {

componentDidMount(){
		this.initGraph()
}
componentDidUpdate(prevProps){
	this.initGraph()
}

	initGraph() {
		function am4themes_myTheme(target) {
			if (target instanceof am4core.ColorSet) {
				target.list = [
					am4core.color("#1BA68D"),
					// am4core.color("#E7DA4F"),
					am4core.color("#E77624"),
					am4core.color("#DF3520"),
					am4core.color("#64297B"),
					am4core.color("#232555")
				];
			}
		}
		// console.log('this.........',this.props.type)
		// Themes begin
		am4core.useTheme( this.props.type === 'financial' ?  am4themes_kelly : am4themes_myTheme);
		am4core.useTheme(am4themes_animated);

		// Create chart instance
		let chart = am4core.create(this.props.dynamicGraphId, am4charts.XYChart);
		// Add data
		chart.data= this.props.graphData
		// chart.data = [{
		// 	"year": 2005,
		// 	"sanctioned": 23.5,
		// 	"grounded": 23.5,
		// 	"completed": 23.5,
		// 	"occupied": 18.1
		// }];
		// console.log('graphData-->>', this.props.graphData)

	
		

		// Create axes
		let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
		categoryAxis.dataFields.category = "year";
		// categoryAxis.numberFormatter.numberFormat = "#";
		categoryAxis.renderer.inversed = true;
		categoryAxis.renderer.grid.template.location = 0;
		categoryAxis.renderer.cellStartLocation = 0.1;
		categoryAxis.renderer.cellEndLocation = 0.9;
		categoryAxis.renderer.labels.template.rotation = -90;
		// categoryAxis.renderer.labels.template.verticalCenter='center'
		categoryAxis.renderer.labels.template.horizontalCenter='middle'
		

		let  valueAxis = chart.xAxes.push(new am4charts.ValueAxis()); 
		valueAxis.renderer.opposite = true;
 
	

		// Create series
		function createSeries(field, name, color) {
			let series = chart.series.push(new am4charts.ColumnSeries());
			series.dataFields.valueX = field;
			series.dataFields.categoryY = "year";
			series.name = name;
			// series.stacked = true;
			// series.stacked  = field==='funded' || field==='sanctioned' ? true : false;
			series.stroke = color;
			// series.fill = color;

			series.columns.template.tooltipText = "{name}: [bold]{valueX}";
			series.columns.template.height = am4core.percent(100);
			// series.sequencedInterpolation = true;
			series.columns.template.column.cornerRadiusBottomRight = 10;
			series.columns.template.column.cornerRadiusTopRight = 10;
			series.columns.template.column.fillOpacity = 0.8;

			// series.columns.template.width = am4core.percent(50);
			// series.columns.template.label.horizontalCenter='center';
			// series.columns.template.column.minGridDistance = 50;

			series.columns.template.adapter.add("fill", function(fill, target) {
				// return chart.colors.getIndex(target.dataItem.index);
				return am4core.color(color ||"#000");
			  });


			let valueLabel = series.bullets.push(new am4charts.LabelBullet());
			valueLabel.label.text = "{valueX}";
			valueLabel.label.adapter.add("text", function(label, target, key) {
				// console.log({label, target, key})
				// console.log('===',target.dataItem, target.dataItem.valueX)
				const sanc = get(target.dataItem,'_dataContext.sanctioned') ? Number(get(target.dataItem,'_dataContext.sanctioned').replaceAll(',','')) : get(target.dataItem,'_dataContext.CentralAssistApproved') ? get(target.dataItem,'_dataContext.CentralAssistApproved') : 0
				const columnValue = get(target.dataItem,'valueX') ? get(target.dataItem,'valueX') :0

				if((get(target.dataItem,'_dataContext.sanctioned') || get(target.dataItem,'_dataContext.CentralAssistApproved')) && sanc!==columnValue){
					const ratio = (columnValue / sanc)*100 ? (columnValue / sanc)*100 : 0
					if(ratio){
						return label + `[bold] (${ratio.toFixed(1)}%)`
						// return label + '[bold]'+ '('+(ratio ? Number(ratio.toFixed(1)):0 )+'%)'
					}else{
						return label
					}
				}
				return label
			  });

			  valueLabel.label.adapter.add("horizontalCenter", function(center, target) {
				if (!target.dataItem) {
				  return center;
				}
				// console.log('1---->',target)
				// console.log('2---->',values.valueY.value, values.openValueY.value)
				// console.log('3---->',target.dataItem.itemWidth)
				// console.log('target---->',target.dataItem, target.dataItem.valueY, target.dataItem.valueX, target.dataItem.valueAxis)	
				if(target.availableWidth > 500){
					target.dx=-100
				}
				return target.availableWidth > 500
				  ? "right"
				  : "left";
			  });

				// valueLabel.events.on("datavalidated", function(ev) {

				// 	// Get objects of interest
				// 	const a = ev.target;
				// 	console.log('a-------->>', a.pixelHeight, a.pixelWidth)
				// 	console.log('ab-------->>', a)
				// 	// let categoryAxis = chart.yAxes.getIndex(0);
				
				// 	// Calculate how we need to adjust chart height
				// 	// let adjustHeight = chart.data.length * cellSize - categoryAxis.pixelHeight;
				
				// 	// // get current chart height
				// 	// let targetHeight = chart.pixelHeight + adjustHeight;
				
				// 	// // Set it on chart's container
				// 	// chart.svgContainer.htmlElement.style.height = targetHeight + "px";
				// });
		
				
			valueLabel.label.horizontalCenter = "left";
			valueLabel.label.dx = -2;
			valueLabel.label.isMeasured = false;
			valueLabel.label.hideOversized = false;
			valueLabel.label.truncate = false;

			let categoryLabel = series.bullets.push(new am4charts.LabelBullet());
			categoryLabel.label.text = "{name}";
			categoryLabel.label.horizontalCenter = "right";
			categoryLabel.label.dx = -10;
			categoryLabel.label.fontSize = 10;
			categoryLabel.label.fill = am4core.color("#fff");
			categoryLabel.label.hideOversized = false;
			categoryLabel.label.truncate = false;
		}
		if(this.props.type === 'financial'){
		createSeries("CentralAssistApproved", "CA Approved",'#04a9f5');
		// createSeries("sanctioned", "Sanctioned" ,'#04a9f5');
		createSeries("TotalCAReleased", "Total CA Released",'#ffa500');
		// createSeries("Expenditure", "Expenditure",'#ffcc5c');
		createSeries("TotalUCReceived", "Total UC Received",'#005807');
	}else{
		createSeries("sanctioned", "Sanctioned",'#04a9f5');
		createSeries("funded", "Funded",'#ffa500');
		createSeries("grounded", "Grounded",'#a8afb8');
		createSeries("completed", "Completed",'#00ad59');
		createSeries("occupied", "Occupied",'#005807');
	}

	

	}

    render() {
        return <div id={this.props.dynamicGraphId} className={this.props.dynamicGraphId} style={{width: '100%', height: this.props.height}} />;
    }
}

export default RespectiveYearsGraph;