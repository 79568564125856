import React from 'react';
// import Modal from "react-animated-modal";
import {Card} from 'react-bootstrap'
import styled from "styled-components";

const Wrapper = styled.div`
position: fixed;
display: flex;
justify-content: center;
align-items: center;
width: 100vw;
height: 100%;
z-index: 1030 !important;
    top: 0;
    left: 0;
`
const Overlay = styled.div`
opacity: 0.55;
background-color: #000;
position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 102;
`
const ModalBody = styled.div`
width: 90%;
min-width: 310px;
max-width: 1000px;
position: relative;
background-color: #fff;
border-radius: 4px;
z-index: 103;
min-height: 200px;
`


class RerenderModal extends React.Component {

    render() {
        const {style,headerClassName='bg-c-blue',props}=this.props
        return (
            <Wrapper>
                <Overlay/>

                <ModalBody
                    // visible={this.props.showModal}
                    // closemodal={this.props.onClose}
                    // type={this.props.animation}
                    {...props}
                >
                  <Card>
                      <Card.Header as="h5" className={headerClassName} style={{display:'flex',justifyContent: 'space-between',paddingRight: 50,alignItems: 'center'}}>
                        {this.props.title || 'Title'}
                        {this.props.anyComponent}
                      </Card.Header>
                      <Card.Body style={{...style, maxHeight:'80vh', overflow: this.props.removeCardBodyOverflow ? 'visible' :'auto'}}>
                          {this.props.children}
                      </Card.Body>
                  </Card>
                </ModalBody>
            </Wrapper>
        );
    }
}

export {RerenderModal};