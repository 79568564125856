import React, { Component } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

class RadiusPieChart extends Component {
  componentDidMount(){
    this.initGraph()
  }
  componentDidUpdate(){
    this.initGraph()
  }

  initGraph() {
    // Themes begin
    function myTheme1(target) {
      if (target instanceof am4core.ColorSet) {
        target.list = [
          am4core.color("#FF8C00"),
          am4core.color("#32CD32"),
          am4core.color("#8A2BE2"),
          am4core.color("#DC143C"),
          am4core.color("#008B8B"),
          am4core.color("#191970")
        ];
      }
    }

    function myTheme2(target) {
      if (target instanceof am4core.ColorSet) {
        target.list = [
          am4core.color("#f44336"),
          am4core.color("#03a9f4"),
          am4core.color("#0067a5"),
          am4core.color("#ffeb3b"), 
          am4core.color("#673ab7"),
          am4core.color("#ff5722")
        ];
      }
    }

    function myTheme3(target) {
      if (target instanceof am4core.ColorSet) {
        target.list = [
          am4core.color("#f6a600"),
          am4core.color("#0067a5"), 
          am4core.color("#FF00FF"),
          am4core.color("#800080"),
          am4core.color("#008080"),
          am4core.color("#00FF00")
        ];
      }
    }

    function myTheme4(target) {
      if (target instanceof am4core.ColorSet) {
        target.list = [
          am4core.color("#FF0000"),
          am4core.color("#fbdb48"),
          am4core.color("#67d868"),
          am4core.color("#808000"),
          am4core.color("#800000"),
          am4core.color("#ff9700")
        ];
      }
    }



    am4core.useTheme(this.props.theme === 'myTheme2' ? myTheme2 : this.props.theme === 'myTheme3' ? myTheme3 : this.props.theme === 'myTheme4' ? myTheme4 :  myTheme1);
    am4core.useTheme(am4themes_animated);
    // Themes end
    // Create chart
    let chart = am4core.create(this.props.id ||'RadiusPieChart', am4charts.PieChart);
    chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

    chart.data=this.props.graphData

    let series = chart.series.push(new am4charts.PieSeries());
    series.dataFields.value = "value";
    series.dataFields.radiusValue = "value";
    series.dataFields.category = "valueLabel";
    series.slices.template.cornerRadius = 6;
    series.colors.step = 1;
    
    series.hiddenState.properties.endAngle = -10;
    series.labels.template.text = "{value}";

    chart.legend = new am4charts.Legend();
    chart.legend.valueLabels.template.text = "";
    chart.legend.labels.template.text = "{name}";
    chart.legend.markers.template.width=15
    chart.legend.markers.template.height=15

    let as = chart.legend.labels.template.states.getKey("active");
    as.properties.textDecoration = "line-through";
    as.properties.fill = am4core.color("#000");

    // let series = chart.series.push(new am4charts.PieSeries());
    // series.dataFields.value = "value";
    // series.dataFields.radiusValue = "value";
    // series.dataFields.category = "country";
    // series.slices.template.cornerRadius = 6;
    // series.colors.step = 3;

    // series.hiddenState.properties.endAngle = -90;

    // chart.legend = new am4charts.Legend();


  }

    render() {
      // console.log('------>>',this.props.graphData)
        return <div id={this.props.id ||'RadiusPieChart'} className={this.props.id ||'RadiusPieChart'} style={{width: '100%', height: this.props.height,...this.props.style}} />;
    }
}

export default RadiusPieChart;