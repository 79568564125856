import React, { Component } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
class CylinderChart3D extends Component {
  componentDidMount(){
    this.initGraph()
  }
  componentDidUpdate(){
    this.initGraph()
  }

  initGraph() {
    // Themes begin
    function myTheme1(target) {
      if (target instanceof am4core.ColorSet) {
        target.list = [
          am4core.color("#FF8C00"),
          am4core.color("#32CD32"),
          am4core.color("#8A2BE2"),
          am4core.color("#DC143C"),
          am4core.color("#008B8B"),
          am4core.color("#191970")
        ];
      }
    }

    function myTheme2(target) {
      if (target instanceof am4core.ColorSet) {
        target.list = [
          am4core.color("#f44336"),
          am4core.color("#03a9f4"),
          am4core.color("#0067a5"),
          am4core.color("#ffeb3b"), 
          am4core.color("#673ab7"),
          am4core.color("#ff5722")
        ];
      }
    }

    function myTheme3(target) {
      if (target instanceof am4core.ColorSet) {
        target.list = [
          am4core.color("#f6a600"),
          am4core.color("#00bfff"),
          am4core.color("#0067a5"), 
          am4core.color("#800080"),
          am4core.color("#008080"),
          am4core.color("#00FF00")
        ];
      }
    }

    function myTheme4(target) {
      if (target instanceof am4core.ColorSet) {
        target.list = [
          am4core.color("#FF0000"),
          am4core.color("#fbdb48"),
          am4core.color("#67d868"),
          am4core.color("#808000"),
          am4core.color("#800000"),
          am4core.color("#ff9700")
        ];
      }
    }



    am4core.useTheme(this.props.theme === 'myTheme2' ? myTheme2 : this.props.theme === 'myTheme3' ? myTheme3 : this.props.theme === 'myTheme4' ? myTheme4 :  myTheme1);
    am4core.useTheme(am4themes_animated);
    // Themes end
    // Create chart
    let chart = am4core.create(this.props.id ||'CylinderChart3D', am4charts.XYChart3D);
        chart.paddingBottom = 30;
        chart.angle = 35;
        chart.depth = 10;
    // Add data
    chart.data = this.props.graphData;
    // [{
    //   "country": "USA",
    //   "visits": '4025'
    // }, {
    //   "country": "China",
    //   "visits": '1882'
    // }];

    // Create axes
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.value = "value";
    categoryAxis.dataFields.category = "valueLabel";

    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 20;
    categoryAxis.renderer.inside = true;
    categoryAxis.renderer.grid.template.disabled = true;


    let labelTemplate = categoryAxis.renderer.labels.template;
      // labelTemplate.rotation = -90;
      // labelTemplate.horizontalCenter = "left";
      // labelTemplate.verticalCenter = "middle";
      labelTemplate.dy = 25; // moves it a bit down;
      // labelTemplate.inside = false; // this is done to avoid settings which are not suitable when label is rotated

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.grid.template.disabled = true;
    valueAxis.renderer.labels.template.disabled = true;

    // valueAxis.renderer.labels.template.adapter.add("text", function(text) {
    //   return text + "%";
    // })
    // Create series
    let series = chart.series.push(new am4charts.ConeSeries());
    series.dataFields.valueY = "value";
    series.dataFields.categoryX = "valueLabel";
    
    let valueLabel = series.bullets.push(new am4charts.LabelBullet());
    valueLabel.label.text = "[bold]{value}";

    // Add bullets
    let bullet = series.bullets.push(new am4charts.Bullet());
    let image = bullet.createChild(am4core.Image);
    image.horizontalCenter = "middle";
    image.verticalCenter = "bottom";
    image.dy = 20;
    image.y = am4core.percent(100);
    image.propertyFields.href = "bullet";
    image.tooltipText = series.columns.template.tooltipText;
    image.propertyFields.fill = "color";
    image.filters.push(new am4core.DropShadowFilter());

    let columnTemplate = series.columns.template;
    columnTemplate.adapter.add("fill", function(fill, target) {
      return chart.colors.getIndex(target.dataItem.index);
    })

    columnTemplate.adapter.add("stroke", function(stroke, target) {
      return chart.colors.getIndex(target.dataItem.index);
    })




  }

    render() {
      // console.log('------>>',this.props.graphData)
        return <div id={this.props.id ||'CylinderChart3D'} className={this.props.id ||'CylinderChart3D'} style={{width: '70%', height: this.props.height,...this.props.style}} />;
    }
}

export default CylinderChart3D;