import React, { Component } from 'react';
import styled from 'styled-components'
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
// import am4themes_animated from "@amcharts/amcharts4/themes/animated";
// import am4themes_material from "@amcharts/amcharts4/themes/material";

const StyledFlex = styled.div`
  display: flex;
  justify-content:center;
  align-item:center;
      padding-top: 5px;
  & > div{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 15px;
    & > span{
      display:block;
      width:15px;
      height:15px;
      margin-right:5px;
      border-radius: 2px;
    }
   
  }
  & div:nth-child(1) span{
    background:#0f6d16;
  }
  & div:nth-child(2) span{
    background:#8bc34a;
  }
  & div:nth-child(3) span{
    background:#ff9800;
  }
  & div:nth-child(4) span{
    background:#23a4d4;
  }
  & div:nth-child(5) span{
    background:#f44336;
  }
`

class DashboardSanctionedHousesGraph extends Component {

    componentDidMount() {

        function am4themes_myTheme(target) {
            if (target instanceof am4core.ColorSet) {
              target.list = [
                am4core.color("#1BA68D"),
                am4core.color("#E7DA4F"),
                am4core.color("#E77624"),
                am4core.color("#DF3520"),
                am4core.color("#64297B"),
                am4core.color("#232555")
              ];
            }
          }

 // Themes begin
am4core.useTheme(am4themes_myTheme);
// Themes end

// Create chart instance
let chart = am4core.create("DashboardSanctionedHousesGraph", am4charts.XYChart);
// chart.scrollbarX = new am4core.Scrollbar();

// Add data
chart.data = [  
{
"country": "Lakshadweep (UT)- 0%",
"visits": 0,
color:'#f44336'
},
{
"country": "A&N Island (UT)- 6%",
"visits": 36,
color:'#f44336'
},
{
"country": "Sikkim- 95%",
"visits": 516,
color:'#0f6d16'
},
{
"country": "Ladakh (UT)- 49%",
"visits": 863,
color:'#23a4d4'
},
{
"country": "Goa- 94%",
"visits": 928,
color:'#0f6d16'
},
{
"country": "Daman & Diu (UT)- 75%",
"visits": 1026,
color:'#8bc34a'
},
{
"country": "Meghalaya- 34%",
"visits": 1597,
color:'#23a4d4'
},
{
"country": "D&N Haveli (UT)- 97%",
"visits": 4601,
color:'#0f6d16'
},
{
"country": "Chandigarh (UT)- 100%",
"visits": 5539,
color:'#0f6d16'
},
{
"country": "Arunachal Pradesh- 98%",
"visits": 7115,
color:'#0f6d16'
},
{
"country": "Himachal Pradesh- 79%",
"visits": 7985,
color:'#8bc34a'
},
{
"country": "Mizoram- 37%",
"visits": 11404,
color:'#23a4d4'
},
{
"country": "Puducherry (UT)- 95%",
"visits": 12928,
color:'#0f6d16'
},
{
"country": "Nagaland- 66%",
"visits": 20986,
color:'#ff9800'
},
{
"country": "Uttarakhand- 53%",
"visits": 21849,
color:'#ff9800'
},
{
"country": "J&K (UT)- 52%",
"visits": 28181,
color:'#ff9800'
},
{
"country": "Manipur- 75%",
"visits": 31948,
color:'#8bc34a'
},
{
"country": "Haryana- 19%",
"visits": 51051,
color:'#f44336'
},
{
"country": "Punjab- 54%",
"visits": 51459,
color:'#ff9800'
},
{
"country": "Tripura- 67%",
"visits": 55254,
color:'#ff9800'
},
{
"country": "Assam- 49%",
"visits": 57147,
color:'#23a4d4'
},
{
"country": "Delhi (NCR)- 100%",
"visits": 59809,
color:'#0f6d16'
},
{
"country": "Kerala- 79%",
"visits": 104579,
color:'#8bc34a'
},
{
"country": "Odisha- 68%",
"visits": 106253,
color:'#ff9800'
},
{
"country": "Rajasthan- 58%",
"visits": 122711,
color:'#ff9800'
},
{
"country": "Jharkhand- 71%",
"visits": 141702,
color:'#8bc34a'
},
{
"country": "Bihar- 51%",
"visits": 161489,
color:'#ff9800'
},
{
"country": "Telangana- 86%",
"visits": 191480,
color:'#8bc34a'
},
{
"country": "Chhattisgarh- 78%",
"visits": 201095,
color:'#8bc34a'
},
{
"country": "West Bengal- 82%",
"visits": 342853,
color:'#8bc34a'
},
{
"country": "Karnataka- 60%",
"visits": 397591,
color:'#ff9800'
},
{
"country": "Maharashtra- 43%",
"visits": 526678,
color:'#23a4d4'
},
{
"country": "Gujarat- 88%",
"visits": 590157,
color:'#8bc34a'
},
{
"country": "Tamil Nadu- 79%",
"visits": 611527,
color:'#8bc34a'
},
{
"country": "Madhya Pradesh- 80%",
"visits": 632924,
color:'#8bc34a'
},
{
"country": "Andhra Pradesh- 39%",
"visits": 789044,
color:'#23a4d4'
},
{
"country": "Uttar Pradesh- 66%",
"visits": 1049661,
color:'#ff9800'
},                               

];


// Create axes
let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
categoryAxis.dataFields.category = "country";
categoryAxis.renderer.grid.template.location = 0;
categoryAxis.renderer.minGridDistance = 10;
// categoryAxis.renderer.labels.template.horizontalCenter = "right";
// categoryAxis.renderer.labels.template.verticalCenter = "middle";
// categoryAxis.renderer.labels.template.rotation = 0;
categoryAxis.tooltip.disabled = true;
categoryAxis.renderer.minHeight = 110;

let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
valueAxis.renderer.minWidth = 50;

// Create series
let series = chart.series.push(new am4charts.ColumnSeries());
series.sequencedInterpolation = true;
series.dataFields.valueX = "visits";
series.dataFields.categoryY = "country";
series.tooltipText = "[{categoryY}: bold]{valueX}[/]";
series.columns.template.strokeWidth = 0;

series.tooltip.pointerOrientation = "vertical";

series.columns.template.column.cornerRadiusBottomRight = 10;
series.columns.template.column.cornerRadiusTopRight = 10;
series.columns.template.column.fillOpacity = 0.8;

// on hover, make corner radiuses bigger
let hoverState = series.columns.template.column.states.create("hover");
hoverState.properties.cornerRadiusBottomRight = 0;
hoverState.properties.cornerRadiusTopRight = 0;
hoverState.properties.fillOpacity = 1;

series.columns.template.adapter.add("fill", function(fill, target) {
  // return chart.colors.getIndex(target.dataItem.index);
  return am4core.color(target.dataItem.dataContext.color ||"#000");
});

let labelBullet = series.bullets.push(new am4charts.LabelBullet())
labelBullet.label.horizontalCenter = "right";
labelBullet.label.text = "{values.valueX.workingValue}";
labelBullet.label.textAlign = "end";
labelBullet.label.truncate  = false;
labelBullet.label.dx = 40;

// Cursor
chart.cursor = new am4charts.XYCursor();

    }

    render() {
        return(
        <>
        <StyledFlex>
          <div><span/> Above 90%</div>
          <div><span/> 70% To 90%</div>
          <div><span/> 50% To 70%</div>
          <div><span/> 25% To 50%</div>
          <div><span/> 0% To 25%</div>
        </StyledFlex>
          <div id='DashboardSanctionedHousesGraph' className="DashboardSanctionedHousesGraph" style={{width: '100%', height: this.props.height}} />
        </>
        );
    }
}

export default DashboardSanctionedHousesGraph;