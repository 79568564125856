import React, { Component } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import get from 'lodash/get'
import * as Cookies from 'js-cookie'

class PieChartWithLegend extends Component {
  state={
    graphValueForCheck0:'',
    graphValueForCheck1:'',
    graphValueForCheck2:'',
    graphValueForCheck3:'',
    thememaster:'',
  }
  componentDidMount(){
    this.initGraph()
    this.setState({
      graphValueForCheck0:get(this.props.graphData,'0.value'),
      graphValueForCheck1:get(this.props.graphData,'1.value'),
      graphValueForCheck2:get(this.props.graphData,'2.value'),
      graphValueForCheck3:get(this.props.graphData,'3.value'),
      thememaster:get(this.props.thememaster,'id'),
    })
  }
  componentDidUpdate(){
    this.setState({
      graphValueForCheck0:get(this.props.graphData,'0.value'),
      graphValueForCheck1:get(this.props.graphData,'1.value'),
      graphValueForCheck2:get(this.props.graphData,'2.value'),
      graphValueForCheck3:get(this.props.graphData,'3.value'),
      thememaster:get(this.props.thememaster,'id'),
    })
    this.initGraph()
  }

  initGraph() {
    const theme = this.props.thememaster ? this.props.thememaster : {}
    function myTheme1(target) {
     
      if (target instanceof am4core.ColorSet) {
      

        target.list = [
          am4core.color(get(theme,'color9') ? get(theme,'color9') :"#FF8C00"),
          am4core.color(get(theme,'color5') ? get(theme,'color5') :"#32CD32"),
          am4core.color(get(theme,'color4') ? get(theme,'color4') :"#8A2BE2"),
          am4core.color(get(theme,'color8') ? get(theme,'color8') :"#DC143C"),
          am4core.color("#008B8B"),
          am4core.color("#191970")
        ];
      }
    }

    function myTheme2(target) {
      if (target instanceof am4core.ColorSet) {
        target.list = [
          am4core.color(get(theme,'color14') ? get(theme,'color14') :"#f44336"),
          am4core.color(get(theme,'color3') ? get(theme,'color3') :"#03a9f4"),
          am4core.color(get(theme,'color6') ? get(theme,'color6') :"#0067a5"),
          am4core.color(get(theme,'color7') ? get(theme,'color7'):"#ffeb3b"), 
          am4core.color(get(theme,'color4') ? get(theme,'color4'):"#673ab7"),
          am4core.color("#ff5722")
        ];
      }
    }

    function myTheme3(target) {
      if (target instanceof am4core.ColorSet) {
        target.list = [
          am4core.color(get(theme,'color9') ? get(theme,'color9'):"#f6a600"),
          am4core.color(get(theme,'color6') ? get(theme,'color6') :"#0067a5"), 
          am4core.color("#FF00FF"),
          am4core.color("#800080"),
          am4core.color("#008080"),
          am4core.color("#00FF00")
        ];
      }
    }

    function myTheme4(target) {
      if (target instanceof am4core.ColorSet) {
        target.list = [
          am4core.color(get(theme,'color8') ? get(theme,'color8') :"#FF0000"),
          am4core.color(get(theme,'color7') ? get(theme,'color7') :"#fbdb48"),
          am4core.color("#67d868"),
          am4core.color("#808000"),
          am4core.color("#800000"),
          am4core.color("#ff9700")
        ];
      }
    }

    function myTheme5(target) {
      if (target instanceof am4core.ColorSet) {
        target.list = [
          am4core.color(get(theme,'color14') ? get(theme,'color14') :"#f44336"),
          am4core.color(get(theme,'color10') ? get(theme,'color10') :"#ffc107"),
          am4core.color(get(theme,'color12') ? get(theme,'color12') :"#8bc34a"),
          am4core.color(get(theme,'color11') ? get(theme,'color11') :"#45a951"),
          am4core.color("#808000"),
          am4core.color("#800000"),
          am4core.color("#ff9700")
        ];
      }
    }

    am4core.useTheme(this.props.theme === 'myTheme2' ? myTheme2 : this.props.theme === 'myTheme3' ? myTheme3 : this.props.theme === 'myTheme4' ? myTheme4 : this.props.theme === 'myTheme5' ? myTheme5 :  myTheme1);
    am4core.useTheme(am4themes_animated);
    // Themes end
    // Create chart
    let chart = am4core.create(this.props.id ||'PieChartWithLegend', get(this.props,'graphType') ? am4charts.PieChart3D : am4charts.PieChart3D);
    chart.depth = 10;

    // Add and configure Series
    let pieSeries = chart.series.push(get(this.props,'graphType') ? new am4charts.PieSeries() : new am4charts.PieSeries3D());
    pieSeries.dataFields.value = "value";
    pieSeries.dataFields.category = "valueLabel";
    pieSeries.dataFields.symbol = "symbol";
    // pieSeries.labels.template.text = "{category}:[bold]{value}[/]";
    pieSeries.labels.template.adapter.add("text", (label, target, key) => {
      return !target.dataItem.value ? '':get(this.props,'noLable') ? Number(target.dataItem.value).toLocaleString('en-in') : '{category}:' + Number(target.dataItem.value).toLocaleString('en-in');
    });
    // Let's cut a hole in our Pie chart the size of 30% the radius
    chart.innerRadius = am4core.percent(45);
    // chart.numberFormatter.intlLocales = "de-AT";

    // Put a thick white border around each Slice
    // pieSeries.slices.template.stroke = am4core.color("#fff");
    // pieSeries.slices.template.strokeWidth = 2;
    // pieSeries.slices.template.strokeOpacity = 1;
    pieSeries.slices.template

      // change the cursor on hover to make it apparent the object can be interacted with
    .cursorOverStyle = [
      {
        "property": "cursor",
        "value": "pointer"
      }
    ];

    pieSeries.alignLabels = false;
    pieSeries.labels.template.bent = true;
    pieSeries.labels.template.radius = 7;
    pieSeries.labels.template.padding(0,0,0,0);

    pieSeries.ticks.template.disabled = true;

    let label = pieSeries.createChild(am4core.Label);
    label.text = "{values.value.sum}";
    label.horizontalCenter = "middle";
    label.verticalCenter = "middle";
    label.fontSize = 16;
    let symbol = pieSeries.createChild(am4core.Label);
    symbol.text = this.props.symbol;
    symbol.horizontalCenter = "middle";
    symbol.verticalCenter = "middle";
    symbol.fontSize = 16;
    symbol.y = -15;
    let unit = pieSeries.createChild(am4core.Label);
    unit.text = this.props.unit;
    unit.horizontalCenter = "middle";
    unit.verticalCenter = "middle";
    unit.fontSize = 12;
    unit.y = 17;
    // symbol.dy = 70;


  // Create a base filter effect (as if it's not there) for the hover to return to
  let shadow = pieSeries.slices.template.filters.push(new am4core.DropShadowFilter());
  shadow.opacity = 0;

  // Create hover state
  let hoverState = pieSeries.slices.template.states.getKey("hover"); // normally we have to create the hover state, in this case it already exists

  // Slightly shift the shadow and make it more prominent on hover
  let hoverShadow = hoverState.filters.push(new am4core.DropShadowFilter());
  hoverShadow.opacity = 0.7;
  hoverShadow.blur = 5;

// Add a legend
chart.legend = new am4charts.Legend();
chart.legend.valueLabels.template.text = "";
chart.legend.labels.template.text = "{name}";
chart.legend.markers.template.width=12
chart.legend.markers.template.height=12
chart.legend.fontSize=12

let as = chart.legend.labels.template.states.getKey("active");
as.properties.textDecoration = "line-through";
as.properties.fill = am4core.color("#000");


chart.data=this.props.graphData

  }

  shouldComponentUpdate(nextProps, nextState) {
//console.log('nexx---->',nextProps,nextState)

    return (
      get(nextProps.graphData,'0.value') !== get(nextState,'graphValueForCheck0') ||
      get(nextProps.graphData,'1.value') !== get(nextState,'graphValueForCheck1') ||
      get(nextProps.graphData,'2.value') !== get(nextState,'graphValueForCheck2') ||
      get(nextProps.graphData,'3.value') !== get(nextState,'graphValueForCheck3')||
      get(nextProps.thememaster,'id') !== get(nextState,'thememaster')
    );
  }


    render() {
      // console.log('graphValueForCheck------>>',this.state.graphValueForCheck)
      // console.log(this.props.id,'------>>',this.props.graphData)
        return <div id={this.props.id ||'PieChartWithLegend'} className={this.props.id ||'PieChartWithLegend'} style={{width: '100%', height: this.props.height,...this.props.style}} />;
    }
}

export default PieChartWithLegend;