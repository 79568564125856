import React, { Component, Suspense } from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import Loadable from "react-loadable";
import { toast } from "react-toastify";
import "../../node_modules/font-awesome/scss/font-awesome.scss";
import * as Cookies from 'js-cookie'
import Loader from "./layout/Loader";
import Aux from "../hoc/_Aux";
import ScrollToTop from "./layout/ScrollToTop";
import routes from "../route";
import mainRoute from "../routes";
import get from 'lodash/get'
import find from 'lodash/find'
import axios from 'axios';
import _ from 'lodash'
import { BASE_URL } from '../store/constant'
// import $ from "jquery";


const AdminLayout = Loadable({
  loader: () => import("./layout/AdminLayout"),
  loading: Loader
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {anystate: ""};
  }

  componentDidMount =()=>{
    // this.checkToken()

    axios.interceptors.request.use(function (config) {
      // Do something before request is sent
      return config;
    }, function (error) {
      // Do something with request error
      return Promise.reject(error);
    });


    // Remove Chat Div

    // _.delay(function(text) {
    //   console.log(text);
    //   $('[href="https://www.chatwoot.com?utm_source=widget_branding&utm_referrer=localhost:3000"]').remove()
    // }, 10000, 'later');
    

    window.chatwootSettings = {
      launcherTitle: "Help Desk",
    };
    // window.addEventListener("chatwoot:ready", function () {
    //   console.log('myiFrame------<><><><><><>',)

    //   // var myiFrame =$('#chatwoot_live_chat_widget').contents().find(".branding--link.justify-center");
    //   //  var iframeDoc = $("#chatwoot_live_chat_widget")[0].contentWindow;
    //   //  console.log('iframeDoc--------->>',iframeDoc)

    //  const iframeHtml=  $("#chatwoot_live_chat_widget").contents().find('body');
      
    //     // var $jqObject = $(iframeDoc).find("body");
    //     // var iframeHtml = $jqObject.html();
    //     // console.log('iframeHtml--------->>',iframeHtml)
    //     // $jqObject.html(iframeHtml + "<br/>chandan Singh");

    // })
      
      // (function(d,t) {
      //   var BASE_URL="https://app.chatwoot.com";
      //   var g=d.createElement(t),s=d.getElementsByTagName(t)[0];
      //   g.src=BASE_URL+"/packs/js/sdk.js";
      //   g.defer = true;
      //   g.async = true;
      //   s.parentNode.insertBefore(g,s);
      //   g.onload=function(){
      //     window.chatwootSDK.run({
      //       websiteToken: 'qP62mUJYkPdpk1qF3tXv3SNa',
      //       baseUrl: BASE_URL,
      //     })
      //   }
      // })(document,"script");
    
  

  //   let doc = myiFrame.contentDocument;
  //  doc.body.innerHTML = doc.body.innerHTML + '<style>/******* Put your styles here *******</style>';
    
  }



  static getDerivedStateFromProps=async(props, state)=>{

  
    

  const userLocalData = localStorage.getItem('userData') ?JSON.parse(localStorage.getItem('userData')) :{}
   const OtherPermission= ["/","/login","/notfound","/sign-up","/test"];
   const allowURLs = JSON.parse(get(userLocalData,'allowUrls') ? get(userLocalData,'allowUrls') : '[]')
 const userData = _.union(allowURLs,OtherPermission);
 const RequestUrl=window.location.pathname;
 const LogoutCondition= ["/login","/notfound","/sign-up","/test"];

setInterval(function(){ 
    if(!JSON.parse(localStorage.getItem('userData'))&&LogoutCondition.indexOf(window.location.pathname)===-1){
      window.location.reload()
    }
      }, 1000);

//console.log('hiiiiiiiiiiii');



    const ogRoute=_.map(mainRoute,m=>m.path)

    let FinalPath='';
      _.each(ogRoute, function (value, key) {
         let PathOnly= value.split('/:')[0];
         RequestUrl.startsWith(PathOnly)
        if(RequestUrl.startsWith(PathOnly)){
          FinalPath=PathOnly;
        }
      });
      if(userData.indexOf(FinalPath) === -1 && !get(userLocalData,'role')==='admin' ){
      // console.log('-------->', get(userLocalData,'role')==='admin')
       props.history.push("/notfound");
    }
  
    axios.defaults.withCredentials=true;
    axios.defaults.headers.common['Access-Control-Allow-Headers'] = 'Origin, X-Requested-With, Content-Type, Accept';
    // axios.defaults.headers.Cookie=`token=${localStorage.getItem('userData')}`;

    axios.interceptors.response.use(function (response) {
      // console.log('process.env----------->>>',process.env)
      return response;
    }, function (error) {
      // console.log('------->',get(error, 'response.data.message', ''))
      if(get(error, 'response.data.message.name', '') === 'TokenExpiredError' || get(error, 'response.data.message.name', '') === 'JsonWebTokenError' || get(error, 'response.data.message', '') === 'Access denied'){
        const pathName=window.location.pathname
        
        Cookies.remove('token', { path: '/' })
        // localStorage.clear();
        localStorage.removeItem('userId')
        localStorage.removeItem('userData')
        props.history.push(`/login?redirect=${pathName}`);
      }
      return Promise.reject(error);
    });

    if(_.get(localStorage,'userId')) {

      await axios.post(`${BASE_URL}/users/updateuserlasttime`)

      await axios
      .get(`${BASE_URL}/users/Checklogin`)
      .then(async(res) => {
      
        if(res.data.holdFor30===2 && get(userLocalData,'role')!=='admin'){
         
          toast.warning(`${ res.data.holdTime}`, {
            position: toast.POSITION.TOP_CENTER,
          });
          const pathName=window.location.pathname
          
          Cookies.remove('token', { path: '/' })
          // localStorage.clear();
          localStorage.removeItem('userId')
          localStorage.removeItem('userData')
          props.history.push(`/login?redirect=${pathName}`);
          //return Promise.reject(error);
  
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
     
      });


    } 

    return {anystate:''}
  }


  
  
  render() {
    const userData = localStorage.getItem('userData') ?JSON.parse(localStorage.getItem('userData')) :{}
    const enableMenu = JSON.parse(get(userData,'allowUrls') ? get(userData,'allowUrls') : '[]')


    const menu = routes.map((route, index) => {
      return route.component ? (
        <Route
          key={index}
          path={route.path}
          exact={route.exact}
          name={route.name}
          isParams={route.isParams}
          render={props => <route.component {...props} />}
        />
      ) : null;
    });

    
    const userId = localStorage.getItem('userId')

    if(!userId && get(this.props,'location.pathname') !== '/login' && get(this.props,'location.pathname') !== '/sign-up' && get(this.props,'location.pathname') !== '/test'){
      this.props.history.push("/login");
    }else if(userId && (get(this.props,'location.pathname') === '/login' || get(this.props,'location.pathname') === '/sign-up')){
      this.props.history.push("/");
    }
    else if(userId && get(userData,'role') !=='admin' && !find([...enableMenu,'/'],obj=> get(this.props,'location.pathname').match(obj))){
      // else if(userId && get(userData,'role') !=='admin' && !([...enableMenu,'/'].indexOf(get(this.props,'location.pathname'))>=0)){
      this.props.history.push("/");
    }

    return (
      <Aux>
        <ScrollToTop>
          <Suspense fallback={<Loader />}>
            <Switch>
              {menu}
              <Route path="/" component={AdminLayout} />
            </Switch>
          </Suspense>
        </ScrollToTop>
      </Aux>
    );
  }
}

export default withRouter(App);
