import React, { Component } from 'react';
import styled from 'styled-components'
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
// import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_material from "@amcharts/amcharts4/themes/material";

const StyledFlex = styled.div`
  display: flex;
  justify-content:center;
  align-item:center;
      padding-top: 5px;
  & > div{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 15px;
    & > span{
      display:block;
      width:15px;
      height:15px;
      margin-right:5px;
      border-radius: 2px;
    }
   
  }
  & div:nth-child(1) span{
    background:#0f6d16;
  }
  & div:nth-child(2) span{
    background:#8bc34a;
  }
  & div:nth-child(3) span{
    background:#ff9800;
  }
  & div:nth-child(4) span{
    background:#23a4d4;
  }
  & div:nth-child(5) span{
    background:#f44336;
  }
`

class DashboardDemandGraph extends Component {

    componentDidMount() {
 // Themes begin
am4core.useTheme(am4themes_material);
// Themes end

// Create chart instance
let chart = am4core.create("DashboardDemandGraph", am4charts.XYChart);
// chart.scrollbarX = new am4core.Scrollbar();

// Add data
chart.data = [  
{
"country": "Lakshadweep (UT)-0%",
"visits": 0,
color:'#f44336'
},
{
"country": "Sikkim-36%",
"visits": 544,
color:'#f44336'
},
{
"country": "Chandigarh (UT)- 14%",
"visits": 579,
color:'#f44336'
},
{
"country": "A&N Island (UT)- 91%",
"visits": 612,
color:'#8bc34a'
},
{
"country": "Goa- 20%",
"visits": 986,
color:'#f44336'
},
{
"country": "Daman & Diu (UT)- 100%",
"visits": 1376,
color:'#0f6d16'
},
{
"country": "Meghalaya- 66%",
"visits": 4693,
color:'#23a4d4'
},
{
"country": "D&N Haveli (UT)- 100%",
"visits": 4734,
color:'#0f6d16'
},
{
"country": "Arunachal Pradesh- 63%",
"visits": 7260,
color:'#23a4d4'
},
{
"country": "Himachal Pradesh- 66%",
"visits": 10158,
color:'#23a4d4'
},
{
"country": "Puducherry (UT)- 94%",
"visits": 13575,
color:'#8bc34a'
},
{
"country": "Delhi (UT)- 24%",
"visits": 19229,
color:'#f44336'
},
{
"country": "Mizoram- 98%",
"visits": 30437,
color:'#8bc34a'
},
{
"country": "Nagaland- 100%",
"visits": 32001,
color:'#0f6d16'
},
{
"country": "Uttarakhand- 83%",
"visits": 41477,
color:'#ff9800'
},
{
"country": "Manipur- 93%",
"visits": 42826,
color:'#8bc34a'
},
{
"country": "Jammu & Kashmir- 100%",
"visits": 54711,
color:'#0f6d16'
},
{
"country": "Tripura- 97%",
"visits": 82267,
color:'#8bc34a'
},
{
"country": "Punjab- 95%",
"visits": 94565,
color:'#8bc34a'
},
{
"country": "Assam- 91%",
"visits": 117710,
color:'#8bc34a'
},
{
"country": "Kerala- 88%",
"visits": 131787,
color:'#ff9800'
},
{
"country": "Orissa- 52%",
"visits": 155162,
color:'#23a4d4'
},
{
"country": "Jharkhand- 100%",
"visits": 199950,
color:'#0f6d16'
},
{
"country": "Rajasthan- 71%",
"visits": 211606,
color:'#ff9800'
},
{
"country": "Telangana- 74%",
"visits": 223214,
color:'#ff9800'
},
{
"country": "Chhattisgarh- 100%",
"visits": 256678,
color:'#0f6d16'
},
{
"country": "Haryana- 91%",
"visits": 271600,
color:'#8bc34a'
},
{
"country": "Bihar- 79%",
"visits": 314755,
color:'#ff9800'
},
{
"country": "West Bengal- 89%",
"visits": 417735,
color:'#ff9800'
},
{
"country": "Karnataka- 94%",
"visits": 660901,
color:'#8bc34a'
},
{
"country": "Gujarat- 88%",
"visits": 671903,
color:'#ff9800'
},
{
"country": "Tamil Nadu- 94%",
"visits": 777791,
color:'#8bc34a'
},
{
"country": "Madhya Pradesh- 94%",
"visits": 795071,
color:'#8bc34a'
},
{
"country": "Maharashtra- 100%",
"visits": 1215498,
color:'#0f6d16'
},
{
"country": "Uttar Pradesh- 100%",
"visits": 1587880,
color:'#0f6d16'
},
{
"country": "Andhra Pradesh- 97%",
"visits": 2012101,
color:'#8bc34a'
},

];


// let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
// categoryAxis.renderer.grid.template.location = 0;
// categoryAxis.dataFields.category = "network";
// categoryAxis.renderer.minGridDistance = 1;
// categoryAxis.renderer.inversed = true;
// categoryAxis.renderer.grid.template.disabled = true;

// let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
// valueAxis.min = 0;
// valueAxis.rangeChangeEasing = am4core.ease.linear;
// valueAxis.rangeChangeDuration = stepDuration;
// valueAxis.extraMax = 0.1;



// Create axes
let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
categoryAxis.dataFields.category = "country";
categoryAxis.renderer.grid.template.location = 0;
categoryAxis.renderer.minGridDistance = 10;
// categoryAxis.renderer.labels.template.horizontalCenter = "right";
// categoryAxis.renderer.labels.template.verticalCenter = "middle";
// categoryAxis.renderer.labels.template.rotation = 0;
categoryAxis.tooltip.disabled = true;
categoryAxis.renderer.minHeight = 110;

let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
valueAxis.renderer.minWidth = 50;

// Create series
let series = chart.series.push(new am4charts.ColumnSeries());
series.sequencedInterpolation = true;
series.dataFields.valueX = "visits";
series.dataFields.categoryY = "country";
series.tooltipText = "[{categoryY}: bold]{valueX}[/]";
series.columns.template.strokeWidth = 0;

series.tooltip.pointerOrientation = "vertical";

series.columns.template.column.cornerRadiusBottomRight = 10;
series.columns.template.column.cornerRadiusTopRight = 10;
series.columns.template.column.fillOpacity = 0.8;

// on hover, make corner radiuses bigger
let hoverState = series.columns.template.column.states.create("hover");
hoverState.properties.cornerRadiusBottomRight = 0;
hoverState.properties.cornerRadiusTopRight = 0;
hoverState.properties.fillOpacity = 1;

series.columns.template.adapter.add("fill", function(fill, target) {
  // return chart.colors.getIndex(target.dataItem.index);
  return am4core.color(target.dataItem.dataContext.color ||"#000");
});

let labelBullet = series.bullets.push(new am4charts.LabelBullet())
labelBullet.label.horizontalCenter = "right";
labelBullet.label.text = "{values.valueX.workingValue}";
labelBullet.label.textAlign = "end";
labelBullet.label.truncate  = false;
labelBullet.label.dx = 40;


// Cursor
chart.cursor = new am4charts.XYCursor();

    }

    render() {
        return(
          <>
              <StyledFlex>
          <div><span/> 100%</div>
          <div><span/> 90% To 100%</div>
          <div><span/> 70% To 90%</div>
          <div><span/> 50% To 70%</div>
          <div><span/> less than 50%</div>
        </StyledFlex>
            <div id='DashboardDemandGraph' className="DashboardDemandGraph" style={{width: '100%', height: this.props.height}} />
          </>
             );
    }
}

export default DashboardDemandGraph;