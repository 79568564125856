import * as XLSX from 'xlsx';
import {FILE_PATH, BASE_URL} from '../store/constant'
import find from 'lodash/find'
import filter from 'lodash/filter'
import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'
import groupBy from 'lodash/groupBy'
import sumBy from 'lodash/sumBy'
import get from 'lodash/get'
import $ from 'jquery';

export const filterProgressData =(data,groupby)=>{
  const grp = groupBy(data,groupby)
  const finalData=map(grp,obj=>{
    const HousesSanctionedEnahncement = sumBy(obj,'HousesSanctionedEnahncement')
    const HousesSanctionedNew = sumBy(obj,'HousesSanctionedNew')
    const completed = sumBy(obj,'completed')
    const occupied = sumBy(obj,'occupied')
    const recentlySanctioned = sumBy(obj,'recentlySanctioned')
    const recentlyTotalWorkOrders = sumBy(obj,'recentlyTotalWorkOrders')
    const totalWorkOrders = sumBy(obj,'totalWorkOrders')
    return({...get(obj,'0'),HousesSanctionedEnahncement,HousesSanctionedNew,completed,occupied,recentlySanctioned,recentlyTotalWorkOrders,totalWorkOrders})
  })
  return finalData
}

export const isEmail = val => {
  if (!val) {
    return false;
  }

  const email = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

  if (!email.test(val)) {
    return false;
  }

  return true;
};
export const isValidPassword = val => {
  if (!val) {
    return false;
  }

  const pass_regex = /(?=^.{8,}$)(?=.*[A-Z])(?=.+[!@#$\-*+?._=])(?=.*\d.*\d)/;

  if (!pass_regex.test(val)) {
    return false;
  }

  return true;
};



export const isEven = num => ((num % 2) === 0) ? true : false


export const getPercent =  (firstValue, secondValue)=> {
  // (counter / 112.24)*100
  return Math.round((Number(secondValue) / Number(firstValue)) * 100);
}


export const queryString =(params)=>{return Object.keys(params).map(key => key + '=' + params[key]).join('&');}

 
export const checkBrokenUrls=(folder, path)=>{
    const url= `${BASE_URL}/drmc/${folder}/${path}` 
    const url1= `${FILE_PATH}/${folder}/${path}` 

    if(!folder || !path){
      return('')
    }
    var tester=new Image();
    // tester.onload=imageFound;
    // tester.onerror=imageNotFound;
    tester.src=url;
    // console.log(tester.complete)
    return tester.complete ? url : url1
}

const renameKeys = (keysMap, obj) =>
  Object.keys(obj).reduce(
    (acc, key) => ({
      ...acc,
      ...{ [keysMap[key] || key]: obj[key] }
    }),
    {}
  );

  const blcFields=[
    'Annexure_ID', 
    'State_Name',
    'Foundation_level',
    'Plinth_level',
    'Lintel_level',
    'Roof_level',
    'Houses_completed'
  ]
  
  const ahpISSRFields=[
    'Annexure_ID', 
    'State_Name',
    'issued_to_Contractor',
    'Foundation_level',
    'Plinth_level',
    'Lintel_level',
    'Roof_level',
    'Houses_completed'
  ]

export const excelFileReader=(file,type,component)=>{

    const promise = new Promise((resolve, reject)=>{
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);
      // console.log('XLSX---------->',XLSX)
      fileReader.onload=(e)=>{
        const bufferArray = e.target.result;
        const wb = XLSX.read(bufferArray, {type:'buffer'});

          /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname]; 
      delete ws.A1;
      const data = XLSX.utils.sheet_to_json(ws);

        const keySet = data[0]
        // console.log('keySet------>>',keySet,map(keySet, k=>k))

        const missMatchedColumn = filter(component==='BLC' ? blcFields :ahpISSRFields, flt=>{
          // console.log('flt------->',flt)
          const fltKey= find(map(keySet, k=>k), mt=>mt.replaceAll(' ','').match(flt))
          // console.log('fltKey------>>', fltKey)
          return(fltKey ? false : true)
        })

        if(!isEmpty(missMatchedColumn)){
          // toast.error(`Excel format not valid for MPR ${component} Update`)
            reject({msg:`Excel format not valid for MPR ${component} Update`})
          return
        }

        // console.log('missMatchedColumn------>>',missMatchedColumn)

        data.shift()
        const dataMap = data.map(o=>{
         return renameKeys(keySet, {...o,type})
        })

        resolve(dataMap)
      }
      fileReader.onerror=(error)=>{
        reject(error)
      }
    })

    return promise.then(d=>{
      // console.log('d----------->',d)
       return d
    })
  
}

export async function export2word(element, filename = 'word_document'){

  //  _html_ will be replace with custom html
  var meta= "Mime-Version: 1.0\nContent-Base: " + window.location.href + "\nContent-Type: Multipart/related; boundary=\"NEXT.ITEM-BOUNDARY\";type=\"text/html\"\n\n--NEXT.ITEM-BOUNDARY\nContent-Type: text/html; charset=\"utf-8\"\nContent-Location: " + window.location.href + "\n\n<!DOCTYPE html>\n<html>\n_html_</html>";
  //  _styles_ will be replaced with custome css
  var head= "<head>\n<meta http-equiv=\"Content-Type\" content=\"text/html; charset=utf-8\">\n<style>\n_styles_\n</style>\n</head>\n";

  // var rowOriginal = document.getElementById(element).getElementsByClassName('row')[0].innerHTML;
  var rowOriginal = $("#"+element).find(".row").clone();
  var row = $("#"+element).find(".row");
  var imgCount = row.find('img')
  var imgCount2 = rowOriginal.find('img')

  imgCount.map((tag, index) => {
    imgCount[tag].parentNode.style = 'display: none;';
  })

  var html = document.getElementById(element).innerHTML;

  
  var blob = new Blob(['\ufeff', html], {
      type: 'application/msword'
  });
  
  var  css = (
         '<style>' +
         `body{font-family: Roboto; width: 1000%;}
         table{width: 100%; border-collapse: collapse; border-spacing: 0;}
         th{border: 1px solid white; background-color: inherit; color: white; max-width: 600px}
         th span{color: #f4c22b}
         td{padding: 10px; border: 1px solid black; max-width: 600px} 
         div.${element}{display: flex; flex-direction: row; justify-content:space-between; align-items:center; flex: 1;}
         .row h3, .row h4, .row p{text-align: center} 
         @page ${element}{size: 1400.95pt 1400.35pt;margin:0.3in 0.3in 0.03in 0.3in;mso-header-margin:0.3in;mso-footer-margin:0.3in;mso-page-orientation: landscape;} div.${element} {page: ${element};}` +
         '</style>'
        );
//  Image Area %%%%
  // var options = { maxWidth: 624};
  // var images = Array();
  // var img = $("#"+element).find("img");
  // for (var i = 0; i < img.length; i++) {
  //     // Calculate dimensions of output image
  //     var w = Math.min(img[i].width, options.maxWidth);
  //     var h = img[i].height * (w / img[i].width);
  //     // Create canvas for converting image to data URL
  //     var canvas = document.createElement("CANVAS");
  //     canvas.width = w;
  //     canvas.height = h;
  //     // Draw image to canvas
  //     var context = canvas.getContext('2d');
  //     context.drawImage(img[i], 0, 0, w, h);
  //     // Get data URL encoding of image
  //     var uri = canvas.toDataURL("image/png");
  //     $(img[i]).attr("src", img[i].src);
  //     img[i].width = w;
  //     img[i].height = h;
  //     // Save encoded image to array
  //     images[i] = {
  //         type: uri.substring(uri.indexOf(":") + 1, uri.indexOf(";")),
  //         encoding: uri.substring(uri.indexOf(";") + 1, uri.indexOf(",")),
  //         location: $(img[i]).attr("src"),
  //         data: uri.substring(uri.indexOf(",") + 1)
  //     };
  // }

  // // Prepare bottom of mhtml file with image data
  var imgMetaData = "\n";
  // for (var j = 0; j < images.length; j++) {
  //     imgMetaData += "--NEXT.ITEM-BOUNDARY\n";
  //     imgMetaData += "Content-Location: " + images[j].location + "\n";
  //     imgMetaData += "Content-Type: " + images[j].type + "\n";
  //     imgMetaData += "Content-Transfer-Encoding: " + images[j].encoding + "\n\n";
  //     imgMetaData += images[j].data + "\n\n";
  //     console.log(images[j].encoding)
      
  // }
  imgMetaData += "--NEXT.ITEM-BOUNDARY--";
// end Image Area %%

   var output = meta.replace("_html_", head.replace("_styles_", css) +  html) + imgMetaData;

  var url = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(output);


  filename = filename ? filename + '.doc' : 'document.doc';


  var downloadLink = document.createElement("a");

  document.body.appendChild(downloadLink);

  if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(blob, filename);
  } else {

      downloadLink.href = url;
      downloadLink.download = filename;
      downloadLink.click();
  }

  document.body.removeChild(downloadLink);
  
  imgCount.map((tag, index) => {
    console.log(imgCount2[tag].parentElement.attributes.style.value)
    imgCount[tag].parentNode.style = imgCount2[tag].parentElement.attributes.style.value;
  })
}


export const exportTableToExcel = (function() {
  var uri = 'data:application/vnd.ms-excel;base64,'
    , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body><table>{table}</table></body></html>'
    , base64 = function(s) { return window.btoa(unescape(encodeURIComponent(s))) }
    , format = function(s, c) { return s.replace(/{(\w+)}/g, function(m, p) { return c[p]; }) }
  return function(table, name) {
    if (!table.nodeType) table = document.getElementById(table)
    var ctx = {worksheet: name || 'Worksheet', table: table.innerHTML}
    // window.location.href = uri + base64(format(template, ctx))
    var link = document.createElement("a");
    link.download = name ? name + '.xls' : 'excel_data.xls';
    link.href = uri + base64(format(template, ctx));
    link.click();
  }
})()

// function imageNotFound() {
//   alert('That image was not found.');
// }

export * from './SchemaValidation'