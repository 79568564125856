import React, {PureComponent} from 'react'
import styled from 'styled-components'
import { useState } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
  
function ImageCrop() {
  const [src, setSrc] = useState(null);
  const [crop, setCrop] = useState({ aspect: 16 / 16 });
  const [image, setImage] = useState(null);
  const [output, setOutput] = useState(null);
  
  const selectImage = (file) => {
    console.log('file-------->',file)
    setSrc(URL.createObjectURL(file));
  };
  
  const cropImageNow = () => {
    // const canvas = document.createElement('canvas');
    var canvas = document.getElementById("canvas");

    const scaleX = image.width / image.width;
    const scaleY = image.height / image.height;
    console.log('image------>>', {image,canvas,crop})

    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');
  
    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    var imageObj1 = new Image();
        imageObj1.src = src
        canvas.onload = function() {
        // ctx.drawImage(imageObj1,0,0);
        console.log('ctx-------->', {ctx,image})

        ctx.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width,
          crop.height,
        );
        }

 
      
    // Converting to base64
    const base64Image = canvas.toDataURL('image/jpeg');
    setOutput(base64Image);
  };
  
  console.log('*******',{src,
    crop,
    image,
    output,})
  return (
    <div className="App">
      <center>
        <input
          type="file"
          accept="image/*"
          onChange={(e) => {
            selectImage(e.target.files[0]);
          }}
        />
        <br />
        <br />
        <div>
          {src && (
            <div>
              {/* <ReactCrop src={src} 
                onImageLoaded={setImage}
                crop={crop} 
                onChange={setCrop} 
              /> */}
               <ReactCrop 
                src={src} 
                crop={crop} 
                onChange={c => setCrop(c)}
                onImageLoaded={(crop)=> console.log('*//*********',{crop})}
                onComplete={(a,b,c,d)=> console.log('*//*********',{a,b,c,d})}
                // onComplete={setImage}
                >
                  <img src={src} 
                    //  onLoad={setImage}
                   />
              </ReactCrop>
              <br />
              <button onClick={cropImageNow}>Crop</button>
              <br />
              <br />
            </div>
          )}
        </div>
        {/* <canvas id="canvas" style={{width:200, height:200}}/> */}
        <div>{output && <img src={output} />}</div>
      </center>
    </div>
  );
}
  

export default ImageCrop;