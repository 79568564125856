import React from 'react';

import Aux from "../../../../../../../../hoc/_Aux";
// import { DEMO } from "../../../../../../../../store/constant";
// const images = require.context('../../../../../../../../assets/images/user', true);
import avatar from '../../../../../../../../assets/images/user/avatar-2.jpg'

const friend = (props) => {
    let photo = ''// '../../../../../../../../../assets/images/user/avatar-2.jpg' //images(`./${props.data.photo}`);
    let timeClass = ['d-block'];
    if (props.data.status) {
        timeClass = [...timeClass, 'text-c-green'];
    } else {
        timeClass = [...timeClass, 'text-c-red'];
    }

    let time = '';
    if (props.data.time) {
        time = <small className={timeClass.join(' ')}>{props.data.time}</small>;
    }

    // let newFriend = '';
    // if (props.data.new) {
    //     newFriend = <div className="live-status">{props.data.new}</div>;
    // }

    return (
        <Aux>
            <div style={{padding:'5px'}} className={props.activeId === props.data.id ? 'media userlist-box ripple active' : 'media userlist-box ripple'} onClick={props.clicked}>
                <a className="media-left" href="#!"> <img style={{width:30}} className="media-object img-radius" src={avatar} alt={props.data.name}/>
                {/* {newFriend} */}
                </a>
                <div className="media-body">
                    <h6 className="chat-header" style={{fontSize:12}}>{props.data.name}{time}</h6>
                </div>
            </div>
        </Aux>
    );
};

export default friend;