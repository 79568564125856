import React, {PureComponent} from 'react'
import {Form} from 'react-bootstrap';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import styled from 'styled-components'

const Label = styled(Form.Label)`
  font-size: 13px;
  & span {
    color: red;
  }
`

const CustomStyle = {
  option: (base, { data, isDisabled, isFocused, isSelected, props }) => {
    return ({
      ...base,
      color: data.label === 'Create New' ? '#0a78c3' : '#143756',
      fontSize: 12,
      // whiteSpace: 'nowrap',
      whiteSpace: 'pre-wrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      padding: '6px 8px',
      backgroundColor: isSelected ? '#ffb74d' : isFocused ? 'hsla(216,94%,58%,0.15)' : '#fff',
      ':active': {
        backgroundColor: '#e7e7e7',
      }
    })
  },
  dropdownIndicator: (base, state) => ({
    ...base,
    transition: 'all .2s ease',
    transform: state.isFocused ? 'rotate(180deg)' : null,
    padding:4,
  }),
  clearIndicator: (base, state) => ({
    ...base,
    transition: 'all .2s ease',
    transform: state.isFocused ? 'rotate(180deg)' : null,
    padding:4,
  }),
  
  control: (base, { isDisabled, isFocused }) => {
    return ({
      ...base,
      backgroundColor: isDisabled ? '#d6d6d6' : isFocused ? 'hsla(0,0%,0%,0.04)' : '#fffdec',
      borderRadius: 3,
      fontSize: 13,
      boxShadow: 'none',
      minHeight: 31,
      // maxHeight: 31,
      color: '#444',
      borderWidth: isDisabled ? 'transparent' : isFocused ? 1 : 1,
      borderStyle: 'solid',
      borderColor: '#ced4da',
      ':hover': {
        // borderColor: isFocused ? 'hsla(0, 0%, 0%, 0.2)' : 'hsla(0, 0%, 0%, 0.2) !important',
        backgroundColor: isDisabled ? '#d6d6d6' : 'hsla(0,0%,0%,0.06) !important'
      },

    })
  },
  indicatorsContainer: (base) => {
    return ({
      ...base,
      // padding: '3px 8px'
    })
  },

  menu: (base) => {
    return ({
      ...base,
      backgroundColor: '#fff',
      top: 26,
      width: '100%',
      position: 'absolute',
      borderRadius: 3,
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: 'hsla(0, 0%, 0%, 0.2)',
      boxShadow: 'none',
      zIndex:999
    })
  },

  menuList: (base) => {
    return ({
      ...base,
      backgroundColor: '#fff',
      paddingTop: 6,
      paddingBottom: 6,
    })
  },
  menuPortal: (base) => {
    return ({
      ...base,
      backgroundColor: 'red',
    })
  },
  input: (base) => {
    return ({
      ...base,
      color: '#444',
      margin: 0,
      paddingBottom: 0,
      paddingTop: 0,
    })
  }
}



class SelectBox extends PureComponent{
  state={
    open: false
  }
  render(){
    const {
      label, 
      placeholder='', 
      style,  
      name='unique',
      isLoading=false,
      options=[],
      isMulti,
      onChange,
      isDisabled,
      value,
      closeMenuOnSelect=true,
      isClearable,
      async=false,
      isrequiredicon,
      menuIsOpen,
      removeIndicatorsContainer 
    }=this.props
    return(
        <Form.Group controlId={name} style={{...style,marginBottom:10}}>
           {label && <Label>{label} {isrequiredicon ? <span>*</span> : ""}</Label>}

           {async ? 
           <AsyncSelect
            defaultOptions={true}
            cacheOptions={true}
            loadOptions={options}
           isLoading={isLoading}
           placeholder={placeholder}
           styles={CustomStyle}
           isMulti={isMulti}
           value={value}
           isDisabled={isDisabled}
           isClearable={isClearable}
           closeMenuOnSelect={closeMenuOnSelect}
           menuIsOpen={menuIsOpen}
           components={
            removeIndicatorsContainer?{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }:null
          }
           onChange={onChange}
         />
           :<Select
              options={options}
              isLoading={isLoading}
              placeholder={placeholder}
              styles={CustomStyle}
              isMulti={isMulti}
              value={value}
              isDisabled={isDisabled}
              isClearable={isClearable}
              closeMenuOnSelect={closeMenuOnSelect}
              menuIsOpen={menuIsOpen}
              onChange={onChange}
              components={
                removeIndicatorsContainer?{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }:null
              }
            />}
        </Form.Group>
    )
  }
}

export {SelectBox}