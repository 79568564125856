import React, { PureComponent } from "react";
import moment from 'moment'
import styled from "styled-components";
import DatePicker from "react-datepicker";

const Container = styled.div`
  position: relative;
  & input{
    height: calc(1.8125rem + 2px);
    font-size: .875rem;
    background: #fffdec;
    &:focus {
    box-shadow: 0 0 0 1px rgba(0, 123, 255, 0.25);
  }
  }
  & > div{
    width: 100%;
  }
`;

const Label = styled.label`
  font-size: 13px;
  display: block;
  & span {
    color: red;
  }
`;

class DateInput extends PureComponent {
//   state = {
//     open: false
//   };
//   handleChange = (date) => {
//     console.log('data----->>', date)
//     this.setState({startDate: date});
// };

  render() {

    const {
      label,
      isrequiredicon,
      onChange,
      selected,
      style
    } = this.props;
    // console.log('vvvvvv---------->', moment('2020-02-02').isValid())
    return (
      <Container className='form-group' style={style}>
          {label && (
            <Label>
              {label} {isrequiredicon ? <span>*</span> : ""}
            </Label>
          )}
          <DatePicker {...this.props} selected={selected && moment(selected).isValid() ? new Date(selected) : null}  onChange={(e)=>onChange?onChange(moment(e).format('YYYY-MM-DD')):null} dateFormat="dd-MM-yyyy"  className="form-control" />
      </Container>
    );
  }
}

export { DateInput };
