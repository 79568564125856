import React from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

import Aux from "../hoc/_Aux";

// function am4themes_myTheme(target) {
//     if (target instanceof am4core.ColorSet) {
//       target.list = [
//         am4core.color("#DC143C"),
//         am4core.color("#32CD32"),
//         am4core.color("#FF8C00"),
//         am4core.color("#008B8B"),
//         am4core.color("#8A2BE2"),
//         am4core.color("#191970")
//       ];
//     }
//   }


class ColumnChartForIndicators extends React.Component {

    componentDidMount(){
        this.initilaize()    
    }

    
    componentDidUpdate(){
        this.initilaize()    
    }

    initilaize=()=> {
    // am4core.useTheme(am4themes_animated);

    let chart = am4core.create(this.props.id || 'ColumnChartForIndicators', am4charts.XYChart)
    chart.colors.step = 2;
    chart.radius = am4core.percent(100);

// chart.legend = new am4charts.Legend()
// chart.legend.position = 'top'
// chart.legend.paddingBottom = 20
// chart.legend.labels.template.maxWidth = 95

let xAxis = chart.xAxes.push(new am4charts.CategoryAxis())
xAxis.dataFields.category = 'years'
xAxis.renderer.cellStartLocation = 0.1
xAxis.renderer.cellEndLocation = 0.9
xAxis.renderer.grid.template.location = 0;

let yAxis = chart.yAxes.push(new am4charts.ValueAxis());
yAxis.min = 0;

function createSeries(value, name) {
    let series = chart.series.push(new am4charts.ColumnSeries())
    series.dataFields.valueY = value
    series.dataFields.categoryX = 'years'
    series.name = name
    series.columns.template.tooltipText = "{name}: [bold]{valueY}";

    series.events.on("hidden", arrangeColumns);
    series.events.on("shown", arrangeColumns);

    let bullet = series.bullets.push(new am4charts.LabelBullet())
    bullet.interactionsEnabled = false
    bullet.dy = 5;
    bullet.label.text = '{valueY}'
    bullet.label.fontSize = 10
    bullet.label.fill = am4core.color('#ffffff')

    return series;
}

 chart.data = this.props.data ? this.props.data : [
    {
        years: 'Place #1',
        grounded: 40,
        completed: 55,
        occupied: 60
    },
    {
        years: 'Place #2',
        grounded: 30,
        completed: 78,
        occupied: 69
    },
    {
        years: 'Place #3',
        grounded: 27,
        completed: 40,
        occupied: 45
    },
    {
        years: 'Place #4',
        grounded: 50,
        completed: 33,
        occupied: 22
    }
]


createSeries('grounded', 'Grounded');
createSeries('completed', 'Completed');
createSeries('occupied', 'Occupied');

function arrangeColumns() {

    let series = chart.series.getIndex(0);

    let w = 1 - xAxis.renderer.cellStartLocation - (1 - xAxis.renderer.cellEndLocation);
    if (series.dataItems.length > 1) {
        let x0 = xAxis.getX(series.dataItems.getIndex(0), "categoryX");
        let x1 = xAxis.getX(series.dataItems.getIndex(1), "categoryX");
        let delta = ((x1 - x0) / chart.series.length) * w;
        if (am4core.isNumber(delta)) {
            let middle = chart.series.length / 2;

            let newIndex = 0;
            chart.series.each(function(series) {
                if (!series.isHidden && !series.isHiding) {
                    series.dummyData = newIndex;
                    newIndex++;
                }
                else {
                    series.dummyData = chart.series.indexOf(series);
                }
            })
            let visibleCount = newIndex;
            let newMiddle = visibleCount / 2;

            chart.series.each(function(series) {
                let trueIndex = chart.series.indexOf(series);
                let newIndex = series.dummyData;

                let dx = (newIndex - trueIndex + middle - newMiddle) * delta

                series.animate({ property: "dx", to: dx }, series.interpolationDuration, series.interpolationEasing);
                series.bulletsContainer.animate({ property: "dx", to: dx }, series.interpolationDuration, series.interpolationEasing);
            })
        }
    }
}

    }

    componentWillUnmount() {
        if (this.chart) {
            this.chart.dispose();
        }
    }

    render() {
        // console.log('data=====>>',this.props.data)
        return (
            <Aux>
                <div id={this.props.id || "ColumnChartForIndicators"} style={{ width: "100%", height: "180px" }} />
            </Aux>
        );
    }
}

export default ColumnChartForIndicators;