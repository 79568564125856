import React from "react";
import styled,{keyframes} from "styled-components";


const spin = keyframes`
0%   { 
    -webkit-transform: rotate(0deg);  /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(0deg);  /* IE 9 */
    transform: rotate(0deg);  /* Firefox 16+, IE 10+, Opera */
}
100% {
    -webkit-transform: rotate(360deg);  /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(360deg);  /* IE 9 */
    transform: rotate(360deg);  /* Firefox 16+, IE 10+, Opera */
}
`

const Container = styled.div`
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
z-index: 1000;

& .loaderSection{
    position: fixed;
    top: 0;
    width: 50%;
    height: 100%;
    background: rgb(255 255 255 / 87%);
    z-index: 1000;
    -webkit-transform: translateX(0);  /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: translateX(0);  /* IE 9 */
    transform: translateX(0);  /* Firefox 16+, IE 10+, Opera */
}

${props=>props.isLoaded && `
visibility: hidden;
-webkit-transform: translateY(-100%);  /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: translateY(-100%);  /* IE 9 */
        transform: translateY(-100%);  /* Firefox 16+, IE 10+, Opera */
-webkit-transition: all 0.3s 1s ease-out;  
        transition: all 0.3s 1s ease-out;
`}
`

const OrgName=styled.div`
display: block;
position: relative;
left: 50%;
top: 50%;
width: 150px;
height: 0px;
margin: -75px 0 0 -75px;
display: flex;
z-index:9999;
justify-content: center;
align-items: center;
font-size: 13px;
font-weight: 600;
`

const Spring = styled.div`
display: block;
position: relative;
left: 50%;
top: 50%;
width: 150px;
height: 150px;
margin: -75px 0 0 -75px;
border-radius: 50%;
border: 3px solid transparent;
border-top-color: #3498db;

-webkit-animation: ${spin} 2s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
animation: ${spin} 2s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */

z-index: 1001;

&:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #e74c3c;

    -webkit-animation: ${spin} 3s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
    animation: ${spin} 3s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
}

&:after {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #f9c922;

    -webkit-animation: ${spin} 1.5s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
      animation: ${spin} 1.5s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
}

${props=>props.isLoaded && `
opacity: 0;
-webkit-transition: all 0.3s ease-out;  
transition: all 0.3s ease-out;
`}

`

const LeftSide=styled.div`
left: 0;
${props=>props.isLoaded && `
-webkit-transform: translateX(-100%) !important;  /* Chrome, Opera 15+, Safari 3.1+ */
-ms-transform: translateX(-100%) !important;  /* IE 9 */
transform: translateX(-100%) !important;  /* Firefox 16+, IE 10+, Opera */

-webkit-transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1.000) !important;  
transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1.000) !important;
`}
`
const RightSide=styled.div`
right: 0;
${props=>props.isLoaded && `
-webkit-transform: translateX(100%) !important;  /* Chrome, Opera 15+, Safari 3.1+ */
-ms-transform: translateX(100%) !important;  /* IE 9 */
transform: translateX(100%) !important;  /* Firefox 16+, IE 10+, Opera */

-webkit-transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1.000) !important;  
transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1.000)!important;
`}
`


class SutterLoader extends React.Component {
    render() {
        const {isLoaded, loaderText='PMAY-Urban', id,style}=this.props
        return (
            <Container isLoaded={isLoaded} id={id} style={style}>
                <Spring isLoaded={isLoaded}/>
                <OrgName>{loaderText}</OrgName>
                <LeftSide  isLoaded={isLoaded} className="loaderSection"/>                 
                <RightSide  isLoaded={isLoaded} className="loaderSection"/>                 
            </Container>
        );
    }
}

export {SutterLoader};
