import React, { Component } from 'react';
/* Imports */
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_kelly from "@amcharts/amcharts4/themes/kelly";

class StackedandClusteredColumnChart extends Component {

  componentDidMount(){
    this.initGraph()
  }
  componentDidUpdate(){
    this.initGraph()
  }

  initGraph() {
    // Themes begin
    am4core.useTheme(am4themes_kelly);
    // Themes end
    // Create chart
    let chart = am4core.create(this.props.id ||'StackedandClusteredColumnChart', am4charts.XYChart);
    // chart.hiddenState.properties.opacity = 0; // this creates initial fade-in
   // Add data
    chart.data=this.props.graphData

    // Create axes
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "year";
    // categoryAxis.title.text = "Local country offices";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 20;
    categoryAxis.renderer.cellStartLocation = 0.1;
    categoryAxis.renderer.cellEndLocation = 0.9;

    let  valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.title.text = this.props.title || '';

    // Create series
    function createSeries(field, name, stacked) {
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = field;
      series.dataFields.categoryX = "year";
      series.name = name;
      series.columns.template.tooltipText = "{name}: [bold]{valueY}[/]";
      series.stacked = stacked;
      series.columns.template.width = am4core.percent(80);

      
      let valueLabel = series.bullets.push(new am4charts.LabelBullet());
      valueLabel.label.text = "{valueY}";
      valueLabel.label.fontSize = 10;
      valueLabel.label.fill = '#fff';
      // valueLabel.label.horizontalCenter = "middle";
      // valueLabel.valign = "middle";
      valueLabel.locationY = 0.5;


    }
 
    if(this.props.graphType==='employment'){
      createSeries("Direct", "Direct", true);
      createSeries("Indirect", "Indirect", true);
    }else{
    createSeries("Centre", "Centre", true);
    createSeries("State", "State", true);
    createSeries("ULB", "ULB", true);
    createSeries("Beneficiaries", "Beneficiaries", true);
    createSeries("Others", "Others", true);
  }
    
    // Add legend
    chart.legend = new am4charts.Legend();
    chart.legend.markers.template.width=15
    chart.legend.markers.template.height=15
  }

    render() {
        return <div id={this.props.id ||'StackedandClusteredColumnChart'} className={this.props.id ||'StackedandClusteredColumnChart'} style={{width: '100%', height: this.props.height,...this.props.style}} />;
    }
}

export default StackedandClusteredColumnChart;